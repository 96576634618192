export const tooltip = (title: string, rows: { label: string; value: string }[], subtitle?: string) => {
  let body = '';

  rows.forEach((r, i) => {
    body += `
      <tr>
        <td style="font-size: 14px; font-weight: 500; color: #3A3C45;" data-cy="${r.label}">${r.value}</td>
        <td style="text-align: right; color: #828599; font-size: 12px; font-weight: 600;">${r.label}</td>
      </tr>`;

    if (i < rows.length - 1) {
      body += `<tr><td colspan="2" style="color: #E0E5F0;"><hr/></td></tr>`;
    }
  });

  if (subtitle === undefined) {
    return `
    <div style="padding: 10px;  min-width: 175px;" data-cy="sales-tooltip">
      <b style="color: #3A3C45; font-size: 16px; font-weight: 700;">${title}</b>
      <br/><br/>
      <table style="width: 100%">${body}</table>
    </div>`;
  } else {
    return `
    <div style="padding: 10px;  min-width: 175px;" data-cy="sales-tooltip">
    <p style="color: #3A3C45; font-size: 16px; font-weight: 700; margin: 0.25rem auto 0.25rem; padding: 0">
      <b>${title}</b>
    </p>
    <p style="color: #757783; font-size: 13px; font-weight: 400; margin: 0 auto 1rem; padding: 0">
      <b>${subtitle}</b>
    </p>
      <table style="width: 100%">${body}</table>
    </div>`;
  }
};

export const tooltipMini = (label: string, value: string) => {
  return `
  <div style="padding: 5px 10px;" data-cy="mini-tooltip">
    <p style="color: #3A3C45;">
      <b>${value}</b>
    </p>
    <p style="color: #757783;">
      <b>${label}</b>
    </p>
  </div>`;
};
