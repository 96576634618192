import { AccessMetadata, AccessRequestParams } from '@gcv/shared';
import { GcvAdminApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { SnackbarStore } from 'stores/SnackBarStore';

export interface GcvAccessPM {
  isLoading: boolean;
  allAccessRecords: AccessMetadata[];
  clientId?: string;
  clientSecret?: string;
}

@injectable()
export class GcvAccessRepo {
  @inject(GcvAdminApi)
  private gcvAdminApi: GcvAdminApi;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  public gcvAccessProgrammersModel: GcvAccessPM = {
    isLoading: true,
    allAccessRecords: []
  };

  load = action(async () => {
    this.loadAllAccessRecords();
  });

  loadAllAccessRecords = action(() => {
    this.gcvAdminApi
      .getAllAccessMetadata()
      .then((records) => {
        this.updateProgrammersModel({ allAccessRecords: records, isLoading: false });
      })
      .catch(() => {
        this.snackbarStore.showErrorSnackbarMessage('Failed to load Access client information');
      });
  });

  updateProgrammersModel = action((programmersModel: Partial<GcvAccessPM>) => {
    this.gcvAccessProgrammersModel = { ...this.gcvAccessProgrammersModel, ...programmersModel };
  });

  createAccessRecord = action(async (request: AccessRequestParams) => {
    await this.gcvAdminApi.createAccessMetadata(request).then((newRecord) => {
      this.updateProgrammersModel({
        allAccessRecords: [...this.gcvAccessProgrammersModel.allAccessRecords, newRecord.accessMetadata],
        isLoading: false,
        clientSecret: newRecord.secret,
        clientId: newRecord.accessMetadata.label
      });
    });
  });

  rotateApiKey = action(async (client_id: string) => {
    await this.gcvAdminApi
      .rotateAccessApiKey(client_id)
      .then((response) => {
        runInAction(() => {
          this.updateProgrammersModel({
            clientId: response.client_id,
            clientSecret: response.client_secret
          });
        });
      })
      .catch((e) => {
        this.snackbarStore.showErrorSnackbarMessage('Failed to load Access client information');
      });
  });
}
