import { useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { Container, ContainerItem, Header, Text } from 'ui/atoms';
import { Card } from 'ui/organisms';

interface Props {
  icon?: string | React.ReactNode;
  title?: string;
  subTitle?: string | React.ReactNode;
  alignSubTitle?: 'left' | 'right' | 'center';
  dataCy?: string;
  noBorder?: boolean;
  titleFont?: string;
  padding?: string;
  image?: React.ReactNode;
}

export const ZeroState: React.FC<Props> = (props) => {
  const { children, icon, image, subTitle, title, titleFont } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container align="center" dataCy={props.dataCy} justify="center" width="100%" padding={0}>
      <ContainerItem dataCy="zero-state-container" width={'100%'} padding={'0'}>
        <Card noBorder={props.noBorder}>
          <Container column align="center" justify="center" width="100%" padding={props.padding}>
            <ContainerItem dataCy="zero-state-icon">
              {typeof icon === 'string' ? <img src={icon} /> : icon}
            </ContainerItem>

            {title && (
              <ContainerItem textAlign="center" dataCy="zero-state-title" padding="16px 8px 8px 8px">
                <Header
                  lineHeight="21px"
                  content={title}
                  type={isMobile ? 'h4' : titleFont === 'small' ? 'h5' : titleFont === 'medium' ? 'h3' : 'h1'}
                />
              </ContainerItem>
            )}

            {subTitle && typeof subTitle === 'string' ? (
              <ContainerItem dataCy="zero-state-subtitle">
                <Text
                  content={subTitle}
                  sx={{ color: 'text.secondary', textAlign: props.alignSubTitle ?? 'left' }}
                  type={titleFont === 'small' ? 'body3' : titleFont === 'medium' ? 'body2' : undefined}
                />
              </ContainerItem>
            ) : (
              subTitle && <ContainerItem dataCy="zero-state-subtitle">{subTitle}</ContainerItem>
            )}

            {children && <ContainerItem data-cy="zero-state-content">{children}</ContainerItem>}

            {image && (
              <ContainerItem
                flex
                data-cy="zero-state-image"
                align="center"
                justify="center"
                padding="16px 0 0 0"
                width="100%"
              >
                {image}
              </ContainerItem>
            )}
          </Container>
        </Card>
      </ContainerItem>
    </Container>
  );
};
