import { AggregatedPosDataFetcherResults } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { TimePeriod } from 'stores/AppViewStateStore';
import { Row } from 'ui/organisms';
import { GcvAccessDataFetcherHistoryPM, GcvAccessDataFetcherHistoryRepo } from './data-fetcher-history.repo';

export interface GcvAccessDataFetcherHistoryVM {
  isLoading: boolean;

  selectedTimePeriod: string;
  aggregatedDataFetcherHistoryRows: Row<AggregatedPosDataFetcherResults>[];
}

@injectable()
export class GcvAccessDataFetcherHistoryPresenter {
  @inject(GcvAccessDataFetcherHistoryRepo)
  private repo: GcvAccessDataFetcherHistoryRepo;

  constructor() {
    makeAutoObservable(this);
  }

  public dataFetcherHistoryViewModel: GcvAccessDataFetcherHistoryVM = {
    isLoading: false,
    aggregatedDataFetcherHistoryRows: [],
    selectedTimePeriod: ''
  };

  public load = action(async () => {
    observe(this.repo, 'gcvDataFetcherHistoryProgrammersModel', (obj) => {
      const programmersModel = obj.newValue as GcvAccessDataFetcherHistoryPM;

      this.updateViewModel({
        isLoading: programmersModel.isLoading,
        aggregatedDataFetcherHistoryRows: programmersModel.aggregatedHistoryRecords.map((record) => {
          return {
            id: record.posType,
            data: record
          };
        })
      });
    });
  });

  public updateTimeRange = action(async (timeRange: TimePeriod) => {
    this.updateViewModel({ selectedTimePeriod: timeRange.value });
    await this.repo.loadDataFetcherHistory(timeRange.dateRange.start, timeRange.dateRange.end);
  });

  private updateViewModel = action((viewModel: Partial<GcvAccessDataFetcherHistoryVM>) => {
    this.dataFetcherHistoryViewModel = { ...this.dataFetcherHistoryViewModel, ...viewModel };
  });
}
