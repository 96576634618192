import * as React from 'react';
import { CardActions } from '@mui/material';

interface Props {
  noPadding: boolean;
  footerBorder?: boolean;
}

export const CardFooter: React.FC<Props> = (props) => {
  return (
    <CardActions
      // sx={{ mt: 'auto', padding: props.noPadding ? '0' : '1rem 2.25rem 2.25rem 2.25rem', width: '100%' }}
      sx={{
        mt: 'auto',
        padding: props.noPadding ? '0' : '1.5rem 1.5rem 1rem',
        width: '100%',
        borderTop: props.footerBorder ? '1px solid #C6CCDA;' : 'none'
      }}
    >
      {props.children}
    </CardActions>
  );
};
