import { CommentIdComponents, CommentType } from '@gcv/shared';
import { Box } from '@mui/material';
import { ReactComponent as CommentIcon } from 'assets/images/ic-comment.svg';
import { ReactComponent as CommentIconThin } from 'assets/images/ic-comment-thin.svg';
import { useComponent } from 'hooks';
import React from 'react';
import { getCommentStore } from 'stores/CommentStore';

/**
 * Comment badge.
 *
 * The onClick and asyncOnClick properties are callbacks and mutually exclusive. The async
 * variant is given precedence. The component will wait for the async callback to complete
 * before continuing.
 */
interface Props extends Record<string, unknown> {
  dataCy?: string;
  disableAutoload?: boolean;
  expanded?: boolean;
  display?: string;
  onClick?: () => void;
  asyncOnClick?: () => Promise<void>;
  post: {
    idComponents: CommentIdComponents;
    title: string;
    type: CommentType;
  };
  thinIcon?: boolean;
}

export const CommentBadge: React.FC<Props> = useComponent((props) => {
  const commentStore = getCommentStore();

  const [count, setCount] = React.useState<number>(0);

  const postId = commentStore.generatePostId(props.post);

  React.useEffect(() => {
    if (!props.disableAutoload) {
      commentStore.fetchCount([props.post]);
    }
  }, []);

  React.useEffect(() => {
    const postCount = commentStore.countsMap[postId];

    if (postCount) {
      setCount(postCount.total);
    } else {
      commentStore.shouldFetchCount(true);
    }
  }, [commentStore.countsMap[postId]]);

  return (
    <Box
      sx={{
        display: props.display ? props.display : 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center'
      }}
      onClick={async (e) => {
        e.stopPropagation();

        // Allow overriding the click event for such cases as opening drawers
        // and comments at the same time. Should client code need to wait for
        // information before rendering completely, then we need to wait too;
        // otherwise, we will render first, and then re-render.

        if (props.asyncOnClick) {
          await props.asyncOnClick();
        } else if (props.onClick) {
          // Handle the usual case of synchronous onClick.
          props.onClick();
        }

        commentStore.setCurrentPost(props.post);
        commentStore.openComments(true);
      }}
      data-cy={props.dataCy}
    >
      {props.thinIcon ? <CommentIconThin /> : <CommentIcon />}

      <Box
        sx={{
          marginLeft: '4px',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '13px',
          lineHeight: '140%',
          textDecorationLine: 'underline',
          whiteSpace: 'nowrap'
        }}
      >
        {props.expanded ? `${count} ${count === 1 ? 'Comment' : 'Comments'}` : count}
      </Box>
    </Box>
  );
});
