import * as React from 'react';
import { Button, Container, ContainerItem, Dialog, Header, Text } from 'ui';
import { ReactComponent as WarningIcon } from 'assets/images/ic-modal-alert.svg';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: () => void;
}

export const FieldDeleteModal: React.FC<Props> = (props) => {
  return (
    <Dialog noActions title="" isOpen={props.isOpen} handleClose={() => props.setIsOpen(false)} width="459px">
      <Container column padding={0} width="100%">
        <ContainerItem justify="center" padding="0 0 20px 0" width="100%">
          <WarningIcon />
        </ContainerItem>
        <ContainerItem justify="center" padding="0 0 20px 0" width="100%">
          <Header type="h2" content="Confirm Deletion" />
        </ContainerItem>
        <ContainerItem justify="center" padding="0 0 86px 0" width="100%">
          <Text content="Are you sure you want to delete this field?" />
        </ContainerItem>
        <ContainerItem justify="center" width="100%">
          <Button
            label="Delete"
            color="warning"
            onClick={() => {
              props.onSave();
              props.setIsOpen(false);
            }}
            width="335px"
          />
        </ContainerItem>
        <ContainerItem justify="center" width="100%">
          <Button label="Cancel" color="text" onClick={() => props.setIsOpen(false)} width="335px" />
        </ContainerItem>
      </Container>
    </Dialog>
  );
};
