import { GreenCheckAwsEnvironmentName } from 'domain/enums/green-check-aws-environment-name';
import { environment } from 'environments/environment';

export const initialiseLendica = (company_id: string, company_name: string) => {
  const isProduction = environment.env === GreenCheckAwsEnvironmentName.PROD;

  // Lendica does not have an SDK or typscript typings
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  lendica.init(
    {
      // Required partner and user credentials
      partner_name: isProduction ? 'gcv_prod' : 'gcv_demo', // Technology vendor name
      company_id: company_id, // Unique client id in technology vendor's system
      company_name: company_name, // Client's business name
      company_access_token: '' // Token/key to retrieve client data from vendor's API
    },
    {
      // Optional config
      access_type: 'ADMIN', // User access type, currently supporting 'VIEW_ONLY' or 'ADMIN'
      environment: isProduction ? 'PRODUCTION' : 'SANDBOX' // Default environment is sandbox if not specified, currently supporting 'SANDBOX' or 'PRODUCTION'
    }
  );
};

export const hideLendica = () => {
  // Lendica does not have an SDK or typscript typings
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  if (lendica) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    lendica.destroy();
  }
};
