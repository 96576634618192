import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { NotificationPreferences, UserInterests } from '@gcv/shared';
import { UsersApi } from 'api';
import { UserStore } from 'stores/UserStore';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { track } from 'third-party-integrations/pendo';

export interface UserInfo {
  firstName?: string;
  middleInitial?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

export interface PM {
  userInfo: UserInfo;
  notificationPreferences: NotificationPreferences;
  interests: UserInterests[];
}

export interface UserSettings extends PM {}

@injectable()
export class CrbUserSettingsRepo {
  @inject(UserStore) private userStore: UserStore;
  @inject(CrbDispensaryStore) private dispensaryStore: CrbDispensaryStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {} as PM;

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = action(async () => {
    const {
      firstName,
      minitial: middleInitial,
      lastName,
      email,
      phone,
      address,
      city,
      state,
      zipcode,
      interests
    } = { ...this.userStore.user };
    this.updateProgrammersModel({
      userInfo: { firstName, middleInitial, lastName, email, phone, address, city, state, zipcode },
      notificationPreferences: this.userStore.user.preferences.notifications,
      interests: interests ? interests : []
    });
  });

  private userSettingsAreEqual = (a: UserInterests[], b: UserInterests[]): boolean => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (const a_interest of a) {
      if (!b.includes(a_interest)) {
        return false;
      }
    }
    return true;
  };

  public updateUserSettings = async (settings: UserSettings) => {
    const userIdDetails: {
      firstName?: string;
      minitial?: string;
      lastName?: string;
      email?: string;
      phone?: string;
      address?: string;
      city?: string;
      state?: string;
      zipcode?: string;
    } = { ...settings.userInfo };

    userIdDetails.minitial = settings.userInfo.middleInitial;

    const usersApi = new UsersApi();

    let user = await usersApi.updateUserIdentification(this.userStore.user.id, userIdDetails);
    const preferences = await usersApi.updateUserPreferences(this.userStore.user.id, {
      notifications: settings.notificationPreferences
    });
    if (!user.interests || !this.userSettingsAreEqual(user.interests, settings.interests)) {
      user = await usersApi.updateUserInterests(this.userStore.user.id, settings.interests);
      track('CRB | Marketplace | Change Interests Save', {
        user_id: user.id,
        crb_name: this.dispensaryStore.currentDispensary.name,
        [UserInterests.AchAndWires]: settings.interests.includes(UserInterests.AchAndWires),
        [UserInterests.BillPay]: settings.interests.includes(UserInterests.BillPay),
        [UserInterests.BusinessBanking]: settings.interests.includes(UserInterests.BusinessBanking),
        [UserInterests.BusinessInsurance]: settings.interests.includes(UserInterests.BusinessInsurance),
        [UserInterests.CashLogistics]: settings.interests.includes(UserInterests.CashLogistics),
        [UserInterests.CommercialLoans]: settings.interests.includes(UserInterests.CommercialLoans),
        [UserInterests.PayrollAndHr]: settings.interests.includes(UserInterests.PayrollAndHr),
        [UserInterests.Payments]: settings.interests.includes(UserInterests.Payments)
      });
    }

    user.preferences = preferences;

    if (user.id === this.userStore.user.id) {
      this.userStore.updateUser(user);
    }

    this.dispensaryStore.updateDispensaryStaff(user);

    return this.updateProgrammersModel(settings);
  };
}
