import 'reflect-metadata';
import { CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import { Provider as RollbarProvider } from '@rollbar/react'; // <-- Provider imports 'rollbar' for us
import { Amplify, API, Auth, Storage } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import { useScript } from 'hooks';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import GA from 'third-party-integrations/analytics';
import { observer } from 'mobx-react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { useIdleTimer } from 'react-idle-timer';
import { BrowserRouter as Router } from 'react-router-dom';
import { getModalStore } from 'stores/ModalStore';
import { rollbarConfig } from 'third-party-integrations/rollbar';
import { Dialog, Snackbar } from 'ui';
import { IdleUserModal } from 'ui/organisms/IdleUserModal/idle-user-modal.organism';
import { environment } from './environments/environment';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { getSnackbarStore } from './stores/SnackBarStore';
import AppRouter from './ui/routing/router';
import { theme } from './ui/theme';
import { SavePromptContextProvider, ButtonOverlayContextProvider } from './app-context';
import { container, createContainer, InjectionProvider } from 'ioc';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { getEndpoints } from 'api/api-util/endpoints';
import { setRedirectUrl } from 'util/auth0-storage.util';
import { getUserStore } from 'stores/UserStore';
import { Messages } from 'ui/organisms/Messages/messages.component';

const auth0Config = {
  ...environment.auth0Config,
  redirectUri: window.location.origin,
  skipRedirectCallback: window.location.pathname === '/secure/crb/company'
} as Auth0ProviderOptions;

Amplify.configure(environment.amplify);
Auth.configure(environment.amplify);
AWS.config.region = environment.amplify.aws_cognito_region;
Storage.configure({ region: environment.amplify.aws_cognito_region });

const Main = () => {
  const [idleModalOpen, setIdleModalOpen] = React.useState(false);

  API.configure({ endpoints: Object.values(getEndpoints(undefined)) });

  // Store URL to handle refresh and redirect after login
  const path = window.location.pathname + window.location.search;
  if (path.includes('/secure/')) {
    setRedirectUrl(path);
  }

  // @ts-expect-error hsConversationsSettings is required by hubspot
  window.hsConversationsSettings = {
    loadImmediately: false,
    inlineEmbedSelector: '#hubspot-live-chat'
  };

  // hubspot form embed script
  useScript('//js.hs-scripts.com/5237927.js', 'hs-script-loader');

  const handleOnIdle = async () => {
    if (getUserStore().isLoggedIn) {
      setIdleModalOpen(true);
    }
  };

  const handleOnActive = () => {
    if (idleModalOpen) {
      setIdleModalOpen(false);
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 min
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  });

  return (
    <React.Suspense fallback={<LinearProgress />}>
      <Auth0Provider {...auth0Config}>
        <ThemeProvider theme={theme}>
          <InjectionProvider container={container}>
            <SavePromptContextProvider>
              <ButtonOverlayContextProvider>
                <CssBaseline />
                <Messages />

                <Router
                  getUserConfirmation={() => {
                    // Empty callback to block the default browser prompt. We use this
                    // for our SaveChangesModal component.
                  }}
                >
                  {GA.init() && <GA.RouteTracker />}
                  {idleModalOpen && <IdleUserModal isOpen={idleModalOpen} setIsOpen={setIdleModalOpen} />}
                  <AppRouter />
                </Router>
              </ButtonOverlayContextProvider>
            </SavePromptContextProvider>
          </InjectionProvider>
        </ThemeProvider>
      </Auth0Provider>
    </React.Suspense>
  );
};

// instantiate ioc container
createContainer('prod');

// render the app
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: environment.gcvConfig.launchDarklyClientSideId,
    user: {
      anonymous: true
    }
  });

  ReactDOM.render(
    <LDProvider>
      <RollbarProvider config={rollbarConfig}>
        <Main />
      </RollbarProvider>
    </LDProvider>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
