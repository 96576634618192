import { Box } from '@mui/material';
import { ReportType } from 'api';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { ContainerItem, InputSelect } from 'ui';
import ReportStatus from './reports-status';

interface Props {
  reportType: ReportType;
  form: UseFormReturn<FieldValues, any>;
}

const SingleStatusReportsDropdown = (props: Props) => {
  const [reportStatus, setReportStatus] = useState<ReportStatus>();

  useEffect(() => {
    const status = new ReportStatus(props.reportType);

    if (status.getReportType() !== ReportType.DEPOSITS) {
      props.form.setValue('status', status.getReportOptions()[0].value);
    }

    setReportStatus(status);
  }, [props.reportType]);

  const placeholder = useMemo(() => {
    // At the time of this writing, the reports that use a single select drop-down,
    // other than DEPOSITS have an actual status of "All". In these cases, we use
    // the existing status as a default value. (For deposits, we use a placeholder
    // for an empty value, and in that case gather all the statuses to send).
    //
    // This trick allows us to use 'defaultValue' with an uncontrolled component
    // without React barking at us. We set a unique 'key' property. the trick
    // comes from here:
    //
    // https://stackoverflow.com/a/64821372
    return reportStatus && reportStatus.getReportType() !== ReportType.DEPOSITS
      ? reportStatus.getReportOptions()[0].value
      : '';
  }, [reportStatus]);

  return (
    <ContainerItem width="100%" padding={'0'}>
      <Box
        sx={{
          mt: '1rem',
          width: '100%',
          height: 'auto',
          position: 'relative'
        }}
      >
        <InputSelect
          name="status"
          label="Status"
          background="white"
          options={reportStatus?.getReportOptions() ?? []}
          defaultValue={placeholder}
          key={`single-status-${placeholder}`}
          emptyLabel={reportStatus?.getReportType() === ReportType.DEPOSITS ? 'All' : undefined}
          {...props.form}
        />
      </Box>
    </ContainerItem>
  );
};

export default SingleStatusReportsDropdown;
