import React from 'react';

interface Props {
  status?: 'green_checked' | 'warning' | 'red_flag' | 'unknown' | 'unchecked';
}

const colorMap = {
  green_checked: '#02b45b',
  warning: '#F49B20',
  red_flag: '#FF4447',
  unknown: '#f2f2f2',
  unchecked: '#5A5E6B'
};
const backgroundColorMap = {
  green_checked: 'rgba(0,178,89,.2)',
  warning: 'rgba(244,155,32,.2)',
  red_flag: 'rgba(255,68,71,.2)',
  unknown: 'rgba(242,242,242,.2)',
  unchecked: 'rgba(90,94,107,.2)'
};

const iconTypeMap = {
  green_checked: 'check_circle',
  warning: 'error',
  red_flag: 'error',
  unknown: 'error',
  unchecked: 'help_outline'
};

const viewValueMap = {
  green_checked: 'Verified',
  warning: 'Warning',
  red_flag: 'Unverified',
  unknown: 'Unknown',
  unchecked: 'Unchecked'
};

export const SaleStatusIndicator = (props: Props) => {
  if (!props.status) {
    return null;
  }

  const color = colorMap[props.status];
  const backgroundColor = backgroundColorMap[props.status];
  const iconType = iconTypeMap[props.status];
  const viewValue = viewValueMap[props.status];

  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: 'auto',
        backgroundColor: backgroundColor,
        borderRadius: '18px',
        padding: '.5rem'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <span className="material-icons" style={{ color: color }}>
          {iconType}
        </span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <span style={{ color }} data-cy="status-text">
          {viewValue}
        </span>
      </div>
    </span>
  );
};
