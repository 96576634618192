import { Box } from '@mui/material';
import { CommentsTabType } from '../comments.model';
import { Badge, Container, Label } from 'ui';
import { useInjection } from 'ioc';
import { CommentStore } from 'stores/CommentStore';
import { CommentsPresenter } from '../comments.presenter';

interface TabProps {
  title: string;
  type: CommentsTabType;
}

export const CommentsTabsHeader: React.FC<TabProps> = (props) => {
  const commentStore = useInjection(CommentStore);
  const presenter = useInjection(CommentsPresenter);

  const isActive = commentStore.commentsOpen && commentStore.activeTab === props.type;

  return (
    <Box
      sx={{
        borderBottom: (theme) => (isActive ? `2px solid ${theme.palette.primary.main}` : '2px solid white'),
        cursor: 'pointer'
      }}
      onClick={() => commentStore.activateTab(props.type)}
    >
      <Container align="center">
        <div style={{ height: '24px', alignItems: 'center', display: 'flex' }}>
          <Label
            content={props.title}
            type={'subtitle1'}
            sx={{
              color: (theme) => (isActive ? theme.palette.text.primary : theme.palette.text.secondary)
            }}
          />
        </div>

        {props.type === 'comments' && presenter.activeCommentsCount > 0 ? (
          <Box
            sx={{
              marginLeft: '17px',
              display: 'inline-block',
              '& .MuiBadge-root': {
                marginTop: '-4px'
              }
            }}
          >
            <Badge
              badgeContent={presenter.activeCommentsCount as number}
              color={isActive ? 'primary' : 'info'}
            ></Badge>
          </Box>
        ) : null}
      </Container>
    </Box>
  );
};
