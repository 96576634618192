import * as React from 'react';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/core/SwitchUnstyled';
import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';

import { theme } from 'ui/theme';
import { Container, ContainerItem, Icon, Tooltip } from 'ui';

const Root = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 33px;
  height: 18px;
  cursor: pointer;
  border: 2px solid rgba(1, 1, 1, 0);

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 10px;
    height: 10px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} {
    outline: 2px solid ${theme.palette.secondary.main};
    border-radius: 10px;
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 17px;
      top: 2px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: ${theme.palette.primary.main};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

interface SwitchProps {
  checked: boolean;
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string | JSX.Element;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  readOnly?: boolean;
  viewOnly?: boolean;
  justifyContent?: 'left' | 'center' | 'right' | 'space-between' | 'space-around';
  tooltip?: string | React.ReactFragment;
  dataCy?: string;
}

/**
 * Return controlled Toggle component.
 *
 * A Toggle component is a stand-alone version of the Switch component. It is associated with
 * no HTML form, and its state is controlled manually by client code, using the "checked" and
 * "onClick" properties. Clients should update state as appropriate via "onClick".
 *
 * For an uncontrolled component to use with forms, see the "Switch" molecule component.
 *
 * @param props properties to configure the widget.
 * @returns toggle component.
 */
export const Toggle: React.FC<SwitchProps> = (props) => {
  const labelPlacement = props.labelPlacement ? props.labelPlacement : 'end';

  // The onClick event gets passed down automatically when we unpack the props with the spread operator.
  return (
    <Container padding="0" margin="0">
      <ContainerItem padding="0" margin="0" flex>
        <FormControlLabel
          control={
            <SwitchUnstyled
              {...props}
              component={Root}
              disabled={props.readOnly}
              checked={props.checked}
              data-cy="switch"
            />
          }
          labelPlacement={labelPlacement}
          sx={{
            fontFamily: 'lato',
            fontSize: '16px',
            fontWeight: 400,
            width: 'fit-content'
          }}
          disabled={props.readOnly}
          label={props.label || ''}
          data-cy="label"
        />
      </ContainerItem>
      {props.tooltip ? (
        <ContainerItem padding="0" align="center" flex margin="0 0 0 -10px">
          <Tooltip variant="text" title={props.tooltip} placement="right">
            <Icon type="help" color="primary" />
          </Tooltip>
        </ContainerItem>
      ) : null}
    </Container>
  );
};
