import * as React from 'react';
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';

interface FormProps {
  handleSubmit?: UseFormHandleSubmit<FieldValues>;
  onSubmit?: (data?: FieldValues) => void;
  dataCy?: string;
}

export const Form: React.FC<FormProps> = (props) => {
  return (
    <form
      noValidate
      data-cy={props.dataCy}
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();

        if (props.onSubmit && props.handleSubmit) {
          props.handleSubmit(props.onSubmit)();
        } else if (props.onSubmit) {
          props.onSubmit();
        }
      }}
      style={{ width: '100%', minHeight: '1px' }}
    >
      {props.children}
    </form>
  );
};
