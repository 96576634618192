import { useAuth0 } from '@auth0/auth0-react';
import { GreenCheckAwsEnvironmentName, PayqwickUserSession } from '@gcv/shared';
import { useRollbar } from '@rollbar/react';
import ApiAuth0Session, { Auth0SessionToken, GCApplicationAccess } from 'api/api-util/api-auth0-session';
import { environment } from 'environments/environment';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { getCommentStore } from 'stores/CommentStore';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { initLogRocket } from 'third-party-integrations/log-rocket';
import { validateRedirectUri } from 'ui/apps/shared/auth0/auth0-login';
import { Spinner } from 'ui/atoms';
import { initializeLoggedInUser } from 'ui/routing/router-util';
import { RouteType, routes } from 'ui/routing/routes';
import { getRedirectURICache } from 'util/auth0-storage.util';
import { getUserStore } from '../../stores/UserStore';
import { CrbRoute } from './route-crb';
import { FiRoute } from './route-fi';
import { GcvRoute } from './route-gcv';
import { UsersApi } from 'api';
import { getAppViewStateStore } from 'stores/AppViewStateStore';

const NotFound = React.lazy(() => import('../apps/shared/not-found'));
const Login = React.lazy(() => import('../apps/shared/auth0/auth0-login'));

export interface RouteProps extends Record<string, unknown> {
  path: string;
  exact: boolean;
  routeType: RouteType;
  component?: React.FC;
}

const AppRouter = () => {
  const history = useHistory();
  const rollbar = useRollbar();

  const userStore = getUserStore();
  const userApi = new UsersApi();
  const commentStore = getCommentStore();
  const snackbarStore = getSnackbarStore();

  const [isLoggedInUserLoading, setIsLoggedInUserLoading] = React.useState(true);
  const [isOrgLoading, setIsOrgLoading] = React.useState(false);

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const location = useLocation();

  const loadLoggedInUser = (userId: string) => {
    if (userId && !userStore.isLoggedIn) {
      userStore
        .load(userId)
        .then((user) => {
          if (user.invitation_status === 'archived') {
            snackbarStore.showErrorSnackbarMessage('Something went wrong. We were unable to log you in.');
            history.push('/logout');
          } else {
            getUserStore().isLoggedIn = true;
          }
          setIsLoggedInUserLoading(false);
        })
        .catch((e) => {
          setIsLoggedInUserLoading(false);
          setIsOrgLoading(false);
          snackbarStore.showErrorSnackbarMessage(
            'There was an issue logging in. Please contact support if the problem persists.'
          );
          history.push('/error');
        });
    }
  };

  const initializeUser = (token: Auth0SessionToken) => {
    ApiAuth0Session.updateToken(token);
    if (location.pathname === '/sso') {
      ApiAuth0Session.selectedApplication = ApiAuth0Session.userAccess;
    }

    // We update the token earlier this this function, ensuring userIdToken is populated
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const auth0Subject = ApiAuth0Session.userIdToken!.sub!;

    // Format: "auth0|<user_id>". Users in GCV / PQ
    if (auth0Subject.includes('auth0|')) {
      // else get user by auth0, that call does the create if not exist
      const userId = auth0Subject.split('|')[1];
      loadLoggedInUser(userId);
    } else {
      // Format: "oidc|Berkshire|00u9mkb3h53KpRsfo4x7" Users from 3rd party bank
      userApi.obtainSsoUser(auth0Subject).then((ssoUser) => {
        loadLoggedInUser(ssoUser.id);
      });
    }
  };

  const cacheRedirectUrl = () => {
    const redirect_uri = new URLSearchParams(location.search).get('redirect_uri');
    if (redirect_uri && location.pathname === '/authorize' && validateRedirectUri(redirect_uri)) {
      getRedirectURICache().cacheRedirectURI(redirect_uri);
    }

    const returnTo_uri = new URLSearchParams(location.search).get('returnTo');
    if (returnTo_uri && location.pathname === '/logout' && validateRedirectUri(returnTo_uri)) {
      getRedirectURICache().cacheReturnToURI(returnTo_uri);
    }
  };

  React.useEffect(() => {
    // reset the current comment if comments are not open on page navigation
    return () => {
      if (!commentStore.commentsOpen) {
        commentStore.setCurrentPost(null);
      }
    };
  }, [location]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isInCypressTestMode = environment.env !== GreenCheckAwsEnvironmentName.PROD && window.Cypress;

  if (isInCypressTestMode) {
    // When testing via Cypress, use programmatic login
    // https://docs.cypress.io/guides/end-to-end-testing/auth0-authentication#Adapting-the-front-end
    React.useEffect(() => {
      const auth0 = JSON.parse(localStorage.getItem('auth0Cypress')!);
      if (auth0) {
        initializeUser(auth0.body);
        setIsOrgLoading(true);
      } else {
        setIsLoggedInUserLoading(false);
        history.push('/');
      }
    }, []);
  } else {
    React.useEffect(() => {
      if (isLoading) {
        return;
      }

      if (window.location.href.indexOf('logout') > -1) {
        cacheRedirectUrl();
        setIsLoggedInUserLoading(false);
        return;
      }

      if (!isLoading && isAuthenticated) {
        getAccessTokenSilently({ detailedResponse: true }).then((token) => {
          initializeUser(token);
        });
      } else {
        cacheRedirectUrl();
        setIsLoggedInUserLoading(false);
        history.push('/');
      }
    }, [isAuthenticated, isLoading]);
  }

  React.useEffect(() => {
    const loadData = async () => {
      const load = async (url: URL) => {
        // Call the get pq user session endpoint GET `/auth/pq-user-session`
        // Response has `sid` and `state` that we need to add as query params
        // TODO come back and remove session token properties once released, should only send sid and state
        const pqUserSession: PayqwickUserSession = await userApi.getPqUserSession();
        // If the backend is returning the sid and state then use then, otherwise use the old components
        if (pqUserSession && pqUserSession.sid && pqUserSession.state) {
          /** Delete any existing state or sid query param */
          url.searchParams.delete('state');
          url.searchParams.delete('sid');
          /** Add the query params from the pq api */
          url.searchParams.append('sid', pqUserSession.sid);
          url.searchParams.append('state', pqUserSession.state);
        } else {
          Object.entries(ApiAuth0Session.sessionToken).forEach(([key, value]) => {
            url.searchParams.append(key, value.toString());
          });
        }

        const angularUrl = url.toString().replace('/%23/', '/#/');
        console.log(`Redirecting to: ${angularUrl}`);
        setIsLoggedInUserLoading(false);
        window.location.replace(angularUrl);
      };

      if (userStore.isLoaded && !isLoading) {
        if (ApiAuth0Session.selectedApplication === GCApplicationAccess.All) {
          // If the user has access to both, redirect to CYOA.
          setIsLoggedInUserLoading(false);
          history.push('/sso');
        } else if (ApiAuth0Session.selectedApplication === GCApplicationAccess.PQ) {
          const cache = getRedirectURICache();
          // If the user only has access to PQ, send them there, along with the token.
          const url = cache.getPayQwickRedirectURI();
          cache.clearCache();
          await load(url);
        } else if (ApiAuth0Session.selectedApplication === GCApplicationAccess.GCV) {
          initializeLoggedInUser(history, userStore, setIsOrgLoading, location.pathname);
        }
      }
    };
    loadData();
  }, [userStore.isLoaded, ApiAuth0Session.selectedApplication]);

  React.useEffect(() => {
    initLogRocket(rollbar);
  }, []);

  if (isLoggedInUserLoading || userStore.isLoading || isOrgLoading) {
    return (
      <Spinner
        text={
          isLoggedInUserLoading || userStore.isLoading ? 'Logging in...' : 'Loading business information...'
        }
      />
    );
  }

  const routesRender =
    (path: string) =>
    (RouteType: RouteType): React.ReactNode => {
      let RouteComponent: (props: RouteProps) => JSX.Element;

      switch (RouteType.type) {
        case 'crb': {
          RouteComponent = (props: RouteProps) => <CrbRoute {...{ ...props }} />;
          break;
        }
        case 'fi': {
          RouteComponent = (props: RouteProps) => <FiRoute {...props} />;
          break;
        }
        case 'gcv': {
          RouteComponent = (props: RouteProps) => <GcvRoute {...props} />;
          break;
        }
        default: {
          RouteComponent = (props: RouteProps) => <Route {...props} />;
        }
      }

      const newPath = path + '/' + RouteType.path;

      return RouteType?.children ? (
        <Route
          key={newPath}
          path={newPath}
          strict
          render={({ match: { path } }) =>
            RouteType.wrapperComponent ? (
              <RouteType.wrapperComponent key={newPath}>
                <Switch>
                  {RouteType.component ? (
                    <RouteComponent
                      key={newPath}
                      path={newPath}
                      component={RouteType.component}
                      exact
                      routeType={RouteType}
                    />
                  ) : null}
                  {RouteType.children?.map(routesRender(path))}
                  <Route component={NotFound} />
                </Switch>
              </RouteType.wrapperComponent>
            ) : (
              <Switch>
                {RouteType.component ? (
                  <RouteComponent
                    key={newPath}
                    path={newPath}
                    component={RouteType.component}
                    exact
                    routeType={RouteType}
                  />
                ) : null}
                {RouteType.children?.map(routesRender(path))}
                <Route component={NotFound} />
              </Switch>
            )
          }
        />
      ) : RouteType.component ? (
        <RouteComponent
          key={newPath}
          path={newPath}
          component={RouteType.component}
          exact
          routeType={RouteType}
        />
      ) : null;
    };

  return (
    <Switch>
      <>{routes.map(routesRender(''))}</>
      <Route component={Login} path={''} exact />
      <Route component={Login} path={'/login'} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default observer(AppRouter);
