import {
  BankCustomFields,
  CustomRequirement,
  CustomRequirementResult,
  CrbServiceProvider,
  Dispensary,
  DocumentChecker,
  DocumentUpload,
  DueDiligenceFulfillment,
  InviteNewUserRequest,
  LicenseDataMap,
  RequirementReference,
  TemplateResponse,
  TemplateResultResponse,
  User,
  UserIdentification,
  IANATimezones,
  OnboardingDocumentRequirement,
  CreateDocumentsRequest,
  LicenseDocumentDataRequest,
  DueDiligenceDocument
} from '@gcv/shared';
import { injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { FieldValues } from 'react-hook-form';
import { DispensariesApi, DocumentsApi, TemplateApi, UsersApi } from 'api';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { getUserStore } from 'stores/UserStore';
import { getSnackbarStore, SnackbarSeverity } from 'stores/SnackBarStore';
import { getCrbBankStore } from 'stores/CrbBankStore';
import { getComplianceStore } from 'stores/ComplianceStore';
import { getObjectMap } from 'util/objectUtils';
import { uploadOrgDocumentToS3, uploadUserDocumentToS3 } from 'util/s3.util';
import { DateTimeHelpers } from 'util/dateTime.util';
import JSZip from 'jszip';
import { environment } from 'environments/environment';
import { getMimeType, openFileInNewWindow } from 'util/files.util';
import FileSaver from 'file-saver';
import { AdditionalInfoUtil } from 'util/additional-info.util';

export interface PM {
  loading: boolean;
  onboardingTemplate: TemplateResponse;
  onboardingResult: TemplateResultResponse;
  allDocumentsMap: { [id: string]: DocumentUpload };
  sharedRootDocs: {
    requirement: OnboardingDocumentRequirement;
    ddDocuments: DueDiligenceDocument[];
    documents: DocumentUpload[];
  }[];
  dispensary: Dispensary;
  dispensaryIsPlantTouching: boolean;
  dispensaryStaff: User[];
  provider: CrbServiceProvider;
  idFront: DocumentUpload | undefined;
  idBack: DocumentUpload | undefined;
  user: User;
  updatedTemplate: TemplateResultResponse;
  dueDiligenceComplete: {
    fulfillmentMap: DueDiligenceFulfillment;
    fulfilled: boolean;
  };
  licenseDataMap: LicenseDataMap;
  isConnectedToPayqwick: boolean;
}

@injectable()
export class OnboardingRepo {
  constructor() {
    makeAutoObservable(this);
  }

  dispensaryStore = getCrbDispensaryStore();
  snackbarStore = getSnackbarStore();
  crbBankStore = getCrbBankStore();
  userStore = getUserStore();
  complianceStore = getComplianceStore();

  dispensariesApi = new DispensariesApi();
  documentsApi = new DocumentsApi();
  usersApi = new UsersApi();
  templateApi = new TemplateApi();

  programmersModel: PM = {
    loading: false,
    onboardingTemplate: {} as TemplateResponse,
    onboardingResult: {} as TemplateResultResponse,
    allDocumentsMap: {},
    dispensary: {} as Dispensary,
    dispensaryIsPlantTouching: false,
    dispensaryStaff: [],
    provider: {} as CrbServiceProvider,
    idFront: undefined,
    idBack: undefined,
    user: {} as User,
    updatedTemplate: {} as TemplateResultResponse,
    dueDiligenceComplete: { fulfillmentMap: {}, fulfilled: false },
    licenseDataMap: {} as LicenseDataMap,
    isConnectedToPayqwick: false,
    sharedRootDocs: []
  };

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  loadOnboarding = async (templateId: string, templateResultId: string, bankId: string) => {
    try {
      const updatedDispensary = await this.dispensariesApi.getDispensary(
        this.dispensaryStore.currentDispensary.id
      );
      const onboardingTemplateResult = await this.templateApi.getCrbTemplateResult(
        updatedDispensary.id,
        templateId,
        templateResultId
      );
      const onboardingTemplate = await this.templateApi.getBankTemplate(
        bankId,
        templateId,
        onboardingTemplateResult.template_version ?? 'latest'
      );
      const dueDiligenceComplete = DocumentChecker.getDispensaryDueDiligenceFulfillment(
        this.dispensaryStore.currentDispensary
      );
      const sharedRootDocs = await this.getSharedDcouments(updatedDispensary.id);
      this.updateProgrammersModel({
        onboardingTemplate: onboardingTemplate,
        dueDiligenceComplete: dueDiligenceComplete,
        dispensary: updatedDispensary,
        dispensaryIsPlantTouching: this.dispensaryStore.currentDispensaryIsPlantTouching,
        dispensaryStaff: this.dispensaryStore.currentDispensaryStaff,
        provider: this.crbBankStore.banks[bankId],
        onboardingResult: onboardingTemplateResult,
        licenseDataMap: this.complianceStore.licenseDataMap,
        user: this.userStore.user,
        isConnectedToPayqwick: this.crbBankStore.isConnectedToPayqwick,
        sharedRootDocs
      });
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('There was a problem loading onboarding template');
    }
  };

  submitOnboardingTemplateResults = async () => {
    const updatedTemplate = await this.templateApi.completeCrbOnboardingTemplate(
      this.programmersModel.dispensary.id,
      this.programmersModel.onboardingTemplate.template_id
    );
    this.programmersModel.updatedTemplate = JSON.parse(JSON.stringify(updatedTemplate));
    Object.keys(this.crbBankStore.banks).map((providerId) => {
      if (
        this.crbBankStore.banks[providerId].templates.value.onboarding.template_id ===
        updatedTemplate.template_id
      ) {
        this.crbBankStore.banks[providerId].templates.value.onboarding.status = updatedTemplate.status;
      }
    });
  };

  updateBusinessDetails = async (data: FieldValues, onNext: () => void) => {
    if (data.sameMailingAddress) {
      data.mailingCity = data.city;
      data.mailingPostalCode = data.postalCode;
      data.mailingState = data.state;
      data.mailingStreetAddress = data.streetAddress;
    }

    await this.dispensariesApi
      .updateDispensaryBusinessDetails(this.dispensaryStore.currentDispensary.id, data)
      .then((updatedDispensary) => {
        this.updateDispensaryStore(updatedDispensary);
        onNext();
        this.snackbarStore.showSnackbar(
          SnackbarSeverity.Success,
          'Business details have been updated successfully.'
        );
      })
      .catch(() => {
        this.snackbarStore.showSnackbar(
          SnackbarSeverity.Error,
          'There was an issue updating business details'
        );
      });
  };

  autoSaveBusinessDetails = async (data: FieldValues) => {
    try {
      if (data.sameMailingAddress) {
        data.mailingCity = data.city;
        data.mailingPostalCode = data.postalCode;
        data.mailingState = data.state;
        data.mailingStreetAddress = data.streetAddress;
      }

      const dispensary = await this.dispensariesApi.updateDispensaryBusinessDetails(
        this.dispensaryStore.currentDispensary.id,
        data
      );
      return dispensary;
    } catch (error) {
      console.log('Issue auto saving business details', error);
    }
  };

  completeDispensaryAccountOwners = async (onNext: () => void) => {
    try {
      onNext();
      this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'Account owners have been set successfully.');
    } catch (e) {
      this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'There was an issue updating account owners.');
    }
  };

  updateOperationalDetails = async (data: FieldValues, onNext: () => void) => {
    try {
      await this.dispensariesApi
        .updateDispensaryOperationalDetails(this.dispensaryStore.currentDispensary.id, data)
        .then((disp) => {
          this.updateDispensaryStore(disp);
          onNext();
          this.snackbarStore.showSnackbar(
            SnackbarSeverity.Success,
            'Operational details have been updated successfully.'
          );
        });
    } catch (e) {
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'There was an issue updating operational details.'
      );
    }
  };

  autoSaveOperationalDetails = async (data: FieldValues) => {
    const dispensary = await this.dispensariesApi.updateDispensaryOperationalDetails(
      this.dispensaryStore.currentDispensary.id,
      data
    );
    this.programmersModel.dispensary = dispensary;
    return dispensary;
  };

  updateDispensaryBankCustomFields = async (
    data: Record<string, unknown>,
    formId: string
  ): Promise<Dispensary | undefined> => {
    try {
      const disp = await this.dispensariesApi.updateDispensaryBankCustomFields(
        this.dispensaryStore.currentDispensary.id,
        formId,
        data
      );
      this.updateDispensaryStore(disp);
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Success,
        'Additional information has been updated successfully.'
      );
      return disp;
    } catch (e) {
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'There was an issue updating additional information.'
      );
    }
  };

  updateDispensaryStore = (dispensary?: Dispensary) => {
    if (dispensary) {
      this.updateProgrammersModel({ dispensary: dispensary });
    }
  };

  //following a similar pattern to initCrb, one of the functions called on refresh that
  //appears to remediate the current dispensary in the store being outdated after completing Onboarding.
  //presenter has function updateDispensaryStore that uses the dispensary in the viewmodel but
  //that disp object is also out of date and therefor not helpful for this goal.
  refreshDispensaryStoreDispensary = async () => {
    const currentDispensaryId = this.dispensaryStore.currentDispensary.id;
    await this.dispensaryStore.loadDispensaries([currentDispensaryId], [], this.userStore.user.id);
    this.dispensaryStore.updateDispensary(this.dispensaryStore.dispensaries[currentDispensaryId]);
  };

  getCrbDocuments = async () => {
    const documents = await this.documentsApi.getDocuments(this.dispensaryStore.currentDispensary.id);
    const documentsMap = getObjectMap(documents, 'id');
    this.programmersModel.allDocumentsMap = documentsMap;
  };

  uploadOnboardingTemplateDocument = async (
    requirementId: string,
    fieldId: string,
    file: File
  ): Promise<DocumentUpload | undefined> => {
    try {
      if (this.programmersModel.onboardingTemplate) {
        const docId = await uploadOrgDocumentToS3(file, this.dispensaryStore.currentDispensary.id);
        const document = await this.documentsApi.putDocument(
          file.name,
          docId,
          this.dispensaryStore.currentDispensary.id
        );

        const updatedDocMap = {
          ...this.programmersModel.allDocumentsMap
        };
        updatedDocMap[document.id] = document;
        this.programmersModel.allDocumentsMap = updatedDocMap;

        return document;
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not upload the file');
    }
  };

  deleteOnboardingTemplateDocument = async (requirementId: string, fieldId: string, file: DocumentUpload) => {
    try {
      if (this.programmersModel.onboardingTemplate) {
        await this.documentsApi.deleteDocument(file.org_id, file.id);

        const updatedDocMap = {
          ...this.programmersModel.allDocumentsMap
        };
        delete updatedDocMap[file.id];
        this.programmersModel.allDocumentsMap = updatedDocMap;
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not delete the file');
    }
  };

  updateRequirementResponse = async (requirementId: string, fieldId: string, value: any) => {
    try {
      if (this.programmersModel.onboardingTemplate) {
        const reqResultIndex = this.programmersModel.onboardingResult.custom_requirement_result.findIndex(
          (item) => item.requirement_id === requirementId
        );
        if (reqResultIndex >= 0) {
          const updatedResponses = {
            ...this.programmersModel.onboardingResult.custom_requirement_result[reqResultIndex].custom_fields
              .responses
          } as Record<string, any>;
          if (value) {
            updatedResponses[fieldId] = value;
          } else {
            delete updatedResponses[fieldId];
          }

          const updatedRequirementResponse = await this.templateApi.updateCrbTemplateRequirement(
            this.dispensaryStore.currentDispensary.id,
            this.programmersModel.onboardingTemplate.bank_id,
            this.programmersModel.onboardingTemplate.template_id,
            requirementId,
            {
              responses: updatedResponses
            } as BankCustomFields
          );

          const updatedTemplateResult = {
            ...this.programmersModel.onboardingResult
          };
          updatedTemplateResult.custom_requirement_result[reqResultIndex] = updatedRequirementResponse;
          this.programmersModel.onboardingResult = updatedTemplateResult;
        }
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not update the template response');
    }
  };

  saveAndCompleteOnboardingTemplate = async (customFieldValues: Record<string, any>) => {
    const additionalInfoUtil = new AdditionalInfoUtil(
      this.programmersModel.onboardingTemplate,
      this.dispensaryStore.currentDispensary.id,
      false
    );

    await additionalInfoUtil.saveAndCompleteOnboardingTemplate(customFieldValues);
  };

  isSectionChanged = (customFieldValues: Record<string, any>, oldResponses: CustomRequirementResult) => {
    let changed = false;
    if (Object.entries(oldResponses.custom_fields.responses).length === 0) {
      // if no responses exist for the section already we need to check the values against the template to see if they're
      // in this section
      const templateSection =
        this.programmersModel.onboardingTemplate.custom_requirements[oldResponses.requirement_id];
      templateSection.custom_section.fields.forEach((field) => {
        if (customFieldValues[field.id]) {
          changed = true;
        }
      });
    } else {
      Object.entries(oldResponses.custom_fields.responses).forEach((oldResponse) => {
        if (
          customFieldValues[oldResponse[0]] !== undefined &&
          JSON.stringify(customFieldValues[oldResponse[0]]) !== JSON.stringify(oldResponse[1])
        ) {
          changed = true;
        }
      });
    }
    return changed;
  };

  autoSaveAdditionalInfo = async (customFieldValues: Record<string, any>, sectionId: string) => {
    const additionalInfoUtil = new AdditionalInfoUtil(
      this.programmersModel.onboardingTemplate,
      this.dispensaryStore.currentDispensary.id,
      false
    );

    additionalInfoUtil.saveSection(customFieldValues, sectionId);
  };

  // account owners functionality

  inviteUser = async (data: FieldValues) => {
    const request: InviteNewUserRequest = {
      userInfo: {
        role: 'dispensary_account_owner',
        email: data.email.trim(),
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        pq_user_role: data.pq_user_role === 'none' ? undefined : data.pq_user_role
      }
    };

    try {
      const response = await this.dispensariesApi.inviteDispensaryUser(
        this.programmersModel.dispensary.id,
        request
      );

      // TODO: update dispensary store with org and user on unload
      // this.updateDispensaryStore(response);
      this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'User has been invited successfully.');
      return response;
    } catch (error) {
      console.log('issue inviting user', error);
      this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'There was an issue inviting the user.');
    }
  };

  // Id verification functionality

  getUserDocs = async (frontId: string | undefined, backId: string | undefined) => {
    try {
      let frontDoc = undefined;
      if (frontId) {
        frontDoc = await this.documentsApi.getUserDocument(this.programmersModel.user.id, frontId);
      }
      let backDoc = undefined;
      if (backId) {
        backDoc = await this.documentsApi.getUserDocument(this.programmersModel.user.id, backId);
      }
      return { frontDoc, backDoc };
    } catch (error) {
      console.log('issue getting user docs', error);
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'There was an issue getting the user identification documents.'
      );
    }
  };

  updateUserIdentification = async (
    data: FieldValues,
    timezone: IANATimezones,
    user: User,
    onNext?: () => void
  ) => {
    try {
      const updateRequest = {
        firstName: data.firstName,
        minitial: data.minitial,
        lastName: data.lastName,
        dateOfBirth: DateTimeHelpers.formatISOToDateString(data.dateOfBirth, timezone),
        phone: data.phone,
        address: data.address,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
        identification: {
          ...user.identification,
          idType: data.idType,
          idNumber: data.idNumber,
          idState: data.idState
        }
      };

      const userStore = getUserStore();
      if (userStore.user && userStore.user.id) {
        const returnedUser = await this.usersApi.updateUserIdentification(userStore.user.id, updateRequest);
        // userStore.updateUser(user);

        if (onNext) {
          onNext();
        }
        this.snackbarStore.showSnackbar(
          SnackbarSeverity.Success,
          'User identification has been updated successfully.'
        );
        return returnedUser;
      } else {
        console.log(
          'user was undefined in user store, failed to update user identification',
          userStore.user,
          updateRequest
        );
      }
    } catch (e) {
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'There was an issue updating user identification.'
      );
    }
  };

  autoSaveUserIdentification = async (data: FieldValues, timezone: IANATimezones, user: User) => {
    const updateRequest = {
      firstName: data.firstName,
      minitial: data.minitial,
      lastName: data.lastName,
      dateOfBirth: DateTimeHelpers.formatISOToDateString(data.dateOfBirth, timezone),
      phone: data.phone,
      address: data.address,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode,
      identification: {
        ...user.identification,
        idType: data.idType,
        idNumber: data.idNumber,
        idState: data.idState
      }
    };
    const returnedUser = await this.usersApi.updateUserIdentification(user.id, updateRequest);
    return returnedUser;
  };

  uploadNewId = async (file: File, user: User) => {
    const s3Key = await uploadUserDocumentToS3(file, user.id);
    return await this.documentsApi.putUserDocument(file.name, s3Key, user.id);
  };

  updateIdentityDocs = action(
    async (newIdFront: File | undefined, newIdBack: File | undefined, user: User) => {
      try {
        const updatedIdentification = { ...user.identification };
        let frontDoc = undefined;
        let backDoc = undefined;
        if (newIdFront) {
          frontDoc = await this.uploadNewId(newIdFront, user);
          updatedIdentification.idFront = {
            filename: frontDoc.file_name,
            document_id: frontDoc.id,
            status: 'review'
          };
        }
        if (newIdBack) {
          backDoc = await this.uploadNewId(newIdBack, user);
          updatedIdentification.idBack = {
            filename: backDoc.file_name,
            document_id: backDoc.id,
            status: 'review'
          };
        }

        const updatedUser = await this.usersApi.updateUserIdentification(user.id, {
          identification: updatedIdentification
        });
        this.snackbarStore.showSnackbar(
          SnackbarSeverity.Success,
          'Documents have been uploaded successfully.'
        );
        return { frontDoc, backDoc, updatedUser };
      } catch (e) {
        this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'There was an issue uploading documents.');
      }
    }
  );

  deleteIdDoc = action(
    async (
      updatedIdentification: {
        idType?: string | undefined;
        idNumber?: string | undefined;
        idState?: string | undefined;
        idFront?: UserIdentification | undefined;
        idBack?: UserIdentification | undefined;
      },
      user: User
    ) => {
      try {
        const returnedUser = await this.usersApi.updateUserIdentification(user.id, {
          identification: updatedIdentification
        });
        return returnedUser;
      } catch (e) {
        this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'There was an issue removing the documents.');
      }
    }
  );

  getAllDocuments = action(async () => {
    const documents = await this.documentsApi.getDocuments(this.dispensaryStore.currentDispensary.id);
    return documents;
  });

  downloadRequirementAttachment = async (requirement: OnboardingDocumentRequirement) => {
    try {
      if (requirement.attachments && requirement.attachments.length > 0 && requirement.bank_id) {
        const zip = new JSZip();
        const attachmentsZip = zip.folder('attachments');
        for (let index = 0; index < requirement?.attachments.length; index++) {
          const attachmentDoc = requirement?.attachments[index];
          await this.documentsApi
            .getDocument(requirement.bank_id, attachmentDoc.id)
            .then(async (document) => {
              await this.documentsApi
                .getDocumentLink(environment.storageConfig.sharedDocument, document.s3_key, 'get')
                .then(async (docS3url) => {
                  await fetch(docS3url.s3LinkPath).then(async (docResponse) => {
                    if (requirement.attachments && requirement.attachments.length === 1) {
                      await docResponse.blob().then(async (docBlob) => {
                        openFileInNewWindow(
                          docBlob,
                          getMimeType(attachmentDoc.file_name),
                          attachmentDoc.file_name
                        );
                      });
                    } else {
                      await docResponse.text().then(async (docBase64) => {
                        attachmentsZip?.file(attachmentDoc.file_name, docBase64, { base64: true });
                      });
                    }
                  });
                });
            });
        }
        if (requirement.attachments.length > 1) {
          zip.generateAsync({ type: 'blob' }).then(async (content) => {
            FileSaver.saveAs(content, 'attachments.zip');
          });
        }
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not download documents');
    }
  };

  setOnboardingDocumentRequirementDoNotHave = async (
    dispensaryId: string,
    templateId: string,
    requirementId: string,
    doNotHave: boolean,
    doNotHaveComment: string
  ) => {
    return this.templateApi.setOnboardingDocumentRequirementDoNotHave(
      dispensaryId,
      templateId,
      requirementId,
      doNotHave,
      doNotHaveComment
    );
  };

  getAtttachment = async (bankId: string, documentId: string) => {
    this.documentsApi.getDocument(bankId, documentId).then((document) => {
      this.documentsApi
        .getDocumentLink(environment.storageConfig.sharedDocument, document.s3_key, 'get')
        .then((s3Url) =>
          fetch(s3Url.s3LinkPath).then(async (response) => {
            const blob = await response.blob();
            openFileInNewWindow(blob, getMimeType(document.file_name), document.file_name);
          })
        );
    });
  };

  removeDocumentFromRequirement = action(
    async (
      requirement: OnboardingDocumentRequirement,
      documentId: string,
      dispensaryId: string,
      templateId: string
    ) => {
      return this.templateApi.removeDocumentFromOnboardingDocumentRequirement(
        dispensaryId,
        templateId,
        requirement.requirement_id,
        documentId
      );
    }
  );

  addDocumentToRequirement = action(
    async (
      requirement: OnboardingDocumentRequirement,
      dispensaryId: string,
      templateId: string,
      documentsRequest: CreateDocumentsRequest
    ) => {
      const result = this.templateApi.addOnboardingDocumentRequirementDocumentsCrbV2(
        dispensaryId,
        templateId,
        requirement.requirement_id,
        documentsRequest
      );
      return result;
    }
  );

  updateLicenseDocument = action(
    async (
      requirement: OnboardingDocumentRequirement,
      documentId: string,
      data: LicenseDocumentDataRequest,
      templateId: string
    ) => {
      const reqResult = await this.templateApi.updateLicenseDocument(
        this.dispensaryStore.currentDispensary.id,
        templateId,
        requirement.requirement_id,
        documentId,
        data
      );
      return reqResult;
    }
  );

  addOnboardingDocumentRequirementDocuments = action(
    async (
      dispensaryId: string,
      templateId: string,
      requirementId: string,
      newDocuments: CreateDocumentsRequest
    ) => {
      const reqResult = await this.templateApi.addOnboardingDocumentRequirementDocumentsCrbV2(
        dispensaryId,
        templateId,
        requirementId,
        newDocuments
      );
      return reqResult;
    }
  );

  getSharedDcouments = action(
    async (
      crbId: string
    ): Promise<
      {
        requirement: OnboardingDocumentRequirement;
        ddDocuments: DueDiligenceDocument[];
        documents: DocumentUpload[];
      }[]
    > => {
      const sharedRootDocs = await this.dispensariesApi.getSharedRootDocuments(crbId);
      return sharedRootDocs;
    }
  );
}
