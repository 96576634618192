import {
  CrbServiceProvider,
  DueDiligenceStatus,
  GREEN_CHECK_DIRECT_PROVIDER_ID,
  GREEN_CHECK_ONBOARDING_BANK_ID,
  MarketplaceOfferCategory,
  MetaCategories
} from '@gcv/shared';
import { DispensariesApi, DocumentsApi } from 'api';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { injectable } from 'inversify';

@injectable()
export class CrbBankStore {
  dispensaryApi = new DispensariesApi();
  documentsApi = new DocumentsApi();

  /**
   * This will be a mapping of all service providers that the dispensary ever connected with.
   * Contains basic data like org name, address, timezone, and template metadata
   * */
  banks: { [bankId: string]: CrbServiceProvider } = {} as { [bankId: string]: CrbServiceProvider };

  isLoaded = false;
  isLoadingBank = false;
  /**
   * Boolean property set to true if the current CRB is connected to Payqwick and not disconnected
   */
  isConnectedToPayqwick = false;

  constructor() {
    makeAutoObservable(this);
  }

  private getEarliestConnectedBank = (banks: CrbServiceProvider[]) => {
    if (!banks.length) {
      return undefined;
    }

    const firstBank = banks.sort((a, b) => {
      const connectedA = a.templates.value.connected_date;
      const connectedB = b.templates.value.connected_date;

      if (connectedA && connectedB) {
        return connectedA < connectedB ? -1 : 1;
      } else {
        return 0;
      }
    })[0];

    return firstBank;
  };

  public getPrimaryBank = (): CrbServiceProvider | undefined => {
    const activeBanks = [];

    for (const sp of Object.values(this.banks)) {
      if (
        sp.templates.value.onboarding.status === DueDiligenceStatus.BANK_APPROVED &&
        sp.templates.value.onboarding.marketplace_offer_snapshot?.offer_category ===
          MarketplaceOfferCategory.Banking
      ) {
        activeBanks.push(sp);
      }
    }

    let firstBank: CrbServiceProvider | undefined;

    if (activeBanks.length) {
      firstBank = this.getEarliestConnectedBank(activeBanks);
    } else {
      firstBank = this.getEarliestConnectedBank(
        Object.values(this.banks).filter(
          (b) =>
            b.templates.value.onboarding.status === DueDiligenceStatus.BANK_APPROVED &&
            !b.templates.value.onboarding.marketplace_offer_snapshot &&
            b.id !== GREEN_CHECK_ONBOARDING_BANK_ID
        )
      );
    }

    return firstBank;
  };

  loadBanks = action(async (dispensaryId: string) => {
    this.isLoadingBank = true;
    const banks = await this.dispensaryApi.getDispensaryServiceProviders(dispensaryId);
    runInAction(() => {
      this.banks = banks;
      this.isLoaded = true;
      this.isLoadingBank = false;
      this.isConnectedToPayqwick = Object.values(banks).some(
        (b: CrbServiceProvider) =>
          b.id === GREEN_CHECK_DIRECT_PROVIDER_ID &&
          b.templates.value.onboarding.status !== DueDiligenceStatus.BANK_DISCONNECTED
      );
    });
  });

  clearStore = action(() => {
    this.banks = {};
    this.isLoaded = false;
  });
}

let store: CrbBankStore | undefined;

export function getCrbBankStore(): CrbBankStore {
  if (!store) {
    store = new CrbBankStore();
  }

  return store;
}
