import palette from '../palette';

export default {
  styleOverrides: {
    colorSecondary: {
      '&$checked': {
        color: palette.primary.main
      }
    }
  }
};
