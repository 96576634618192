import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { FieldValues } from 'react-hook-form';
import {
  MainFeatures,
  NotificationPreference,
  NotificationPreferences,
  NotificationTypes,
  OrganizationRoleResolver
} from '@gcv/shared';
import { FiUserSettingsRepo, PM, UserInfo } from './fi-user-settings.repo';
import { orgFeatureEnabled } from 'util/org.util';
import { FiBankStore } from 'stores/FiBankStore';
import { UserStore } from 'stores/UserStore';

export type { UserInfo } from './fi-user-settings.repo';

export interface NotificationData {
  name: NotificationTypes;
  description: string;
  hasInApp: boolean;
  hasEmail: boolean;
}

export interface FormData extends FieldValues {
  userInfo: UserInfo;
  settings: Record<keyof VM['settings'], NotificationData>;
}

export class NotificationSetting implements NotificationData {
  static _preferences: NotificationPreferences | undefined;

  name: NotificationTypes;
  description: string;
  tooltip?: string;
  hasInApp: boolean;
  hasEmail: boolean;

  static initialize(preferences: NotificationPreferences) {
    NotificationSetting._preferences = preferences;
  }

  get preferences() {
    if (NotificationSetting._preferences === undefined) {
      throw new Error('Class is not initialized.');
    }

    return NotificationSetting._preferences;
  }

  constructor(name: NotificationTypes, description: string, tooltip?: string) {
    this.name = name;
    this.description = description;
    this.tooltip = tooltip;

    const preference = this.preferences[name];

    this.hasInApp =
      preference === NotificationPreference.EmailAndApp || preference === NotificationPreference.App;
    this.hasEmail =
      preference === NotificationPreference.EmailAndApp || preference === NotificationPreference.Email;
  }

  public getNotificationPreference() {
    if (this.hasInApp && this.hasEmail) {
      return NotificationPreference.EmailAndApp;
    } else if (this.hasInApp) {
      return NotificationPreference.App;
    } else if (this.hasEmail) {
      return NotificationPreference.Email;
    } else {
      return NotificationPreference.None;
    }
  }
}

export interface VM {
  isLoading: boolean;
  userInfo: UserInfo;
  settings: {
    accounts: NotificationSetting[];
    reports: NotificationSetting[];
    deposits: NotificationSetting[];
    finCEN: NotificationSetting[];
    comments: NotificationSetting[];
    questionnaires: NotificationSetting[];
    coreData: NotificationSetting[];
    monitoring: NotificationSetting[];
    negativeNews: NotificationSetting[];
  };
  depositEnabled: boolean;
  userIsStandard: boolean;
  userIsAccountOwner: boolean;
}

@injectable()
export class FiUserSettingsPresenter {
  @inject(FiUserSettingsRepo)
  private repo: FiUserSettingsRepo;
  @inject(FiBankStore)
  private bankStore: FiBankStore;
  @inject(UserStore)
  private userStore: UserStore;

  public viewModel: VM = {
    isLoading: true,
    userInfo: {},
    settings: {
      accounts: [],
      reports: [],
      deposits: [],
      finCEN: [],
      comments: [],
      questionnaires: [],
      coreData: [],
      monitoring: [],
      negativeNews: []
    },
    depositEnabled: true,
    userIsStandard: false,
    userIsAccountOwner: false
  };

  constructor() {
    makeAutoObservable(this);
  }

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  public load = action(async (editCrbInfo: boolean) => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      const depositEnabled = orgFeatureEnabled(this.bankStore.bank, MainFeatures.Deposits);

      NotificationSetting.initialize(programmersModel.notificationPreferences);

      // For each group of settings, this will be the order the UI presents the settings in.
      const accounts = [
        new NotificationSetting(
          NotificationTypes.NEW_DISPENSARY_TO_REVIEW,
          'An account completed onboarding and is ready for review'
        ),
        new NotificationSetting(
          NotificationTypes.NEW_DOCUMENT_UPLOADED,
          'An account uploaded a document to fulfill a Due Diligence requirement'
        ),
        new NotificationSetting(
          NotificationTypes.NEW_COMMENT_ADDED_TO_REQUIREMENT,
          'An account added a comment to fulfill a Due Diligence requirement'
        ),
        new NotificationSetting(
          NotificationTypes.CRBM_LICENSE_STATUS_UPDATE,
          "An account's marijuana license status has changed"
        ),
        new NotificationSetting(
          NotificationTypes.MARKETPLACE_NEW_APPLICATION,
          'A new account started the application process'
        )
      ];

      if (editCrbInfo) {
        accounts.push(
          new NotificationSetting(
            NotificationTypes.CRB_EDIT_INFO,
            'An account has changed their company information',
            'In-app notifications will be sent as individual changes occur; however, email alerts will be batched and delivered the next morning to limit the number of emails received.'
          )
        );
      }

      const reports = [
        new NotificationSetting(NotificationTypes.ACTIVITY_REPORTING, 'Report is available for download')
      ];

      const deposits = [
        new NotificationSetting(
          NotificationTypes.NEW_DEPOSIT,
          'An account created a deposit that is ready for review'
        )
      ];

      const finCEN = [
        new NotificationSetting(NotificationTypes.NEW_FINCEN_REPORT, 'New FinCEN report available')
      ];

      const comments = [
        new NotificationSetting(NotificationTypes.COMMENT_REPLY, 'Comment replies (replies to your comment)'),
        new NotificationSetting(
          NotificationTypes.COMMENT_MENTION,
          'Comment mentions (comments that mention you)'
        ),
        new NotificationSetting(
          NotificationTypes.COMMENT_CC,
          'New comment alert (all comments made by a CRB)'
        )
      ];

      const questionnaires = [
        new NotificationSetting(
          NotificationTypes.FI_QUESTIONNAIRE_COMPLETE,
          'A questionnaire response is received'
        ),
        new NotificationSetting(
          NotificationTypes.USER_TASK_ASSIGNED,
          'A manual task has been assigned to you'
        ),
        new NotificationSetting(
          NotificationTypes.COMMENT_TASK_ASSIGNED,
          'A comment task has been assigned to you'
        )
      ];

      const coreData = [
        new NotificationSetting(NotificationTypes.SFTP_NO_DATA, 'An empty core data file has been received'),
        new NotificationSetting(
          NotificationTypes.SFTP_PARSING_FAILURE,
          'File received but could not be processed'
        ),
        new NotificationSetting(
          NotificationTypes.SFTP_MISSED_SCHEDULE,
          'File expected due to transmission schedule but not received'
        )
      ];

      const monitoring = [
        new NotificationSetting(
          NotificationTypes.NEW_AUTO_GENENRATED_REVIEW_AVAILABLE,
          'New monitoring review available'
        ),
        new NotificationSetting(
          NotificationTypes.BATCH_NEW_AUTO_GENENRATED_REVIEW_AVAILABLE,
          'New monitoring review available (grouped notifications)'
        )
      ];

      const negativeNews = [
        new NotificationSetting(NotificationTypes.NEGATIVE_NEWS_FOUND, 'Negative news alerts')
      ];

      this.updateViewModel({
        userInfo: { ...programmersModel.userInfo },
        settings: {
          accounts,
          reports,
          deposits,
          finCEN,
          comments,
          questionnaires,
          coreData,
          monitoring,
          negativeNews
        },
        depositEnabled: depositEnabled
      });
    });
    const resolver = new OrganizationRoleResolver();
    await this.repo.load();
    this.updateViewModel({
      isLoading: false,
      userIsStandard: resolver.userHasRole(this.bankStore.bank.groups, this.userStore.user, 'bank_user'),
      userIsAccountOwner: resolver
        .getUsersGroups(this.bankStore.bank.groups, this.userStore.user.id)
        .some((group) => group.name === 'Account Owner')
    });
  });

  public updateUserSettings = async (data: FormData) => {
    const userInfo = data.userInfo as UserInfo;
    const notifications = {} as NotificationPreferences;

    const keys = Object.keys(data.settings) as unknown as (keyof VM['settings'])[];

    keys
      .map((key) => {
        return data.settings[key] as NotificationData;
      })
      .flat()
      .forEach((config) => {
        notifications[config.name] =
          config.hasInApp && config.hasEmail
            ? NotificationPreference.EmailAndApp
            : config.hasInApp
            ? NotificationPreference.App
            : config.hasEmail
            ? NotificationPreference.Email
            : NotificationPreference.None;
      });

    return await this.repo.updateUserSettings({
      userInfo: userInfo,
      notificationPreferences: notifications
    });
  };
}
