import { OrganizationRoleResolver } from '@gcv/shared';
import { BanksApi, DispensariesApi, DocumentsApi, TemplateApi, UsersApi } from 'api';
import { BanksApiMock } from 'api/mocks/BanksApiMock';
import { DocumentsApiMock } from 'api/mocks/DocumentsApiMock';
import { TemplateApiMock } from 'api/mocks/TemplateApiMock';
import { UsersApiMock } from 'api/mocks/UsersApiMock';
import { Container, interfaces } from 'inversify';
import OrganizationRoleResolverMock from 'mocks/organization-role-resolver.mock';
import * as React from 'react';
import 'reflect-metadata';
import { AppViewStateStore, getAppViewStateStore } from 'stores/AppViewStateStore';
import { CommentStore, getCommentStore } from 'stores/CommentStore';
import { ComplianceStore, getComplianceStore } from 'stores/ComplianceStore';
import { CrbBankStore, getCrbBankStore } from 'stores/CrbBankStore';
import { CrbDispensaryStore, getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { FiBankStore, getFiBankStore } from 'stores/FiBankStore';
import { FiDispensaryStore, getFiDispensaryStore } from 'stores/FiDispensaryStore';
import { NotificationStore, getNotificationStore } from 'stores/NotificationStore';
import { SnackbarStore, getSnackbarStore } from 'stores/SnackBarStore';
import { UserStore, getUserStore } from 'stores/UserStore';
import { CompanyProfilePresenter } from 'ui/apps/crb/company-profile/company-profile.presenter';
import { CompanyProfileRepo } from 'ui/apps/crb/company-profile/company-profile.repo';
import { CrbInboxPresenter } from 'ui/apps/crb/inbox/inbox.presenter';
import { CrbInboxRepo } from 'ui/apps/crb/inbox/inbox.repo';
import { ProviderDetailsPresenter } from 'ui/apps/crb/my-providers/pages/provider-details/provider-details.presenter';
import { ProviderDetailsRepo } from 'ui/apps/crb/my-providers/pages/provider-details/provider-details.repo';
import { OnboardingPresenter } from 'ui/apps/crb/onboarding-two/onboarding.presenter';
import { OnboardingRepo } from 'ui/apps/crb/onboarding-two/onboarding.repo';
import { CrbUserSettingsPresenter } from 'ui/apps/crb/users/pages/crb-user-settings.presenter';
import { CrbUserSettingsRepo } from 'ui/apps/crb/users/pages/crb-user-settings.repo';
import { CrbWelcomePresenter } from 'ui/apps/crb/welcome/welcome.presenter';
import { CrbWelcomeRepo } from 'ui/apps/crb/welcome/welcome.repo';
import { FiAccountsArchivedPresenter } from 'ui/apps/fi/accounts/pages/archived/archived.presenter';
import { FiAccountsArchivedRepo } from 'ui/apps/fi/accounts/pages/archived/archived.repo';
import { QuestionnairePresenter } from 'ui/apps/fi/accounts/pages/questionnaire/questionnaire.presenter';
import { QuestionnaireRepo } from 'ui/apps/fi/accounts/pages/questionnaire/questionnaire.repo';
import { FiDailySummariesPresenter } from 'ui/apps/fi/daily-summaries/daily-summaries.presenter';
import { FiDailySummariesRepo } from 'ui/apps/fi/daily-summaries/daily-summaries.repo';
import { FiDailySummariesDetailsPresenter } from 'ui/apps/fi/daily-summaries/pages/daily-summary-detail/daily-summary-details.presenter';
import { FiDailySummariesDetailsRepo } from 'ui/apps/fi/daily-summaries/pages/daily-summary-detail/daily-summary-details.repo';
import { FiDashboardPresenter } from 'ui/apps/fi/dashboard/dashboard.presenter';
import { FiDashboardRepo } from 'ui/apps/fi/dashboard/dashboard.repo';
import { FiDepositsPresenter } from 'ui/apps/fi/deposits/deposits.presenter';
import { FiDepositsRepo } from 'ui/apps/fi/deposits/deposits.repo';
import { ReportsPresenter } from 'ui/apps/fi/reports/reports.presenter';
import { ReportsRepo } from 'ui/apps/fi/reports/reports.repo';
import { FiTasksModalPresenter } from 'ui/apps/fi/tasks/components/tasks-modal.presenter';
import { FiTasksModalRepo } from 'ui/apps/fi/tasks/components/tasks-modal.repo';
import { FiTasksPresenter } from 'ui/apps/fi/tasks/tasks.presenter';
import { FiTasksRepo } from 'ui/apps/fi/tasks/tasks.repo';
import { FiUserSettingsPresenter } from 'ui/apps/fi/users/pages/fi-user-settings.presenter';
import { FiUserSettingsRepo } from 'ui/apps/fi/users/pages/fi-user-settings.repo';
import { GcvAccessCrbPosDataFetcherHistoryPresenter } from 'ui/apps/gcv/access/tabs/datafetcher-history-by-crb-pos/crb-pos-history.presenter';
import { GcvAccessCrbPosDataFetcherHistoryRepo } from 'ui/apps/gcv/access/tabs/datafetcher-history-by-crb-pos/crb-pos-history.repo';
import { GcvAccessDataFetcherHistoryPresenter } from 'ui/apps/gcv/access/tabs/datafetcher-history/data-fetcher-history.presenter';
import { GcvAccessDataFetcherHistoryRepo } from 'ui/apps/gcv/access/tabs/datafetcher-history/data-fetcher-history.repo';
import { GcvAccessTimingConfigsPresenter } from 'ui/apps/gcv/access/tabs/datafetcher-timing-configs/timing-configs.presenter';
import { GcvAccessTimingConfigsRepo } from 'ui/apps/gcv/access/tabs/datafetcher-timing-configs/timing-configs.repo';
import { GcvAccessPresenter } from 'ui/apps/gcv/access/tabs/manage-access/manage-access.presenter';
import { GcvAccessRepo } from 'ui/apps/gcv/access/tabs/manage-access/manage-access.repo';
import { GcvEditAccessPresenter } from 'ui/apps/gcv/access/tabs/manage-access/pages/edit-access/edit-access.presenter';
import { GcvEditAccessRepo } from 'ui/apps/gcv/access/tabs/manage-access/pages/edit-access/edit-access.repo';
import { ComplianceRulesPresenter } from 'ui/apps/gcv/cre/cre.presenter';
import { ComplianceRulesRepo } from 'ui/apps/gcv/cre/cre.repo';
import { StateContextPresenter } from 'ui/apps/gcv/cre/pages/state-context/state-context.presenter';
import { StateContextRepo } from 'ui/apps/gcv/cre/pages/state-context/state-context.repo';
import { CommentsPresenter } from 'ui/organisms/Comments/comments.presenter';
import { CommentsRepo } from 'ui/organisms/Comments/comments.repo';
import { FilterSelectPresenter } from 'ui/organisms/FilterSelect/filter-select.organism.presenter';
import { DispensariesApiMock } from './api/mocks/DispensariesApiMock';
import { CrbConnectOrgPresenter } from './ui/apps/crb/connect-org/connect-org.presenter';
import { CrbConnectOrgRepo } from './ui/apps/crb/connect-org/connect-org.repo';
import { MyProvidersPresenter } from './ui/apps/crb/my-providers/my-providers.presenter';
import { MyProvidersRepo } from './ui/apps/crb/my-providers/my-providers.repo';

import { FiNavDrawerPresenter } from 'ui/organisms/FiNavDrawer/fi-nav-drawer.presenter';

const InjectionContext = React.createContext<{ container: Container | null }>({ container: null });

type Props = {
  container: Container;
};

const InjectionProvider: React.FC<Props> = (props) => {
  return (
    <InjectionContext.Provider value={{ container: props.container }}>
      {props.children}
    </InjectionContext.Provider>
  );
};

function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>) {
  const { container } = React.useContext(InjectionContext);

  if (!container) {
    throw new Error('IOC Container is not initialized');
  }

  return container.get<T>(identifier);
}

let container: Container;

// context can be used to switch the bindings based on environment or some other criteria
// transient objects are automatically bound, such as APIs
const createContainer = (context: 'test' | 'dev' | 'prod') => {
  container = new Container({
    autoBindInjectable: true,
    defaultScope: 'Transient'
  });

  if (context === 'dev' || context === 'prod') {
    bind();
    bindShared();
  } else if (context === 'test') {
    bind();
    bindTest();
  }
};

const bind = () => {
  // Presenters
  container.bind(CommentsPresenter).to(CommentsPresenter).inSingletonScope();
  container.bind(CompanyProfilePresenter).to(CompanyProfilePresenter).inSingletonScope();
  container.bind(CrbConnectOrgPresenter).to(CrbConnectOrgPresenter).inSingletonScope();
  container.bind(CrbInboxPresenter).to(CrbInboxPresenter).inSingletonScope();
  container.bind(CrbUserSettingsPresenter).to(CrbUserSettingsPresenter).inSingletonScope();
  container.bind(FiAccountsArchivedPresenter).to(FiAccountsArchivedPresenter).inSingletonScope();
  container.bind(FiDailySummariesPresenter).to(FiDailySummariesPresenter).inSingletonScope();
  container.bind(FiDailySummariesDetailsPresenter).to(FiDailySummariesDetailsPresenter).inSingletonScope();
  container.bind(FiDashboardPresenter).to(FiDashboardPresenter).inSingletonScope();
  container.bind(FiDepositsPresenter).to(FiDepositsPresenter).inSingletonScope();
  container.bind(FiTasksModalPresenter).to(FiTasksModalPresenter).inSingletonScope();
  container.bind(FiTasksPresenter).to(FiTasksPresenter).inSingletonScope();
  container.bind(FiUserSettingsPresenter).to(FiUserSettingsPresenter).inSingletonScope();
  container.bind(FilterSelectPresenter).to(FilterSelectPresenter).inSingletonScope();
  container.bind(MyProvidersPresenter).to(MyProvidersPresenter).inSingletonScope();
  container.bind(OnboardingPresenter).to(OnboardingPresenter).inSingletonScope();
  container.bind(ProviderDetailsPresenter).to(ProviderDetailsPresenter).inSingletonScope();
  container.bind(QuestionnairePresenter).to(QuestionnairePresenter).inSingletonScope();
  container.bind(ReportsPresenter).to(ReportsPresenter).inSingletonScope();
  container.bind(CrbWelcomePresenter).to(CrbWelcomePresenter).inSingletonScope();
  container.bind(ComplianceRulesPresenter).to(ComplianceRulesPresenter).inSingletonScope();
  container.bind(ComplianceRulesRepo).to(ComplianceRulesRepo).inSingletonScope();
  container.bind(StateContextPresenter).to(StateContextPresenter).inSingletonScope();
  container.bind(StateContextRepo).to(StateContextRepo).inSingletonScope();
  container.bind(GcvAccessPresenter).to(GcvAccessPresenter).inSingletonScope();
  container.bind(GcvEditAccessPresenter).to(GcvEditAccessPresenter).inSingletonScope();
  container.bind(GcvAccessTimingConfigsPresenter).to(GcvAccessTimingConfigsPresenter).inSingletonScope();
  container
    .bind(GcvAccessDataFetcherHistoryPresenter)
    .to(GcvAccessDataFetcherHistoryPresenter)
    .inSingletonScope();
  container
    .bind(GcvAccessCrbPosDataFetcherHistoryPresenter)
    .to(GcvAccessCrbPosDataFetcherHistoryPresenter)
    .inSingletonScope();
  container.bind(FiNavDrawerPresenter).to(FiNavDrawerPresenter).inSingletonScope();

  // Repositories
  container.bind(CommentsRepo).to(CommentsRepo).inSingletonScope();
  container.bind(CompanyProfileRepo).to(CompanyProfileRepo).inSingletonScope();
  container.bind(CrbConnectOrgRepo).to(CrbConnectOrgRepo).inSingletonScope();
  container.bind(CrbInboxRepo).to(CrbInboxRepo).inSingletonScope();
  container.bind(CrbUserSettingsRepo).to(CrbUserSettingsRepo).inSingletonScope();
  container.bind(FiAccountsArchivedRepo).to(FiAccountsArchivedRepo).inSingletonScope();
  container.bind(FiDailySummariesRepo).to(FiDailySummariesRepo).inSingletonScope();
  container.bind(FiDailySummariesDetailsRepo).to(FiDailySummariesDetailsRepo).inSingletonScope();
  container.bind(FiDashboardRepo).to(FiDashboardRepo).inSingletonScope();
  container.bind(FiDepositsRepo).to(FiDepositsRepo).inSingletonScope();
  container.bind(FiTasksModalRepo).to(FiTasksModalRepo).inSingletonScope();
  container.bind(FiTasksRepo).to(FiTasksRepo).inSingletonScope();
  container.bind(FiUserSettingsRepo).to(FiUserSettingsRepo).inSingletonScope();
  container.bind(MyProvidersRepo).to(MyProvidersRepo).inSingletonScope();
  container.bind(OnboardingRepo).to(OnboardingRepo).inSingletonScope();
  container.bind(ProviderDetailsRepo).to(ProviderDetailsRepo).inSingletonScope();
  container.bind(QuestionnaireRepo).to(QuestionnaireRepo).inSingletonScope();
  container.bind(ReportsRepo).to(ReportsRepo).inSingletonScope();
  container.bind(CrbWelcomeRepo).to(CrbWelcomeRepo).inSingletonScope();
  container.bind(GcvAccessRepo).to(GcvAccessRepo).inSingletonScope();
  container.bind(GcvEditAccessRepo).to(GcvEditAccessRepo).inSingletonScope();
  container.bind(GcvAccessTimingConfigsRepo).to(GcvAccessTimingConfigsRepo).inSingletonScope();
  container.bind(GcvAccessDataFetcherHistoryRepo).to(GcvAccessDataFetcherHistoryRepo).inSingletonScope();
  container
    .bind(GcvAccessCrbPosDataFetcherHistoryRepo)
    .to(GcvAccessCrbPosDataFetcherHistoryRepo)
    .inSingletonScope();

  // Stores are already singletons and used in many places so we will just grab the existing singleton
  // This can be refactored to use the standard inversify singletons when time allows
  container.bind(AppViewStateStore).toDynamicValue(() => getAppViewStateStore());
  container.bind(CommentStore).toDynamicValue(() => getCommentStore());
  container.bind(ComplianceStore).toDynamicValue(() => getComplianceStore());
  container.bind(CrbBankStore).toDynamicValue(() => getCrbBankStore());
  container.bind(CrbDispensaryStore).toDynamicValue(() => getCrbDispensaryStore());
  container.bind(FiBankStore).toDynamicValue(() => getFiBankStore());
  container.bind(FiDispensaryStore).toDynamicValue(() => getFiDispensaryStore());
  container.bind(NotificationStore).toDynamicValue(() => getNotificationStore());
  container.bind(SnackbarStore).toDynamicValue(() => getSnackbarStore());
  container.bind(UserStore).toDynamicValue(() => getUserStore());
};

const bindShared = () => {
  // shared
  container.bind(OrganizationRoleResolver).toDynamicValue(() => new OrganizationRoleResolver());
};

const bindTest = () => {
  // API
  container.bind(BanksApi).to(BanksApiMock);
  container.bind(DispensariesApi).to(DispensariesApiMock);
  container.bind(DocumentsApi).to(DocumentsApiMock);
  container.bind(TemplateApi).to(TemplateApiMock);
  container.bind(UsersApi).to(UsersApiMock);

  // shared
  container.bind(OrganizationRoleResolver).toDynamicValue(() => new OrganizationRoleResolverMock());
};

export { InjectionProvider, container, createContainer, useInjection };
