import palette from '../palette';

export default {
  styleOverrides: {
    colorInfo: {
      backgroundColor: palette.divider,
      color: palette.text.primary
    }
  }
};
