import { Box, useMediaQuery, useTheme } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import { ReactComponent as FileIcon } from 'assets/images/file-clip-icon.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Mention, MentionsInput } from 'react-mentions';
import { UserStore } from 'stores/UserStore';
import {
  Avatar,
  Button,
  Container,
  ContainerItem,
  Error,
  File as FileAtom,
  Icon,
  InputCheckBox,
  Text,
  ToggleButtons,
  Tooltip
} from 'ui';
import { cropInput } from 'util/format.util';
import { CommentsPresenter } from '../comments.presenter';
import { CommentModalExternalConfirmation } from './comments-modal-external-confirmation';
import { CommentsModalTaskComplete } from './comments-modal-task-complete';
import { CommentsModalTaskCreate } from './comments-modal-task-create';

interface Props extends Record<string, unknown> {
  disableExternalComment?: boolean;
  externalOrgId?: string;
  orgId: string;
}

export const CommentsBarFooter: React.FC<Props> = useComponent((props) => {
  const { tasksComments } = useFlags();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const presenter = useInjection(CommentsPresenter);
  const userStore = useInjection(UserStore);

  const form = useForm({ mode: 'onBlur' });

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const commentInputRef = React.useRef<HTMLTextAreaElement | null>(null);

  React.useEffect(() => {
    presenter.getMentionableUsers(
      props.orgId,
      props.disableExternalComment ? undefined : props.externalOrgId
    );
  }, [props.orgId, props.externalOrgId, presenter.viewModel.isExternalComment, props.disableExternalComment]);

  React.useEffect(() => {
    presenter.focusCommentInput(commentInputRef);

    if (
      !presenter.viewModel.isExternalComment &&
      (presenter.isCrbApp || (!!presenter.viewModel.reply && !presenter.viewModel.reply.internal))
    ) {
      presenter.markCommentExternal(true);
    }

    if (props.disableExternalComment) {
      presenter.markCommentExternal(false);
    }
  }, []);

  React.useEffect(() => {
    if (presenter.viewModel.reply) {
      presenter.focusCommentInput(commentInputRef);
      presenter.markCommentExternal(!presenter.viewModel.reply.internal);
    }
  }, [presenter.viewModel.reply?.id]);

  const onSubmitComment = async (data: FieldValues) => {
    await presenter.onSubmitComment(data);
    form.reset();
  };

  if (!presenter.showCommentsBarFooter) {
    return <></>;
  }

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderBottom: '0px',
        borderRight: '0px',
        width: isMobile ? '340px' : '384px',
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bottom: 0,
        right: 0
      }}
    >
      <MuiAccordion
        disableGutters
        elevation={0}
        expanded={true}
        TransitionProps={{ easing: { enter: '1000ms' } }}
        sx={{ width: '100%' }}
      >
        <Container column width="100%">
          <ContainerItem width="100%" padding="0">
            <Container padding="0" align="center" width="100%">
              <ContainerItem align="center" padding="0" width="100%">
                <Container padding="0">
                  <ContainerItem justify="left" padding="0" width="60%">
                    {presenter.viewModel.reply && (
                      <div
                        style={{
                          backgroundColor: theme.palette.grey[100],
                          borderRadius: '6px',
                          marginLeft: '42px',
                          padding: '2px 5px',
                          width: 'fit-content'
                        }}
                      >
                        <Container padding="0" justify="space-between" align="center">
                          <ContainerItem padding="0px">
                            <Text content={`Replying to ${presenter.viewModel.reply.poster}`} type="body3" />
                          </ContainerItem>
                          <ContainerItem padding="0 0 0 8px" flex>
                            <CloseIcon
                              style={{ cursor: 'pointer', width: '15px', height: '15px' }}
                              onClick={() => presenter.setReply(null)}
                            />
                          </ContainerItem>
                        </Container>
                      </div>
                    )}
                  </ContainerItem>

                  <ContainerItem justify="right" padding="0" width="40%">
                    <ToggleButtons
                      buttons={[
                        {
                          label: 'External',
                          value: 'external',
                          tooltip: props.disableExternalComment
                            ? 'You cannot mention external users for internal reports.'
                            : ''
                        },
                        { label: 'Internal', value: 'internal' }
                      ]}
                      defaultValue={
                        (presenter.viewModel.reply && presenter.viewModel.reply.internal) ||
                        props.disableExternalComment
                          ? 'internal'
                          : presenter.viewModel.isExternalComment || presenter.isCrbApp
                          ? 'external'
                          : 'internal'
                      }
                      onChange={(selection: string | null) => {
                        const isExternalComment = selection === 'external';
                        presenter.markCommentExternal(isExternalComment);

                        if (isExternalComment) {
                          form.setValue('createTask', false);
                        }
                      }}
                      readOnly={!!presenter.viewModel.reply || props.disableExternalComment}
                    />
                  </ContainerItem>
                </Container>
              </ContainerItem>

              <ContainerItem padding="0" width="100%">
                <Box
                  sx={{
                    '& .mentions__input': {
                      outline: '0',
                      color: theme.palette.text.primary,
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                      border: `1px solid`,
                      background: (theme) => theme.palette.background.default,
                      borderColor: (theme) => theme.palette.divider,
                      borderRadius: '5px',
                      padding: '5px 10px',
                      width: '100%'
                    },
                    '& .mentions--singleLine .mentions__control': {
                      display: 'inline-block'
                    },
                    '& .mentions--singleLine .mentions__highlighter': {
                      outline: '0',
                      color: theme.palette.text.primary,
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                      border: `1px solid`,
                      background: (theme) => theme.palette.background.default,
                      borderColor: (theme) => theme.palette.divider,
                      borderRadius: '5px',
                      padding: '5px 10px',
                      width: '100%'
                    },
                    '& .mentions--singleLine .mentions__input': {
                      outline: '0',
                      color: theme.palette.text.primary,
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                      background: (theme) => theme.palette.background.default,
                      borderColor: (theme) => theme.palette.divider,
                      borderRadius: '5px',
                      padding: '5px 10px',
                      width: '100%'
                    },
                    '& .mentions--multiLine .mentions__control': {
                      outline: '0',
                      color: theme.palette.text.primary,
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                      background: (theme) => theme.palette.background.default,
                      borderColor: (theme) => theme.palette.divider,
                      borderRadius: '5px',
                      padding: '5px 10px',
                      width: '100%'
                    },
                    '& .mentions--multiLine .mentions__highlighter': {
                      border: '1px solid transparent'
                    },
                    '& .mentions--multiLine .mentions__input': {
                      outline: '0',
                      color: theme.palette.text.primary,
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                      background: (theme) => theme.palette.background.default,
                      borderColor: (theme) => theme.palette.divider,
                      borderRadius: '5px',
                      padding: '5px 10px',
                      width: '100%'
                    },
                    '& .mentions__suggestions__list': {
                      backgroundColor: 'white',
                      border: '1px solid rgba(0, 0, 0, 0.15)',
                      fontSize: '10pt',
                      maxHeight: '500px',
                      width: '325px',
                      overflow: 'scroll'
                    },
                    '& .mentions__suggestions__item': {
                      padding: '5px 15px',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
                      width: '325px'
                    },
                    '& .mentions__suggestions__item--focused': {
                      backgroundColor: '#cee4e5'
                    },
                    '& .mentions__mention': {
                      position: 'relative',
                      zIndex: '1',
                      color: theme.palette.primary.main,
                      textShadow: `1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
                          -1px -1px 1px white`,
                      textDecoration: 'underline',
                      pointerEvents: 'none'
                    },
                    '& .mentions--multiLine .mentions__input:focus': {
                      color: (theme) => theme.palette.text.primary,
                      borderColor: (theme) => theme.palette.primary.main,
                      outline: '0',
                      boxShadow: `0px 0px 0px 3px rgba(3, 166, 91, 0.25)`
                    }
                  }}
                >
                  <Container padding="0" width="100%">
                    <ContainerItem align="center" justify="center" height="36px" width="10%">
                      <input
                        type="file"
                        id="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={presenter.addFile}
                      />
                      <FileIcon
                        style={{ cursor: 'pointer', marginTop: '-2px' }}
                        onClick={() => fileInputRef?.current?.click()}
                        width="20px"
                      />
                    </ContainerItem>

                    <ContainerItem padding="8px 0 0 8px" width="90%">
                      <MentionsInput
                        allowSpaceInQuery
                        allowSuggestionsAboveCursor
                        value={presenter.viewModel.commentVal}
                        onChange={(event, newValue, newPlainTextValue, mentions) => {
                          presenter.setCommentValue(event.target.value);
                          presenter.mentionUsers(
                            mentions.map((m) => {
                              return {
                                id: m.id,
                                display: m.display,
                                type: m.type
                              };
                            })
                          );

                          if (!mentions.length) {
                            form.setValue('createTask', false);
                          }
                        }}
                        inputRef={commentInputRef}
                        className="mentions"
                        placeholder="Add a comment. @Mention someone to notify them."
                        style={{ minHeight: '4rem' }}
                      >
                        <Mention
                          appendSpaceOnAdd
                          trigger="@"
                          markup={'[@@@]__display__(__id__)[@@@]'}
                          data={presenter.viewModel.usersList}
                          renderSuggestion={(suggestion, search, highlightedDisplay) => {
                            const name = suggestion?.display || '';
                            const [first, last] = name
                              ?.replace(/\s+/g, ' ')
                              .trim()
                              .replace('@ ', '@')
                              .split(' ');
                            const posterInitials =
                              first && last ? first[1].toLocaleUpperCase() + last[0].toLocaleUpperCase() : '';

                            return (
                              <div className="user">
                                <Container padding="0px">
                                  <ContainerItem>
                                    <Avatar content={posterInitials} size="medium" />
                                  </ContainerItem>
                                  <ContainerItem>
                                    <Text content={cropInput(name.slice(1, 5000), 20)} type="body1" inline />
                                    {presenter.viewModel.usersDictionary[suggestion.id]?.orgId !==
                                    props.orgId ? (
                                      <div
                                        style={{
                                          marginLeft: '.5rem',
                                          fontFamily: 'Lato',
                                          fontStyle: 'normal',
                                          fontWeight: 'bold',
                                          lineHeight: '14px',
                                          fontSize: '10px',
                                          backgroundColor: 'rgba(198, 204, 218, 0.5)',
                                          borderRadius: '50px',
                                          padding: '2px 7px',
                                          height: '20px',
                                          display: 'inline-flex',
                                          justifyContent: 'center',
                                          flexDirection: 'column'
                                        }}
                                      >
                                        External
                                      </div>
                                    ) : null}

                                    <Text
                                      content={cropInput(
                                        presenter.viewModel.usersDictionary[suggestion.id]?.email || '',
                                        32
                                      )}
                                      type="body3"
                                    />
                                  </ContainerItem>
                                </Container>
                              </div>
                            );
                          }}
                          style={{
                            color: theme.palette.primary.main,
                            position: 'relative',
                            zIndex: '1',
                            textShadow: `1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
                              -1px -1px 1px white`,
                            border: '0px',
                            pointerEvents: 'none',
                            width: '100%'
                          }}
                        />
                      </MentionsInput>
                      {presenter.viewModel.hasError ? <Error content="Comment cannot be empty" /> : null}
                    </ContainerItem>
                  </Container>
                </Box>
              </ContainerItem>
            </Container>
          </ContainerItem>

          <ContainerItem justify="right" width="100%" padding="8px 0 4px 8px">
            <Container padding="0" width="100%">
              <ContainerItem justify="left" padding="0" width="253px" margin="0 0 0 38px">
                {tasksComments &&
                  window.location.href.includes('/secure/fi') &&
                  (presenter.viewModel.reply && presenter.viewModel.reply.hasOwnProperty('task') ? (
                    <>
                      <span
                        style={{
                          display: 'inline-block',
                          marginRight: '-16px',
                          marginTop: '8px',
                          width: '200px'
                        }}
                      >
                        <InputCheckBox
                          {...form}
                          readOnly={
                            !presenter.viewModel.isBankAdmin &&
                            !presenter.viewModel.currentTask?.assigned_users
                              ?.map((u) => u.id)
                              .includes(userStore.user.id) &&
                            presenter.viewModel.reply.posted_by !== userStore.user.id
                          }
                          label={'Mark Task as Complete'}
                          name={'completeTask'}
                        />
                      </span>
                    </>
                  ) : !presenter.viewModel.reply ? (
                    <>
                      <span style={{ display: 'inline-block', marginRight: '-16px', width: '125px' }}>
                        <InputCheckBox
                          {...form}
                          readOnly={!presenter.userMentioned}
                          label={'Create Task'}
                          name={'createTask'}
                        />
                      </span>

                      {!presenter.userMentioned && (
                        <span style={{ display: 'inline-block', width: '30px' }}>
                          <Tooltip
                            variant="text"
                            title={'@Mention someone to create a task from a comment.'}
                            placement="top"
                          >
                            <Icon type="help" color="primary" />
                          </Tooltip>
                        </span>
                      )}
                    </>
                  ) : null)}
              </ContainerItem>

              <ContainerItem justify="right" padding="0">
                <Button
                  label={presenter.viewModel.reply ? 'Reply' : 'Post'}
                  onClick={form.handleSubmit(onSubmitComment)}
                  color="primary"
                />
              </ContainerItem>
            </Container>
          </ContainerItem>

          {presenter.viewModel.files.length ? (
            <div style={{ maxHeight: '80px', overflow: 'auto', width: '367px' }}>
              {presenter.viewModel.files.map((file) => (
                <ContainerItem padding="0" width="100%" margin="2px 0">
                  <FileAtom
                    borderNone
                    fullWidth
                    paddingNone
                    file={file}
                    onRemoveFile={presenter.removeFile}
                  />
                </ContainerItem>
              ))}
            </div>
          ) : null}
        </Container>
      </MuiAccordion>

      {presenter.viewModel.isExternalConfirmationModalOpen && <CommentModalExternalConfirmation />}

      {presenter.viewModel.isCreateTaskModalOpen && (
        <CommentsModalTaskCreate submitCommentAndTask={onSubmitComment} />
      )}

      {presenter.viewModel.isMarkAsCompleteModalOpen && (
        <CommentsModalTaskComplete submitCommentAndTask={onSubmitComment} />
      )}
    </Box>
  );
});
