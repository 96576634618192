export const searchUtil = <T>(
  callback: (filteredList: T[]) => void,
  unfilteredList: T[],
  properties: (keyof T)[],
  value: string
) => {
  const filteredList = unfilteredList.filter((l) => {
    let match = false;

    for (const name of properties) {
      if ((l[name] as unknown as string).toLowerCase().includes(value.toLowerCase())) {
        match = true;
      }
    }

    return match;
  });

  callback(filteredList);
};
