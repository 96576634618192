import { AggregatedPosDataFetcherResults } from '@gcv/shared';
import { GcvAdminApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { SnackbarStore } from 'stores/SnackBarStore';

export interface GcvAccessDataFetcherHistoryPM {
  isLoading: boolean;
  aggregatedHistoryRecords: AggregatedPosDataFetcherResults[];
}

@injectable()
export class GcvAccessDataFetcherHistoryRepo {
  @inject(GcvAdminApi)
  private gcvAdminApi: GcvAdminApi;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  public gcvDataFetcherHistoryProgrammersModel: GcvAccessDataFetcherHistoryPM = {
    isLoading: true,
    aggregatedHistoryRecords: []
  };

  loadDataFetcherHistory = action((startDate: string, endDate: string) => {
    this.updateProgrammersModel({
      isLoading: true
    });
    this.gcvAdminApi
      .getAggregatedDataFetcherHistory(startDate, endDate)
      .then((records) => {
        this.updateProgrammersModel({
          aggregatedHistoryRecords: records
        });
      })
      .catch(() => {
        this.snackbarStore.showErrorSnackbarMessage('Failed to load data fetcher history');
      })
      .finally(() => {
        this.updateProgrammersModel({
          isLoading: false
        });
      });
  });

  updateProgrammersModel = action((programmersModel: Partial<GcvAccessDataFetcherHistoryPM>) => {
    this.gcvDataFetcherHistoryProgrammersModel = {
      ...this.gcvDataFetcherHistoryProgrammersModel,
      ...programmersModel
    };
  });
}
