import { GreenCheckAwsEnvironmentName } from 'domain/enums/green-check-aws-environment-name';
import { Environment } from './env-interface';

export const environment: Environment = {
  production: true,
  amplify: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:75185a74-d801-4a5d-a411-36e045c6a9e7',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_5L85M1ie7',
    aws_user_pools_web_client_id: '6tffno15077jjkb28ucb4scf4b'
  },
  gcvConfig: {
    launchDarklyClientSideId: '5ba1164b0a11eb3ed9f9514d',
    webSocketUrl: 'wss://76ue4aihh7.execute-api.us-east-1.amazonaws.com/Prod'
  },
  basePath: 'https://prod-okta.greencheckverified.com',
  banksApiBasePath: 'https://prod-banks-api.greencheckverified.com',
  storageConfig: {
    commentDocument: 'prod-comment-upload-documents-bucket',
    salesFile: 'prod-new-data-file-bucket',
    orgDocument: 'prod-org-documents-file-bucket',
    userDocument: 'prod-user-documents-file-bucket',
    sharedDocument: 'prod-gcv-shared-documents-file-bucket',
    coreTransactions: 'prod-core-transaction-data',
    sftpCoreTransactions: 'prod-sftp-core-transaction-data',
    publicFiles: 'prod-gcv-public-data-file-bucket'
  },
  env: GreenCheckAwsEnvironmentName.PROD,
  auth0Config: {
    domain: 'auth.greencheckverified.com',
    clientId: 'p8j2QPobxh4NGCTxAvEwf8GQRFClEPER'
  },
  payqwickDefaultRedirectUri: 'https://vault.payqwick.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
