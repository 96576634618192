import { FormControl, InputBaseComponentProps } from '@mui/material';
import * as React from 'react';
import { Control, Controller, FieldError, Validate } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Container, ContainerItem, Error, Icon, Input, InputLabel, Tooltip } from 'ui/atoms';
import { errorMessages } from 'util/forms.util';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputCurrencyProps {
  label: string;
  name: string;
  control?: Control;
  dataCy?: string;
  defaultValue?: string;
  onBlur?: () => void;
  onChange?: (e?: number) => void;
  readOnly?: boolean;
  viewOnly?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<string | number>;
    minLength?: ValidationRule<string | number>;
    max?: ValidationRule<string | number>;
    min?: ValidationRule<string | number>;
    pattern?: ValidationRule<RegExp>;
    validate?: Validate<string | number> | Record<string, Validate<string | number>>;
  };
  tooltip?: string;
  customInput?: React.ComponentType<any>;
}

export const InputCurrency: React.FC<InputCurrencyProps> = (props) => {
  const [fieldError, setFieldError] = React.useState<FieldError>();

  const isAllowed = (numberValue: NumberFormatValues) => {
    // previously part of the NumberFormat component (isAllowed prop) but was not triggered when
    // form values were changed programmatically, this way we can get the errors on every change
    // (and clear errors on every change too)
    let isValid = true;

    // allow entering negative sign
    if (numberValue.value === '-') {
      return isValid;
    }

    if (!numberValue.value) {
      // empty is still valid, but we also want an error message
      isValid = true;
      setFieldError({
        message: `${props.label} invalid. Currency field must contain a number.`,
        type: 'valueAsNumber'
      });
    } else if (isNaN(+numberValue.value)) {
      isValid = false;
      setFieldError({
        message: `${props.label} invalid. Currency field must contain a number.`,
        type: 'valueAsNumber'
      });
    } else {
      setFieldError(undefined);
    }

    return isValid;
  };

  return (
    <FormControl fullWidth data-cy={props.dataCy}>
      <Container padding={0} align="flex-start">
        <ContainerItem padding={0} align="center" flex margin="0 0 6px 0">
          <InputLabel
            dataCy={'input-label'}
            label={props.label}
            name={props.name}
            required={props.viewOnly ? false : props.rules?.required?.value}
            readOnly={props.readOnly}
            rules={props.rules}
            viewOnly={props.viewOnly}
          />
        </ContainerItem>

        {props.tooltip ? (
          <ContainerItem padding={0} align="center" flex margin="-3px 0 0 0">
            <Tooltip variant="text" title={props.tooltip} placement="right">
              <Icon type="help" color="primary" />
            </Tooltip>
          </ContainerItem>
        ) : null}
      </Container>

      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
          <>
            <NumberFormat
              className={'MuiInput-input MuiInputBase-input'}
              decimalSeparator={'.'}
              onBlur={() => {
                if (props.onBlur) {
                  props.onBlur();
                }

                onBlur();
              }}
              onValueChange={(e) => {
                isAllowed({
                  value: e.value,
                  floatValue: e.floatValue,
                  formattedValue: e.formattedValue
                });
                const number =
                  e.floatValue || e.floatValue === 0 ? Math.round(Number(e.floatValue) * 100) : undefined;

                if (props.onChange) {
                  props.onChange(number);
                }
                onChange(number);
              }}
              value={value == null ? '' : value / 100}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={props.readOnly || props.viewOnly}
              placeholder={'$0.00'}
              readOnly={props.readOnly}
              viewOnly={props.viewOnly}
              name={props.name}
              error={error}
              customInput={props.customInput ? props.customInput : Input}
            />

            <Error
              content={
                props.viewOnly
                  ? ''
                  : fieldError
                  ? `* ${fieldError.message || errorMessages()[fieldError.type]}`
                  : error
                  ? `* ${props.label} ${error.message || errorMessages()[error.type]}`
                  : ''
              }
              dataCy="validation-message"
            />
          </>
        )}
      />
    </FormControl>
  );
};
