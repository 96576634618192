import { Box, Step, StepButton, StepContent, Stepper as StepperContainer } from '@mui/material';
import { ReactComponent as CheckCircle } from 'assets/images/check-circle-filled-icon.svg';

import * as React from 'react';
import { theme } from 'ui/theme';

export type StepProps = {
  onNext: () => void;
  onBack: () => void;
  stepNumber: number;
  isLastStep: boolean;
  totalSteps: number;
};
export type StepContentProps = {
  header: string;
  handleStep: (step: number) => void;
  index: number;
};

export interface StepperStep {
  label: string;
  id: string;
  complete: boolean;
  Content?: (props: StepProps) => JSX.Element;
  ContentElement?: (props: StepContentProps) => JSX.Element;
  active?: boolean;
}

interface StepperProps {
  activeStep?: number;
  handleStep: (step: number) => void;
  disableStepperButton?: boolean;
  linear?: boolean;
  orientation?: 'horizontal' | 'vertical';
  steps: StepperStep[];
  dataCy?: string;
}

export const Stepper: React.FC<StepperProps> = (props) => {
  const orientation = props.orientation ? props.orientation : 'vertical';
  const linear = props.linear ? props.linear : false;

  const hasStepperButtonEnabled = (): boolean => {
    // Optional property allows for when we need the stepper button's click disabled.
    return !Boolean(props.disableStepperButton);
  };

  return (
    <StepperContainer
      activeStep={props.activeStep}
      nonLinear={!linear}
      orientation={orientation}
      data-cy={props.dataCy}
      sx={{
        '& .MuiStepConnector-line': {
          borderLeft: (theme) => `2px dotted ${theme.palette.divider}`,
          marginLeft: '40px'
        },
        '& .MuiStepContent-root': {
          borderLeft: (theme) => `2px dotted ${theme.palette.divider}`,
          marginLeft: '20px',
          marginBottom: '2px'
        }
      }}
    >
      {props.steps.map((step, index) => {
        return (
          <Step
            sx={{
              backgroundColor: props.activeStep === index ? 'rgba(3, 166, 91, 0.07)' : '',
              borderLeft: props.activeStep === index ? `4px solid ${theme.palette.primary.main} ` : null,
              margin: props.activeStep === index ? '2px 0' : '0'
            }}
            key={index}
            completed={step.complete}
            active={step.active}
          >
            <StepButton
              icon={
                !step.complete ? (
                  <Box
                    sx={{
                      border: (theme) =>
                        `1px solid ${
                          index === props.activeStep ? theme.palette.primary.main : theme.palette.divider
                        }`,
                      color: (theme) =>
                        index === props.activeStep ? theme.palette.primary.main : theme.palette.text.primary,
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      backgroundColor: 'white',
                      fontFamily: 'Lato',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '20px',
                      lineHeight: '37px',
                      textAlign: 'center',
                      alignContent: 'center'
                    }}
                  >
                    {index + 1}
                  </Box>
                ) : (
                  <CheckCircle height="40px" width="40px" style={{ color: theme.palette.primary.main }} />
                )
              }
              disableRipple
              data-cy={`${step.id}-step`}
              onClick={() => {
                if (hasStepperButtonEnabled()) {
                  props.handleStep(index);
                }
              }}
              sx={{ p: '0 0 0 31px', m: 0 }}
            >
              <Box
                sx={
                  index === props.activeStep
                    ? { color: (theme) => theme.palette.primary.main, fontWeight: 700 }
                    : null
                }
                data-cy="step-label"
              >
                {step.label}
              </Box>
            </StepButton>
            <StepContent>
              {step.ContentElement
                ? step.ContentElement({
                    header: step.label,
                    handleStep: props.handleStep,
                    index: index
                  })
                : // <br />
                  null}
              {/* empty line to enable animation when changing steps */}
              {/* removed empty line as per new designs, space between steps should be consistent */}
            </StepContent>
          </Step>
        );
      })}
    </StepperContainer>
  );
};
