export enum ErrorCodes {
  Unknown = -1,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  OtherHTTPError = 600
}

export abstract class BaseError extends Error {
  cause: Error;
  code: ErrorCodes;

  /**
   * An error message "guaranteed" safe to show end-users.
   */
  friendlyErrorMessage?: string | undefined;

  constructor(error: Error) {
    super();
    this.cause = error;
    this.code = ErrorCodes.Unknown;
    this.message = error?.message ?? 'Error';
  }
}
