import { colors } from '@mui/material';

export default {
  styleOverrides: {
    contained: {
      boxShadow: 'none',
      backgroundColor: colors.grey[100],
      '&:hover': {
        backgroundColor: colors.grey[300]
      },
      textTransform: 'none' as const
    },
    text: {
      textTransform: 'none' as const
    },
    endIcon: {
      marginLeft: 0
    },
    sizeSmall: {
      fontSize: '13px',
      height: '35px !important',
      lineHeight: '15px',
      padding: '10px 21px'
    }
  }
};
