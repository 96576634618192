import { DailySummary, IANATimezones } from '@gcv/shared';
import { BanksApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { AppViewStateStore } from 'stores/AppViewStateStore';
import { FiBankStore } from 'stores/FiBankStore';

export interface PM {
  dailySummaries: DailySummary[];
  loadingDailySummaries: boolean;
  timezone: IANATimezones;
  loadingDataError: boolean;
  chartUrl?: string;
}

@injectable()
export class FiDailySummariesRepo {
  @inject(BanksApi) private banksApi: BanksApi;
  @inject(AppViewStateStore) private appViewStore: AppViewStateStore;
  @inject(FiBankStore) private bankStore: FiBankStore;

  constructor() {
    makeAutoObservable(this);
  }
  programmersModel: PM = {
    dailySummaries: [],
    loadingDailySummaries: true,
    timezone: IANATimezones.America_NewYork,
    loadingDataError: false
  };

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = async () => {
    this.updateProgrammersModel({
      loadingDataError: false,
      loadingDailySummaries: true
    });

    try {
      const [summaries] = await Promise.all([this.fetchDailySummaries()]);

      this.updateProgrammersModel({
        dailySummaries: summaries,
        loadingDataError: false,
        loadingDailySummaries: false,
        timezone: this.bankStore.bank.iana_timezone
      });
    } catch (e) {
      this.updateProgrammersModel({
        loadingDataError: true,
        loadingDailySummaries: false,
        timezone: this.bankStore.bank.iana_timezone
      });
    }
  };

  fetchDailySummaries = async () => {
    try {
      return await this.banksApi.getDailySummaries(
        this.bankStore.bank.id,
        this.appViewStore.dailySummariesTimePeriod.dateRange.start,
        this.appViewStore.dailySummariesTimePeriod.dateRange.end
      );
    } catch (e) {
      throw new Error('There was an issue loading page data. Contact support for additional help.');
    }
  };
}
