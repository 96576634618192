import { DocumentUpload, OnboardingDocumentRequirementResult, TemplateResultResponse } from '@gcv/shared';
import { BanksApi, TemplateApi } from 'api';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FiBankStore } from 'stores/FiBankStore';
import { FiDispensaryStore } from 'stores/FiDispensaryStore';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { Button, Container, ContainerItem, Dialog, InputSelect, SelectOption } from 'ui';

interface Props extends Record<string, unknown> {
  copyFiles?: boolean;
  dispensaryId: string;
  documentIds: string[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (templateResult: TemplateResultResponse) => void;
  req: OnboardingDocumentRequirementResult;
  allDocumentsMap: {
    [id: string]: DocumentUpload;
  };
  otherDocs?: {
    location: string;
    description: string;
    document: DocumentUpload;
  }[];
}

export const MoveDocumentsModal: React.FC<Props> = useComponent((props) => {
  const bankStore = useInjection(FiBankStore);
  const dispensaryStore = useInjection(FiDispensaryStore);
  const templateApi = useInjection(TemplateApi);
  const banksApi = useInjection(BanksApi);

  const form = useForm();

  const [requirementOptions, setRequirementOptions] = React.useState<SelectOption[]>([]);
  const [isMovingDocument, setIsMovingDocument] = React.useState(false);

  React.useEffect(() => {
    const load = async () => {
      const dispensary = dispensaryStore.findDispensary(props.dispensaryId);

      if (!dispensary) {
        return;
      }

      const template = await templateApi.getBankTemplate(
        bankStore.bank.id,
        dispensary.assigned_onboarding_template.template_id,
        'latest'
      );

      const options: SelectOption[] = [];
      Object.values(template.requirements)
        .filter((tr) => tr.requirement_id !== 'business_licenses')
        .filter((r) => !r.archived)
        .filter((r) => r.requirement_id !== props.req.requirement_id)
        .forEach((r) => {
          const option = { label: r.name, value: r.requirement_id };
          options.push(option);
        });
      setRequirementOptions(options);
    };

    load();
  }, []);

  const getFileName = (docId: string): string => {
    return props.req.documents[docId]?.bank_friendly_name ?? props.allDocumentsMap[docId]?.file_name;
  };

  const getOtherLocation = (
    docId: string
  ): 'questionnaire_template' | 'due_diligence_template' | undefined => {
    if (props.req.requirement_id !== 'other') {
      return undefined;
    }

    const doc = props?.otherDocs?.find((d) => d.document.id === docId);

    if (!doc) {
      return 'due_diligence_template';
    }

    return doc?.location === 'Due Diligence Template' ? 'due_diligence_template' : 'questionnaire_template';
  };

  const onSubmit = async (data: FieldValues) => {
    setIsMovingDocument(true);
    let hasError = false;

    for (const docId of props.documentIds) {
      try {
        const response = await banksApi.moveDocument(
          bankStore.bank.id,
          props.dispensaryId,
          docId,
          props.req.requirement_id,
          data.requirement,
          getOtherLocation(docId)
        );

        props.onSubmit(response);
      } catch (e) {
        console.log(e);
        hasError = true;
        getSnackbarStore().showErrorSnackbarMessage(`Failed to move document ${getFileName(docId)}`);
      }
    }

    if (!hasError) {
      getSnackbarStore().showSuccessSnackbarMessage('Document(s) moved successfully');
    }

    setIsMovingDocument(false);
    props.onClose();
  };

  return (
    <Dialog
      handleClose={props.onClose}
      onCancel={props.onClose}
      title={props.copyFiles ? 'Copy to Requirement' : 'Move document(s)'}
      isOpen={props.isOpen}
      action={
        <Button
          useMinWidth
          color="primary"
          label={props.copyFiles ? 'Copy to Requirement' : 'Move'}
          onClick={form.handleSubmit((data) => onSubmit(data))}
          isLoading={isMovingDocument}
        />
      }
    >
      <Container width="100%">
        <ContainerItem justify="center" width="100%">
          <p>
            The following document(s) will be {props.copyFiles ? 'copied' : 'moved'}:
            <br />
            {props.documentIds.map((d, i) => (
              <span key={d}>
                {getFileName(d)}
                {props.documentIds.length - 1 !== i && <>, </>}
              </span>
            ))}
          </p>
        </ContainerItem>
        <ContainerItem width="100%">
          <InputSelect
            {...form}
            placeholder="Select destination"
            label={props.copyFiles ? 'Copy to' : 'Move to'}
            name={'requirement'}
            options={requirementOptions}
          />
        </ContainerItem>
      </Container>
    </Dialog>
  );
});
