import { InputLabel as MuiInputLabel } from '@mui/material';
import * as React from 'react';
import { Validate } from 'react-hook-form';
import { Container, ContainerItem, Icon, Tooltip } from 'ui';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface Props {
  dataCy?: string;
  name: string;
  label: string;
  readOnly?: boolean;
  id?: string;
  required?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<string | number>;
    minLength?: ValidationRule<string | number>;
    max?: ValidationRule<string | number>;
    min?: ValidationRule<string | number>;
    pattern?: ValidationRule<RegExp>;
    validate?: Validate<string | number> | Record<string, Validate<string | number>>;
  };
  viewOnly?: boolean;
  tooltip?: string;
  darkLabel?: boolean;
}

export const InputLabel: React.FC<Props> = (props) => {
  return (
    <Container align="center" height={'fit-content'} padding={0}>
      <ContainerItem flex align="center" padding={0}>
        <MuiInputLabel
          shrink
          data-cy={props.dataCy}
          htmlFor={props.name}
          required={props.viewOnly ? false : props.required}
          sx={{
            color: (theme) =>
              props.darkLabel
                ? theme.palette.text.primary
                : props.viewOnly
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
            position: 'relative',
            fontFamily: 'Lato',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '15px',
            maxWidth: '100%',
            transform: 'none',
            transition: 'none',
            whiteSpace: 'normal'
          }}
        >
          {props.label}
        </MuiInputLabel>
      </ContainerItem>
      {props.tooltip ? (
        <ContainerItem padding={0} align="center" flex margin="-3px 0 0 2px">
          <Tooltip variant="text" title={props.tooltip} placement="right">
            <Icon type="help" color="primary" />
          </Tooltip>
        </ContainerItem>
      ) : null}
    </Container>
  );
};
