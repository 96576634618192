import { DocumentsApi, S3DocumentLink } from 'api';
import { environment } from 'environments/environment';
import { encodeFileForUpload } from './files.util';

export interface DocumentConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document?: any;
  orgId?: string;
  userType?: 'bank' | 'dispensary' | 'gcv';
  s3_key?: string;
}

type S3Payload = string | Blob;

function createPUTRequestInit(payload: S3Payload) {
  return {
    method: 'PUT',
    headers: {
      'Content-Type': 'text/plain'
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: payload // body data type must match "Content-Type" header
  } as RequestInit;
}

async function createPayload(file: File, encode = false): Promise<S3Payload> {
  return new Promise<S3Payload>((resolve, reject) => {
    if (encode) {
      encodeFileForUpload(file)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve(file as Blob);
    }
  });
}

async function uploadFileToS3(link: S3DocumentLink, file: File, encode = false) {
  return new Promise<string>((resolve, reject) => {
    createPayload(file, encode)
      .then((payload) => {
        return fetch(link.s3LinkPath, createPUTRequestInit(payload));
      })
      .then(() => {
        resolve(link.s3_key);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function uploadDocumentToS3(
  bucket: string,
  keyPath: string,
  document: File,
  encode = false
): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const documentsApi = new DocumentsApi();

    documentsApi
      .getDocumentLink(bucket, keyPath, 'put')
      .then((link) => {
        return uploadFileToS3(link, document, encode);
      })
      .then((key) => {
        resolve(key);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function normalizeKeyPath(keyPath: string): string {
  // These selections appear in the UI as instructions to the user.
  const permittedExtensions = ['csv', 'tsv', 'xls', 'xlsx', 'xml', 'txt'];

  if (permittedExtensions.includes(keyPath.split('.').pop() ?? '')) {
    return keyPath;
  } else {
    // The back-end will fail if we don't have a file extension.
    return `${keyPath}.txt`;
  }
}

async function uploadFlatFileToS3(bucket: string, flatFile: string, keyPath: string): Promise<string> {
  // A file ingested via Flatfile (https://flatfile.com) has to be handled differently.
  return new Promise<string>((resolve, reject) => {
    const documentsApi = new DocumentsApi();

    documentsApi
      .getDocumentLink(bucket, normalizeKeyPath(keyPath), 'put')
      .then((link) => {
        fetch(link.s3LinkPath, createPUTRequestInit(flatFile)).then(() => {
          resolve(link.s3_key);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function uploadFiCoreTransactionsFlatFileToS3(
  flatFile: string,
  keyPath: string
): Promise<string> {
  return uploadFlatFileToS3(environment.storageConfig.coreTransactions, flatFile, keyPath);
}

export async function uploadCrbSalesTransactionFlatFileToS3(
  flatFile: string,
  keyPath: string
): Promise<string> {
  return uploadFlatFileToS3(environment.storageConfig.salesFile, flatFile, keyPath);
}

export async function uploadGcvSharedDocumentToS3(file: File, orgId: string): Promise<string> {
  const keyPath = `${orgId}/${file.name}`;
  return uploadDocumentToS3(environment.storageConfig.sharedDocument, keyPath, file, true);
}

export async function uploadUserDocumentToS3(file: File, userId: string): Promise<string> {
  const keyPath = `${userId}/${file.name}`;
  return uploadDocumentToS3(environment.storageConfig.userDocument, keyPath, file, true);
}

export async function uploadOrgDocumentToS3(file: File, orgId: string): Promise<string> {
  const keyPath = `${orgId}/${file.name}`;
  return uploadDocumentToS3(environment.storageConfig.orgDocument, keyPath, file, true);
}

export async function uploadCommentDocumentToS3(file: File, orgId: string): Promise<string> {
  const keyPath = `${orgId}/${file.name}`;
  return uploadDocumentToS3(environment.storageConfig.commentDocument, keyPath, file, true);
}

/** Uploads a file to our public s3 bucket. Does not encode files. Used for bank theme logo and email logo */
export async function uploadPublicDocumentToS3(file: File, orgId: string): Promise<string> {
  const keyPath = `${orgId}/${file.name}`;
  return uploadDocumentToS3(environment.storageConfig.publicFiles, keyPath, file);
}

export async function viewDocument(config: DocumentConfig, documentId: string, bucket?: string) {
  const documentsApi = new DocumentsApi();

  try {
    const { s3_key } = await documentsApi.getDocument(config.orgId ?? '', documentId);
    const configWithS3Key: DocumentConfig = { ...config, s3_key };
    const { s3LinkPath } = await getPresignedUrl(configWithS3Key, 'get', bucket);

    const body = await fetch(s3LinkPath, {
      headers: { 'Content-Type': 'text/plain' },
      method: 'GET'
    }).then((response) => response.blob());

    return body;
  } catch (e) {
    console.log(e);
  }
}

export async function getDocResponse(config: DocumentConfig, documentId: string, bucket?: string) {
  const documentsApi = new DocumentsApi();

  try {
    const { s3_key } = await documentsApi.getDocument(config.orgId ?? '', documentId);
    const configWithS3Key: DocumentConfig = { ...config, s3_key };
    const { s3LinkPath } = await getPresignedUrl(configWithS3Key, 'get', bucket);

    return await fetch(s3LinkPath, {
      headers: { 'Content-Type': 'text/plain' },
      method: 'GET'
    });
  } catch (e) {
    console.log(e);
  }
}

async function getPresignedUrl(config: DocumentConfig, action: 'put' | 'get', bucket?: string) {
  const documentsApi = new DocumentsApi();
  const { s3_key, userType } = config;

  return await documentsApi.getDocumentLink(
    bucket ? bucket : `${environment.env}-org-documents-file-bucket`,
    s3_key ? s3_key : '',
    action,
    userType
  );
}

export function encodeS3Filename(filename: string): string | undefined {
  try {
    const encodings: { [key: string]: string } = {
      '+': '%2B',
      '!': '%21',
      '"': '%22',
      '#': '%23',
      $: '%24',
      '&': '%26',
      "'": '%27',
      '(': '%28',
      ')': '%29',
      '*': '%2A',
      ',': '%2C',
      ':': '%3A',
      ';': '%3B',
      '=': '%3D',
      '?': '%3F',
      '@': '%40'
    };
    const encodedName = filename.replace(/([+!"#$&'()*+,:;=?@])/gim, (match) => encodings[match]);
    return encodedName;
  } catch (e) {
    console.log(e);
  }
}
