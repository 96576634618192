import { useComponent } from 'hooks/useComponent';
import { useInjection } from 'ioc';
import { debounce, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Button, Container, ContainerItem, OnBoardingPage, Spinner } from 'ui';
import { AddressCard } from './components/address-card';
import { BusinessDetailsCard } from './components/business-details-card';
import { ContactCard } from './components/contact-card';
import { OnboardingPresenter } from '../../onboarding.presenter';

interface Props extends Record<string, unknown> {
  onNext: () => void;
  onBack: () => void;
  isLastStep: boolean;
  stepNumber: number;
  totalSteps: number;
}

const BusinessDetails: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const subtitle = presenter.viewModel.provider.orgName || 'Your service provider';
  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: presenter.viewModel.dispensary.name,
      dba: presenter.viewModel.dispensary.dba,
      entityType: presenter.viewModel.dispensary.entityType,
      establishedDate: presenter.viewModel.dispensary.establishedDate,
      iana_timezone: presenter.viewModel.dispensary.iana_timezone,
      ein: presenter.viewModel.dispensary.ein,
      streetAddress: presenter.viewModel.dispensary.streetAddress,
      city: presenter.viewModel.dispensary.city,
      state: presenter.viewModel.dispensary.state,
      postalCode: presenter.viewModel.dispensary.postalCode,
      mailingStreetAddress: presenter.viewModel.dispensary.mailingStreetAddress,
      mailingCity: presenter.viewModel.dispensary.mailingCity,
      mailingState: presenter.viewModel.dispensary.mailingState,
      mailingPostalCode: presenter.viewModel.dispensary.mailingPostalCode,
      phone: presenter.viewModel.dispensary.phone,
      website: presenter.viewModel.dispensary.website,
      sameMailingAddress:
        !presenter.viewModel.dispensary.mailingStreetAddress ||
        presenter.viewModel.dispensary.streetAddress === presenter.viewModel.dispensary.mailingStreetAddress
    } as FieldValues
  });

  const onChangeSave = React.useCallback(
    debounce(() => {
      presenter.autoSaveBusinessDetails(form.getValues());
    }, 2000),
    []
  );

  return (
    <OnBoardingPage
      alignBottomActions="center"
      title="Business Details"
      subtitle={`${subtitle} will use this information to confirm details about your business.`}
      step={props.stepNumber}
      totalSteps={props.totalSteps}
      bgCard
      bottomActions={
        <Container padding={0}>
          <ContainerItem>
            <Button color="default-outlined" label="Back" onClick={props.onBack} dataCy="back-button" />
          </ContainerItem>
          <ContainerItem>
            <Button
              label={props.isLastStep ? 'Submit' : 'Continue'}
              color="primary"
              onClick={form.handleSubmit((data) =>
                presenter.submitBusinessDetails(data, props.onNext, props.isLastStep)
              )}
              dataCy="save-button"
              isLoading={presenter.viewModel.isSavingBusinessDetails}
            />
          </ContainerItem>
        </Container>
      }
      dataCy="business-details-page"
    >
      {!presenter.viewModel.isLoading ? (
        <Container padding="0">
          <ContainerItem padding="0" width="100%">
            <BusinessDetailsCard form={form} isMobile={isMobile} onChange={onChangeSave} />
          </ContainerItem>

          <ContainerItem padding="0" width="100%">
            <AddressCard form={form} isMobile={isMobile} onChange={onChangeSave} />
          </ContainerItem>

          <ContainerItem padding="0" width="100%">
            <ContactCard form={form} isMobile={isMobile} onChange={onChangeSave} />
          </ContainerItem>
        </Container>
      ) : (
        <Spinner />
      )}
    </OnBoardingPage>
  );
});
export default BusinessDetails;
