import { AccordionSummary, Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as DropDownArrow } from 'assets/images/down-arrow-grey.svg';
import { ReactComponent as CommentIcon } from 'assets/images/ic-comment.svg';
import { ReactComponent as DropUpArrow } from 'assets/images/up-arrow-grey.svg';
import { useInjection } from 'ioc';
import * as React from 'react';
import { CommentStore } from 'stores/CommentStore';
import { Badge, Container, ContainerItem, Header } from 'ui';
import { CommentsPresenter } from '../comments.presenter';
import { useComponent } from 'hooks';

export const CommentsBarHeader: React.FC = useComponent(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const presenter = useInjection(CommentsPresenter);
  const commentStore = useInjection(CommentStore);

  return (
    <AccordionSummary
      sx={{
        '& .MuiAccordionSummary-content': {
          m: 0
        }
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: commentStore.commentsOpen ? (isMobile ? '340px' : '384px') : 'auto'
        }}
      >
        <Container column padding={0} width="100%">
          <ContainerItem flex padding={isMobile ? '10px' : '10px'} align="center" width="100%">
            <Box
              data-cy="comment-bar-header"
              onClick={() => commentStore.openComments(!commentStore.commentsOpen)}
              sx={{
                justifyContent: commentStore.commentsOpen ? 'space-between' : 'flex-start',
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}
            >
              <ContainerItem padding={0} flex align="center" margin="0 10px 0 0">
                <Badge
                  color={'primary'}
                  invisible={!isMobile || commentStore.commentsOpen || presenter.activeCommentsCount === 0}
                  badgeContent={presenter.activeCommentsCount}
                >
                  <CommentIcon
                    style={{
                      marginRight: isMobile && !commentStore.commentsOpen ? '0' : '8px'
                    }}
                  />
                </Badge>

                {!isMobile || commentStore.commentsOpen ? (
                  <Header
                    type="h5"
                    content={
                      commentStore.postTitle && commentStore.postTitle.length > 35
                        ? commentStore.postTitle.substring(0, 35) + '...'
                        : commentStore.postTitle
                    }
                    textOverflow="ellipsis"
                  />
                ) : undefined}

                {!isMobile || commentStore.commentsOpen ? (
                  <Box
                    sx={{
                      paddingLeft: '5px',
                      display: 'inline-block',
                      ml: '12px'
                    }}
                  >
                    <Badge color={'primary'} badgeContent={presenter.activeCommentsCount}></Badge>
                  </Box>
                ) : null}
              </ContainerItem>

              {commentStore.commentsOpen ? (
                <DropDownArrow
                  style={{
                    marginLeft: isMobile ? '0' : '8px',
                    alignItems: 'center',
                    display: 'flex',
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px'
                  }}
                />
              ) : (
                <DropUpArrow
                  style={{
                    marginLeft: isMobile ? '0' : '8px',
                    alignItems: 'center',
                    display: 'flex',
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px'
                  }}
                />
              )}
            </Box>
          </ContainerItem>

          <ContainerItem padding={0} width="100%">
            <Box
              sx={{
                borderBottom: (theme) =>
                  commentStore.commentsOpen ? `1px solid ${theme.palette.divider}` : '0px',
                height: '1px'
              }}
            />
          </ContainerItem>
        </Container>
      </Box>
    </AccordionSummary>
  );
});
