/* eslint-disable */
interface WithKey {
  [key: string]: any;
}

export function deepEqual(x: WithKey, y: WithKey): boolean {
  const ok = Object.keys,
    tx = typeof JSON.parse(JSON.stringify(x)),
    ty = typeof JSON.parse(JSON.stringify(y));
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
}

interface Item<T = any> {
  [key: string]: T;
}

export function getObjectMap<T extends Item>(array: T[], mapKeyProperty: keyof T): Item<T> {
  const map: Record<keyof T, T> = {} as Record<keyof T, T>;

  for (const item of array) {
    map[item[mapKeyProperty]] = item;
  }

  return map;
}

export function filterOutEmptyProperties<T extends Record<string, unknown>>(obj: T): T {
  Object.keys(obj).forEach(
    (key) => (obj[key] === undefined || obj[key] === null || obj[key] === '') && delete obj[key]
  );

  return obj;
}

export function filterObjectMap<T>(
  map: { [key: string]: T },
  filterFunction: (mapValue: T) => boolean
): { [key: string]: T } {
  const filteredObject: { [key: string]: T } = {};
  Object.entries(map).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];
    if (filterFunction(value)) {
      filteredObject[key] = entry[1];
    }
  });
  return filteredObject;
}
/* eslint-enable */
