import { useComponent } from 'hooks/useComponent';
import * as React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Header, Container, ContainerItem, Form, InputDate, InputText } from 'ui';
import { useInjection } from 'ioc';
import { OnboardingPresenter } from '../../../onboarding.presenter';

interface Props extends Record<string, unknown> {
  form: UseFormReturn<FieldValues>;
  isMobile: boolean;
  onChange?: () => void;
}
export const PersonalDetailsForm: React.FC<Props> = useComponent(({ form, isMobile, onChange }) => {
  const presenter = useInjection(OnboardingPresenter);
  return (
    <Container padding="0" dataCy="personal-details-card">
      <Container padding="0 0 8px 0">
        <Header mobileHeader={isMobile} content="Personal Details" type="h2" />
      </Container>
      <Form>
        <Container padding="0" width="100%">
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputText
              label="First Name"
              name="firstName"
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="first-name"
              onChange={onChange}
              onBlur={onChange}
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputText
              label="Middle Name"
              name="minitial"
              dataCy="middle-name"
              onChange={onChange}
              onBlur={onChange}
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputText
              label="Last Name"
              name="lastName"
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="last-name"
              onChange={onChange}
              onBlur={onChange}
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputDate
              label="Date of Birth"
              name="dateOfBirth"
              timezone={presenter.viewModel.dispensary.iana_timezone}
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="date-of-birth"
              onChange={onChange}
              onBlur={onChange}
              {...form}
            />
          </ContainerItem>
        </Container>
      </Form>
    </Container>
  );
});
