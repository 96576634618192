import { Box } from '@mui/material';
import * as React from 'react';
import { Text } from 'ui/atoms';
import { ReactComponent as CheckIcon } from 'assets/images/check-circle-filled-icon.svg';
import palette from 'ui/theme/palette';

interface Props {
  type: 'warning' | 'default' | 'info';
  title: string | JSX.Element;
  subtitle?: string; // Available only if title is of type string.
  status?: string | React.ReactNode;
  action?: React.ReactNode;
}

export const NoticeBar: React.FC<Props> = (props) => {
  const backgroundColor =
    props.type === 'warning'
      ? palette.warning.light
      : props.type === 'info'
      ? '#98c5ff66'
      : palette.background.secondary;
  return (
    <Box
      data-cy="noticebar-container"
      sx={{
        padding: '8px 35px',
        height: '57px',
        background: backgroundColor,
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        {typeof props.title === 'string' ? (
          <Text content={props.title} dataCy="dialog-title" sx={{ fontWeight: 'bold' }} />
        ) : (
          props.title
        )}

        {props.subtitle && typeof props.title === 'string' && (
          <Text content={props.subtitle} dataCy="dialog-title" sx={{ marginLeft: '4px' }} />
        )}
      </Box>
      {(props.status || props.action) && (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          {props.status && typeof props.status === 'string' ? (
            <>
              <CheckIcon width="20px" height="20px" />
              <Text content={props.status} sx={{ color: palette.primary.main, marginLeft: '4px' }} />
            </>
          ) : (
            props.status
          )}
          {props.action ? <Box sx={{ marginLeft: '14px' }}> {props.action} </Box> : null}
        </Box>
      )}
    </Box>
  );
};
