import { Box } from '@mui/material';
import { width } from '@mui/system';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import * as React from 'react';
import { Container, ContainerItem, Title } from 'ui/atoms';

interface Props {
  align: 'left' | 'center' | 'right';
  handleClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void;
  title: string;
  width?: string; // added this prop to increase the width of title for bigger width dialogs
  isMobile?: boolean;
  hideClose?: boolean;
}

export const DialogHeader: React.FC<Props> = (props) => {
  return (
    <Container
      dataCy="dialog-header"
      margin={props.isMobile ? '17px 17px 12px 17px' : '24px'}
      align="flex-start"
      padding="0px"
    >
      <ContainerItem grow width="80%" margin="0.25rem 0 0 0" padding="0px">
        <Title mobileHeader={props.isMobile} justify="left" content={props.title} dataCy="dialog-title" />
      </ContainerItem>
      {!props.hideClose && (
        <ContainerItem margin="0px" padding="0px">
          <Box sx={{ '&:hover': { color: (theme) => theme.palette.primary.main } }}>
            <CloseIcon
              width="30px"
              height="30px"
              data-cy="close-icon"
              onClick={(e) => props.handleClose(e, 'closeIcon')}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </ContainerItem>
      )}
    </Container>
  );
};
