import { Bank, MinifiedCrbTemplateInfo } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, reaction } from 'mobx';
import { FiBankStore } from 'stores/FiBankStore';
import { FiDispensaryStore } from 'stores/FiDispensaryStore';
import { getLicenseType } from 'util/org.util';
import { capitalizeWords } from 'util/strings.util';

export interface AccountRow {
  id: string;
  name: string;
  city: string;
  state: string;
  orgType: string;
  muo_id: string;
  assigned_onboarding_template: MinifiedCrbTemplateInfo;
}

export interface PM {
  archivedAccountsList: AccountRow[];
  bank: Bank | null;
  isLoading: boolean;
}

@injectable()
export class FiAccountsArchivedRepo {
  @inject(FiBankStore)
  private bankStore: FiBankStore;

  @inject(FiDispensaryStore)
  private dispensaryStore: FiDispensaryStore;

  get dispensaryParentAccounts() {
    return this.dispensaryStore.dispensariesArchived
      .map((dispensary) => {
        const parent = this.dispensaryStore.bankMuos
          .concat(this.dispensaryStore.bankMuosArchived)
          .find((m) => m.id === dispensary.muo_id);

        return { label: parent?.name || 'None', value: parent?.id || '--' };
      })
      .filter((dispensary, idx, dispensaries) => {
        const exists = dispensaries.findIndex((disp) => disp.value === dispensary.value) === idx;
        if (dispensary.label === 'None') return false;
        return exists;
      });
  }

  get dispensaryLicenseTypes() {
    return [
      ...new Set(
        this.dispensaryStore.dispensariesArchived.map((dispensary) => {
          if (!getLicenseType(dispensary)) {
            return '--';
          }
          return capitalizeWords(getLicenseType(dispensary));
        })
      )
    ].map((licenseType) => {
      if (licenseType === '--') {
        return {
          label: 'None',
          value: licenseType
        };
      }
      return {
        label: licenseType,
        value: licenseType
      };
    });
  }

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => [this.dispensaryStore.dispensariesArchived, this.dispensaryStore.bankMuosArchived],
      this.mapArchiveAccounts
    );
  }

  public programmersModel: PM = {
    bank: null,
    archivedAccountsList: [],
    isLoading: true
  };

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = action(async () => {
    this.mapArchiveAccounts();
    this.updateProgrammersModel({
      bank: this.bankStore.bank,
      isLoading: false
    });
  });

  public mapArchiveAccounts = () => {
    const accountList = this.dispensaryStore.dispensariesArchived.map((d) => {
      return {
        id: d.id,
        name: d.name,
        city: d.city,
        state: d.state,
        orgType: d.orgType,
        muo_id: d.muo_id ?? '',
        assigned_onboarding_template: d.assigned_onboarding_template
      };
    });

    const accountMuoList = this.dispensaryStore.bankMuosArchived.map((d) => {
      return {
        id: d.id,
        name: d.name,
        city: d.city,
        state: d.state,
        orgType: d.orgType,
        muo_id: '',
        assigned_onboarding_template: d.assigned_onboarding_template
      };
    });

    this.updateProgrammersModel({
      archivedAccountsList: [...accountList, ...accountMuoList]
    });
  };
}
