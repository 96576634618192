import { StateRulesResponse } from '@gcv/shared';
import { GcvAdminApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { SnackbarStore } from 'stores/SnackBarStore';

export interface ComplianceRulesPM {
  isLoading: boolean;
  stateRules: StateRulesResponse;
}

@injectable()
export class ComplianceRulesRepo {
  @inject(GcvAdminApi)
  private gcvAdminApi: GcvAdminApi;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  public complianceRulesProgrammersModel: ComplianceRulesPM = {
    isLoading: true,
    stateRules: {}
  };

  load = action(() => {
    this.gcvAdminApi
      .getComplianceRulesLastModifiedData()
      .then((stateRulesResponse) => {
        this.updateProgrammersModel({ stateRules: stateRulesResponse });
      })
      .catch(() => {
        this.snackbarStore.showErrorSnackbarMessage('Failed to load compliance information');
      })
      .finally(() => {
        this.updateProgrammersModel({ isLoading: false });
      });
  });

  updateProgrammersModel = action((programmersModel: Partial<ComplianceRulesPM>) => {
    this.complianceRulesProgrammersModel = { ...this.complianceRulesProgrammersModel, ...programmersModel };
  });
}
