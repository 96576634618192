import { IANATimezones, TaskAssociation } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { FieldValues } from 'react-hook-form';
import { CreateTaskModalData, FormData, TaskAssignee, TaskAssigneeType } from './tasks-modal.model';
import { FiTasksModalRepo, PM } from './tasks-modal.repo';
import { DateTimeHelpers } from 'util/dateTime.util';
import { createPastDateValidator } from 'util/validation.util';
import { SelectOption } from 'ui';
import { DateTime } from 'luxon';

const NO_ASSOCIATED_ACCOUNT_ID = '0000000000000000';

export interface VM extends PM {
  isLoading: boolean;
  defaultFormData: FieldValues;
  associatedWithOptions: SelectOption[];
  loadingAssignees: boolean;
}

@injectable()
export class FiTasksModalPresenter {
  @inject(FiTasksModalRepo)
  private repo: FiTasksModalRepo;

  private getDefaultFormData = (timezone: IANATimezones) => {
    const data = {
      title: '',
      assigneeId: '',
      description: '',
      associatedDispensaryId: this.noAssociatedAccount.value,
      associatedArea: '',
      dueDate: DateTime.now()
        .plus({ days: 7 })
        .setZone(timezone, { keepLocalTime: true })
        .startOf('day')
        .toUTC()
        .toISO(),
      dueDateInternal: DateTime.now()
        .plus({ days: 7 })
        .setZone(timezone, { keepLocalTime: true })
        .startOf('day')
        .toUTC()
        .toISO()
    };

    return { ...data } as FieldValues;
  };

  public readonly noAssociatedAccount = {
    label: 'No Associated Account',
    value: NO_ASSOCIATED_ACCOUNT_ID
  } as SelectOption;

  public viewModel: VM = {
    isLoading: true,
    defaultFormData: {},
    associatedWithOptions: [],
    assignees: [],
    accounts: [this.noAssociatedAccount],
    dispensaries: [],
    timezone: IANATimezones.America_NewYork,
    loadingAssignees: false
  };

  public validateDueDate;

  constructor() {
    makeAutoObservable(this);
    // This is a stub that is updated when we load.
    this.validateDueDate = createPastDateValidator(IANATimezones.America_NewYork);
  }

  public transformFormData = (data: FormData, assigneesModel: TaskAssignee[]): CreateTaskModalData => {
    const assignee = assigneesModel.find((a) => a.id === data.assigneeId);

    return {
      title: data.title,
      description: data.description,
      assignedUserIds: assignee?.type === TaskAssigneeType.USER ? assignee?.userIds : undefined,
      assignedGroupIds: assignee?.type === TaskAssigneeType.GROUP ? assignee?.userIds : undefined,
      associatedArea: data.associatedArea,
      dueDate: DateTimeHelpers.getEndOfDateIsoString(
        DateTimeHelpers.formatJsDateToISO(new Date(data.dueDate), this.viewModel.timezone),
        this.viewModel.timezone
      ),
      dueDateInternal: DateTimeHelpers.getEndOfDateIsoString(
        DateTimeHelpers.formatJsDateToISO(new Date(data.dueDate), this.viewModel.timezone),
        this.viewModel.timezone
      ),
      associatedDispensaryId:
        data.associatedDispensaryId !== this.noAssociatedAccount.value ? data.associatedDispensaryId : null,
      assignedOrgId: data.taskType === 'external' ? data.associatedDispensaryId : null
    } as CreateTaskModalData;
  };

  public updateAssignees = action(async (orgId: string) => {
    this.viewModel.loadingAssignees = true;
    const assignees = await this.repo.getAssignees(orgId);
    this.viewModel.assignees = assignees;
    this.viewModel.loadingAssignees = false;
    return assignees;
  });

  public load = action(async () => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      const accounts = [this.noAssociatedAccount, ...programmersModel.accounts];
      const dispensaries = programmersModel.dispensaries;

      const associatedWithOptions: SelectOption[] = [
        { label: 'Accounts', value: TaskAssociation.ACCOUNTS },
        { label: 'FinCEN Reports', value: TaskAssociation.FINCEN },
        { label: 'Deposit Records', value: TaskAssociation.DEPOSIT_RECORDS },
        { label: 'Monitoring Reviews', value: TaskAssociation.MONITORING_REVIEWS },
        { label: 'Sales Data', value: TaskAssociation.SALES_DATA },
        { label: 'Other', value: TaskAssociation.OTHER }
      ];

      this.validateDueDate = createPastDateValidator(programmersModel.timezone);
      this.updateViewModel({
        ...this.viewModel,
        defaultFormData: this.getDefaultFormData(programmersModel.timezone),
        ...programmersModel,
        associatedWithOptions,
        accounts,
        dispensaries
      });
    });

    await this.repo.load();
    this.updateViewModel({ isLoading: false });
  });

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });
}
