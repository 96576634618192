import { useComponent } from 'hooks/useComponent';
import { debounce, useMediaQuery, useTheme } from '@mui/material';
import { CommentType } from '@gcv/shared';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { useInjection } from 'ioc';

import { Button, Container, ContainerItem, OnBoardingPage, Spinner } from 'ui';
import { getCommentStore } from 'stores/CommentStore';
import { IdentificationForm } from './components/identification-form';
import { PersonalDetailsForm } from './components/personal-details-form';
import { ContactInfoForm } from './components/contact-info';
import { UserHomeAddressForm } from './components/user-home-address-form';
import { OnboardingPresenter } from '../../onboarding.presenter';

interface Props extends Record<string, unknown> {
  onBack: () => void;
  onNext: () => void;
  isLastStep: boolean;
  stepNumber: number;
  totalSteps: number;
}

export const IdVerification: React.FC<Props> = useComponent((props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const presenter = useInjection(OnboardingPresenter);
  const commentStore = getCommentStore();

  const subtitle = presenter.viewModel.provider.orgName || 'Your service provider';

  React.useEffect(() => {
    commentStore.setCurrentPost({
      type: CommentType.ACCOUNT,
      title: presenter.viewModel.dispensary.name,
      idComponents: {
        orgId: presenter.viewModel.dispensary.id,
        crbId: presenter.viewModel.dispensary.id,
        fiId: presenter.viewModel.provider.id
      }
    });
    loadForm();
  }, []);

  const form = useForm({
    mode: 'onBlur',
    defaultValues: { hasUploadedId: false } as FieldValues
  });

  const uploadedIdCallback = (isUploaded: boolean) => {
    form.setValue('hasUploadedId', isUploaded);
    form.trigger('hasUploadedId');
  };

  const loadForm = async () => {
    form.reset({
      firstName: presenter.viewModel.user.firstName,
      minitial: presenter.viewModel.user.minitial,
      lastName: presenter.viewModel.user.lastName,
      email: presenter.viewModel.user.email,
      dateOfBirth: presenter.viewModel.user.dateOfBirth,
      phone: presenter.viewModel.user.phone,
      address: presenter.viewModel.user.address,
      city: presenter.viewModel.user.city,
      state: presenter.viewModel.user.state,
      zipcode: presenter.viewModel.user.zipcode,
      idType: presenter.viewModel.user.identification?.idType,
      idNumber: presenter.viewModel.user.identification?.idNumber,
      idState: presenter.viewModel.user.identification?.idState,
      hasUploadedId: !!presenter.viewModel.idFrontDocument && !!presenter.viewModel.idBackDocument
    } as FieldValues);
  };

  const handleSubmit = async (data: FieldValues) => {
    presenter.submitUserIdentification(data, props.onNext, props.isLastStep);
  };

  const onChangeSave = React.useCallback(
    debounce(() => {
      presenter.autoSaveUserIdentification(form.getValues());
    }, 2000),
    []
  );

  return (
    <OnBoardingPage
      alignBottomActions="center"
      dataCy="id-verification-page"
      title="ID Verification"
      subtitle={`${subtitle} requires this information to comply with Know Your Customer (KYC) requirements.`}
      step={props.stepNumber}
      totalSteps={props.totalSteps}
      bgCard
      bottomActions={
        <Container justify="flex-end">
          <ContainerItem>
            <Button color="default-outlined" label="Back" onClick={props.onBack} dataCy="back-button" />
          </ContainerItem>
          <ContainerItem>
            <Button
              label={props.isLastStep ? 'Submit' : 'Continue'}
              color="primary"
              onClick={form.handleSubmit(handleSubmit)}
              isLoading={presenter.viewModel.isSavingIdVerification}
              dataCy="save-button"
            />
          </ContainerItem>
        </Container>
      }
    >
      <Container column padding={0}>
        <ContainerItem padding="0" width="100%">
          <PersonalDetailsForm isMobile={isMobile} form={form} onChange={() => onChangeSave()} />
        </ContainerItem>

        <ContainerItem padding="0" width="100%">
          <UserHomeAddressForm isMobile={isMobile} form={form} onChange={() => onChangeSave()} />
        </ContainerItem>

        <ContainerItem padding="0" width="100%">
          <ContactInfoForm isMobile={isMobile} form={form} onChange={() => onChangeSave()} />
        </ContainerItem>

        <ContainerItem padding="0" width="100%">
          <IdentificationForm
            isMobile={isMobile}
            form={form}
            uploadedIdCallback={uploadedIdCallback}
            onChange={() => onChangeSave()}
          />
        </ContainerItem>
      </Container>
    </OnBoardingPage>
  );
});
