import { DocumentUpload as DocumentUploadType } from '@gcv/shared';
import { Box } from '@mui/material';
import { DocumentsApi } from 'api';
import { ReactComponent as DocumentIcon } from 'assets/images/document-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash-icon.svg';
// import { ReactComponent as ExternalIcon } from 'assets/images/ic_open_new_tab.svg';
import { ReactComponent as ViewDocument } from 'assets/images/view-document.svg';

import React from 'react';
import { Icon } from 'ui';
import { theme } from 'ui/theme';
import { getMimeType, openFileInNewWindow } from 'util/files.util';
import { cropInput } from 'util/format.util';

interface Props {
  documentUpload: DocumentUploadType;
  fileBucket: string;
  onRemoveFile?: (file: DocumentUploadType) => void;
  dataCy?: string;
  fullWidth?: boolean;
  viewOnly?: boolean;
  borderNone?: boolean;
  readOnly?: boolean;
  sx?: React.CSSProperties;
  disableFileDelete?: boolean;
}

export const DocumentUpload: React.FC<Props> = (props) => {
  const documentsApi = new DocumentsApi();

  const viewFile = (file: DocumentUploadType) => {
    documentsApi.getDocumentLink(props.fileBucket, file.s3_key, 'get').then((s3Url) =>
      fetch(s3Url.s3LinkPath).then(async (response) => {
        const blob = await response.blob();
        openFileInNewWindow(blob, getMimeType(file.file_name), file.file_name);
      })
    );
  };

  return (
    <Box
      data-cy={props.dataCy}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: props.fullWidth ? '100%' : '420px',
        boxSizing: 'border-box',
        padding: '12px',
        borderBottom: props.borderNone ? '0px' : (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: 'rgba(216, 244, 231, 0.5)'
        },
        ...props.sx
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <DocumentIcon width="30px" height="22px" />
        <div
          data-cy="uploaded-file-name"
          onClick={() => viewFile(props.documentUpload)}
          style={{
            cursor: 'pointer',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '32px',
            verticalAlign: 'center',
            marginLeft: '10px'
          }}
        >
          {cropInput(props.documentUpload.file_name, 30, 'document')}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {props.viewOnly ? (
          <ViewDocument
            data-cy="view-icon"
            height="24px"
            width="24px"
            style={{ cursor: 'pointer' }}
            onClick={() => viewFile(props.documentUpload)}
          />
        ) : null}
        {!props.readOnly && !props.viewOnly && !props.disableFileDelete ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <TrashIcon
              data-cy="remove-icon"
              height="20px"
              width="18px"
              style={{ cursor: 'pointer' }}
              onClick={() => (props.onRemoveFile ? props.onRemoveFile(props.documentUpload) : undefined)}
            />
          </div>
        ) : null}
      </div>
    </Box>
  );
};
