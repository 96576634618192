import {
  Checkbox,
  Chip,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import { Pill, Tooltip } from 'ui';
import palette from 'ui/theme/palette';

export type SelectOption = {
  value: number | string;
  label: string;
  action?: () => void;
  plant_touching?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
  disabledTooltip?: string;
};

interface Props {
  dataCy?: string;
  additionalOnChange?: (e: SelectChangeEvent<any>) => void;
  background?: 'white' | 'grey' | 'dark-grey';
  name: string;
  label: string;
  multiSelect?: boolean;
  onBlur?: () => void;
  onChange: (...event: any[]) => void;
  options: SelectOption[];
  readOnly?: boolean;
  value: any;
  viewOnly?: boolean;
  disabled?: boolean;
  emptyLabel?: string;
  listItemsWidth?: string;
  addTag?: string;
  useChip?: boolean;
  placeholder?: string;
  headerComponent?: boolean;
  disableSelected?: boolean;
  disablePortal?: boolean;
}

export const Select: React.FC<Props> = (props) => {
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
  const [haveEllipses, setHaveEllipses] = React.useState(false);

  React.useEffect(() => {
    // If the value is undefined we don't want to set the selected value to undefined, it should instead stay as the default empty array
    if (props.value !== undefined) {
      setSelectedValues(
        // On autofill we get a stringified value.
        typeof props.value === 'string' ? props.value.split(',') : props.value
      );
    }
  }, [props.value]);

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value }
    } = event;

    setSelectedValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const backgroundColor =
    props.background === 'white'
      ? palette.background.paper
      : props.background === 'dark-grey'
      ? palette.background.secondary
      : palette.background.default;

  const isEllipses = (e: any) => {
    setHaveEllipses(e.offsetWidth < e.scrollWidth);
  };

  return (
    <MuiSelect
      data-cy={props.dataCy}
      MenuProps={{
        disablePortal: props.disablePortal,
        PaperProps: {
          sx: {
            borderRadius: '5px',
            marginTop: '8px',
            maxHeight: '300px',
            overflowY: 'auto'
          }
        }
      }}
      sx={{
        width: '100%',
        height: props.headerComponent ? '36px' : undefined,
        '&.Mui-focused .MuiSelect-select': {
          boxShadow: '0px 0px 0px 3px #03A65B40',
          borderColor: 'primary.border'
        },
        '&: hover .MuiSelect-outlined': {
          borderColor: props.headerComponent ? 'primary.border' : undefined,
          cursor: props.readOnly ? 'not-allowed' : 'default'
        },
        '& .MuiSelect-outlined': {
          // height is 36px but the considering the padding 6px on top and 9px bottom and 1px border we are making height as 36 - 6 - 9 - 2 = 19px
          height: props.headerComponent ? '19px !important' : '23px',
          minHeight: props.headerComponent ? '16px !important' : undefined,
          fontSize: props.headerComponent ? '14px' : undefined,
          paddingTop: props.headerComponent ? '6px !important' : '0'
        },

        '& .MuiSelect-select': {
          alignItems: 'center',
          background: props.viewOnly
            ? 'none'
            : props.headerComponent
            ? `${palette.background.paper}`
            : `${palette.background.default}`,
          border: '1px solid',
          borderColor: (theme) => (props.viewOnly ? 'transparent' : theme.palette.divider),
          color: (theme) =>
            props.readOnly || props.viewOnly ? theme.palette.text.secondary : theme.palette.text.primary,
          display: 'inline-block',
          marginTop: '0',
          padding: props.viewOnly
            ? 0
            : (props.value || selectedValues) && props.options.some((o) => o.icon)
            ? '7px 8px 9px 11px'
            : '0.5rem 0.75rem 0.5rem 10px',
          WebkitTextFillColor: 'inherit',
          fontWeight: 400,
          lineHeight: '20px',

          '&.Mui-disabled': {
            color: (theme) => (props.viewOnly ? theme.palette.text.primary : theme.palette.text.secondary),
            WebkitTextFillColor: 'inherit'
          },

          '& > span': {
            margin: '0 !important',
            height: '100%',
            display: 'table-cell',
            verticalAlign: 'middle',

            '& > svg': {
              top: '2px !important'
            }
          }
        },
        '& .MuiSelect-icon': {
          fontSize: '20px'
        },
        fieldset: {
          visibility: 'hidden'
        }
      }}
      disabled={props.readOnly || props.viewOnly}
      IconComponent={({ className }) => {
        return props.readOnly || props.viewOnly ? (
          <></>
        ) : (
          <span className={clsx(className, 'material-icons')}>expand_more</span>
        );
      }}
      input={<OutlinedInput label={props.label} />}
      label={props.label}
      name={props.name}
      onBlur={() => props.onBlur && props.onBlur()}
      onChange={(e) => {
        handleChange(e);
        props.onChange(e);
        props.additionalOnChange && props.additionalOnChange(e);
      }}
      multiple={props.multiSelect}
      value={
        props.multiSelect
          ? selectedValues.filter((value) => value !== '')
          : props.value !== undefined
          ? props.value === '' && props.viewOnly
            ? '--'
            : props.value === '' && props.readOnly
            ? ''
            : props.value
          : ''
      }
      renderValue={
        props.multiSelect
          ? (selected) => {
              if (selected.length === 0) {
                return props.placeholder
                  ? <Box sx={{ color: palette.text.secondary }}>{props.placeholder}</Box> || ''
                  : props.emptyLabel || '';
              } else {
                if (props.useChip) {
                  return selected.map((s: string | number) => {
                    const value = props.options.find((o) => o.value === s)?.label;
                    if (value) {
                      return <Chip sx={{ marginRight: '2px' }} key={value} label={value} />;
                    } else {
                      return undefined;
                    }
                  });
                } else {
                  return selected
                    .filter((s: string | number) => s !== '')
                    .map((s: string | number) => props.options.find((o) => o.value === s)?.label)
                    .join(', ');
                }
              }
            }
          : (selected) => {
              const selectedOption = props.options.find((o) => o.value === selected);

              if (selected.length === 0) {
                return props.placeholder
                  ? <Box sx={{ color: palette.text.secondary }}>{props.placeholder}</Box> || ''
                  : props.emptyLabel || '';
              } else if (selectedOption?.icon) {
                const icon = selectedOption.icon;
                const label = props.options.find((o) => o.value === selected)?.label;

                return (
                  <>
                    <span style={{ padding: 0, margin: '0 5px' }}>{icon}</span>
                    <span>{label}</span>
                  </>
                );
              } else {
                return props.options.find((o) => o.value === selected)?.label;
              }
            }
      }
      displayEmpty
    >
      {(props.readOnly || props.viewOnly) && props.value === '' && (
        <MenuItem selected={true} value="">
          --
        </MenuItem>
      )}
      {props.options.map((o, index) =>
        //The typing for MenuItem.value does not allow for boolean, however it does work as expected.
        //Ignoring compilation errors here to allow for boolean values. Added unit tests.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        o.disabled && o.disabledTooltip ? (
          <Tooltip
            placement={'top-start'}
            title={o.disabledTooltip ?? ''}
            disabled={!o.disabled || !o.disabledTooltip}
          >
            <MenuItem
              sx={{
                minWidth: 'calc(100%)',
                maxWidth: '300px',
                wordBreak: 'break-word',
                whiteSpace: 'initial',
                display: 'flex',
                justifyContent: props.useChip ? 'normal' : 'flex-start',
                ' .MuiCheckbox-root': {
                  padding: '6px',
                  ' .MuiSvgIcon-root': {
                    width: '1rem',
                    height: '1rem'
                  }
                },
                ' .MuiTypography-root': {
                  paddingBottom: '2px'
                }
              }}
              key={o.value ? o.value.toString() + index : index}
              value={o.value}
              disabled={o.disabled || (props.disableSelected && o.value === props.value)}
              onClick={() => {
                if (o.action) o.action();
              }}
            >
              {props.multiSelect && <Checkbox checked={selectedValues.indexOf(o.value.toString()) > -1} />}

              {
                // This adjustment of 70px is arbitrary, but seems to make things align correctly. This is
                // just an ugly hack, and I think there must be a better way of making this widget work.
                props.listItemsWidth ? (
                  <Typography
                    variant="inherit"
                    noWrap
                    width={`calc(${props.listItemsWidth} - 70px)`}
                    onMouseEnter={(e) => isEllipses(e.target)}
                  >
                    <Tooltip placement={'top-start'} title={o.label} disabled={!haveEllipses} ellipses={true}>
                      {o.icon}
                      {o.label}
                    </Tooltip>
                  </Typography>
                ) : o.label && o.label.length > 40 ? (
                  <Tooltip placement={'top'} title={o.label}>
                    <span>
                      {o.icon}
                      {o.label.substr(0, 35) + '...'}
                    </span>
                  </Tooltip>
                ) : (
                  <span>
                    {o.icon}
                    {o.label}
                  </span>
                )
              }
              {props.addTag ? o.plant_touching ? <Pill text={props.addTag} /> : null : null}
            </MenuItem>
          </Tooltip>
        ) : (
          <MenuItem
            sx={{
              minWidth: 'calc(100%)',
              maxWidth: '300px',
              wordBreak: 'break-word',
              whiteSpace: 'initial',
              display: 'flex',
              justifyContent: props.useChip ? 'normal' : 'flex-start',
              ' .MuiCheckbox-root': {
                padding: '6px',
                ' .MuiSvgIcon-root': {
                  width: '1rem',
                  height: '1rem'
                }
              },
              ' .MuiTypography-root': {
                paddingBottom: '2px'
              }
            }}
            key={o.value ? o.value.toString() + index : index}
            value={o.value}
            disabled={o.disabled || (props.disableSelected && o.value === props.value)}
            onClick={() => {
              if (o.action) o.action();
            }}
          >
            {props.multiSelect && <Checkbox checked={selectedValues.indexOf(o.value.toString()) > -1} />}

            {
              // This adjustment of 70px is arbitrary, but seems to make things align correctly. This is
              // just an ugly hack, and I think there must be a better way of making this widget work.
              props.listItemsWidth ? (
                <Typography
                  variant="inherit"
                  noWrap
                  width={`calc(${props.listItemsWidth} - 70px)`}
                  onMouseEnter={(e) => isEllipses(e.target)}
                >
                  <Tooltip placement={'top-start'} title={o.label} disabled={!haveEllipses} ellipses={true}>
                    {o.icon}
                    {o.label}
                  </Tooltip>
                </Typography>
              ) : o.label && o.label.length > 40 ? (
                <Tooltip placement={'top'} title={o.label}>
                  <span>
                    {o.icon}
                    {o.label.substr(0, 35) + '...'}
                  </span>
                </Tooltip>
              ) : (
                <span>
                  {o.icon}
                  {o.label}
                </span>
              )
            }
            {props.addTag ? o.plant_touching ? <Pill text={props.addTag} /> : null : null}
          </MenuItem>
        )
      )}
    </MuiSelect>
  );
};
