import React from 'react';
import { InputSelect } from 'ui/molecules/InputSelect/input-select.molecule';
import { Container, ContainerItem, Header, Text, View, ViewItem } from 'ui/atoms';
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import { Switch } from 'ui/molecules/Switch/switch.molecule';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { theme } from 'ui/theme';

export interface BusinessHours {
  [day: string]: {
    openHour?: string;
    closeHour?: string;
    isOpen?: boolean;
  };
}

const timeOptions: { value: string; label: string }[] = [
  // { value: '00:15', label: '12:15 AM' },
  { value: '00:30', label: '12:30 AM' },
  // { value: '00:45', label: '12:45 AM' },
  { value: '01:00', label: '1:00 AM' },
  // { value: '01:15', label: '1:15 AM' },
  { value: '01:30', label: '1:30 AM' },
  // { value: '01:45', label: '1:45 AM' },
  { value: '02:00', label: '2:00 AM' },
  // { value: '02:15', label: '2:15 AM' },
  { value: '02:30', label: '2:30 AM' },
  // { value: '02:45', label: '2:45 AM' },
  { value: '03:00', label: '3:00 AM' },
  // { value: '03:15', label: '3:15 AM' },
  { value: '03:30', label: '3:30 AM' },
  // { value: '03:45', label: '3:45 AM' },
  { value: '04:00', label: '4:00 AM' },
  // { value: '04:15', label: '4:15 AM' },
  { value: '04:30', label: '4:30 AM' },
  // { value: '04:45', label: '4:45 AM' },
  { value: '05:00', label: '5:00 AM' },
  // { value: '05:15', label: '5:15 AM' },
  { value: '05:30', label: '5:30 AM' },
  // { value: '05:45', label: '5:45 AM' },
  { value: '06:00', label: '6:00 AM' },
  // { value: '06:15', label: '6:15 AM' },
  { value: '06:30', label: '6:30 AM' },
  // { value: '06:45', label: '6:45 AM' },
  { value: '07:00', label: '7:00 AM' },
  // { value: '07:15', label: '7:15 AM' },
  { value: '07:30', label: '7:30 AM' },
  // { value: '07:45', label: '7:45 AM' },
  { value: '08:00', label: '8:00 AM' },
  // { value: '08:15', label: '8:15 AM' },
  { value: '08:30', label: '8:30 AM' },
  // { value: '08:45', label: '8:45 AM' },
  { value: '09:00', label: '9:00 AM' },
  // { value: '09:15', label: '9:15 AM' },
  { value: '09:30', label: '9:30 AM' },
  // { value: '09:45', label: '9:45 AM' },
  { value: '10:00', label: '10:00 AM' },
  // { value: '10:15', label: '10:15 AM' },
  { value: '10:30', label: '10:30 AM' },
  // { value: '10:45', label: '10:45 AM' },
  { value: '11:00', label: '11:00 AM' },
  // { value: '11:15', label: '11:15 AM' },
  { value: '11:30', label: '11:30 AM' },
  // { value: '11:45', label: '11:45 AM' },
  { value: '12:00', label: '12:00 PM' },
  // { value: '12:15', label: '12:15 PM' },
  { value: '12:30', label: '12:30 PM' },
  // { value: '12:45', label: '12:45 PM' },
  { value: '13:00', label: '1:00 PM' },
  // { value: '13:15', label: '1:15 PM' },
  { value: '13:30', label: '1:30 PM' },
  // { value: '13:45', label: '1:45 PM' },
  { value: '14:00', label: '2:00 PM' },
  // { value: '14:15', label: '2:15 PM' },
  { value: '14:30', label: '2:30 PM' },
  // { value: '14:45', label: '2:45 PM' },
  { value: '15:00', label: '3:00 PM' },
  // { value: '15:15', label: '3:15 PM' },
  { value: '15:30', label: '3:30 PM' },
  // { value: '15:45', label: '3:45 PM' },
  { value: '16:00', label: '4:00 PM' },
  // { value: '16:15', label: '4:15 PM' },
  { value: '16:30', label: '4:30 PM' },
  // { value: '16:45', label: '4:45 PM' },
  { value: '17:00', label: '5:00 PM' },
  // { value: '17:15', label: '5:15 PM' },
  { value: '17:30', label: '5:30 PM' },
  // { value: '17:45', label: '5:45 PM' },
  { value: '18:00', label: '6:00 PM' },
  // { value: '18:15', label: '6:15 PM' },
  { value: '18:30', label: '6:30 PM' },
  // { value: '18:45', label: '6:45 PM' },
  { value: '19:00', label: '7:00 PM' },
  // { value: '19:15', label: '7:15 PM' },
  { value: '19:30', label: '7:30 PM' },
  // { value: '19:45', label: '7:45 PM' },
  { value: '20:00', label: '8:00 PM' },
  // { value: '20:15', label: '8:15 PM' },
  { value: '20:30', label: '8:30 PM' },
  // { value: '20:45', label: '8:45 PM' },
  { value: '21:00', label: '9:00 PM' },
  // { value: '21:15', label: '9:15 PM' },
  { value: '21:30', label: '9:30 PM' },
  // { value: '21:45', label: '9:45 PM' },
  { value: '22:00', label: '10:00 PM' },
  // { value: '22:15', label: '10:15 PM' },
  { value: '22:30', label: '10:30 PM' },
  // { value: '22:45', label: '10:45 PM' },
  { value: '23:00', label: '11:00 PM' },
  // { value: '23:15', label: '11:15 PM' },
  { value: '23:30', label: '11:30 PM' }
  // { value: '23:45', label: '11:45 PM' }
];

const openTimeOptions = [{ value: '00:00', label: '12:00 AM' }, ...timeOptions];

const closeTimeOptions = [{ value: '23:59', label: '12:00 AM' }, ...timeOptions];

const timeDictionary = (
  options: {
    value: string;
    label: string;
  }[]
): Record<string, unknown> =>
  options.reduce((acc: Record<string, unknown>, o) => {
    acc[o.value] = o.label;
    return acc;
  }, {} as Record<string, unknown>);

const weekDays = {
  Monday: {
    openHour: '09:00',
    closeHour: '17:00',
    isOpen: true
  },
  Tuesday: {
    openHour: '09:00',
    closeHour: '17:00',
    isOpen: true
  },
  Wednesday: {
    openHour: '09:00',
    closeHour: '17:00',
    isOpen: true
  },
  Thursday: {
    openHour: '09:00',
    closeHour: '17:00',
    isOpen: true
  },
  Friday: {
    openHour: '09:00',
    closeHour: '17:00',
    isOpen: true
  },
  Saturday: {
    openHour: '',
    closeHour: '',
    isOpen: undefined
  },
  Sunday: {
    openHour: '',
    closeHour: '',
    isOpen: undefined
  }
};

interface Props {
  form: UseFormReturn<FieldValues>;
  readonly?: boolean;
  onChange?: () => void;
}

export const HoursOfOperation: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const validateHours = (
    openName: string,
    closeName: string,
    closedHour: number | string,
    openHour: number | string
  ): boolean => {
    let isValid = false;

    if (closedHour > openHour) {
      isValid = true;
      props.form.clearErrors(openName);
      props.form.clearErrors(closeName);
    }

    return isValid;
  };

  return (
    <>
      {Object.entries(weekDays).map(([day]) => {
        const openName = `businessHours.${day.toLowerCase()}.openHour`;
        const closedName = `businessHours.${day.toLowerCase()}.closeHour`;
        const isOpenName = `businessHours.${day.toLowerCase()}.isOpen`;

        return props.readonly ? (
          <Box
            key={day}
            sx={{
              width: '163px',
              margin: '0.5rem 1rem 0.5rem 0',
              flexBasis: '163px',
              flexGrow: 1,
              display: 'inline-flex',
              flexDirection: 'column',
              background: '#F2F4FA',
              borderRadius: '6px',
              height: '73px',
              justifyContent: 'center'
            }}
            data-cy={`${day}-container`}
          >
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '.35rem'
              }}
            >
              <Text
                type="body1"
                content={
                  !props.form.watch(isOpenName)
                    ? 'Closed'
                    : `${timeDictionary(openTimeOptions)[props.form.watch(openName)]} - ${
                        timeDictionary(closeTimeOptions)[props.form.watch(closedName)]
                      }`
                }
              />
            </div>
            <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
              <Header type="h3" content={day} />
            </div>
          </Box>
        ) : (
          <div
            style={{
              borderBottom: isMobile ? 'none' : `1px solid ${theme.palette.divider}`
            }}
          >
            <Container
              key={day}
              dataCy={`${day}-container`}
              align="center"
              justify="center"
              padding="0"
              width="100%"
            >
              <View paddingNone parentPaddingBottom="0">
                <ViewItem size={isMobile ? [12, 12, 12, 12, 12] : [12, 12, 12, 5, 6]}>
                  <Container padding={isMobile ? '4px 0' : '12px 0'} align="center">
                    <ContainerItem padding="0" margin="0 36px 0 0" flex width="100px" align="center">
                      <Text type="body1" content={day} sx={{ fontWeight: 'bold' }} dataCy="day-label" />
                    </ContainerItem>
                    <ContainerItem flex width="35%" align="center">
                      <Switch
                        label={props.form.watch(isOpenName) ? 'Open' : 'Closed'}
                        name={isOpenName}
                        onChange={() => {
                          if (props.onChange) {
                            props.onChange();
                          }
                        }}
                        onBlur={() => {
                          if (props.onChange) {
                            props.onChange();
                          }
                        }}
                        {...props.form}
                      />
                    </ContainerItem>
                  </Container>
                </ViewItem>

                {props.form.watch(isOpenName) ? (
                  <ViewItem size={isMobile ? [12, 12, 12, 12, 12] : [12, 12, 12, 7, 6]}>
                    <Container padding="0" width="100%">
                      <ContainerItem
                        padding="0"
                        margin="0 6px 0 0 "
                        flex
                        column
                        width={isMobile ? '50%' : '250px'}
                        minWidth={isMobile ? 'none' : '157px'}
                        height="80px"
                        align="center"
                      >
                        <InputSelect
                          label="From"
                          name={openName}
                          rules={{
                            required: { message: 'is required', value: true },
                            validate: (t) =>
                              validateHours(openName, closedName, props.form.watch(closedName), t)
                          }}
                          validateError="must be before To"
                          options={openTimeOptions}
                          dataCy="from-hour-dropdown"
                          additionalOnChange={() => {
                            if (props.onChange) {
                              props.onChange();
                            }
                          }}
                          onBlur={() => {
                            if (props.onChange) {
                              props.onChange();
                            }
                          }}
                          {...props.form}
                        />
                      </ContainerItem>

                      <ContainerItem
                        padding="0"
                        margin="0 0 0 6px"
                        flex
                        column
                        width={isMobile ? '50%' : '250px'}
                        minWidth={isMobile ? 'none' : '157px'}
                        height="80px"
                        align="center"
                      >
                        <InputSelect
                          label="To"
                          name={closedName}
                          options={closeTimeOptions}
                          rules={{
                            required: { message: 'is required', value: true },
                            validate: (t) =>
                              validateHours(openName, closedName, t, props.form.watch(openName))
                          }}
                          validateError="must be after From"
                          dataCy="to-hour-dropdown"
                          additionalOnChange={() => {
                            if (props.onChange) {
                              props.onChange();
                            }
                          }}
                          onBlur={() => {
                            if (props.onChange) {
                              props.onChange();
                            }
                          }}
                          {...props.form}
                        />
                      </ContainerItem>
                    </Container>
                  </ViewItem>
                ) : null}
              </View>
            </Container>
          </div>
        );
      })}
    </>
  );
};
