import { AccessRequestParams, AccessScopes, AccessScopesToUserTypeMap, AccessUserType } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { SnackbarStore } from 'stores/SnackBarStore';
import { SelectOption } from 'ui';
import { Row } from 'ui/organisms';
import { GcvAccessPM, GcvAccessRepo } from './manage-access.repo';

export interface GcvAccessVM {
  isLoading: boolean;
  accessClientRows: Row<AccessClientRow>[];

  // createAccessRecordModal props
  createAccessRecordModalOpen: boolean;
  createAccessRecordModalLoading: boolean;
  selectedUserType?: AccessUserType;
  availableScopes: SelectOption[];
  selectedScopes?: AccessScopes[];

  // clientCredentialsModal
  clientCredentialsModalOpen: boolean;
  clientId?: string;
  clientSecret?: string;
}

export interface AccessClientRow {
  name: string;
  userType: AccessUserType;
  scopes: string;
}

@injectable()
export class GcvAccessPresenter {
  @inject(GcvAccessRepo)
  private repo: GcvAccessRepo;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  public manageAccessViewModel: GcvAccessVM = {
    isLoading: true,
    accessClientRows: [],
    availableScopes: [],
    createAccessRecordModalOpen: false,
    createAccessRecordModalLoading: false,
    clientCredentialsModalOpen: false
  };

  public load = action(async () => {
    observe(this.repo, 'gcvAccessProgrammersModel', (obj) => {
      const programmersModel = obj.newValue as GcvAccessPM;

      this.updateViewModel({
        isLoading: programmersModel.isLoading,
        accessClientRows: programmersModel.allAccessRecords.map((r) => {
          return {
            id: r.label,
            data: {
              name: r.value.name,
              userType: r.value.user_type,
              scopes: r.value.scopes.sort().join(', ')
            }
          };
        }),
        clientId: programmersModel.clientId,
        clientSecret: programmersModel.clientSecret
      });
    });

    await this.repo.load();
  });

  private updateViewModel = action((viewModel: Partial<GcvAccessVM>) => {
    this.manageAccessViewModel = { ...this.manageAccessViewModel, ...viewModel };
  });

  public setCreateAccessRecordModalOpen = action((open: boolean) => {
    this.updateViewModel({ createAccessRecordModalOpen: open });
  });

  public setCreateAccessRecordModalLoading = action((loading: boolean) => {
    this.updateViewModel({ createAccessRecordModalLoading: loading });
  });

  public setUserType = action((userType: AccessUserType) => {
    const availableScopes: SelectOption[] = Object.entries(AccessScopes)
      .map(([label, value]) => {
        return {
          label,
          value
        };
      })
      .filter((s) => AccessScopesToUserTypeMap[userType].includes(s.value));

    this.updateViewModel({ selectedUserType: userType, availableScopes });
  });

  public setSelectedScopes = action((selectedScopes: AccessScopes[]) => {
    this.updateViewModel({ selectedScopes });
  });

  public createAccessRecord = action(async (request: AccessRequestParams) => {
    try {
      this.setCreateAccessRecordModalLoading(true);
      await this.repo.createAccessRecord(request);
      this.setClientCredentialsModalOpen(true);
      this.snackbarStore.showSuccessSnackbarMessage('Successfully created new client API key.');
    } catch (e: any) {
      if (e?.response?.errors && e?.response?.errors?.length) {
        this.snackbarStore.showErrorSnackbarMessage(e.response.errors[0]);
      } else {
        this.snackbarStore.showErrorSnackbarMessage('Failed to create access record');
      }
    } finally {
      this.updateViewModel({ selectedUserType: undefined, selectedScopes: [], availableScopes: [] });
      this.setCreateAccessRecordModalOpen(false);
      this.setCreateAccessRecordModalLoading(false);
    }
  });

  public setClientCredentialsModalOpen = action((open: boolean) => {
    this.updateViewModel({ clientCredentialsModalOpen: open });
  });

  public rotateApiKey = action(async (client_id: string) => {
    await this.repo
      .rotateApiKey(client_id)
      .then(() => this.setClientCredentialsModalOpen(true))
      .catch(() => {
        this.snackbarStore.showErrorSnackbarMessage('Failed to rotate API key');
      });
  });
}
