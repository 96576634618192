import {
  DataFetcherStrategy,
  DataFetcherWorkflowTypes,
  IntegrationTiming,
  IntegrationTimingMetaV3,
  IntegrationTimingType,
  IntervalConfiguration,
  PosDataFetcherWorkflowTypes,
  PosType,
  ScheduleConfiguration
} from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { SnackbarStore } from 'stores/SnackBarStore';
import { Row } from 'ui/organisms';
import { UpsertTimingConfigFormValues } from './components/upsert-timing-config-modal';
import { GcvAccessTimingConfigsPM, GcvAccessTimingConfigsRepo } from './timing-configs.repo';

export interface GcvAccessTimingConfigsVM {
  isLoading: boolean;
  timingConfigRows: Row<TimingConfigClientRow>[];

  // upsert timing config modal
  upsertTimingConfigModalLoading: boolean;
  upsertTimingConfigModalOpen: boolean;
  selectedPosType?: PosType;
  selectedTimingConfigs: IntegrationTiming[];

  // kickoff data fetcher modal
  kickoffDataFetcherModalLoading: boolean;
  kickoffDataFetcherModalOpen: boolean;
}

export interface TimingConfigClientRow {
  pos: PosType;
  inventory?: string;
  products?: string;
  sales?: string;
  customers?: string;
}

@injectable()
export class GcvAccessTimingConfigsPresenter {
  @inject(GcvAccessTimingConfigsRepo)
  private repo: GcvAccessTimingConfigsRepo;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  public timingConfigViewModel: GcvAccessTimingConfigsVM = {
    isLoading: true,
    timingConfigRows: [],

    // upsert timing config modal
    upsertTimingConfigModalLoading: false,
    upsertTimingConfigModalOpen: false,
    selectedTimingConfigs: [],

    // kickoff data fetcher modal
    kickoffDataFetcherModalLoading: false,
    kickoffDataFetcherModalOpen: false
  };

  public load = action(async () => {
    observe(this.repo, 'gcvTimingConfigsProgrammersModel', (obj) => {
      const programmersModel = obj.newValue as GcvAccessTimingConfigsPM;

      const getScheduleTextForEndpointType = (
        endpointName: string,
        timingConfigs: IntegrationTimingMetaV3
      ) => {
        const timingConfig = timingConfigs.value.find((tc) => tc.endpoint === endpointName);
        if (timingConfig) {
          return timingConfig.timing_type === IntegrationTimingType.Interval
            ? `Interval ${(timingConfig.configuration as IntervalConfiguration).minutes} mins`
            : `Schedule ${(timingConfig.configuration as ScheduleConfiguration).scheduled_times.join(', ')}`;
        } else {
          return '';
        }
      };

      this.updateViewModel({
        isLoading: programmersModel.isLoading,
        timingConfigRows: Object.values(programmersModel.allTimingConfigsMap).map((r) => {
          return {
            id: r.label as PosType,
            data: {
              pos: r.label as PosType,
              inventory: getScheduleTextForEndpointType(PosDataFetcherWorkflowTypes.loadInventory, r),
              products: getScheduleTextForEndpointType(PosDataFetcherWorkflowTypes.loadProducts, r),
              customers: getScheduleTextForEndpointType(PosDataFetcherWorkflowTypes.loadCustomers, r),
              sales: getScheduleTextForEndpointType(PosDataFetcherWorkflowTypes.loadSales, r)
            }
          };
        })
      });
    });

    await this.repo.load();
  });

  private updateViewModel = action((viewModel: Partial<GcvAccessTimingConfigsVM>) => {
    this.timingConfigViewModel = { ...this.timingConfigViewModel, ...viewModel };
  });

  //#region Upsert timing configs
  public setUpsertTimingConfigdModalOpen = action((open: boolean, posType?: PosType) => {
    if (posType) {
      const timingConfig = this.repo.gcvTimingConfigsProgrammersModel.allTimingConfigsMap[posType];
      this.updateViewModel({
        upsertTimingConfigModalOpen: open,
        selectedPosType: posType,
        selectedTimingConfigs: [...timingConfig?.value]
      });
    } else {
      this.updateViewModel({
        upsertTimingConfigModalOpen: open,
        selectedPosType: undefined,
        selectedTimingConfigs: []
      });
    }
  });

  public setUpsertTimingConfigdModalLoading = action((loading: boolean) => {
    this.updateViewModel({ upsertTimingConfigModalLoading: loading });
  });

  public setSelectedPosType = action((posType: PosType | undefined) => {
    const timingConfig = posType
      ? this.repo.gcvTimingConfigsProgrammersModel.allTimingConfigsMap[posType]
      : null;
    this.updateViewModel({
      selectedPosType: posType,
      selectedTimingConfigs: timingConfig?.value ? [...timingConfig?.value] : []
    });
  });

  public updateSelectedTimingConfig = action((timingConfig: IntegrationTiming) => {
    const exitingConfigIndex = this.timingConfigViewModel.selectedTimingConfigs?.findIndex(
      (c) => c.endpoint === timingConfig.endpoint
    );
    if (exitingConfigIndex >= 0) {
      this.timingConfigViewModel.selectedTimingConfigs?.splice(exitingConfigIndex, 1, timingConfig);
      this.updateViewModel({
        selectedTimingConfigs: [...this.timingConfigViewModel.selectedTimingConfigs]
      });
    } else {
      this.updateViewModel({
        selectedTimingConfigs: [...this.timingConfigViewModel.selectedTimingConfigs, timingConfig]
      });
    }
  });

  public removeSelectedTimingConfig = action((endpoint: string) => {
    const exitingConfigIndex = this.timingConfigViewModel.selectedTimingConfigs?.findIndex(
      (c) => c.endpoint === endpoint
    );
    if (exitingConfigIndex >= 0) {
      this.timingConfigViewModel.selectedTimingConfigs?.splice(exitingConfigIndex, 1);
      this.updateViewModel({
        selectedTimingConfigs: [...this.timingConfigViewModel.selectedTimingConfigs]
      });
    }
  });

  public saveTimingConfigs = action(async (formData: UpsertTimingConfigFormValues, closeModal: boolean) => {
    try {
      this.setUpsertTimingConfigdModalLoading(true);
      const input = this.transformFormaDataIntoTiming(formData);
      await this.repo.upsertTimingConfig(formData.posType, input);
      this.snackbarStore.showSuccessSnackbarMessage('Successfully saved timing config.');
    } catch {
      this.snackbarStore.showErrorSnackbarMessage('Failed to save timing configs');
    } finally {
      if (closeModal) {
        this.setSelectedPosType(undefined);
        this.setUpsertTimingConfigdModalOpen(false);
      }
      this.setUpsertTimingConfigdModalLoading(false);
    }
  });

  private transformFormaDataIntoTiming = (formData: UpsertTimingConfigFormValues): IntegrationTiming[] => {
    const timingConfigs: IntegrationTiming[] = [];

    for (const workflowType of Object.values(PosDataFetcherWorkflowTypes)) {
      const timingType = formData[`${workflowType}-timing_type`];
      if (timingType) {
        timingConfigs.push({
          endpoint: workflowType,
          timing_type: timingType,
          configuration:
            timingType === IntegrationTimingType.Interval
              ? { minutes: formData[`${workflowType}-minutes`]! }
              : {
                  use_business_timezone: true, // defaulting this for now
                  scheduled_times: Array.isArray(formData[`${workflowType}-scheduled_times`])
                    ? (formData[`${workflowType}-scheduled_times`] as string[])
                    : ((formData[`${workflowType}-scheduled_times`] as string).split(',') as string[])
                },
          gcv_only: false
        });
      }
    }
    return timingConfigs;
  };
  //#endregion Upsert timing configs

  //#region kickoff data fetcher
  public setKickoffDataFetcherModalOpen = action((open: boolean) => {
    this.updateViewModel({
      kickoffDataFetcherModalOpen: open
    });
  });

  public setKickoffDataFetcherModalLoading = action((loading: boolean) => {
    this.updateViewModel({ kickoffDataFetcherModalLoading: loading });
  });

  public kickoffDataFetcher = action(
    async (
      posType: PosType,
      dataWorkflowType: DataFetcherWorkflowTypes,
      strategy: DataFetcherStrategy,
      toDate: string,
      fromDate: string,
      crbId?: string
    ) => {
      try {
        this.setKickoffDataFetcherModalLoading(true);
        await this.repo.kickoffDataFetcher(posType, dataWorkflowType, strategy, toDate, fromDate, crbId);
        this.snackbarStore.showSuccessSnackbarMessage('Successfully kicked off data fetcher.');
      } catch {
        this.snackbarStore.showErrorSnackbarMessage('Failed to kickoff data fetcher');
      } finally {
        this.setKickoffDataFetcherModalOpen(false);
        this.setKickoffDataFetcherModalLoading(false);
      }
    }
  );
  //#endregion kickoff data fetcher
}
