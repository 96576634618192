import palette from '../palette';

export default {
  styleOverrides: {
    root: {},
    filled: {
      opacity: 1,
      color: palette.text.secondary
    }
  }
};
