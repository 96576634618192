import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { Icon } from '../Icon/icon.atom';
import { Tooltip, Container, Text } from 'ui';

interface Props {
  content: string;
  dataCy?: string;
  icon?: string;
  inline?: boolean;
  type?: 'h1' | 'h2' | 'h3' | 'h4';
  justify?: string;
  maxWidth?: string;
  Tooltip?: string;
  mobileHeader?: boolean;
}

export const Title: React.FC<Props> = (props) => {
  const inlineTitle = props.inline ? 'inline' : 'flex';
  const justifyTitle = props.justify ? props.justify : 'center';
  const maxWidth = props.maxWidth ? props.maxWidth : '100%';

  return props.mobileHeader ? (
    <>
      <Text
        data-cy={props.dataCy}
        content={props.content}
        sx={{
          fontWeight: '700',
          fontSize: '17px',
          lineHeight: '24px',
          width: maxWidth
        }}
      />
    </>
  ) : (
    <Typography
      data-cy={props.dataCy}
      sx={{
        alignItems: 'center',
        display: inlineTitle,
        width: maxWidth,
        justifyContent: justifyTitle
      }}
      variant={props.type || 'h2'}
    >
      <Tooltip title={props.Tooltip ? props.Tooltip : props.content} placement="top">
        <span
          className={props.icon ? 'title-with-icon' : 'title'}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
            display: 'inline-block',
            width: maxWidth
          }}
        >
          {props.content}
        </span>
      </Tooltip>
      {props.icon && (
        <Box sx={{ display: 'flex', pl: '0.5rem' }}>
          <Icon dataCy="title-icon" type={props.icon} />
        </Box>
      )}
    </Typography>
  );
};
