export const PrimaryRegulator = [
  { label: 'CFTC', value: 'CFTC' },
  { label: 'Federal Reserve', value: 'Federal Reserve' },
  { label: 'FDIC', value: 'FDIC' },
  { label: 'IRS', value: 'IRS' },
  { label: 'NCUA', value: 'NCUA' },
  { label: 'OCC', value: 'OCC' },
  { label: 'SEC', value: 'SEC' },
  { label: 'FHFA', value: 'FHFA' },
  { label: 'Not applicable', value: 'Not applicable' }
] as { value: string; label: string }[];
