import { Box } from '@mui/material';
import * as React from 'react';
import { Button, ContainerItem, Header, Icon, Text, Hyperlink, Container } from 'ui/atoms';
import { Dialog } from 'ui/organisms';
import {
  Notification,
  NotificationTypes,
  SalesIngestionFailureNotificationData,
  SalesIngestionPartialSuccessRetailNotificationData
} from '@gcv/shared';
import { DateTimeHelpers } from 'util/dateTime.util';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  notification: Notification | null;
}

export const SalesNotificationModal: React.FC<Props> = (props) => {
  const dispensaryStore = getCrbDispensaryStore();
  const isRetail = dispensaryStore?.currentDispensary?.posConfig?.posName === 'GcvFaker_GcvCsv';
  const isNonRetail = dispensaryStore?.currentDispensary?.posConfig?.posName === 'GcvCsv_Quickbooks';

  return (
    <Dialog
      noActions
      isOpen={props.isOpen}
      handleClose={props.onClose}
      title={''}
      alignTitle="center"
      dataCy="edit-user-modal"
    >
      <Container padding="0">
        <ContainerItem
          height={props.notification?.type === NotificationTypes.SALES_INGESTION_FAILURE ? '573px' : '633px'}
          width="459px"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                <Icon size={60} type={'info_outline'} color={'error'}></Icon>
              </Box>
              <Box
                sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', marginTop: '1rem' }}
              >
                <Header
                  content={
                    props.notification?.type === NotificationTypes.SALES_INGESTION_FAILURE
                      ? 'Your sales could not be uploaded'
                      : `${
                          (props.notification?.data as SalesIngestionPartialSuccessRetailNotificationData)
                            ?.total_unprocessable_rows
                        } out of ${
                          (props.notification?.data as SalesIngestionPartialSuccessRetailNotificationData)
                            ?.total_rows
                        } rows could not be uploaded`
                  }
                  type="h2"
                ></Header>
              </Box>
              <Box sx={{ marginTop: '2rem' }}>
                <Text
                  content={`Sales upload on 
          ${DateTimeHelpers.formatISOToFriendlyDateString(
            (props.notification?.data as SalesIngestionFailureNotificationData)?.date_time,
            dispensaryStore.currentDispensary.iana_timezone
          )}`}
                ></Text>
              </Box>

              {props.notification?.type !== NotificationTypes.SALES_INGESTION_FAILURE ? (
                <Box
                  sx={{
                    marginTop: '1rem',
                    textAlign: 'left'
                  }}
                >
                  <Text
                    content={`Please ensure that every sale has the following required values:`}
                    sx={{ marginBottom: '2rem' }}
                  ></Text>
                  {isRetail ? (
                    <Header content={`Transaction ID, Customer ID, Receipt Date`} type="h4"></Header>
                  ) : isNonRetail ? (
                    <Header content={`Date, Customer`} type="h4"></Header>
                  ) : (
                    ''
                  )}
                  <Text content={`When you're ready, please try again.`} sx={{ marginTop: '2rem' }} />
                  <Text content={`Need help`} inline sx={{ fontWeight: 'bold', fontStyle: 'italic' }} />
                  <Text content={`? Visit our `} inline />
                  <Hyperlink
                    url="https://support.greencheckverified.com/en/knowledge/upload-non-retail-sales-manually"
                    label="Support Center"
                    newTab
                  />{' '}
                  <Text content={`for step-by-step instructions.`} inline />
                </Box>
              ) : (
                <Box
                  sx={{
                    textAlign: 'left',
                    marginTop: '1rem'
                  }}
                >
                  <Text
                    content={`An error occurred. Please try again. If the problem continues, please contact`}
                    inline
                  ></Text>{' '}
                  <Hyperlink url="support@greencheckverified.com" label="support@greencheckverified.com" />
                </Box>
              )}
            </div>
            <div style={{ marginTop: '4rem' }}>
              <Container justify="center">
                <ContainerItem width="335px">
                  <Button color="primary" label="Confirm" onClick={props.onComplete} fullWidth></Button>
                </ContainerItem>
                <ContainerItem width="335px">
                  <Button color="default-text" label="Close" onClick={props.onClose} fullWidth></Button>
                </ContainerItem>
              </Container>
            </div>
          </div>
        </ContainerItem>
      </Container>
    </Dialog>
  );
};
