import { AllowedS3FileMimeTypes, DocumentUpload } from '@gcv/shared';
import { Box } from '@mui/material';
import heic2any from 'heic2any';
import React, { useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { theme } from 'ui/theme';
import { Error, File as FileAtom } from '../../atoms';

interface Props {
  onFilesChange: (files: File[]) => void;
  acceptedTypes: 'all' | 'csv' | 'images' | 'imagesAndPdfs' | 'xml';
  multiple: boolean;
  dataCy?: string;
  readOnly?: boolean;
  fullWidth?: boolean;
  existingFiles?: DocumentUpload[];
  newFilesPadding?: string; // added this prop because, the existingFiles & newFiles are not aligned properly in Custom Field Questionnaire modal .
  hardResetComponent?: boolean; // to reset the FileContainer when checked Create Another Field check box as data needs to be cleared
}

// use MIME types instead of file extensions as drag n' dropped files don't have extension info
// adding heic manually here because we should be converting this to jpeg before saving it
const acceptedFileTypes = Object.values(AllowedS3FileMimeTypes).join(',') + ',image/heic,image/heif';

const typesMap = {
  all: { viewValue: 'All', value: acceptedFileTypes },
  csv: { viewValue: 'CSV', value: 'text/csv' },
  images: { viewValue: 'Image', value: 'image/*,image/heic,image/heif' }, //heic needs to be added manually
  imagesAndPdfs: { viewValue: 'Image or PDF', value: 'image/*,application/pdf,image/heic,image/heif' },
  xml: { viewValue: 'XML', value: 'text/xml' }
};

export const InputFile: React.FC<Props> = (props) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const [fileErrors, setFileErrors] = React.useState<FileRejection[]>([]);

  // to reset the FileContainer when checked Create Another Field check box as data needs to be cleared
  const resetFileContainer = () => {
    setFiles([]);
  };

  React.useEffect(() => {
    if (props.existingFiles) {
      const uploadedFiles = props.existingFiles.map((f) => {
        return {
          name: f.file_name
        } as File;
      });

      setFiles(uploadedFiles);
    }
  }, []);

  React.useEffect(() => {
    resetFileContainer();
  }, [props.hardResetComponent]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];
        if (file.type === 'image/heic' || file.type === 'image/heif') {
          const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg'
          });
          const regexHeic = new RegExp(`.heic`, 'ig');
          const regexHeif = new RegExp(`.heif`, 'ig');

          const convertedFile = new File(
            [convertedBlob as BlobPart],
            file.name.replaceAll(regexHeic, '.jpeg').replaceAll(regexHeif, '.jpeg'),
            {
              lastModified: file.lastModified,
              type: 'image/jpeg'
            }
          );

          acceptedFiles[i] = convertedFile;
        }
      }
      //add accepted files to our current file list
      const newFiles = [...files, ...acceptedFiles];
      setFiles(newFiles);
      props.onFilesChange(newFiles);
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: async (fileRejections, e) => {
      setFileErrors(fileRejections);
    },
    accept: typesMap[props.acceptedTypes].value,
    multiple: props.multiple
  });

  const fileRejectionItems = React.useMemo(
    () =>
      fileErrors.length > 0 ? (
        <Box sx={{ width: '100%', maxHeight: '100px', overflow: 'auto', m: '1rem', p: '1rem' }}>
          {fileErrors.map(({ file, errors }, index) => (
            <Box sx={{ width: '100%' }}>
              <Error
                dataCy="rejected-files"
                content={`${file.name} rejected: ${errors.map((e) => e.message)}`}
              />
            </Box>
          ))}
        </Box>
      ) : null,
    [fileErrors]
  );

  const onRemoveFile = (fileIndex: number) => {
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
    props.onFilesChange(newFiles);
  };

  return (
    <div data-cy={props.dataCy} style={{ width: props.fullWidth ? '100%' : '420px' }}>
      {files.length > 0 ? (
        <>
          <div data-cy="uploaded-files" style={{ width: '100%', maxHeight: '200px', overflow: 'auto' }}>
            {files.map((file, index) => (
              <FileAtom
                newFilesPadding={props.newFilesPadding} // added this prop because, the existingFiles & newFiles are not aligned properly in Custom Field Questionnaire modal .
                key={file.name + index}
                file={file}
                onRemoveFile={() => onRemoveFile(index)}
                fullWidth={props.fullWidth}
              />
            ))}
          </div>
          {props.readOnly ? null : props.multiple ? (
            <>
              <Box
                sx={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: (theme) => theme.palette.primary.main,
                  cursor: 'pointer',
                  margin: '10px 0',
                  display: 'flex',
                  height: '30px'
                }}
                {...getRootProps()}
                data-cy="upload-another-file"
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div
                    style={{
                      margin: 'auto',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                    data-cy="active-drag-text"
                  >
                    Drop the files here ...
                  </div>
                ) : (
                  <div
                    style={{
                      margin: 'auto',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{ marginRight: '10px', color: theme.palette.primary.main }}
                      data-cy="add-icon"
                    >
                      control_point
                    </span>
                    <span
                      style={{ color: theme.palette.primary.main, margin: '2px 0 0 0' }}
                      data-cy="upload-text"
                    >
                      Upload Another File
                    </span>
                  </div>
                )}
              </Box>
              {fileRejectionItems}
            </>
          ) : null}
        </>
      ) : props.readOnly ? null : (
        <Box
          sx={{
            minHeight: '110px',
            width: props.fullWidth ? '100%' : '350px',
            background: (theme) => `${theme.palette.background.default}`,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            boxSizing: 'border-box',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          {...getRootProps()}
          data-cy="upload-file-dropzone"
        >
          <input
            style={{
              fontSize: '12px',
              position: 'absolute',
              cursor: 'pointer',
              left: '0',
              top: '1px',
              right: '0',
              bottom: '0px',
              opacity: '0',
              height: '120px'
            }}
            {...getInputProps()}
          />
          {isDragActive ? (
            <Box
              sx={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '21px',
                color: (theme) => `${theme.palette.text.secondary}`
              }}
              data-cy="active-drag-text"
            >
              Drop the files here..
            </Box>
          ) : (
            <div
              style={{
                width: '100%',
                marginTop: '1rem',
                marginBottom: '1rem'
              }}
            >
              <div style={{ textAlign: 'center', width: '100%' }}>
                <span
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '18px',
                    lineHeight: '21px',
                    color: `${theme.palette.text.secondary}`
                  }}
                  data-cy="inactive-drag-text"
                >
                  Drag file here or{' '}
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '18px',
                      lineHeight: '21px',
                      color: theme.palette.primary.main
                    }}
                  >
                    Browse
                  </span>
                </span>
              </div>
              <div style={{ textAlign: 'center', width: '100%', marginTop: '10px' }}>
                <Box
                  sx={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '13px',
                    lineHeight: '19px',
                    color: (theme) => `${theme.palette.text.secondary}`
                  }}
                  data-cy="accepted-file-types"
                >
                  Max file size: 20MB
                </Box>
              </div>
            </div>
          )}
          {fileRejectionItems}
        </Box>
      )}
    </div>
  );
};
