import {
  Dispensary,
  DueDiligenceStatus,
  Invitation,
  MinifiedDispensary,
  MUO,
  MUOBankResponse
} from '@gcv/shared';
import { BanksApi, DispensariesApi } from 'api';
import { injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { getComplianceStore } from './ComplianceStore';
import { getFiBankStore } from './FiBankStore';

@injectable()
export class FiDispensaryStore {
  allOrgs = () => [...this.bankMuos, ...this.dispensaries];
  bankApi = new BanksApi();
  bankMuos: MUOBankResponse[] = [] as MUOBankResponse[];
  bankMuosArchived: MUOBankResponse[] = [] as MUOBankResponse[];
  bankStore = getFiBankStore();
  complianceStore = getComplianceStore();
  dispensaries: MinifiedDispensary[] = [] as MinifiedDispensary[];
  invitedDispensaries: Invitation[] = [] as Invitation[];
  dispensariesArchived: MinifiedDispensary[] = [] as MinifiedDispensary[];
  dispensaryApi = new DispensariesApi();
  isLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadAllOrganizations = action(async () => {
    const minDispensariesPromise = this.bankApi
      .getMinifiedDispensaries(this.bankStore.bank.id)
      .then((minDispensaries) => {
        runInAction(() => {
          this.dispensaries = minDispensaries.filter(
            (d) => d.assigned_onboarding_template.status !== DueDiligenceStatus.BANK_DISCONNECTED
          );
          this.dispensariesArchived = minDispensaries.filter(
            (d) => d.assigned_onboarding_template.status === DueDiligenceStatus.BANK_DISCONNECTED
          );
        });
      });
    const bankMuosPromise = this.bankApi.getMuo(this.bankStore.bank.id).then((bankMuos) => {
      runInAction(() => {
        this.bankMuos = bankMuos.filter(
          (d) => d.assigned_onboarding_template.status !== DueDiligenceStatus.BANK_DISCONNECTED
        );
        this.bankMuosArchived = bankMuos.filter(
          (d) => d.assigned_onboarding_template.status === DueDiligenceStatus.BANK_DISCONNECTED
        );
      });
    });
    const invitedDispensariesPromise = this.bankApi
      .getInvitedDispensaries(this.bankStore.bank.id)
      .then((invitedDispnesaries: Invitation[]) => {
        runInAction(() => {
          this.invitedDispensaries = invitedDispnesaries;
        });
      });

    await Promise.all([minDispensariesPromise, bankMuosPromise, invitedDispensariesPromise]);
    runInAction(() => {
      this.isLoaded = true;
    });
  });

  updateInvitedDispensaries = action(async () => {
    await this.bankApi
      .getInvitedDispensaries(this.bankStore.bank.id)
      .then((invitedDispnesaries: Invitation[]) => {
        runInAction(() => {
          this.invitedDispensaries = invitedDispnesaries;
        });
      });
  });

  updateMinifiedDispensary = action((minifiedDispensary: MinifiedDispensary) => {
    const newDispensaries = [...this.dispensaries];

    const index = newDispensaries.findIndex((item) => item.id === minifiedDispensary.id);

    if (index >= 0) {
      if (minifiedDispensary.assigned_onboarding_template.status === DueDiligenceStatus.BANK_DISCONNECTED) {
        newDispensaries.splice(index, 1);
        this.dispensariesArchived.push(minifiedDispensary);
      } else {
        newDispensaries[index] = minifiedDispensary;
      }

      this.dispensaries = newDispensaries;
    }
  });

  updateMinifiedDispensaryFromFullDispensary = action((dispensary: Dispensary) => {
    const newDispensaries = [...this.dispensaries];

    const index = newDispensaries.findIndex((item) => item.id === dispensary.id);

    if (index >= 0) {
      const minifiedDispensary: MinifiedDispensary = {
        ...dispensary,
        last_login_date: newDispensaries[index].last_login_date,
        assigned_onboarding_template: newDispensaries[index].assigned_onboarding_template,
        internal_metadata: newDispensaries[index].internal_metadata,
        status: newDispensaries[index].status,
        archived_meta_data: newDispensaries[index].archived_meta_data
      };
      newDispensaries[index] = minifiedDispensary;
    }

    this.dispensaries = newDispensaries;
  });

  addDispensary = action((dispensary: MinifiedDispensary) => {
    const newDispensaries = [...this.dispensaries, dispensary];
    this.dispensaries = newDispensaries;
  });

  addMuo = action((muo: MUOBankResponse) => {
    const newMuos = [...this.bankMuos, muo];
    this.bankMuos = newMuos;
  });

  updateMuo = action((muo: MUO) => {
    const newMuos = [...this.bankMuos];

    const index = newMuos.findIndex((item) => item.id === muo.id);

    if (index >= 0) {
      const muoResponse = {
        ...muo,
        assigned_onboarding_template: newMuos[index].assigned_onboarding_template,
        internal_metadata: newMuos[index].internal_metadata
      };
      newMuos[index] = muoResponse;
    }

    this.bankMuos = newMuos;
  });

  updateMuoBankResponse = action((muo: MUOBankResponse) => {
    const newMuos = [...this.bankMuos];

    const index = newMuos.findIndex((item) => item.id === muo.id);

    if (index >= 0) {
      if (muo.assigned_onboarding_template.status === DueDiligenceStatus.BANK_DISCONNECTED) {
        this.bankMuos.splice(index, 1);
        this.bankMuosArchived.push(muo);
      } else {
        newMuos[index] = muo;
      }
    }

    this.bankMuos = newMuos;
  });

  get dispensaryIdNameTable() {
    // MobX will cache the lookup table and re-compute when 'dispensaries' or 'bankMuos' changes.
    const lookupTable = new Map<string, string>();

    this.dispensaries.forEach((d) => {
      if (d.id && d.name) {
        lookupTable.set(d.id, d.name);
      }
    });

    this.bankMuos.forEach((m) => {
      if (m.id && m.name) {
        lookupTable.set(m.id, m.name);
      }
    });

    return lookupTable;
  }

  /**
   * Return dispensary name associated with ID.
   *
   * @param id dispensary ID.
   * @returns the dispensary name, or undefined if not found.
   */
  lookupDispensaryName = (id: string): string | undefined => {
    return this.dispensaryIdNameTable.get(id);
  };

  findDispensary = (dispensaryId: string) => {
    return (
      this.dispensaries.find((d) => d.id === dispensaryId) ??
      this.dispensariesArchived.find((d) => d.id === dispensaryId) ??
      this.bankMuos.find((d) => d.id === dispensaryId) ??
      this.bankMuosArchived.find((d) => d.id === dispensaryId)
    );
  };
}

let store: FiDispensaryStore | undefined;

export function getFiDispensaryStore(): FiDispensaryStore {
  if (!store) {
    store = new FiDispensaryStore();
  }

  return store;
}
