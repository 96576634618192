import { CommentIdComponents, CommentType, CommentTypeUtil, PostCount } from '@gcv/shared';
import { CommentsApi } from 'api';
import { injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';

@injectable()
export class CommentStore {
  activeTab: 'comments' | 'activity' | 'archived' = 'comments';
  commentsOpen = false;
  currentPostId: string | null = null;
  idComponents: CommentIdComponents = {} as CommentIdComponents;
  fetchingCount = false;
  loading = false;
  countsMap: PostCount = {};
  postTitle = '';
  type: CommentType = CommentType.ACCOUNT;
  currentlyFetchingCount = false;

  commentsApi = new CommentsApi();

  constructor() {
    makeAutoObservable(this);
  }

  generatePostId = (post: { type: CommentType; idComponents: CommentIdComponents }): string => {
    return (
      CommentTypeUtil.getCommentPostIdv2(
        post.type,
        post.idComponents.fiId,
        post.idComponents.crbId,
        post.idComponents
      ) ?? ''
    );
  };

  setCurrentPost = action(
    (post: { type: CommentType; title: string; idComponents: CommentIdComponents } | null) => {
      if (post) {
        this.type = post.type;
        this.postTitle = post.title;
        this.idComponents = post.idComponents;
        this.currentPostId = this.generatePostId(post);
      } else {
        this.currentPostId = null;
      }
    }
  );

  openComments = action((isOpen: boolean, tab?: 'comments' | 'activity' | 'archived') => {
    // we only need a loading spinner if opening a different comment and the comment drawer is already open
    if (this.commentsOpen) {
      this.loading = true;
    }

    if (tab) {
      this.activeTab = tab;
    }

    this.commentsOpen = isOpen;

    // reset to comments tab on close for openComments calls that do not pass in a tab
    if (!isOpen) {
      this.activeTab = 'comments';
    }

    // artificial delay per request from product team
    setTimeout(() => {
      runInAction(() => {
        this.loading = false;
      });
    }, 500);
  });

  shouldFetchCount = action((fetch: boolean) => {
    this.fetchingCount = fetch;
  });

  fetchCount = action(async (posts: { type: CommentType; idComponents: CommentIdComponents }[]) => {
    const postIds = posts.map((post) => this.generatePostId(post)).filter((p) => p);

    if (postIds.length === 0) {
      // Otherwise, we get a 400 response: "Error: No Post Ids given".
      return;
    }

    try {
      if (this.currentlyFetchingCount && postIds.length > 0 && postIds[0] === this.currentPostId) return;
      this.currentlyFetchingCount = true;
      const counts = await this.commentsApi.getCommentCount(postIds);
      this.currentlyFetchingCount = false;
      runInAction(() => {
        Object.keys(counts).forEach((key) => {
          this.countsMap[key] = counts[key];
        });

        this.fetchingCount = false;
      });
    } catch (e) {
      console.log(e);
    }
  });

  activateTab = action((tab: 'comments' | 'activity' | 'archived') => {
    this.activeTab = tab;
  });

  clearStore = action(() => {
    this.countsMap = {};
  });
}

let commentStore: CommentStore | undefined;

export function getCommentStore(): CommentStore {
  if (!commentStore) {
    commentStore = new CommentStore();
  }

  return commentStore;
}
