import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

interface Props {
  location: { pathname: string; search: string };
  options: Record<string, unknown>;
}

interface State {}

class GoogleAnalytics extends Component<Props, State> {
  componentDidMount() {
    this.logPageChange(this.props.location.pathname, this.props.location.search);
  }

  componentDidUpdate({ location: prevLocation }: { location: { pathname: string; search: string } }) {
    const {
      location: { pathname, search }
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname: string, search = '') {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = () => {
  const isGAEnabled = process.env.NODE_ENV === 'production';

  if (isGAEnabled) {
    switch (process.env.REACT_APP_ENV) {
      case 'production':
        ReactGA.initialize('UA-140083832-4', { gaOptions: { siteSpeedSampleRate: 100 } });
        break;
      case 'staging':
        ReactGA.initialize('UA-140083832-3', { gaOptions: { siteSpeedSampleRate: 100 } });
        break;
      default:
        // integration
        ReactGA.initialize('UA-140083832-2', { gaOptions: { siteSpeedSampleRate: 100 } });
        break;
    }
  }

  return isGAEnabled;
};

const log = (category: string, action: string, label: string, value?: number) => {
  ReactGA.event({ category, action, label, value: value ?? 0 });
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  log
};
