import { CRBLicenseStatus, DepositStatus, FinCENReportStatus, ReportType, SalesActivityStatus } from 'api';

/**
 * Return instance representing form values for status of given report type.
 */
class ReportStatus {
  private reportType: ReportType;

  constructor(reportType: ReportType) {
    this.reportType = reportType;
  }

  public getReportType() {
    return this.reportType;
  }

  public getReportOptions() {
    switch (this.getReportType()) {
      case ReportType.DEPOSITS:
        return [
          { value: DepositStatus.Pending, label: 'Pending' },
          { value: DepositStatus.Accepted, label: 'Reconciled' },
          { value: DepositStatus.UnderReview, label: 'Under Review' }
        ];
      case ReportType.FINCENCTR:
      /* Fall through. */
      case ReportType.FINCENSAR:
        return [
          { value: FinCENReportStatus.All, label: 'All' },
          { value: FinCENReportStatus.Complete, label: 'Complete' },
          { value: FinCENReportStatus.Open, label: 'Open' }
        ];
      case ReportType.SALES:
        return [
          { value: SalesActivityStatus.Unchecked, label: 'Unchecked' },
          { value: SalesActivityStatus.RedFlag, label: 'Unverified' },
          { value: SalesActivityStatus.GreenChecked, label: 'Verified' },
          { value: SalesActivityStatus.Warning, label: 'Warning' }
        ];
      case ReportType.LICENSE:
        return [
          { value: CRBLicenseStatus.Active, label: 'Active' },
          { value: CRBLicenseStatus.ApprovedPending, label: 'Approved/Pending' },
          { value: CRBLicenseStatus.Inactive, label: 'Inactive' },
          { value: CRBLicenseStatus.PreLicense, label: 'Pre-License' },
          { value: CRBLicenseStatus.Unknown, label: 'Unknown' },
          { value: CRBLicenseStatus.Unlicensed, label: 'Unlicensed' },
          { value: CRBLicenseStatus.Unmatched, label: 'Unmatched' }
        ];
      default:
        return [{ value: '', label: '' }];
    }
  }

  public getAllReportValues() {
    return this.getReportOptions().map((o) => o.value);
  }
}

export default ReportStatus;
