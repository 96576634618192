import { TablePagination } from 'ui/molecules';
import { Row } from 'ui/organisms';
import { Box, SelectChangeEvent } from '@mui/material';
import palette from 'ui/theme/palette';

interface Props<T> {
  minWidth?: number | string;
  rows: Row<T>[];
  footerRows?: JSX.Element;
  rowsPerPage: number;
  page: number;
  defaultRowCount: number;
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleRowsPerPageChange: (value: number) => void;
}

export const TableFooter = <T,>(props: Props<T>) => {
  return (
    <>
      {props.footerRows && (
        <Box
          sx={{
            backgroundColor: palette.background.secondary,
            borderTop: `1px solid ${palette.divider}`
          }}
        >
          {props.footerRows}
        </Box>
      )}

      <TablePagination
        minWidth={props.minWidth}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        defaultRowCount={props.defaultRowCount}
        rowsCount={props.rows.length}
        handlePageChange={props.handlePageChange}
        handleRowsPerPageChange={props.handleRowsPerPageChange}
      />
    </>
  );
};
