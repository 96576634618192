import { IANATimezones } from '@gcv/shared';
import { DateTimeHelpers } from './dateTime.util';

/**
 * Return function to validate given date is not in the past.
 *
 * @param timezone client timezone used to construct today's date.
 * @returns validator function.
 */
export const createPastDateValidator = (timezone: IANATimezones) => {
  const validateDate = (value: string | number) => {
    const getStartOfDay = () => {
      return DateTimeHelpers.getStartOfTodayIsoString(timezone);
    };

    const valid = getStartOfDay() <= value.toString();
    return valid || 'cannot be past date.';
  };

  return validateDate;
};
