import { InsightPattern, InsightMetadata, InsightResult } from '@gcv/shared';
import { api } from './api-util/api';

export class InsightsApi {
  async getInsightResults(bankId: string, reviewId: string): Promise<InsightResult[]> {
    return await api().get(`/banks/${bankId}/insight-results/review/${reviewId}`);
  }

  async setInsightFlagStatus(
    bankId: string,
    insightResultId: string,
    isFlagged: boolean
  ): Promise<InsightResult> {
    return await api().put(`/banks/${bankId}/insight-result/${insightResultId}/set-flagged-status`, {
      flagged: isFlagged
    });
  }

  async setInsightReviewedStatus(
    bankId: string,
    insightResultId: string,
    isReviewed: boolean,
    note?: string
  ): Promise<InsightResult> {
    return await api().put(`/banks/${bankId}/insight-result/${insightResultId}/set-reviewed-status`, {
      reviewed: isReviewed,
      note: note
    });
  }

  async fetchInsightsMetadata(bankId: string): Promise<InsightMetadata> {
    return api().get(`/banks/${bankId}/insights-config`);
  }

  async updateInsightsPattern(
    bankId: string,
    pattern: InsightPattern,
    patternId: string
  ): Promise<InsightMetadata> {
    return api().put(`/banks/${bankId}/insights-config/${patternId}`, pattern);
  }
}
