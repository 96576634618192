export default {
  styleOverrides: {
    tooltip: {
      fontSize: '12px',
      lineHeight: '15px',
      color: '#757783',
      backgroundColor: 'white',
      border: '1px solid #C6CCDA'
    }
  }
};
