import { IANATimezones } from '@gcv/shared';
import { DateTime } from 'luxon';

const dataFormatMonths = 'yyyy-MM';
const dataFormat = 'yyyy-MM-dd';
const timeFormat = 'hh:mm a';
const tableFormat = 'MM/dd/yyyy';
const tableFormatMonths = 'MM/yyyy';
const tableFormatWithTime = 'MM/dd/yyyy hh:mm a';

export const DateTimeHelpers = {
  /**
   * Returns a UTC ISO string for right now
   * @example 2021-04-20T10:00:00.000Z
   */
  getUtcIsoString: () => {
    return DateTime.utc().toISO();
  },
  /**
   * Given an ISO string date and a timezone, returns the end of that day in UTC time
   * @example 2021-04-20T10:00:00.000Z -> 2021-04-21T09:59:59.999Z
   */
  getEndOfDateIsoString: (ISODate: string, timezone: IANATimezones | string) => {
    return DateTime.fromISO(ISODate, { zone: timezone }).endOf('day').toUTC().toISO();
  },
  /**
   * Gets the start of today's date in the organization's timezone
   * @example 2021-04-20T05:00:00.000Z
   */
  getStartOfTodayIsoString: (timezone: IANATimezones | string) => {
    return DateTime.utc().setZone(timezone).startOf('day').toUTC().toISO();
  },
  /**
   * Converts an ISO string to a date without time formatted for aggregated data
   * @example 2021-04-20T10:00:00.000Z -> 2021-04-20
   */
  formatISOToDateString: (ISODate: string, timezone: IANATimezones) => {
    return ISODate ? DateTime.fromISO(ISODate, { zone: timezone }).toFormat(dataFormat) : '--';
  },
  /**
   * Converts an ISO string to a date without time formatted for aggregated data
   * @example 2021-04-20T10:00:00.000Z -> 2021-04-20
   */
  formatISOToTimeString: (ISODate: string, timezone: IANATimezones) => {
    return ISODate ? DateTime.fromISO(ISODate, { zone: timezone }).toFormat(timeFormat) : '--';
  },
  /**
   * Converts an ISO string to a user friendly date string
   * @example 2021-04-20T10:00:00.000Z -> Apr 20, 2021
   */
  formatISOToFriendlyDateString: (ISODate: string, timezone: IANATimezones) => {
    return ISODate ? DateTime.fromISO(ISODate, { zone: timezone }).toLocaleString(DateTime.DATE_MED) : '--';
  },
  /**
   * Converts an ISO string to a date formatted for table data
   * @example 2021-04-20T10:00:00.000Z -> 04/20/2021
   */
  formatISOToTableDateString: (ISODate: string, timezone: IANATimezones) => {
    return ISODate ? DateTime.fromISO(ISODate, { zone: timezone }).toFormat(tableFormat) : '--';
  },
  /**
   * Converts a date with time string to a date formatted for table data with time
   * @example 2021-04-20T10:00:00.000Z -> 04/20/2021 04:20 pm
   */
  formatISODateStringToTableDateAndTimeString: (ISODate: string, timezone: IANATimezones) => {
    return ISODate ? DateTime.fromISO(ISODate, { zone: timezone }).toFormat(tableFormatWithTime) : '--';
  },
  /**
   * Converts a date with time string to a date formatted for table data with time
   * @example 2021-04-20T10:00:00.000Z -> 04/20/2021 04:20 pm
   */
  formatISODateStringToDateString: (ISODate: string, timezone: IANATimezones, format: string) => {
    return ISODate ? DateTime.fromISO(ISODate, { zone: timezone }).toFormat(format) : '--';
  },
  /**
   * Converts an ISO string to a user friendly date at time string
   * @example if periodUpperCase is true, 2021-04-20T10:00:00.000Z -> Apr 20, 2021 at 8:00 AM
   * @example if periodUpperCase is false or undefined, 2021-04-20T10:00:00.000Z -> Apr 20, 2021 at 8:00 am
   *
   * note: for developers outside US timezones you may see the date displayed as 9 sept 2022
   */
  formatISOToDateAtTimeString: (ISODate: string, timezone: IANATimezones, periodUpperCase?: boolean) => {
    if (periodUpperCase) {
      return ISODate
        ? DateTime.fromISO(ISODate, { zone: timezone }).toLocaleString(DateTime.DATE_MED) +
            ' at ' +
            DateTime.fromISO(ISODate, { zone: timezone }).toLocaleString(DateTime.TIME_SIMPLE).toUpperCase()
        : '--';
    }
    return ISODate
      ? DateTime.fromISO(ISODate, { zone: timezone }).toLocaleString(DateTime.DATE_MED) +
          ' at ' +
          DateTime.fromISO(ISODate, { zone: timezone }).toLocaleString(DateTime.TIME_SIMPLE)
      : '--';
  },
  /**
   * Converts an ISO string to a user friendly date and time string with timezone
   * @example 2014-08-06T17:07:04.000Z -> August 6, 2014, 1:07:04 PM EDT
   */
  formatISOToDateAtTimeStringWithTimezone: (ISODate: string, timezone: IANATimezones) => {
    return ISODate
      ? DateTime.fromISO(ISODate, { zone: timezone }).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
      : '--';
  },
  /**
   * Converts any ISO string to UTC and returns it
   * @example 2021-04-20T00:00:00.000-04:00 -> 2021-04-20T04:00:00.000Z
   */
  formatISOToUtcIsoString: (ISODate: string, timezone: IANATimezones) => {
    return ISODate ? DateTime.fromISO(ISODate, { zone: timezone }).toUTC().toISO() : '--';
  },
  /**
   * Converts a date without time string to a user friendly date
   * @example 2021-04-20 -> Apr 20, 2021
   */
  formatDateStringToFriendlyDateString: (dateString: string, timezone: IANATimezones) => {
    return dateString
      ? DateTime.fromFormat(dateString, dataFormat, { zone: timezone }).toLocaleString(DateTime.DATE_MED)
      : '--';
  },
  /**
   * Converts a date without time string to a user friendly month of year string
   * @example 2021-04 -> April 2021
   */
  formatMonthDateStringToFriendlyMonthString: (dateString: string, timezone: IANATimezones) => {
    return dateString
      ? DateTime.fromFormat(dateString, dataFormatMonths, { zone: timezone }).toFormat('MMMM y')
      : '--';
  },
  /**
   * Converts a date without time string to a user friendly month of year string
   * @example 2021-04-20 -> April 2021
   */
  formatDateStringToFriendlyMonthString: (dateString: string, timezone: IANATimezones) => {
    return dateString
      ? DateTime.fromFormat(dateString, dataFormat, { zone: timezone }).toFormat('MMMM y')
      : '--';
  },
  /**
   * Converts a date without time string to a UTC ISO string
   * @example 2021-04-20 -> 2021-04-20T04:00:00.000Z
   */
  formatDateStringToISOString: (dateString: string, timezone: IANATimezones) => {
    return dateString
      ? DateTime.fromFormat(dateString, dataFormat, { zone: timezone }).toUTC().toISO()
      : '--';
  },
  /**
   * Converts a date without time string to a date formatted for table data
   * @example 2021-04-20 -> 04/20/2021
   */
  formatDateStringToTableDateString: (dateString: string, timezone: IANATimezones) => {
    return dateString
      ? DateTime.fromFormat(dateString, dataFormat, { zone: timezone }).toFormat(tableFormat)
      : '--';
  },
  /**
   * Converts a month/year date without time string to a date formatted for table data
   * @example 2021-04 -> 04/2021
   */
  formatDateStringMonthsToTableDateString: (dateString: string, timezone: IANATimezones) => {
    return dateString
      ? DateTime.fromFormat(dateString, dataFormatMonths, { zone: timezone }).toFormat(tableFormatMonths)
      : '--';
  },
  /**
   * Converts a Javascript Date object to a short date string. Should only be used in components
   * that rely on JS Date objects
   * @example Tue Apr 20 2021 00:00:00 GMT-0400 (Eastern Daylight Time) -> Apr 20, 2021
   */
  formatJsDateToFriendlyDate: (date: Date, timezone: IANATimezones) =>
    date
      ? DateTime.fromJSDate(date, {
          zone: timezone
        }).toLocaleString(DateTime.DATE_MED)
      : '--',
  /**
   * Converts a Javascript Date object to a shorter date string. Should only be used in components
   * that rely on JS Date objects
   * @example Tue Apr 20 2021 00:00:00 GMT-0400 (Eastern Daylight Time) -> 4/20/2021
   */
  formatJsDateToShortDate: (date: Date, timezone: IANATimezones) =>
    date
      ? DateTime.fromJSDate(date, {
          zone: timezone
        }).toLocaleString(DateTime.DATE_SHORT)
      : '--',
  /**
   * Converts a Javascript Date object to a table date string. Should only be used in components
   * that rely on JS Date objects
   * @example Tue Apr 20 2021 00:00:00 GMT-0400 (Eastern Daylight Time) -> 04/20/2021
   */
  formatJsDateToTableDate: (date: Date, timezone: IANATimezones) =>
    date ? DateTime.fromJSDate(date).setZone(timezone, { keepLocalTime: true }).toFormat(tableFormat) : '--',
  /**
   * Converts a JavaScript Date object to an ISO date string (minus the time). Should only be used
   * in components that rely on JS Date objects
   * @example Tue Apr 20 2021 00:00:00 GMT-0400 (Eastern Daylight Time) -> 2021-04-20
   */
  formatJsDateToDateString: (date: Date, timezone: IANATimezones) =>
    date ? DateTime.fromJSDate(date).setZone(timezone, { keepLocalTime: true }).toFormat('yyyy-MM-dd') : '--',
  /**
   * Parses a date time object from the given date in the given timezone
   * @example 2021-04-20 -> DateTime
   */
  parseFromDateString: (dateString: string, timezone: IANATimezones) => {
    return DateTime.fromFormat(dateString ?? '', dataFormat, { zone: timezone });
  },
  /**
   * Parses a date time object from the given date in the given timezone
   * @example 04/20/2021 -> DateTime
   */
  parseFromTableDateString: (dateString: string, timezone: IANATimezones) => {
    return DateTime.fromFormat(dateString ?? '', tableFormat, { zone: timezone });
  },
  /**
   * Parses a date time object from the given ISO string in the given timezone
   * @example 2021-04-20T10:00:00.000Z -> DateTime
   */
  parseFromISOString: (ISODate: string, timezone: IANATimezones | string) => {
    return DateTime.fromISO(ISODate ?? '', { zone: timezone });
  },

  formatJsDateToISO: (date: Date, timezone: string): string => {
    //JS Dates come in client local timezone, this sets the zone and keeps the time at start and end of day
    return DateTime.fromJSDate(date).setZone(timezone, { keepLocalTime: true }).toUTC().toISO();
  }
};
