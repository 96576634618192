import { Box, Paper } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { ReactComponent as ArrowForward } from 'assets/images/arrow-forward.svg';
import * as React from 'react';

interface AccordionProps {
  label: string;
  icon: React.ReactNode;
  containerPadding?: string;
  contentPadding?: string;
  margin?: string;
  disabled?: boolean;
  defaultOpen?: boolean;
  dataCy?: string;
}

export const CardAccordion: React.FC<AccordionProps> = (props) => {
  const [expanded, setExpanded] = React.useState(props.defaultOpen ?? false);

  return (
    <Paper
      sx={{
        padding: props.containerPadding ? props.containerPadding : '0.65rem 1rem',
        borderRadius: '6px',
        margin: props.margin ? props.margin : '1rem',
        width: '100%'
      }}
      variant="outlined"
    >
      <MuiAccordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        data-cy={props.dataCy}
        disabled={props.disabled}
        sx={{
          '&.MuiAccordion-root': {
            borderRadius: '0px',
            boxShadow: 'none',
            borderBottom: 'none'
          },
          '&.MuiAccordion-root.Mui-disabled': {
            background: 'none'
          },
          '& .MuiAccordionDetails-root': {
            padding: '0 10px 0 2px'
          },
          p: 0
        }}
      >
        <MuiAccordionSummary
          expandIcon={<ArrowForward />}
          sx={{
            zIndex: 999,
            '& .MuiAccordionSummary-root': {
              display: 'flex',
              padding: '0 10px 0 2px',
              borderRadius: 0
            },
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)'
            },
            '.MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center',
              m: 0
            },
            p: props.contentPadding ? props.contentPadding : 0
          }}
        >
          <Box sx={{ mr: '1rem', mt: '0.5rem' }}>{props.icon}</Box>
          <Typography
            variant="h4"
            sx={{
              color: (theme) => (props.disabled ? theme.palette.text.disabled : theme.palette.text.primary)
            }}
          >
            {props.label}
          </Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails
          sx={{
            width: '100%'
          }}
        >
          {props.children}
        </MuiAccordionDetails>
      </MuiAccordion>
    </Paper>
  );
};
