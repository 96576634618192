import {
  TemplateResponse,
  CustomTextSingleLineField,
  CustomRadioField,
  CustomCheckboxField,
  CustomSelectField,
  CustomFieldDataType,
  CustomFieldType,
  CustomField,
  DocumentUpload,
  IANATimezones,
  CustomRequirement,
  QuestionnaireResponse,
  SmartRuleOperator,
  SmartRuleAction,
  CustomSection
} from '@gcv/shared';
import {
  Controller,
  FieldValues,
  useFieldArray,
  UseFormReturn,
  useFormState,
  useWatch
} from 'react-hook-form';
import {
  Header,
  Container,
  ContainerItem,
  Text,
  InputCheckBox,
  FileContainer,
  InputDate,
  InputLabel,
  InputSelect,
  InputText,
  InputRadio,
  InputCurrency,
  InputEmail,
  InputPhone,
  InputUrl,
  InputNumber,
  Error,
  Input
} from 'ui';
import { Box, debounce, FormControl, useMediaQuery, useTheme } from '@mui/material';
import palette from 'ui/theme/palette';
import { environment } from 'environments/environment';
import { OverlaySpinner } from 'ui/molecules';
import * as React from 'react';
import { errorMessages } from 'util/forms.util';
import { InputCurrencyClone } from './input-currency-clone';
import { InputNumberClone } from './input-number-clone';
import { PreviewCustomFormDisplayContentType } from '../CustomForm/preview-custom-form-display-content-type';
import { PreviewCustomFormAttachments } from '../CustomForm/preview-custom-form-attachments';

interface Props extends Record<string, unknown> {
  form: UseFormReturn<FieldValues>;
  requirement: TemplateResponse | QuestionnaireResponse;
  editMode: boolean;
  onFileUpload: (
    requirementId: string,
    fieldId: string,
    files: File[]
  ) => Promise<DocumentUpload | undefined> | undefined;
  onFileDelete: (requirementId: string, fieldId: string, file: DocumentUpload) => void;
  documentMap: { [id: string]: DocumentUpload };
  isLoading: boolean;
  timezone: IANATimezones;
  onBlur?: () => void;
  fieldWidth?: string;
  addExistingFilesToMap?: (fieldId: string, docIds: string[]) => void;
  refreshFileComponent?: boolean;
  disableFileDelete?: boolean;
}

export const shouldDisplayFieldWithSmartRule = (
  field: CustomField | CustomSection,
  formValues: FieldValues
) => {
  if (field && field.smart_rule) {
    let result = true;

    field.smart_rule.conditions.forEach((c) => {
      switch (c.operator) {
        case SmartRuleOperator.IsEmpty:
          result =
            result &&
            (!formValues[c.targetFieldId] ||
              formValues[c.targetFieldId] === '' ||
              formValues[c.targetFieldId] === '--');
          break;

        case SmartRuleOperator.IsEqualTo:
          result =
            result &&
            (formValues[c.targetFieldId] === c.targetValue?.trim() ||
              (typeof formValues[c.targetFieldId] === 'string' &&
                formValues[c.targetFieldId].includes(';') &&
                formValues[c.targetFieldId].split(';').find((i: string) => i === c.targetValue?.trim())));
          break;

        case SmartRuleOperator.IsFilled:
          result =
            result &&
            formValues[c.targetFieldId] &&
            formValues[c.targetFieldId] !== '' &&
            formValues[c.targetFieldId] !== '--';
          break;

        case SmartRuleOperator.IsNotEqualTo:
          result =
            result &&
            (formValues[c.targetFieldId] !== c.targetValue?.trim() ||
              (typeof formValues[c.targetFieldId] === 'string' &&
                !formValues[c.targetFieldId].includes(c.targetValue?.trim())));
          break;

        default:
          break;
      }
    });

    if (field.smart_rule.action === SmartRuleAction.Show) {
      return result;
    } else if (field.smart_rule.action === SmartRuleAction.Hide) {
      return !result;
    }
  }

  return true;
};

export const CustomFileUploader: React.FC<{
  field: CustomField;
  form: UseFormReturn<FieldValues>;
  editMode: boolean;
  documentMap: { [id: string]: DocumentUpload };
  requirementId: string;
  onFileUpload: (
    requirementId: string,
    fieldId: string,
    files: File[]
  ) => Promise<DocumentUpload | undefined> | undefined;
  onFileDelete: (requirementId: string, fieldId: string, file: DocumentUpload) => void;
  addExistingFilesToMap?: (fieldId: string, docIds: string[]) => void;
  refreshFileComponent?: boolean;
  disableFileDelete?: boolean;
  orgId?: string;
}> = (props) => {
  const [existingFiles, setExistingFiles] = React.useState<DocumentUpload[]>([]);

  // In questionnaires, cuz of autosave method, uploaded files also get updated as existingFiles
  // to remove the duplicacy, refreshFileComponent is used. to reset FileContainer
  const [refreshFileComponent, setRefreshFileComponent] = React.useState(false);

  const triggerRefresh = (value: boolean) => {
    setRefreshFileComponent(value);
  };

  React.useEffect(() => {
    if (props.refreshFileComponent) {
      triggerRefresh(props.refreshFileComponent);
    }
  }, [props.refreshFileComponent]);

  React.useEffect(() => {
    const ids = props.form.getValues()[props.field.id];
    const docs: DocumentUpload[] = [];

    // Before Multiple files support, Values for File field are different for questionnaires & Templates
    // Questionnaires takes DocumentUpload format && Templates takes just docIds
    // to support multiple files, these different formats are causing problems
    // so Now FormValue is made generic with docIDs as their values

    // Below chain of if else's are for same purpose,
    // to support single docID, single documentUpload & array of docIds(this has from multiple files support

    // existingFileMap is required while submititng the form,
    // existingFileMap is a map with key as fieldId and value as docIds
    if (Array.isArray(ids)) {
      ids.forEach((id) => {
        if (typeof id === 'string' && id.length > 0) {
          if (props.documentMap[id]) docs.push(props.documentMap[id]);
        }
        if (typeof id === 'object') {
          docs.push(id);
        }
      });
      if (props.addExistingFilesToMap) props.addExistingFilesToMap(props.field.id, ids);
    } else if (typeof ids === 'string') {
      if (props.documentMap[ids]) docs.push(props.documentMap[ids]);
      if (props.addExistingFilesToMap) props.addExistingFilesToMap(props.field.id, [ids]);
    } else if (typeof ids === 'object') {
      docs.push(ids);
      if (props.addExistingFilesToMap) props.addExistingFilesToMap(props.field.id, [ids?.id]);
    }
    setExistingFiles(docs);
  }, [props.form.getValues(props.field.id), refreshFileComponent]);

  return (
    <FormControl fullWidth>
      <Box data-cy={props.field.id}>
        <InputLabel
          dataCy={'input-label'}
          label={props.field.label}
          name={props.field.id}
          required={props.field.required}
          readOnly={!props.editMode}
          darkLabel
          rules={{}}
        />
      </Box>
      <PreviewCustomFormAttachments noLabel attachments={props.field.attachments} orgId={props.orgId} />
      {!props.editMode ? (
        <Text
          sx={{
            fontWeight: 700,
            lineHeight: '15px',
            fontSize: '13px',
            margin: '4px 0 0 5px',
            color: (theme) => theme.palette.text.primary
          }}
          content="Uploaded files"
        />
      ) : null}
      <Controller
        control={props.form.control}
        name={props.field.id}
        rules={{ required: { message: 'is required', value: props.field.required || false } }}
        render={({ fieldState: { error } }) => (
          <>
            {!existingFiles.length && !props.editMode ? (
              <Box sx={{ paddingTop: '8px' }}>
                <Text content="No files uploaded" type="body3" />
              </Box>
            ) : (
              <Box sx={{ paddingTop: '8px' }}>
                <FileContainer
                  fullWidth
                  acceptedTypes="all"
                  multiple
                  disableFileDelete={props.disableFileDelete}
                  viewOnly={!props.editMode}
                  readOnly={!props.editMode}
                  fileBucket={environment.storageConfig.orgDocument}
                  existingS3Files={existingFiles}
                  dataCy="file-container"
                  onNewFilesChange={async (files: File[]) => {
                    props.onFileUpload(props.requirementId, props.field.id, files);
                  }}
                  onRemoveExistingFile={(file: DocumentUpload) => {
                    props.onFileDelete(props.requirementId, props.field.id, file);
                    const updatedFiles = existingFiles.filter((doc) => doc.id !== file.id);
                    const ids = updatedFiles.map((doc) => doc.id);
                    setExistingFiles(updatedFiles);
                    props.form.setValue(props.field.id, ids);
                    props.form.trigger();
                  }}
                  hardResetComponent={refreshFileComponent}
                  triggerRefresh={triggerRefresh}
                  {...props.form}
                />
              </Box>
            )}
            {props.editMode && (
              <Error
                content={
                  error ? `* ${props.field.label} ${error.message || errorMessages()[error.type]}` : ''
                }
                dataCy="validation-message"
              />
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export const AdditionalInfoGenerator: React.FC<Props> = (props) => {
  // Updates on change of radio/select fields as its used only for smart-rule
  const [formValues, setFormValues] = React.useState(props.form.getValues());
  const [animate, setAnimation] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const debouncedSaveFormValues = React.useCallback(
    debounce((data: FieldValues) => {
      setFormValues(data);
    }, 150),
    []
  );

  const resetFieldValue = (fieldId: string) => {
    if (props.form.getValues()[fieldId]) {
      props.form.setValue(fieldId, undefined);
    }
  };

  const onValueChange = () => {
    if (props.onBlur) {
      props.onBlur();
    }
    debouncedSaveFormValues(props.form.getValues());
    if (!animate) {
      setAnimation(true);
    }
  };

  const onChange = React.useCallback(
    debounce(() => {
      if (props.onBlur) {
        props.onBlur();
      }
    }, 2000),
    []
  );

  const CurrencyInputWrapper: React.FC<any> = (wrapperProps) => {
    return <Input {...wrapperProps} disableAutoWidth fullWidth />;
  };

  const NumberInputWrapper: React.FC<any> = (wrapperProps) => {
    return <Input {...wrapperProps} disableAutoWidth fullWidth />;
  };

  const getField = (field: CustomField, requirementId: string) => {
    const existingValue = props.form.getValues()[field.id];
    switch (field.type) {
      case CustomFieldType.TextSingleLine:
        switch ((field as CustomTextSingleLineField).dataType) {
          case CustomFieldDataType.Currency:
            return (
              <InputCurrencyClone
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onChange={onChange}
                customInput={CurrencyInputWrapper}
                {...props.form}
              />
            );

          case CustomFieldDataType.Email:
            if (!existingValue || existingValue === '--') props.form.setValue(field.id, '');
            return (
              <InputEmail
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onBlur={props.onBlur}
                onChange={onChange}
                disableAutoWidth
                darkLabel
                {...props.form}
              />
            );

          case CustomFieldDataType.Number:
            return (
              <InputNumberClone
                unformatted
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{
                  required: { message: 'is required', value: field.required || false }
                }}
                dataCy={field.id}
                onChange={onChange}
                customInput={NumberInputWrapper}
                {...props.form}
              />
            );

          case CustomFieldDataType.Phone:
            return (
              <InputPhone
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onBlur={props.onBlur}
                onChange={onChange}
                darkLabel
                {...props.form}
              />
            );

          case CustomFieldDataType.Text:
            if (!existingValue || existingValue === '--') props.form.setValue(field.id, '');
            return (
              <InputText
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onBlur={props.onBlur}
                onChange={onChange}
                disableAutoWidth
                darkLabel
                {...props.form}
              />
            );

          case CustomFieldDataType.Url:
            if (!existingValue || existingValue === '--') props.form.setValue(field.id, '');
            return (
              <InputUrl
                label={field.label}
                name={field.id}
                viewOnly={!props.editMode}
                rules={{ required: { message: 'is required', value: field.required || false } }}
                dataCy={field.id}
                onBlur={props.onBlur}
                onChange={onChange}
                darkLabel
                {...props.form}
              />
            );

          default:
            break;
        }
        break;

      case CustomFieldType.TextMultiLine:
        if (!existingValue || existingValue === '--') props.form.setValue(field.id, '');
        return (
          <InputText
            label={field.label}
            name={field.id}
            multiline
            minRows={3}
            viewOnly={!props.editMode}
            rules={{ required: { message: 'is required', value: field.required || false } }}
            dataCy={field.id}
            onBlur={props.onBlur}
            onChange={onChange}
            disableAutoWidth
            darkLabel
            {...props.form}
          />
        );

      case CustomFieldType.Select:
        return (
          <InputSelect
            listItemsWidth="auto"
            label={field.label}
            name={field.id}
            options={(field as CustomSelectField).options.split(';').map((option) => {
              return {
                label: option.trim(),
                value: option.trim()
              };
            })}
            viewOnly={!props.editMode}
            rules={{ required: { message: 'is required', value: field.required || false } }}
            dataCy={field.id}
            onBlur={props.onBlur}
            additionalOnChange={onValueChange}
            {...props.form}
          />
        );

      case CustomFieldType.Radio:
        return (
          <InputRadio
            label={field.label}
            name={field.id}
            options={(field as CustomRadioField).options.split(';').map((option) => {
              return {
                label: option.trim(),
                value: option.trim()
              };
            })}
            justifyContent="column"
            viewOnly={!props.editMode}
            rules={{ required: { message: 'is required', value: field.required || false } }}
            dataCy={field.id}
            onChange={onValueChange}
            {...props.form}
          />
        );

      case CustomFieldType.File:
        return (
          <CustomFileUploader
            field={field}
            form={props.form}
            editMode={props.editMode}
            documentMap={props.documentMap}
            onFileUpload={props.onFileUpload}
            onFileDelete={props.onFileDelete}
            requirementId={requirementId}
            addExistingFilesToMap={props.addExistingFilesToMap}
            refreshFileComponent={props.refreshFileComponent}
            disableFileDelete={props.disableFileDelete}
            orgId={props.requirement.bank_id}
          />
        );

      case CustomFieldType.Checkbox:
        return (
          <>
            <InputLabel label={field.label} name={field.id} required={field.required} darkLabel />
            <Box
              sx={{ display: 'inline-flex', flexDirection: 'column', paddingTop: '5px', flexWrap: 'wrap' }}
            >
              {(field as CustomCheckboxField).options.split(';').map((option, index) => {
                const trimmedOption = option.trim();
                const formFieldValue = props.form.watch(field.id);
                const isOptionChecked =
                  formFieldValue &&
                  typeof formFieldValue === 'string' &&
                  formFieldValue.includes(trimmedOption);

                return (
                  <Box key={`${option}_${index}`} sx={{ mb: '0.5rem' }}>
                    <InputCheckBox
                      /**
                       * As we have same `name` property to all the options,
                       * adding key forces the options to re-render whenever
                       * the form value change so they are all seeing the
                       * latest form value.
                       */
                      key={Math.random()}
                      label={trimmedOption}
                      name={field.id}
                      dataCy={field.id}
                      readOnly={!props.editMode}
                      viewOnly={!props.editMode}
                      checked={isOptionChecked}
                      doNotconsiderFormValue
                      doNotDisplayErrors
                      rules={{
                        validate: (val) => {
                          if (field.required) {
                            const formValue = props.form.getValues()[field.id];

                            return formValue && typeof formValue === 'string' && formValue.length > 0;
                          }
                          return true;
                        }
                      }}
                      transformAndSave={(checked: boolean, onChange: (value: any) => void) => {
                        let currentValue = props.form.getValues()[field.id];

                        if (checked) {
                          if (currentValue) {
                            if (typeof currentValue === 'string' && !currentValue.includes(trimmedOption)) {
                              currentValue += ';' + trimmedOption;
                            }
                          } else {
                            currentValue = trimmedOption;
                          }
                        } else {
                          if (
                            currentValue &&
                            typeof currentValue === 'string' &&
                            currentValue.includes(trimmedOption)
                          ) {
                            currentValue = currentValue
                              .split(';')
                              .filter((v) => v !== trimmedOption)
                              .join(';');
                          }
                        }

                        onChange(currentValue);

                        if (props.onBlur) {
                          props.onBlur();
                        }
                      }}
                      {...props.form}
                    />
                  </Box>
                );
              })}
            </Box>

            {Object.keys(props.form.formState.errors).includes(field.id) && (
              <Error
                content={
                  props.form.formState.errors[field.id]
                    ? `* ${field.label} ${
                        props.form.formState.errors[field.id].message ||
                        errorMessages()[props.form.formState.errors[field.id].type]
                      }`
                    : ''
                }
              />
            )}
          </>
        );

      case CustomFieldType.Date:
        return (
          <InputDate
            label={field.label}
            name={field.id}
            viewFormat="MM-dd-yyyy"
            timezone={props.timezone}
            viewOnly={!props.editMode}
            rules={{ required: { message: 'is required', value: field.required || false } }}
            dataCy={field.id}
            onBlur={props.onBlur}
            onChange={props.onBlur}
            darkLabel
            {...props.form}
          />
        );

      case CustomFieldType.DisplayContent:
        return (
          <Container padding="0" width={isMobile ? '100%' : '580px'}>
            <PreviewCustomFormDisplayContentType
              field={field}
              attachmentsPadding="10px 0 4px 0"
              orgId={props.requirement.bank_id}
            />
          </Container>
        );
    }
  };

  const fieldAnimation = (field: CustomField | CustomSection) =>
    field.smart_rule && animate ? '1.5s linear SMART_RULE_ANIM' : null;

  return (
    <Container padding="0" column dataCy="info-form-container" width="100%">
      {props.isLoading && <OverlaySpinner dataCy="overlay-spinner" />}
      {Object.keys(props.requirement.custom_requirements)
        .filter((sectionId) => {
          return (
            !props.requirement.custom_requirements[sectionId].archived &&
            props.requirement.custom_requirements[sectionId].custom_section.fields.length
          );
        })
        .sort((a, b) => {
          const aa = props.requirement.custom_requirements[a].order;
          const bb = props.requirement.custom_requirements[b].order;

          return aa > bb ? 1 : aa === bb ? 0 : -1;
        })
        .map((key) => {
          const requirement = props.requirement.custom_requirements[key];

          if (shouldDisplayFieldWithSmartRule(requirement.custom_section, props.form.getValues())) {
            return (
              <Container
                padding="0"
                column
                width="100%"
                dataCy="form-section"
                key={requirement.custom_section.id}
              >
                <Box
                  sx={{
                    width: '100%',
                    WebkitAnimation: fieldAnimation(requirement.custom_section),
                    MozAnimation: fieldAnimation(requirement.custom_section),
                    OAnimation: fieldAnimation(requirement.custom_section),
                    animation: fieldAnimation(requirement.custom_section),
                    animationIterationCount: 1,
                    '@-webkit-keyframes SMART_RULE_ANIM': {
                      from: {
                        backgroundColor: '#FFFCE6'
                      },
                      to: {
                        backgroundColor: 'white'
                      }
                    }
                  }}
                >
                  <ContainerItem dataCy={requirement.id}>
                    <Header mobileHeader={isMobile} content={requirement.custom_section.label} type={'h2'} />
                  </ContainerItem>

                  {requirement.custom_section.description && (
                    <ContainerItem padding={'0 8px 8px'}>
                      <Text
                        sx={{ whiteSpace: 'pre-line' }}
                        content={requirement.custom_section.description}
                        type="body2"
                      />
                    </ContainerItem>
                  )}
                </Box>
                {requirement.custom_section.fields.map((item) => {
                  if (shouldDisplayFieldWithSmartRule(item, props.form.getValues())) {
                    return (
                      <Box
                        sx={{
                          width: '100%',
                          WebkitAnimation: fieldAnimation(item),
                          MozAnimation: fieldAnimation(item),
                          OAnimation: fieldAnimation(item),
                          animation: fieldAnimation(item),
                          animationIterationCount: 1,
                          '@-webkit-keyframes SMART_RULE_ANIM': {
                            from: {
                              backgroundColor: '#FFFCE6'
                            },
                            to: {
                              backgroundColor: 'white'
                            }
                          }
                        }}
                        key={item.id}
                      >
                        <ContainerItem dataCy="custom-section" width={props.fieldWidth}>
                          <Box
                            sx={{
                              mb: '0.5rem'
                            }}
                          >
                            {getField(item, key)}
                          </Box>

                          {item.helpText && (
                            <Box>
                              <Text
                                content={item.helpText}
                                sx={{
                                  color: palette.text.secondary,
                                  fontFamily: 'Lato',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  fontSize: '13px',
                                  lineHeight: '18.2px'
                                }}
                              />
                            </Box>
                          )}
                        </ContainerItem>
                      </Box>
                    );
                  }
                  resetFieldValue(item.id);
                  return null;
                })}
              </Container>
            );
          } else {
            return null;
          }
        })}
    </Container>
  );
};
