import { ContainerItem } from 'ui/atoms';
import { PageTab } from 'ui/templates';

interface Props {
  activeTab: number;
  tabs: PageTab[];
  zeroState?: React.ReactNode;
}

export const TabContent: React.FC<Props> = (props) => {
  const CurrentTab = props.tabs[props.activeTab]?.component;

  return (
    <ContainerItem width="100%">
      {props.zeroState ? props.zeroState : CurrentTab ? <CurrentTab /> : null}
    </ContainerItem>
  );
};
