import { FormControl, TextField } from '@mui/material';
import * as React from 'react';
import { Control, Controller, FieldError, Validate } from 'react-hook-form';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { Container, ContainerItem, Error, Icon, InputLabel, Tooltip } from 'ui/atoms';
import { errorMessages } from 'util/forms.util';

interface CustomProps {
  onChange: (e?: number) => void;
  setFieldError: (value: React.SetStateAction<FieldError | undefined>) => void;
  label: string;
  viewOnly?: boolean;
  readOnly?: boolean;
  name: string;
  value: any;
}

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputCurrencyProps {
  label: string;
  name: string;
  control?: Control;
  dataCy?: string;
  defaultValue?: string;
  onBlur?: () => void;
  onChange?: (e?: number) => void;
  readOnly?: boolean;
  viewOnly?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<string | number>;
    minLength?: ValidationRule<string | number>;
    max?: ValidationRule<string | number>;
    min?: ValidationRule<string | number>;
    pattern?: ValidationRule<RegExp>;
    validate?: Validate<string | number> | Record<string, Validate<string | number>>;
  };
  tooltip?: string;
  customInput?: React.ComponentType<any>;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, value, setFieldError, label, readOnly, viewOnly, ...other } = props;

    return (
      <NumberFormat
        {...other}
        allowLeadingZeros={true}
        className={'MuiInput-input MuiInputBase-input'}
        decimalScale={2}
        decimalSeparator={'.'}
        defaultValue={value}
        disabled={props.readOnly || props.viewOnly}
        fixedDecimalScale={true}
        getInputRef={ref}
        placeholder={'$0.00'}
        prefix={'$'}
        readOnly={props.readOnly}
        thousandSeparator={true}
        onValueChange={(e) => {
          const number =
            e.floatValue || e.floatValue === 0 ? Math.round(Number(e.floatValue) * 100) : undefined;

          if (number || number === 0) {
            onChange(number);
          }
        }}
        isAllowed={(numberValue) => {
          let isValid = true;

          // allow entering negative sign
          if (numberValue.value === '-') {
            return isValid;
          }

          if (numberValue.value.length > 16) {
            isValid = false;
          } else if (!numberValue.value) {
            // empty is still valid, but we also want an error message
            isValid = true;
            setFieldError({
              message: `${props.label} invalid. Currency field must contain a number.`,
              type: 'valueAsNumber'
            });
          } else if (isNaN(+numberValue.value)) {
            isValid = false;
            setFieldError({
              message: `${props.label} invalid. Currency field must contain a number.`,
              type: 'valueAsNumber'
            });
          } else {
            setFieldError(undefined);
          }

          return isValid;
        }}
      />
    );
  }
);

export const InputCurrencyClone: React.FC<InputCurrencyProps> = (props) => {
  const [fieldError, setFieldError] = React.useState<FieldError>();

  return (
    <FormControl fullWidth data-cy={props.dataCy}>
      <Container padding={0} align="flex-start">
        <ContainerItem padding={0} align="center" flex margin="0 0 6px 0">
          <InputLabel
            dataCy={'input-label'}
            label={props.label}
            name={props.name}
            required={props.viewOnly ? false : props.rules?.required?.value}
            readOnly={props.readOnly}
            rules={props.rules}
            viewOnly={props.viewOnly}
            darkLabel
          />
        </ContainerItem>

        {props.tooltip ? (
          <ContainerItem padding={0} align="center" flex margin="-3px 0 0 0">
            <Tooltip variant="text" title={props.tooltip} placement="right">
              <Icon type="help" color="primary" />
            </Tooltip>
          </ContainerItem>
        ) : null}
      </Container>
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              sx={{
                backgroundColor: (theme) => (props.viewOnly ? 'none' : theme.palette.background.default),
                borderColor: (theme) => theme.palette.grey[400],
                height: '41px',
                width: '100%',
                '.MuiInputBase-input': {
                  border: 'none',
                  background: 'none',
                  borderRadius: '5px',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  height: '41px',
                  padding: props.viewOnly ? '1px 0 5px' : '0.5rem 0.75rem',
                  width: '100%',
                  color: '#3A3C45'
                },
                '.MuiInputBase-root': {
                  background: 'none',
                  border: props.viewOnly ? 'none' : `1px solid`,
                  borderColor: (theme) => (fieldError ? theme.palette.error.main : theme.palette.divider),

                  '&.Mui-focused': {
                    borderColor: (theme) =>
                      fieldError ? theme.palette.error.main : theme.palette.primary.main,
                    boxShadow: fieldError
                      ? `0px 0px 0px 3px rgba(255, 78, 80, 0.25)`
                      : `0px 0px 0px 3px rgba(3, 166, 91, 0.25)`
                  },

                  '& fieldset': {
                    border: 'none'
                  }
                }
              }}
              name={props.name}
              value={value == null ? '' : value / 100}
              onChange={(e) => {
                onChange(e);
                if (props.onChange) {
                  props.onChange();
                }
              }}
              onBlur={() => {
                if (props.onBlur) {
                  props.onBlur();
                }

                onBlur();
              }}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  setFieldError: setFieldError,
                  label: props.label,
                  readOnly: props.readOnly,
                  viewOnly: props.viewOnly
                }
              }}
            />
            <Error
              content={
                props.viewOnly
                  ? ''
                  : fieldError
                  ? `* ${fieldError.message || errorMessages()[fieldError.type]}`
                  : error
                  ? `* ${props.label} ${error.message || errorMessages()[error.type]}`
                  : ''
              }
              dataCy="validation-message"
            />
          </>
        )}
      />
    </FormControl>
  );
};
