import { Box } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Background, Drawer, Stepper, StepperStep } from 'ui';
import useQueryString from 'util/queryString.util';

interface OnboardingStepperProps {
  handleComplete: () => void;
  completeSetupLaterRedirectPath: string;
  steps: StepperStep[];
  dataCy?: string;
  openNavDrawer: boolean;
  permanent: boolean;
  setOpenNavMenu: (isOpen: boolean) => void;
  updateOnStepChange?: () => void;
  icon?: React.ReactNode;
  // passed the GCV logo as prop from Onboarding index because Logo will act as Link and redirect
  //   user to dashboard. Link cannot be used outside router.
  //  Here OnboardingStepper is not wrapped under router but instead it is an organism.
  // Hence, Logo is passedas prop (reason - failing tests)
}

export const OnboardingStepper: React.FC<OnboardingStepperProps> = (props) => {
  const history = useHistory();
  const [queryStringStep, setQueryStringStep] = useQueryString('step', props.steps[0].id);
  const getDefaultStepFromQueryString = (): number => {
    if (queryStringStep) {
      const step = props.steps.find((s) => s.id === queryStringStep);
      if (step && props.steps.indexOf(step) !== undefined) {
        return props.steps.indexOf(step);
      }
    }
    return 0;
  };

  const [activeStep, setActiveStep] = React.useState(getDefaultStepFromQueryString());

  const totalSteps = () => {
    return props.steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return props.steps.every((step) => step.complete);
  };

  const handleStep = (step: number) => {
    if (props.updateOnStepChange) {
      props.updateOnStepChange();
    }
    setActiveStep(step);
    setQueryStringStep(props.steps[step].id);

    if (!props.permanent) {
      props.setOpenNavMenu(false);
    }
  };

  const handleNext = () => {
    if (props.updateOnStepChange) {
      props.updateOnStepChange();
    }
    if (isLastStep() && allStepsCompleted()) {
      props.handleComplete();
    } else {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has not been completed
            props.steps.findIndex((step) => !step.complete)
          : activeStep + 1;
      setActiveStep(newActiveStep);
      setQueryStringStep(props.steps[newActiveStep].id);
    }
  };

  const handleBack = () => {
    if (props.updateOnStepChange) {
      props.updateOnStepChange();
    }
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setQueryStringStep(props.steps[activeStep - 1].id);
    }
  };

  const CurrentStep = props.steps[activeStep]?.Content;

  return (
    <>
      <Drawer
        anchor="left"
        hideBackdrop={props.permanent}
        onClose={() => props.setOpenNavMenu(false)}
        open={props.openNavDrawer}
        permanent={props.permanent}
        dataCy={props.dataCy}
      >
        {/* passed the GCV logo as prop because Logo will act as Link and redirect 
      user to dashboard. Link cannot be used outside router. 
      Here OnboardingStepper is not wrapped under router but instead it is an organism.
      Hence, Logo is passedas prop (reason - failing tests) */}
        {props.icon && props.icon}
        <Box sx={{ width: '260px', pt: '41px' }}>
          <Stepper
            activeStep={activeStep}
            handleStep={handleStep}
            steps={props.steps}
            dataCy="onboarding-stepper"
          />
        </Box>

        {/* <Box sx={{ width: '260px', pt: '50px', textAlign: 'center' }}>
          {props.steps.every((s) => s.complete) ? (
            <Button
              label="Complete"
              color="primary"
              onClick={props.handleComplete}
              dataCy="complete-button"
            />
          ) : (
            <Button
              label="Complete Setup Later"
              color="primary-outlined"
              onClick={() => history.push(props.completeSetupLaterRedirectPath)}
              dataCy="complete-setup-later-button"
            />
          )}
        </Box> */}
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1
        }}
      >
        <Background ignoreSidebar>
          {CurrentStep && (
            <CurrentStep
              totalSteps={props.steps.length}
              onNext={handleNext}
              onBack={handleBack}
              stepNumber={activeStep + 1}
              isLastStep={isLastStep()}
            />
          )}
        </Background>
      </Box>
    </>
  );
};
