import qs from 'query-string';
import { useState, useCallback } from 'react';

export const setQueryStringWithoutPageReload = (qsValue: string) => {
  const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + qsValue;
  window.history.pushState({ path: newurl }, '', newurl);
};

const getQueryStringValue = (key: string, queryString = window.location.search): string => {
  const values = qs.parse(queryString);
  return values[key] as string;
};

const setQueryStringValue = (key: string, value: string, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({
    ...values,
    [key]: value
  });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

function useQueryString(key: string, initialValue: string): [string, (newValue: string) => void] {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export default useQueryString;
