import {
  Activity,
  MentionableUsers,
  OrganizationRoleResolver,
  Post,
  RequirementCommentIdComponents,
  Role,
  TaskStatus
} from '@gcv/shared';
import { TasksApi } from 'api/TasksApi';
import { BanksApi, DispensariesApi, CommentsApi } from 'api';
import { inject, injectable } from 'inversify';
import { DateTime } from 'luxon';
import { action, makeAutoObservable } from 'mobx';
import { SuggestionDataItem } from 'react-mentions';
import { CommentStore } from 'stores/CommentStore';
import { UserStore } from 'stores/UserStore';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';

export interface PM {
  activities: Activity[];
  isLoading: boolean;
  post: Post | null;
  usersDictionary: Record<string, any>;
  usersList: SuggestionDataItem[];
}

@injectable()
export class CommentsRepo {
  @inject(CommentsApi)
  private commentsApi: CommentsApi;

  @inject(BanksApi)
  private banksApi: BanksApi;

  @inject(DispensariesApi)
  private dispensariesApi: DispensariesApi;

  @inject(TasksApi)
  private tasksApi: TasksApi;

  @inject(CommentStore)
  private commentStore: CommentStore;

  @inject(UserStore)
  private userStore: UserStore;

  @inject(CrbDispensaryStore)
  private crbDispensaryStore: CrbDispensaryStore;

  @inject(OrganizationRoleResolver)
  private roleResolver: OrganizationRoleResolver;

  public programmersModel: PM = {
    activities: [],
    isLoading: true,
    post: null,
    usersDictionary: {},
    usersList: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  private updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  public getActivities = async (bankId: string) => {
    this.updateProgrammersModel({ isLoading: true });

    const idComponents = this.commentStore.idComponents as RequirementCommentIdComponents;

    if (idComponents.crbId && idComponents.requirementId && bankId) {
      const activitiesRes = await this.commentsApi.getActivities(
        idComponents.crbId,
        idComponents.requirementId,
        bankId
      );

      const sortedActivities = activitiesRes.sort((a, b) => (a.created_date > b.created_date ? 1 : -1));

      this.updateProgrammersModel({
        activities: sortedActivities,
        isLoading: false
      });
    }
  };

  public getMentionableUsers = async (orgId: string, externalOrgId?: string, isExternalComment?: boolean) => {
    // bust cached users when switching back and forth between internal and external
    this.updateProgrammersModel({
      usersDictionary: {},
      usersList: []
    });

    let mentionableUsers: MentionableUsers;
    let orgUsers: {
      orgId: string;
      id: string;
      fullname: string;
      email: string;
    }[] = [];

    if (isExternalComment && externalOrgId) {
      const internalMentionableUsers = await this.commentsApi.getMentionableUsers(
        orgId,
        this.commentStore.type
      );
      const externalMentionableUsers = await this.commentsApi.getMentionableUsers(
        externalOrgId,
        this.commentStore.type
      );

      mentionableUsers = {
        orgId: internalMentionableUsers.orgId,
        orgName: internalMentionableUsers.orgName,
        users: internalMentionableUsers.users.concat(externalMentionableUsers.users)
      };

      orgUsers = orgUsers.concat(
        internalMentionableUsers.users.map((u) => ({ ...u, orgId: internalMentionableUsers.orgId })),
        externalMentionableUsers.users.map((u) => ({ ...u, orgId: externalMentionableUsers.orgId }))
      );
    } else {
      mentionableUsers = await this.commentsApi.getMentionableUsers(orgId, this.commentStore.type);
      orgUsers = mentionableUsers.users.map((u) => ({ ...u, orgId: mentionableUsers.orgId }));
    }

    const usersMap = orgUsers.reduce((acc, u) => {
      acc[u.id] = u;
      return acc;
    }, {} as Record<string, any>);
    const usersList = orgUsers
      .sort((a, b) => (a.fullname > b.fullname ? 1 : -1))
      .map((u) => ({ id: u.id, display: '@' + u.fullname }));

    this.updateProgrammersModel({
      usersDictionary: usersMap,
      usersList
    });
  };

  public getPost = async (isCrbApp: boolean, bankId?: string) => {
    if (!this.commentStore.currentPostId) {
      return;
    }

    this.updateProgrammersModel({ isLoading: true });

    let post: Post;
    if (!isCrbApp && bankId) {
      post = await this.banksApi.getPostForBank(bankId, this.commentStore.currentPostId);
    } else {
      post = await this.dispensariesApi.getPostForDispensary(
        this.crbDispensaryStore.currentDispensary.id,
        this.commentStore.currentPostId
      );
    }

    this.updateProgrammersModel({
      post,
      isLoading: false
    });
  };

  public getTasks = async (orgId: string, bankId: string, status: TaskStatus) => {
    return await this.tasksApi.getTasks(bankId, orgId, status);
  };

  public markTaskAsComplete = async (bankId: string, taskId: string, completedNote: string) => {
    return await this.tasksApi.updateFiTask(
      bankId,
      taskId,
      TaskStatus.COMPLETE,
      completedNote,
      this.userStore.user.id,
      DateTime.now().toISO()
    );
  };

  public areCommentsEnabledForUser = async (orgId: string): Promise<boolean> => {
    const disabledRoles: Role[] = ['bank_examiner'];
    try {
      const user = this.userStore.user;
      for (const company of user.companies) {
        if (company.id === orgId) {
          const userOrgRoles =
            company.companyType === 'bank'
              ? this.roleResolver.getRolesOfUser((await this.banksApi.getBankById(orgId)).groups, user.id)
              : this.roleResolver.getRolesOfUser(
                  (await this.dispensariesApi.getDispensary(orgId)).groups,
                  user.id
                );
          return !disabledRoles.some((role) => {
            return userOrgRoles.includes(role);
          });
        }
      }
      return true;
    } catch {
      return true;
    }
  };
}
