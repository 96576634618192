export const posOptions = [
  { label: 'Adilas', value: 'Adilas' },
  { label: 'Alleaves', value: 'Alleaves' },
  { label: 'BioTrack', value: 'BioTrack' },
  { label: 'BioTrack Wholesale', value: 'BioTrack Wholesale' },
  { label: 'Blaze', value: 'Blaze' },
  { label: 'Brytemap', value: 'Brytemap' },
  { label: 'Canix', value: 'Canix' },
  { label: 'Cova', value: 'Cova' },
  { label: 'Cultivera', value: 'Cultivera' },
  { label: 'Distru', value: 'Distru' },
  { label: 'Dutchie', value: 'Dutchie' },
  { label: 'Dutchie Wholesale', value: 'Dutchie Wholesale' },
  { label: 'Flourish', value: 'Flourish' },
  { label: 'Flowhub', value: 'Flowhub' },
  { label: 'Greenbits', value: 'Greenbits' },
  { label: 'GrowFlow', value: 'GrowFlow' },
  { label: 'GrowFlow Wholesale', value: 'GrowFlow Wholesale' },
  { label: 'IndicaOnline', value: 'IndicaOnline' },
  { label: 'LeafLink', value: 'LeafLink' },
  { label: 'Meadow', value: 'Meadow' },
  { label: 'Metrc', value: 'Metrc' },
  { label: 'MJ Freeway', value: 'MJ Freeway' },
  { label: 'MJ Freeway Wholesale', value: 'MJ Freeway Wholesale' },
  { label: 'POSaBIT', value: 'POSaBIT' },
  { label: 'Proteus420', value: 'Proteus420' },
  { label: 'Quickbooks', value: 'Quickbooks' },
  { label: 'Quickbooks Desktop', value: 'Quickbooks Desktop' },
  { label: 'Sage Intacct', value: 'Sage Intacct' },
  { label: 'Sweed', value: 'Sweed' },
  { label: 'Treez', value: 'Treez' },
  { label: 'Weave', value: 'Weave' },
  { label: 'Xero', value: 'Xero' },
  { label: 'Unknown', value: 'Unknown' },
  { label: 'Other', value: 'Other' },
  { label: 'None', value: 'None' }
];
