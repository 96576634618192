import { useMediaQuery } from '@mui/material';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Container, ContainerItem, Form, InputSelect } from 'ui';
import { theme } from 'ui/theme';
import { DocumentationStatusFilters, OnboardingPresenter } from '../../../onboarding.presenter';

export const DocumentationFilters: React.FC<{ isMobile: boolean }> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const form = useForm({
    defaultValues: {
      category: presenter.viewModel.categoryFilter,
      status: presenter.viewModel.statusFilter
    } as FieldValues
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    form.setValue('category', presenter.viewModel.categoryFilter);
  }, [presenter.viewModel.categoryFilter]);

  React.useEffect(() => {
    form.setValue('status', presenter.viewModel.statusFilter);
  }, [presenter.viewModel.statusFilter]);

  const statusOptions: { label: string; value: DocumentationStatusFilters }[] = [
    { label: 'All Statuses', value: 'all' },
    { label: 'Fulfilled', value: 'fulfilled' },
    { label: 'Unfulfilled', value: 'unfulfilled' }
  ];

  return (
    <Form>
      <Container
        padding="0"
        dataCy="filter-container"
        justify={isMobile ? 'center' : 'flex-end'}
        width="100%"
      >
        <ContainerItem padding="0 0 0 8px" width={props.isMobile ? '50%' : '250px'}>
          <InputSelect
            {...form}
            background="white"
            label=""
            name="status"
            additionalOnChange={(e) =>
              presenter.setStatusFilter(e.target.value as DocumentationStatusFilters)
            }
            options={statusOptions}
            noLabel
            dataCy="status-filter"
          />
        </ContainerItem>
      </Container>
    </Form>
  );
});
