import { Badge as MuiBadge } from '@mui/material';
import React from 'react';

type colortype = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export interface BadgeProps {
  badgeContent: number;
  color: string | undefined;
  dataCy?: string;
  overlap?: 'rectangular' | 'circular' | undefined;
  invisible?: boolean;
}

export const Badge: React.FC<BadgeProps> = (props) => {
  return (
    <MuiBadge
      badgeContent={props.badgeContent}
      color={props.color as colortype}
      data-cy={props.dataCy}
      overlap={props.overlap}
      invisible={props.invisible}
      sx={{ minWidth: '1px', minHeight: '1px' }}
    >
      {props.children}
    </MuiBadge>
  );
};
