import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import React from 'react';
import { Avatar, Tooltip } from 'ui';

interface AvatarGroupProps {
  avatarList: { label: string; tooltip: string }[];
  size: 'small' | 'medium' | 'large';
  length: number;
  sx?: SxProps<Theme>;
  dataCy: string;
}

export const AvatarGroup: React.FC<AvatarGroupProps> = (props) => {
  const displayAvatars = props.avatarList.slice(0, props.length);
  const isOverLength = props.avatarList.length > props.length;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
          margin: props.size === 'large' ? '0 0 0 -17px' : '0 0 0 -6px'
        },
        ...props.sx
      }}
      data-cy={props.dataCy}
    >
      {displayAvatars.map((a, i) => {
        if (a) {
          return (
            <Tooltip key={a.tooltip + i} placement="top" title={a.tooltip}>
              <Avatar content={a.label} size={props.size}></Avatar>
            </Tooltip>
          );
        }
      })}
      {isOverLength ? (
        <Tooltip
          key={'additional-avatars'}
          placement="top"
          title={props.avatarList.slice(props.length).map((a) => {
            return (
              <>
                {a.tooltip}
                <br />
              </>
            );
          })}
        >
          <Avatar content={`+${props.avatarList.length - props.length}`} size={props.size}></Avatar>
        </Tooltip>
      ) : null}
    </Box>
  );
};
