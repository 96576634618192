import { IngestionReport } from '@gcv/shared';
import { GcvAdminApi } from 'api';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { getSnackbarStore } from 'stores/SnackBarStore';
import {
  Accordion,
  Button,
  Container,
  ContainerItem,
  Drawer,
  InputCurrency,
  InputNumber,
  InputText
} from 'ui';

interface IngestionReportDrawerProps {
  ingestion: IngestionReport;
  dispensaryName: string;
  drawerOpen: boolean;
  toggleDrawer: (open: boolean) => void;
}

export const IngestionReportDrawer: React.FC<IngestionReportDrawerProps> = (
  props: IngestionReportDrawerProps
) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const gcvAdminApi = new GcvAdminApi();
  const defaultValues = {
    ...props.ingestion,
    dispensary_name: props.dispensaryName
  } as FieldValues;

  const form = useForm({
    defaultValues: {
      mode: 'onBlur',
      defaultValues: defaultValues
    }
  } as FieldValues);

  React.useEffect(() => {
    form.reset(defaultValues);
  }, [props.ingestion.execution_id]);

  const rerunIngestion = () => {
    setIsLoading(true);
    gcvAdminApi
      .rerunIngestions([props.ingestion.execution_id])
      .catch(() => {
        getSnackbarStore().showErrorSnackbarMessage('There was an issue rerunning ingestion.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      title={props.dispensaryName}
      subtitle={props.ingestion?.date}
      open={props.drawerOpen}
      onClose={() => props.toggleDrawer(false)}
      dataCy="sale-drawer"
    >
      <Container>
        <ContainerItem>
          <InputText viewOnly label="Execution ID" name="execution_id" {...form} />
        </ContainerItem>
        <ContainerItem>
          <InputText viewOnly label="Dispensary ID" name="dispensary_id" {...form} />
        </ContainerItem>

        <ContainerItem grow>
          <Accordion label="Ingestion Information">
            <ContainerItem>
              <InputNumber viewOnly label="Total Rows" name="totalRows" {...form} />
            </ContainerItem>
            <ContainerItem>
              <InputNumber
                viewOnly
                label="Total UnproccessableRows"
                name="totalUnproccessableRows"
                {...form}
              />
            </ContainerItem>
            <ContainerItem>
              <InputNumber
                viewOnly
                label="Total Rows Missing Transaction ID"
                name="rawSalesMissingTransactionId"
                {...form}
              />
            </ContainerItem>
            <ContainerItem>
              <InputNumber viewOnly label="Total Sales Count" name="totalSalesCount" {...form} />
            </ContainerItem>
            <ContainerItem>
              <InputNumber
                viewOnly
                label="Total Sales After Merging Transaction ID"
                name="rawSalesWithTransactionIdCombined"
                {...form}
              />
            </ContainerItem>
            <ContainerItem>
              <InputNumber viewOnly label="Error Count" name="error_count" {...form} />
            </ContainerItem>
            <ContainerItem>
              <InputText viewOnly label="Earliest Sale Date" name="earliestSaleDate" {...form} />
            </ContainerItem>
            <ContainerItem>
              <InputCurrency
                viewOnly
                label="Total Ingestion Dollars"
                name="totalIngestionDollars"
                {...form}
              />
            </ContainerItem>
          </Accordion>
        </ContainerItem>
        <ContainerItem grow>
          <Accordion label="Compliance Information">
            <ContainerItem>
              <InputNumber viewOnly label="Compliant Sales Count" name="compliantSalesCount" {...form} />
            </ContainerItem>
            <ContainerItem>
              <InputNumber
                viewOnly
                label="Noncompliant Sales Count"
                name="nonCompliantSalesCount"
                {...form}
              />
            </ContainerItem>
            <ContainerItem>
              <InputNumber viewOnly label="Unchecked Sales Count" name="uncheckedSalesCount" {...form} />{' '}
            </ContainerItem>
          </Accordion>
        </ContainerItem>
        <ContainerItem grow>
          <Accordion label="Payment Type Information">
            <ContainerItem>
              <InputCurrency viewOnly label="Cash Sales Count" name="cashSalesCount" {...form} />
            </ContainerItem>
            <ContainerItem>
              <InputCurrency viewOnly label="Debit Sales Count" name="debitSalesCount" {...form} />
            </ContainerItem>
            <ContainerItem>
              <InputCurrency viewOnly label="Credit Sales Count" name="creditSalesCount" {...form} />
            </ContainerItem>
            <ContainerItem>
              <InputCurrency viewOnly label="ACH Sales Count" name="achSalesCount" {...form} />
            </ContainerItem>
            <ContainerItem>
              <InputCurrency viewOnly label="Wire Sales Count" name="wireSalesCount" {...form} />
            </ContainerItem>
          </Accordion>
        </ContainerItem>
      </Container>

      <Container>
        <ContainerItem>
          <Button
            label="Rerun Ingestion"
            color="primary"
            isLoading={isLoading}
            onClick={rerunIngestion}
          ></Button>
        </ContainerItem>
      </Container>
    </Drawer>
  );
};
