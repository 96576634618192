import * as React from 'react';
import {
  Draggable,
  DraggableProvidedDragHandleProps,
  DraggingStyle,
  NotDraggingStyle
} from 'react-beautiful-dnd';

interface Props {
  id: string;
  index: number;
  children: (dragHandle: DraggableProvidedDragHandleProps | undefined) => React.ReactNode;
  dataCy?: string;
}

export const Drag: React.FC<Props> = (props) => {
  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ) => ({
    borderLeft: isDragging ? '2px solid #03a65b' : 'none',
    borderRadius: '6px',
    margin: '8px 0',
    ...draggableStyle
  });

  return (
    <Draggable key={props.id} draggableId={props.id} index={props.index}>
      {(draggableProvided, draggableSnapshot) => (
        <div
          {...draggableProvided.draggableProps}
          ref={draggableProvided.innerRef}
          style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
        >
          {props.children(draggableProvided.dragHandleProps)}
        </div>
      )}
    </Draggable>
  );
};
