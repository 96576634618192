import { TableSortLabel as MaterialTableSortLabel } from '@mui/material';
import { MouseEventHandler } from 'react';
import { Column } from 'ui/organisms';
import { Text } from '..';

interface Props<T> {
  orderBy: keyof T;
  order: 'asc' | 'desc';
  column?: Column<T>;
  handleSort: (
    event: React.MouseEvent<unknown>,
    property: keyof T | string
  ) => void | MouseEventHandler<HTMLSpanElement>;
}

export const TableSortLabel = <T,>(props: Props<T>) => {
  return (
    <MaterialTableSortLabel
      active={props.orderBy === 'selected'}
      direction={props.orderBy === 'selected' ? props.order : 'asc'}
      onClick={(e) => {
        if (props.column) {
          props.handleSort(e, props.column?.id);
        } else {
          props.handleSort(e, 'selected');
        }
      }}
    >
      {props.column && (
        <Text content={props.column.label?.toString()} dataCy={'columnLabel'} type={'body1'}></Text>
      )}
    </MaterialTableSortLabel>
  );
};
