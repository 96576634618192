import * as React from 'react';
import { FormControlLabel, Radio as MuiRadioButton } from '@mui/material';

interface Props {
  checked?: boolean;
  dataCy?: string;
  disabled?: boolean;
  handleSelect?: (value: string) => void;
  label?: string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  name?: string;
  readOnly?: boolean;
  value: string | boolean;
}

export const RadioButton: React.FC<Props> = (props) => {
  return (
    <FormControlLabel
      data-cy={`radio-button-label-${props.label}`}
      disabled={props.readOnly}
      control={
        <MuiRadioButton
          disableRipple
          checked={props.checked}
          color="primary"
          disabled={props.readOnly || props.disabled}
          data-cy={props.dataCy}
          name={props.name}
          onChange={() => {
            if (props.handleSelect) {
              props.handleSelect(props.value.toString());
            }
          }}
          size="small"
          sx={{
            padding: '1px',
            color: (theme) => theme.palette.divider,
            border: (theme) => `1px solid ${theme.palette.common.white}`,
            marginRight: !props.label ? 0 : props.labelPlacement === 'end' ? '.25rem' : '',
            marginLeft: !props.label ? 0 : props.labelPlacement === 'start' ? '.25rem' : '',
            marginTop: !props.label ? 0 : props.labelPlacement === 'top' ? '.25rem' : '',
            marginBottom: !props.label ? 0 : props.labelPlacement === 'bottom' ? '.25rem' : '',
            '&.Mui-focusVisible': {
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
              boxSizing: 'border-box',
              backgroundColor: 'transparent',
              borderRadius: '4px'
            }
          }}
          value={props.value}
        />
      }
      label={props.label}
      labelPlacement={props.labelPlacement}
      sx={{
        alignItems: 'flex-start',
        fontFamily: 'lato',
        fontSize: '16px',
        fontWeight: 400,
        ml: '-4px',
        mr: !props.label ? 0 : '16px'
      }}
    />
  );
};
