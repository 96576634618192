import React from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import WidgetCharts from 'fusioncharts/fusioncharts.widgets';
import ReactFusioncharts from 'react-fusioncharts';

import {
  AreaConfig,
  BubbleConfig,
  BulletConfig,
  chartOptions,
  HeatmapConfig,
  HorizontalBarConfig,
  PieConfig,
  RadarConfig,
  VerticalBarConfig,
  MultiLineConfig,
  VerticalErrorBarConfig,
  StackedColumnConfig
} from './chart-options';

type CommonProps = {
  height?: number;
  customChartOptions?: Record<string, any>;
};

type ChartProps =
  | {
      type: 'msline';
      config: MultiLineConfig;
    }
  | {
      type: 'area';
      config: AreaConfig;
    }
  | {
      type: 'bubble';
      config: BubbleConfig;
    }
  | {
      type: 'bullet';
      config: BulletConfig;
    }
  | {
      type: 'heatmap';
      config: HeatmapConfig;
    }
  | {
      type: 'horizontalBar';
      config: HorizontalBarConfig;
    }
  | {
      type: 'pie';
      config: PieConfig;
    }
  | {
      type: 'radar';
      config: RadarConfig;
    }
  | {
      type: 'verticalBar';
      config: VerticalBarConfig;
    }
  | {
      type: 'verticalErrorBar';
      config: VerticalErrorBarConfig;
    }
  | {
      type: 'stackedColumn';
      config: StackedColumnConfig;
    };

type Props = CommonProps & ChartProps;

export const Chart: React.FC<Props> = (props) => {
  Charts(FusionCharts);
  PowerCharts(FusionCharts);
  WidgetCharts(FusionCharts);

  (FusionCharts.options as unknown as any)['license']({
    key: process.env.REACT_APP_FUSION_CHARTS_KEY,
    creditLabel: false
  });

  let chartType = '';
  let dataSource = {};

  switch (props.type) {
    case 'area':
      chartType = 'stackedcolumn2dline';

      dataSource = {
        chart: { ...chartOptions.area, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'bubble':
      chartType = 'bubble';

      dataSource = {
        chart: { ...chartOptions.bubble, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'bullet':
      chartType = 'hbullet';

      dataSource = {
        chart: { ...chartOptions.bullet, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'heatmap':
      chartType = 'heatmap';
      dataSource = {
        chart: { ...chartOptions.heatmap, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'horizontalBar':
      chartType = 'stackedbar2d';

      const dataSet = Object.values(props.config.dataset);
      const dataValue1 = dataSet[0].data[0].value ? dataSet[0].data[0].value : 0;
      const dataValue2 = dataSet[1].data[0].value ? dataSet[1].data[0].value : 0;
      const dataValue3 = dataSet[2].data[0].value ? dataSet[2].data[0].value : 0;

      (chartOptions.horizontalBar as unknown as any)['yAxisMaxValue'] = dataValue1 + dataValue2 + dataValue3;

      dataSource = {
        chart: { ...chartOptions.horizontalBar, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'pie':
      chartType = 'doughnut2d';

      dataSource = {
        chart: { ...chartOptions.pie, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'radar':
      chartType = 'radar';

      dataSource = {
        chart: { ...chartOptions.radar, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'verticalBar':
      chartType = 'column2d';
      dataSource = {
        chart: { ...chartOptions.verticalBar, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'msline':
      chartType = 'msline';
      dataSource = {
        chart: { ...chartOptions.msline, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'verticalErrorBar':
      chartType = 'errorbar2d';
      dataSource = {
        chart: { ...chartOptions.verticalErrorBar, ...props.customChartOptions },
        ...props.config
      };
      break;
    case 'stackedColumn':
      chartType = 'scrollstackedcolumn2d';
      dataSource = {
        chart: { ...chartOptions.stackedColumn, ...props.customChartOptions },
        ...props.config
      };
      break;
  }

  return (
    <ReactFusioncharts
      type={chartType}
      width="100%"
      height={props.height ? props.height : 300}
      dataFormat="JSON"
      dataSource={dataSource}
    />
  );
};
