import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      color: palette.icon,
      minWidth: 32,
      marginRight: '1rem',
      marginLeft: '0.25rem'
    }
  }
};
