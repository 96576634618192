import { ToggleButton as Toggle, ToggleButtonGroup, styled } from '@mui/material';
import * as React from 'react';
import { Tooltip } from 'ui/atoms';
import palette from 'ui/theme/palette';

interface Props {
  buttons: { label: string | JSX.Element; value: string; tooltip?: string }[];
  defaultValue?: string;
  onChange: (selection: string | null) => void;
  readOnly?: boolean;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    background: palette.background.secondary,
    border: 0,
    borderRaduis: 0,
    color: palette.text.secondary,
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '15px',
    textTransform: 'none',

    '&.MuiToggleButton-root.Mui-disabled': {
      background: palette.background.secondary,
      border: 0,
      color: palette.text.disabled,
      pointerEvents: 'auto',
      cursor: 'not-allowed'
    },
    '&:not(:first-of-type)': {
      borderRadius: '0 6px 6px 0'
    },
    '&:first-of-type': {
      borderRadius: '6px 0 0 6px'
    },
    '&.Mui-selected': {
      background: palette.primary.main,
      color: palette.background.paper,
      '&:hover': {
        boxShadow: 'none',
        background: palette.primary.dark
      }
    },
    '&.Mui-selected.MuiToggleButtonGroup-grouped.Mui-disabled': {
      background: palette.background.disabled,
      border: 0,
      color: palette.text.disabled,
      cursor: 'not-allowed'
    },
    '&.MuiButtonBase-root': {
      width: '60px',
      height: '22px',
      border: 0
    }
  }
}));

export const ToggleButtons: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState<string | null>();

  React.useEffect(() => {
    if (props.defaultValue) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  const onChange = (_: React.MouseEvent<HTMLElement>, selection: string | null) => {
    if (selection !== null) {
      setValue(selection);

      props.onChange(selection);
    }
  };

  return (
    <StyledToggleButtonGroup
      exclusive
      color="primary"
      defaultValue={props.defaultValue}
      disabled={props.readOnly}
      onChange={onChange}
      value={value}
    >
      {props.buttons.map((b) => {
        if (b.tooltip) {
          return (
            <Toggle disableRipple value={b.value}>
              <Tooltip placement="top" title={b.tooltip}>
                {b.label}
              </Tooltip>
            </Toggle>
          );
        } else {
          return (
            <Toggle disableRipple value={b.value}>
              {b.label}
            </Toggle>
          );
        }
      })}
    </StyledToggleButtonGroup>
  );
};
