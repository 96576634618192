import { Typography } from '@mui/material';
import * as React from 'react';

interface Props {
  color?: 'primary' | 'secondary' | 'default' | 'error';
  content: string;
  dataCy?: string;
  inline?: boolean;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  textOverflow?: 'ellipsis' | 'clip' | 'unset';
  lineHeight?: string;
  whiteSpace?: 'normal' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'break-spaces';
  mobileHeader?: boolean;
}

export const Header: React.FC<Props> = (props) => {
  const color = props.color ? props.color : 'default';
  const component = props.inline ? 'span' : 'div';
  const variant = props.type ? props.type : 'h1';
  const textOverflow = props.textOverflow ? props.textOverflow : 'unset';
  const overflow = props.textOverflow ? 'hidden' : 'unset';
  const lineHeight = props.lineHeight ? props.lineHeight : 'normal';

  if (props.mobileHeader) {
    return (
      <Typography
        data-cy={props.dataCy}
        color="default"
        component="div"
        overflow="unset"
        sx={{
          fontSize: '17px',
          fontWeight: '700',
          lineHeight: '24px'
        }}
      >
        {props.content}
      </Typography>
    );
  }

  return (
    <Typography
      color={color}
      component={component}
      data-cy={props.dataCy}
      variant={variant}
      textOverflow={textOverflow}
      overflow={overflow}
      sx={{ minHeight: '1px', lineHeight: lineHeight, whiteSpace: props.whiteSpace }}
    >
      {props.content}
    </Typography>
  );
};
