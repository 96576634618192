import { USStates } from '@gcv/shared';

export const USStateOptions: {
  label: string;
  value: USStates;
}[] = [
  {
    label: 'Alabama',
    value: USStates.Alabama
  },
  {
    label: 'Alaska',
    value: USStates.Alaska
  },
  {
    label: 'American Samoa',
    value: USStates.American_Samoa
  },
  {
    label: 'Arizona',
    value: USStates.Arizona
  },
  {
    label: 'Arkansas',
    value: USStates.Arkansas
  },
  {
    label: 'California',
    value: USStates.California
  },
  {
    label: 'Colorado',
    value: USStates.Colorado
  },
  {
    label: 'Connecticut',
    value: USStates.Connecticut
  },
  {
    label: 'Delaware',
    value: USStates.Delaware
  },
  {
    label: 'District of Columbia',
    value: USStates.District_of_Columbia
  },
  {
    label: 'Federated States of Micronesia',
    value: USStates.Federated_States_of_Micronesia
  },
  {
    label: 'Florida',
    value: USStates.Florida
  },
  {
    label: 'Georgia',
    value: USStates.Georgia
  },
  {
    label: 'Guam',
    value: USStates.Guam
  },
  {
    label: 'Hawaii',
    value: USStates.Hawaii
  },
  {
    label: 'Idaho',
    value: USStates.Idaho
  },
  {
    label: 'Illinois',
    value: USStates.Illinois
  },
  {
    label: 'Indiana',
    value: USStates.Indiana
  },
  {
    label: 'Iowa',
    value: USStates.Iowa
  },
  {
    label: 'Kansas',
    value: USStates.Kansas
  },
  {
    label: 'Kentucky',
    value: USStates.Kentucky
  },
  {
    label: 'Louisiana',
    value: USStates.Louisiana
  },
  {
    label: 'Maine',
    value: USStates.Maine
  },
  {
    label: 'Marshall Islands',
    value: USStates.Marshall_Islands
  },
  {
    label: 'Maryland',
    value: USStates.Maryland
  },
  {
    label: 'Massachusetts',
    value: USStates.Massachusetts
  },
  {
    label: 'Michigan',
    value: USStates.Michigan
  },
  {
    label: 'Minnesota',
    value: USStates.Minnesota
  },
  {
    label: 'Mississippi',
    value: USStates.Mississippi
  },
  {
    label: 'Missouri',
    value: USStates.Missouri
  },
  {
    label: 'Montana',
    value: USStates.Montana
  },
  {
    label: 'Nebraska',
    value: USStates.Nebraska
  },
  {
    label: 'Nevada',
    value: USStates.Nevada
  },
  {
    label: 'New Hampshire',
    value: USStates.New_Hampshire
  },
  {
    label: 'New Jersey',
    value: USStates.New_Jersey
  },
  {
    label: 'New Mexico',
    value: USStates.New_Mexico
  },
  {
    label: 'New York',
    value: USStates.New_York
  },
  {
    label: 'North Carolina',
    value: USStates.North_Carolina
  },
  {
    label: 'North Dakota',
    value: USStates.North_Dakota
  },
  {
    label: 'Northern Mariana Islands',
    value: USStates.Northern_Mariana_Islands
  },
  {
    label: 'Ohio',
    value: USStates.Ohio
  },
  {
    label: 'Oklahoma',
    value: USStates.Oklahoma
  },
  {
    label: 'Oregon',
    value: USStates.Oregon
  },
  {
    label: 'Palau',
    value: USStates.Palau
  },
  {
    label: 'Pennsylvania',
    value: USStates.Pennsylvania
  },
  {
    label: 'Puerto Rico',
    value: USStates.Puerto_Rico
  },
  {
    label: 'Rhode Island',
    value: USStates.Rhode_Island
  },
  {
    label: 'South Carolina',
    value: USStates.South_Carolina
  },
  {
    label: 'South Dakota',
    value: USStates.South_Dakota
  },
  {
    label: 'Tennessee',
    value: USStates.Tennessee
  },
  {
    label: 'Texas',
    value: USStates.Texas
  },
  {
    label: 'U.S. Minor Outlying Islands',
    value: USStates.US_Minor_Outlying_Islands
  },
  {
    label: 'Utah',
    value: USStates.Utah
  },
  {
    label: 'Vermont',
    value: USStates.Vermont
  },
  {
    label: 'Virgin Islands',
    value: USStates.Virgin_Islands
  },
  {
    label: 'Virginia',
    value: USStates.Virginia
  },
  {
    label: 'Washington',
    value: USStates.Washington
  },
  {
    label: 'West Virginia',
    value: USStates.West_Virginia
  },
  {
    label: 'Wisconsin',
    value: USStates.Wisconsin
  },
  {
    label: 'Wyoming',
    value: USStates.Wyoming
  },
  {
    label: 'Armed Forces Americas',
    value: USStates.Armed_Forces_Americas
  },
  {
    label: 'Armed Forces Europe',
    value: USStates.Armed_Forces_Europe
  },
  {
    label: 'Armed Forces Pacific',
    value: USStates.Armed_Forces_Pacific
  }
];
