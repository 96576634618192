import { Typography } from '@mui/material';
import { ReactComponent as QuestionMark } from 'assets/images/ic-modal-questionmark.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { Button, Container, ContainerItem, Dialog } from 'ui';
import { CommentsPresenter } from '../comments.presenter';
import { RootComment } from '@gcv/shared';

interface Props extends Record<string, unknown> {
  archived?: boolean;
  comment: RootComment;
  isOpen: boolean;
  onClose: () => void;
}

export const CommentsModalTaskArchive: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(CommentsPresenter);

  return (
    <Dialog noActions title={''} isOpen={props.isOpen} handleClose={props.onClose} width="460px">
      <Container padding={0} width="100%">
        <ContainerItem justify="center" padding="0 8px 20px 8px" width="100%">
          <QuestionMark />
        </ContainerItem>

        <ContainerItem justify="center" width="100%">
          <Typography variant="h3">Are you sure you'd like to archive this comment?</Typography>
        </ContainerItem>

        <ContainerItem justify="center" padding="0 8px 75px 8px" width="100%">
          <Typography variant="body2">Archiving this comment will delete the associated task.</Typography>
        </ContainerItem>

        <ContainerItem justify="center" width="100%">
          <Button
            fullWidth
            color="primary"
            label="Confirm"
            onClick={() => presenter.archiveOrUnarchiveComment(!!props.archived, props.comment.id)}
          />
        </ContainerItem>

        <ContainerItem justify="center" width="100%">
          <Button fullWidth color="default-text" label="Cancel" onClick={props.onClose} />
        </ContainerItem>
      </Container>
    </Dialog>
  );
});
