import { ReviewTargets } from '@gcv/shared';
import { Box } from '@mui/material';
import { useComponent } from 'hooks';
import * as React from 'react';
import { useMemo } from 'react';
import { FilterSelect, FilterSelectButton, FilterSelectOption, InputLabel } from 'ui';
import { DataTransformer } from 'util/data.util';
import {
  ACCOUNT_NUMBERS_LABEL,
  getReviewTargetsFromSelection
} from './muo-account-number-location-filter.util';

interface Props extends Record<string, unknown> {
  label?: string;
  onChange?: (targets: ReviewTargets) => void;
  accounts: FilterSelectOption[];
}

export const MuoAccountNumberLocationFilter: React.FC<Props> = useComponent((props) => {
  const [filterToggle, setFilterToggle] = React.useState(false);
  const [currentOptions, setCurrentOptions] = React.useState<FilterSelectOption[]>(
    JSON.parse(JSON.stringify(props.accounts))
  );
  const [appliedOptions, setAppliedOptions] = React.useState<FilterSelectOption[]>(
    JSON.parse(JSON.stringify(props.accounts))
  );

  const toggleFilter = () => {
    setFilterToggle(!filterToggle);
  };

  const onApply = (data: FilterSelectOption[]) => {
    setCurrentOptions(data);
    setAppliedOptions(JSON.parse(JSON.stringify(data)));
    const selected = new DataTransformer().selected(data);
    const targets = getReviewTargetsFromSelection(selected, data);
    if (props.onChange) {
      props.onChange(targets);
    }
    setFilterToggle(false);
  };

  const onCancel = () => {
    setCurrentOptions(appliedOptions);
    setTimeout(() => setFilterToggle(false), 1);
  };

  const selectedAccounts = useMemo(() => {
    const accountNumberFilter = appliedOptions.find((o) => o.value === ACCOUNT_NUMBERS_LABEL);

    if (accountNumberFilter) {
      return accountNumberFilter.children ?? [];
    } else {
      return appliedOptions.flatMap((o) => o.children ?? []);
    }
  }, [appliedOptions]);

  return (
    <Box sx={{ position: 'relative' }}>
      {props.label && (
        <Box sx={{ mb: '6px' }}>
          <InputLabel name="account-number-filter-label" label={props.label} />
        </Box>
      )}
      <FilterSelectButton
        filterToggle={filterToggle}
        toggleFilter={toggleFilter}
        selectedFilters={selectedAccounts}
        noMargin
      />
      {filterToggle ? (
        <FilterSelect
          transform={'raw'}
          options={currentOptions}
          onApply={onApply}
          onCancel={onCancel}
          config={{
            title: 'Accounts',
            height: '250px',
            emitAllOnEmpty: true,
            emitExcludeParent: false,
            clearChildren: { isHidden: false },
            count: { isHidden: true },
            search: { isHidden: true },
            selectAllClearAll: { isHidden: false, position: 'top' },
            showSelectedOnly: { isHidden: true },
            showHidden: { isHidden: true, label: '' },
            type: 'dropdown'
          }}
        />
      ) : null}
    </Box>
  );
});
