import { DueDiligenceRequirementTemplate } from '@gcv/shared';
import { Box } from '@mui/material';
import { DocumentsApi } from 'api';
import { ReactComponent as DownloadIcon } from 'assets/images/download-icon.svg';
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg';
import { environment } from 'environments/environment';
import { Card, Container, ContainerItem, Dialog, Tooltip } from 'ui';
import { getMimeType, openFileInNewWindow } from 'util/files.util';

interface Props {
  attachmentsModalOpen: boolean;
  setAttachmentsModalOpen: (open: boolean) => void;
  templateRequirement: DueDiligenceRequirementTemplate;
  bankId: string;
}

export const AttachmentsModal: React.FC<Props> = (props) => {
  const documentsApi = new DocumentsApi();

  const viewFile = (fileId: string) => {
    documentsApi.getDocument(props.bankId, fileId).then((file) => {
      documentsApi
        .getDocumentLink(environment.storageConfig.sharedDocument, file.s3_key, 'get')
        .then((s3Url) =>
          fetch(s3Url.s3LinkPath).then(async (response) => {
            const blob = await response.blob();
            openFileInNewWindow(blob, getMimeType(file.file_name), file.file_name);
          })
        );
    });
  };

  return (
    <Dialog
      title="Attachments"
      handleClose={() => props.setAttachmentsModalOpen(false)}
      isOpen={props.attachmentsModalOpen}
    >
      <Card noPadding>
        <div
          style={{
            maxHeight: '500px',
            overflow: 'auto'
          }}
        >
          <Container column width="100%">
            <Box sx={{ width: '100%', maxHeight: '350px', overflow: 'auto' }}>
              <ContainerItem width="100%">
                {props.templateRequirement.attachments &&
                  props.templateRequirement.attachments.map((a) => {
                    return (
                      <Container align="center" dataCy="attachment-container" justify="center" padding={0}>
                        <div
                          style={{
                            borderBottom: '1px solid #DBE1ED',
                            cursor: 'pointer',
                            margin: '0.25rem',
                            width: '250px',
                            display: 'flex'
                          }}
                          onClick={() => viewFile(a.id)}
                        >
                          <ContainerItem>
                            <FileIcon style={{ height: '25px', width: '20px' }} />
                          </ContainerItem>

                          <ContainerItem grow>
                            {a.file_name.length <= 15 && <>{a.file_name}</>}
                            {a.file_name.length > 15 && (
                              <Tooltip title={a.file_name}>{a.file_name.slice(0, 15)}...</Tooltip>
                            )}
                          </ContainerItem>

                          <ContainerItem>
                            <DownloadIcon />
                          </ContainerItem>
                        </div>
                      </Container>
                    );
                  })}
              </ContainerItem>
            </Box>
          </Container>
        </div>
      </Card>
    </Dialog>
  );
};
