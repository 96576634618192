import { Template, TemplateResponse } from '@gcv/shared';
import { TemplateApi } from 'api';

export class AdditionalInfoUtil {
  templateApi = new TemplateApi();

  templateResponse: TemplateResponse;
  dispensaryId: string;
  isBank: boolean;

  constructor(templateResponse: TemplateResponse, dispensaryId: string, isBank: boolean) {
    this.templateResponse = templateResponse;
    this.dispensaryId = dispensaryId;
    this.isBank = isBank;
  }

  // check that we are saving only the fields associated with the section
  // and not the entire fieldset in each section
  public deDupeCustomFields = (customFields: Record<string, any>, sectionId: string) => {
    const sectionFields: Record<string, any> = { responses: {} };
    const templateSection = this.templateResponse.custom_requirements[sectionId];

    templateSection.custom_section.fields.forEach((field) => {
      if (customFields.responses[field.id] != null) {
        sectionFields.responses[field.id] = customFields.responses[field.id];
      }
    });

    return sectionFields;
  };

  public saveAndCompleteOnboardingTemplate = async (customFieldValues: Record<string, any>) => {
    try {
      if (this.templateResponse) {
        const requirementIds = Object.entries(this.templateResponse.custom_requirements)
          .filter(([k, v]) => !v.archived)
          .map(([k, v]) => k);

        const savePromises: Promise<void>[] = [];

        for (let index = 0; index < requirementIds.length; index++) {
          const sectionId = requirementIds[index];

          savePromises.push(
            this.saveSection(this.deDupeCustomFields(customFieldValues, sectionId), sectionId)
          );
        }

        await Promise.all(savePromises);
      }
    } catch (error) {
      console.log('Issue saving section', error);
    }
  };

  public saveSection = async (customFieldValues: Record<string, any>, sectionId: string) => {
    if (!this.templateResponse.custom_requirements[sectionId].archived) {
      if (this.isBank) {
        await this.templateApi.updateBankCrbTemplateRequirement(
          this.dispensaryId,
          this.templateResponse.bank_id,
          this.templateResponse.template_id,
          sectionId,
          customFieldValues
        );
      } else {
        await this.templateApi.updateCrbTemplateRequirement(
          this.dispensaryId,
          this.templateResponse.bank_id,
          this.templateResponse.template_id,
          sectionId,
          customFieldValues
        );
      }
    }
  };
}
