export const capitalizeWords = (words: string) =>
  words
    ?.trim()
    ?.split(' ')
    ?.map((word) => word[0]?.toUpperCase() + word.substr(1))
    ?.join(' ');

export const capitalizeVariables = (words: string) => {
  return words
    ?.trim()
    ?.split('_')
    ?.map((word) => word[0]?.toUpperCase() + word.substr(1))
    ?.join(' ')
    ?.split('-')
    ?.map((word) => word[0]?.toUpperCase() + word.substr(1))
    ?.join(' ');
};

export const isNumber = (value: string | number) => {
  if (!value) {
    return false;
  }

  return !isNaN(+value);
};

/**
 * Returns number if the @value is a number or
 * a stringified version of a number.
 *
 * Usecase
 * - Make the values being passed to row of a
 * table sortable, especially when the string contains only number
 *
 * Returns the @value passed in all the other cases.
 * @param value
 * @returns sortable string or a number
 */
export const makeSortable = (value: string | number) => {
  if (!value) {
    return value;
  }

  // Value maybe number or a string maybe a number
  if (isNumber(value)) {
    return Number(value);
  } else {
    return value;
  }
};

export const str2bool = (value: string) => {
  if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true' || value === '1') {
      return true;
    }
    if (value.toLowerCase() === 'false' || value === '0') {
      return false;
    }
  }

  return value;
};

export const camelToTitleSpaced = (value: string) =>
  value?.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');

export const cleanString = function (input: string) {
  let output = '';
  for (let i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) <= 127) {
      output += input.charAt(i);
    }
  }
  // While o isn't a special character we're cleaning up the rest of the bullets
  // and it looks weird to leave it in the narrative when it's on it's own
  // when it's wrapped with the new line and tab it should only be a bullet point
  output = output.replace(/\no\t/g, '\n\t');
  return output;
};

export const removeUnderscore = (value: string) => value?.replace(/_/g, ' ');
