import { inject, injectable } from 'inversify';
import { MyProvidersRepo, PM, ProgrammerProviderType } from './my-providers.repo';
import { action, makeAutoObservable, observe } from 'mobx';
import { SnackbarStore } from 'stores/SnackBarStore';
import { CrbBankStore } from 'stores/CrbBankStore';
import {
  MarketplaceOfferCategory,
  DueDiligenceStatus,
  GREEN_CHECK_ONBOARDING_BANK_ID,
  MarketplaceServiceProviderOffer,
  MarketplaceProfessionalServicesOfferRequest
} from '@gcv/shared';
import { convertDueDiligenceStatus } from 'util/org.util';
import { capitalizeVariables } from 'util/strings.util';

export type ViewProviderType = {
  [bankId: string]: {
    id: string;
    image_url: string;
    name: string;
    status: string;
    latest_activity: string;
    category: string;
    template_id: string;
    template_result_id: string;
  };
};

export interface VM {
  isLoading: boolean;
  providers: ViewProviderType | null;
}

@injectable()
export class MyProvidersPresenter {
  @inject(MyProvidersRepo) private repo: MyProvidersRepo;
  @inject(SnackbarStore) private snackbarStore: SnackbarStore;
  @inject(CrbBankStore) private crbBankStore: CrbBankStore;

  constructor() {
    makeAutoObservable(this);
  }

  viewModel: VM = {
    isLoading: true,
    providers: {}
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  private loadStore = action(async () => {
    try {
      await this.repo.load();
    } catch (e) {
      const error = e as Error;
      this.snackbarStore.showErrorSnackbarMessage(error.message);
    }
  });

  private processViewModelCategory(
    offer: MarketplaceServiceProviderOffer | MarketplaceProfessionalServicesOfferRequest
  ): string {
    if (offer.offer_category === MarketplaceOfferCategory.ProfessionalServices) {
      return capitalizeVariables(
        (offer as unknown as MarketplaceProfessionalServicesOfferRequest).offer_sub_category
      );
    } else {
      return capitalizeVariables(offer.offer_category);
    }
  }

  private convertProgrammerProviderToViewProvider(
    programmerProvider: ProgrammerProviderType
  ): ViewProviderType {
    const viewProvider: ViewProviderType = {};
    Object.keys(programmerProvider).map((pp) => {
      if (pp === GREEN_CHECK_ONBOARDING_BANK_ID) return;
      viewProvider[pp] = {
        category: programmerProvider[pp].templates.value.onboarding.marketplace_offer_snapshot
          ? this.processViewModelCategory(
              programmerProvider[pp].templates.value.onboarding.marketplace_offer_snapshot!
            )
          : capitalizeVariables(MarketplaceOfferCategory.Banking),
        id: programmerProvider[pp].templates.value.onboarding.marketplace_offer_snapshot
          ? (programmerProvider[pp].templates.value.onboarding.marketplace_offer_snapshot
              ?.provider_id as string)
          : (pp as string),
        image_url: programmerProvider[pp].templates.value.onboarding.marketplace_offer_snapshot
          ? (programmerProvider[pp].templates.value.onboarding.marketplace_offer_snapshot
              ?.image_url as string)
          : (this.crbBankStore.banks[pp].theme?.logo_s3_key as string),
        name: programmerProvider[pp].templates.value.onboarding.marketplace_offer_snapshot
          ? (programmerProvider[pp].templates.value.onboarding.marketplace_offer_snapshot?.title as string)
          : (this.crbBankStore.banks[pp].orgName as string),
        latest_activity: programmerProvider[pp].templates.value.onboarding.last_updated,
        template_id: programmerProvider[pp].templates.value.onboarding.template_id,
        template_result_id: programmerProvider[pp].templates.value.onboarding.template_result_id,
        status: convertDueDiligenceStatus(
          programmerProvider[pp].templates.value.onboarding.status as DueDiligenceStatus
        )
      };
    });
    return viewProvider;
  }

  load = action(async () => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      this.updateViewModel({
        isLoading: programmersModel.loadingProviders,
        providers:
          programmersModel && programmersModel.providers && !programmersModel.loadingProviders
            ? this.convertProgrammerProviderToViewProvider(
                programmersModel.providers as ProgrammerProviderType
              )
            : {}
      });
    });

    await this.loadStore();
  });
}
