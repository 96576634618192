import { ComplianceRuleDefinition, ComplianceRuleType, LicenseManagerData, USStates } from '@gcv/shared';
import { GcvAdminApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { SnackbarStore } from 'stores/SnackBarStore';

export interface StateContextPM {
  isLoading: boolean;
  state: USStates;
  stateContext?: ComplianceRuleDefinition;
  medicalContext?: ComplianceRuleDefinition;
  adultContext?: ComplianceRuleDefinition;
  licenseData?: LicenseManagerData;
}

@injectable()
export class StateContextRepo {
  @inject(GcvAdminApi)
  private gcvAdminApi: GcvAdminApi;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  public stateContextProgrammersModel: StateContextPM = {
    isLoading: true,
    state: USStates.Alabama
  };

  load = action(async (state: USStates) => {
    this.updateProgrammersModel({
      state,
      isLoading: true
    });

    const licensesPromise = this.gcvAdminApi.getStateLicenseInformation(state);
    const rulesPromise = this.gcvAdminApi.getStateComplianceMetadata(state);

    await Promise.all([rulesPromise, licensesPromise])
      .then(([stateRulesResponse, licensesResponse]) => {
        this.updateProgrammersModel({
          stateContext: stateRulesResponse.find((r) => r?.category === 'state-compliance-rules')?.value,
          medicalContext: stateRulesResponse.find((r) => r?.category === 'medical-compliance-rules')?.value,
          adultContext: stateRulesResponse.find((r) => r?.category === 'recreational-compliance-rules')
            ?.value,
          licenseData: licensesResponse?.value
        });
      })
      .catch((e) => {
        console.log(e);
        this.snackbarStore.showErrorSnackbarMessage('Failed to load compliance information');
      })
      .finally(() => {
        this.updateProgrammersModel({ isLoading: false });
      });
  });

  updateProgrammersModel = action((programmersModel: Partial<StateContextPM>) => {
    this.stateContextProgrammersModel = { ...this.stateContextProgrammersModel, ...programmersModel };
  });

  saveComplianceRules = action(
    (complianceType: ComplianceRuleType, rulesDefinition: ComplianceRuleDefinition) => {
      this.updateProgrammersModel({ isLoading: true });

      let context;
      switch (complianceType) {
        case ComplianceRuleType.STATE:
          context = this.stateContextProgrammersModel.stateContext;
          break;
        case ComplianceRuleType.MED:
          context = this.stateContextProgrammersModel.medicalContext;
          break;
        case ComplianceRuleType.REC:
          context = this.stateContextProgrammersModel.adultContext;
          break;
      }
      const isCreate = context == null;

      const endpoint = isCreate
        ? this.gcvAdminApi.createStateComplianceMetadata
        : this.gcvAdminApi.updateStateComplianceMetadata;

      return endpoint(complianceType, this.stateContextProgrammersModel.state, rulesDefinition)
        .then((updatedRules) => {
          switch (complianceType) {
            case ComplianceRuleType.STATE:
              this.updateProgrammersModel({ stateContext: updatedRules.value });
              break;
            case ComplianceRuleType.MED:
              this.updateProgrammersModel({ medicalContext: updatedRules.value });
              break;
            case ComplianceRuleType.REC:
              this.updateProgrammersModel({ adultContext: updatedRules.value });
              break;
          }
          this.snackbarStore.showSuccessSnackbarMessage(
            `Successfully saved ${complianceType} compliance rules for ${this.stateContextProgrammersModel.state}`
          );
        })
        .catch((e) => {
          console.log(e);
          this.snackbarStore.showErrorSnackbarMessage(`There was an error saving compliance rules`);
        })
        .finally(() => {
          this.updateProgrammersModel({ isLoading: false });
        });
    }
  );

  addNewLicense = action((newLicense: string) => {
    this.updateProgrammersModel({ isLoading: true });

    const endpoint =
      this.stateContextProgrammersModel.licenseData == null
        ? this.gcvAdminApi.createStateLicenseInformation
        : this.gcvAdminApi.updateStateLicenseInformation;

    const newLicenseId = newLicense
      .split(' ')
      .map((word) => word.toLowerCase())
      .join('-');

    const updatedLicenseData = (
      this.stateContextProgrammersModel.licenseData?.business_license_types ?? []
    ).concat({ value: newLicenseId, viewValue: newLicense });
    return endpoint(this.stateContextProgrammersModel.state, {
      business_license_types: updatedLicenseData,
      employee_license_required:
        this.stateContextProgrammersModel.licenseData?.employee_license_required ?? false
    })
      .then((udpatedLicenses) => {
        this.updateProgrammersModel({ licenseData: udpatedLicenses.value });
        this.snackbarStore.showSuccessSnackbarMessage(
          `Successfully saved licenses for ${this.stateContextProgrammersModel.state}`
        );
      })
      .catch((e) => {
        console.log(e);
        this.snackbarStore.showErrorSnackbarMessage(`There was an error saving licenses`);
      })
      .finally(() => {
        this.updateProgrammersModel({ isLoading: false });
      });
  });

  archiveLicense = action((licenseToArchive: string) => {
    this.updateProgrammersModel({ isLoading: true });

    const updatedLicenseData = (
      this.stateContextProgrammersModel.licenseData?.business_license_types ?? []
    ).filter((license) => license.value !== licenseToArchive);

    return this.gcvAdminApi
      .updateStateLicenseInformation(this.stateContextProgrammersModel.state, {
        business_license_types: updatedLicenseData,
        employee_license_required:
          this.stateContextProgrammersModel.licenseData?.employee_license_required ?? false
      })
      .then((udpatedLicenses) => {
        this.updateProgrammersModel({ licenseData: udpatedLicenses.value });
        this.snackbarStore.showSuccessSnackbarMessage(
          `Successfully removed license from ${this.stateContextProgrammersModel.state}`
        );
      })
      .catch((e) => {
        console.log(e);
        this.snackbarStore.showErrorSnackbarMessage(`There was an error saving licenses`);
      })
      .finally(() => {
        this.updateProgrammersModel({ isLoading: false });
      });
  });
}
