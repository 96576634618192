import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface SpinnerProps {
  text?: string;
  dataCy?: string;
  dataCyText?: string;
}

export const Spinner: React.FC<SpinnerProps> = (props) => {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: '70px',
        display: 'flex',
        width: '100%'
      }}
    >
      <Box sx={{ margin: '0 auto' }}>
        <Box
          sx={{
            position: 'relative'
          }}
        >
          <CircularProgress
            variant="determinate"
            sx={{
              height: '70px',
              width: '70px',
              color: 'rgba(3, 166, 91, 0.3)',
              position: 'absolute',
              marginLeft: '-20px'
            }}
            value={100}
          />

          <CircularProgress
            color="primary"
            sx={{ height: '70px', width: '70px', position: 'absolute', marginLeft: '-20px' }}
            data-cy={props.dataCy ? props.dataCy : 'loading-spinner'}
          />
        </Box>
      </Box>

      {props.text ? (
        <div
          style={{
            width: 'fit-content',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#828599',
            textAlign: 'center',
            margin: '0 auto',
            marginTop: '55px'
          }}
          data-cy={props.dataCyText}
        >
          {props.text}
        </div>
      ) : null}
    </Box>
  );
};
