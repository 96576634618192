import { inject, injectable } from 'inversify';
import { CrbServiceProvider } from '@gcv/shared';
import { action, makeAutoObservable } from 'mobx';
import { DispensariesApi } from '../../../../api/DispensariesApi';
import { CrbDispensaryStore } from '../../../../stores/CrbDispensaryStore';

export type ProgrammerProviderType = { [bankId: string]: CrbServiceProvider };

export interface PM {
  providers: ProgrammerProviderType | null;
  loadingProviders: boolean;
}

@injectable()
export class MyProvidersRepo {
  @inject(DispensariesApi) private dispensariesApi: DispensariesApi;
  @inject(CrbDispensaryStore) private crbDispensaryStore: CrbDispensaryStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {
    providers: null,
    loadingProviders: true
  };

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = action(async () => {
    this.updateProgrammersModel({ loadingProviders: true });
    const providers = await this.fetchDispensaryServiceProviders();
    this.updateProgrammersModel({
      providers,
      loadingProviders: false
    });
  });

  fetchDispensaryServiceProviders = action(async () => {
    try {
      return await this.dispensariesApi.getDispensaryServiceProviders(
        this.crbDispensaryStore.currentDispensary.id
      );
    } catch (error) {
      console.log(error);
      throw new Error('There was a problem fetching service providers');
    }
  });
}
