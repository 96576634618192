import { useRollbarPerson } from '@rollbar/react';
import { useComponent } from 'hooks';
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { updateLogRocketConfig } from 'third-party-integrations/log-rocket';
import { Spinner } from 'ui';
import { getUserType, userCanDoAction, userHasRole } from 'ui/routing/router-util';
import { getUserStore } from '../../stores/UserStore';
import { RouteProps } from './router';

export const CrbRoute: React.FC<RouteProps> = useComponent(
  ({ component: Component, path, exact, routeType }: RouteProps) => {
    let isDispensaryUser = false;
    let userHasRoles = false;
    let userHasAction = false;

    const dispensaryStore = getCrbDispensaryStore();
    const userStore = getUserStore();

    //check user type
    if (userStore.isLoaded) {
      const type = getUserType(userStore.user);

      if (type === 'dispensary') {
        isDispensaryUser = true;
      }

      useRollbarPerson({
        id: userStore.user?.id,
        name: userStore.user?.firstName + ' ' + userStore?.user?.lastName,
        email: userStore.user?.email
      });
      updateLogRocketConfig(userStore.user);
    }

    //check user role and action
    if (userStore.isLoaded && dispensaryStore.isLoaded && dispensaryStore.currentDispensary.groups) {
      userHasRoles = userHasRole(dispensaryStore.currentDispensary.groups, userStore.user, routeType.roles);
      userHasAction = userCanDoAction(
        dispensaryStore.currentDispensary.groups,
        userStore.user,
        routeType.requiredAction
      );
    }

    return userStore.user.marketing_user_in_progress ||
      (isDispensaryUser && userHasRoles && userHasAction) ? (
      <Route exact={exact} path={path} component={Component} />
    ) : !userStore.isLoaded || !dispensaryStore.isLoaded || !dispensaryStore.currentDispensary.groups ? (
      <Spinner />
    ) : !userHasRoles ? (
      <Redirect to={{ pathname: '/error' }} />
    ) : (
      <Redirect to={{ pathname: '/' }} />
    );
  }
);
