import { CommentType, IANATimezones } from '@gcv/shared';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { CommentStore } from 'stores/CommentStore';
import { CommentsPresenter } from './comments.presenter';
import { CommentsBarAnchor } from './components/comments-bar-anchor';
import { CommentsBarBody } from './components/comments-bar-body';
import { CommentsBarContainer } from './components/comments-bar-container';
import { CommentsBarFooter } from './components/comments-bar-footer';
import { CommentsBarHeader } from './components/comments-bar-header';

interface Props extends Record<string, unknown> {
  iana_timezone: IANATimezones;
  orgId: string;

  bankId?: string; // used to make getActivities call bank specific
  disableExternalComment?: boolean;
  externalOrgId?: string;
  onClose?: () => void;
  viewFile?: (id: string) => void;
}

export const Comments: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(CommentsPresenter);
  const commentStore = useInjection(CommentStore);

  const anchorRef = React.useRef<HTMLDivElement | null>(null);
  const [commentsEnabledForUser, setCommentsEnabledForUser] = React.useState(false);

  React.useEffect(() => {
    presenter.load(props.orgId, props.externalOrgId);
    presenter.areCommentsEnabledForUser(props.orgId).then((val) => {
      setCommentsEnabledForUser(val);
    });
  }, []);

  React.useEffect(() => {
    const load = async () => {
      if (commentStore.commentsOpen) {
        await presenter.getPost(props.bankId);
      }
      if (
        commentStore.type === CommentType.REQUIREMENT ||
        commentStore.type === CommentType.REQUIREMENT_DOCUMENT
      ) {
        await presenter.getActivities(props.bankId);
      }
    };

    load();
  }, [commentStore.commentsOpen, commentStore.currentPostId, commentStore.type]);

  return (
    <>
      {commentsEnabledForUser && (
        <>
          <CommentsBarAnchor anchorRef={anchorRef} />

          <CommentsBarContainer anchorRef={anchorRef} onClose={props.onClose}>
            <CommentsBarHeader />

            {commentStore.commentsOpen && (
              <>
                <CommentsBarBody
                  bankId={props.bankId ?? ''}
                  iana_timezone={props.iana_timezone}
                  orgId={props.orgId}
                  viewFile={props.viewFile}
                />

                <CommentsBarFooter
                  disableExternalComment={props.disableExternalComment}
                  externalOrgId={props.externalOrgId}
                  orgId={props.orgId}
                />
              </>
            )}
          </CommentsBarContainer>
        </>
      )}
    </>
  );
});
