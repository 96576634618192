import { injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

export interface PM {}

@injectable()
export class ReportsRepo {
  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {};

  updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });
}
