import { Activity, Comment, CommentType, MentionableUsers, Post, PostCount } from '@gcv/shared';
import { api } from './api-util/api';
import { injectable } from 'inversify';

@injectable()
export class CommentsApi {
  async getActivities(crb_id: string, req_id: string, bank_id: string): Promise<Activity[]> {
    return await api().get(`/dispensaries/${crb_id}/banks/${bank_id}/requirements/${req_id}/activities`);
  }
  async getComment(commentId: string): Promise<Comment> {
    return await api().get(`/comments/${commentId}`);
  }

  async getPost(postId: string): Promise<Post> {
    return await api().get(`/post/${postId}`);
  }

  async getMentionableUsers(orgId: string, type: CommentType): Promise<MentionableUsers> {
    return await api().get(`/comments/org/${orgId}/type/${type}/mentionable-users`);
  }

  async getCommentCount(postIds: string[]): Promise<PostCount> {
    return await api().post('/posts/count', { postIds });
  }

  async addComment(body: {
    content: string;
    internal: boolean;
    idComponents: any;
    type: CommentType;
    parentId?: string;
    documents?: { s3Key: string; filename: string }[];
    mentionedUsers?: string[];
    muoDispensaryId?: string;
  }): Promise<Post> {
    return await api().post(`/comments`, body);
  }

  async addCommentV2(body: {
    content: string;
    internal: boolean;
    idComponents: any;
    type: CommentType;
    fiId?: string;
    crbId?: string;
    parentId?: string;
    documents?: { s3Key: string; filename: string }[];
    mentionedUsers?: string[];
    muoDispensaryId?: string;
  }): Promise<Post> {
    return await api().post(`/commentsV2`, body);
  }

  async archiveComment(commentId: string): Promise<Post> {
    return await api().put(`/comments/${commentId}/archive`, {});
  }

  async unarchiveComment(commentId: string): Promise<Post> {
    return await api().put(`/comments/${commentId}/unarchive`, {});
  }
}
