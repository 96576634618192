import { useEffect } from 'react';
import { InputRadio } from 'ui';

export enum UserReportAccountType {
  Users = 'users',
  Contacts = 'contacts'
}

/** Field name used by form. */
const userReportRadioButtonsField = 'user_report_radio_buttons';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UserReportRadioButtons = (props: any) => {
  const options = [
    { label: 'Users', value: UserReportAccountType.Users },
    { label: 'Contacts', value: UserReportAccountType.Contacts }
  ];

  useEffect(() => {
    // Match the default selected.
    props.form.setValue(userReportRadioButtonsField, UserReportAccountType.Users);
    // A race condition found in production code is most easily (and safely) solved this way.
    setTimeout(() => props.callback(UserReportAccountType.Users), 1);
  }, []);

  const handleChange = (value: UserReportAccountType) => {
    props.callback(value);
  };

  return (
    <InputRadio
      onChange={handleChange}
      justifyContent="row"
      label={''}
      name={userReportRadioButtonsField}
      options={options}
      {...props.form}
    />
  );
};

export default UserReportRadioButtons;
