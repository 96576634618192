export const formatCurrencyString: (unformattedString: string) => string = (unformattedString: string) => {
  return `$${(Number(unformattedString) / 100).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
};

export const formatCurrencyNumberFromString: (formattedString: string) => number = (
  formattedString: string
) => {
  // remove $ from string and convert to number
  return Number(formattedString.replace('$', '').replace(',', ''));
};

export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value / multiplier) * multiplier;
};
