import { ClickAwayListener } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { InputSearch, FilterHeader, FilterFooter, FilterListChild, FilterListBasic } from 'ui';

interface Props {
  filterHeader: string;
  filterList: FilterListChild[];
  includeSearch?: boolean;
  onApplyFilter: (selected: FilterListChild[], filterList: FilterListChild[]) => void;
  onCancel: () => void;
  listMaxHeight?: string;
  unset?: boolean;
}

export const FilterDropdownBasic: React.FC<Props> = (props) => {
  const getSelectedItemsFromProps = () => {
    const selectedList: FilterListChild[] = [];
    props.filterList.forEach((filterListChild: FilterListChild) => {
      if (filterListChild.selected) {
        selectedList.push(filterListChild);
      }
    });
    return selectedList;
  };

  const [selectedList, setSelectedList] = React.useState<FilterListChild[]>(getSelectedItemsFromProps());
  const [localFilterList, setLocalFilterList] = React.useState(props.filterList);
  const [selectAllState, setSelectAllState] = React.useState(0);

  const searchTermChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
    clear?: boolean
  ) => {
    let searchTerm = '';
    if (!clear && e !== null) {
      searchTerm = e.target.value;
    }
    if (searchTerm.length) {
      const tempList = props.filterList.map((listItem) => {
        if (listItem.label.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) {
          listItem.visible = true;
        } else {
          listItem.visible = false;
        }
        return listItem;
      });
      setLocalFilterList(tempList);
    } else {
      const tempList = props.filterList.map((listItem) => {
        listItem.visible = true;
        return listItem;
      });
      setLocalFilterList(tempList);
    }
  };

  const selectAll = () => {
    const selectedList: FilterListChild[] = [];
    props.filterList.forEach((filterListChild: FilterListChild) => {
      if (filterListChild.visible !== false) {
        filterListChild.selected = true;
        selectedList.push(filterListChild);
      }
    });
    setSelectedList(selectedList);
    setLocalFilterList(props.filterList);
    setSelectAllState(selectAllState + 1);
  };

  const clearFilter = () => {
    props.filterList.forEach((filterListChild: FilterListChild) => {
      filterListChild.selected = false;
    });
    setSelectedList([]);
  };

  const listItemSelected = (listItem: FilterListChild) => {
    if (
      selectedList.find((item) => {
        return item.value === listItem.value;
      })
    ) {
      selectItem([listItem]);
    } else {
      const newSelectedList = [...selectedList, listItem];
      setSelectedList(newSelectedList);
    }
  };

  const selectItem = (subListItems: FilterListChild[]) => {
    const newSelectedList = selectedList.filter((selectedListItem) => {
      return !subListItems.find((subListItem) => subListItem.value === selectedListItem.value);
    });
    setSelectedList(newSelectedList);
  };

  const onCancel = () => {
    searchTermChanged(null, true);
    props.onCancel();
  };

  const onSave = () => {
    props.onApplyFilter(selectedList, props.filterList);
    searchTermChanged(null, true);
  };

  return (
    <ClickAwayListener onClickAway={onCancel}>
      <Box
        className="filterDropdownBasic-topBox"
        sx={{
          position: 'absolute',
          width: '350px',
          backgroundColor: '#fff',
          right: props.unset ? 'unset' : '0',
          zIndex: '20',
          marginTop: '8px',
          boxShadow:
            '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiFormGroup-root': {
            padding: '0 0 0 14px'
          },
          '& .MuiList-padding': {
            padding: '8px'
          }
        }}
      >
        <FilterHeader
          label={props.filterHeader}
          selectedItems={selectedList}
          onClearFilter={clearFilter}
          onSelectAll={selectAll}
        />
        <Box
          sx={{
            padding: '12px 20px',
            maxHeight: props.listMaxHeight,
            overflowY: 'auto',
            borderBottom: '1px solid',
            borderColor: 'divider'
          }}
        >
          <InputSearch label={'Search'} onChange={searchTermChanged} />
        </Box>

        <Box>
          <FilterListBasic
            filterList={localFilterList}
            onChange={listItemSelected}
            selectedList={selectedList}
            selectAllState={selectAllState}
          />
        </Box>
        <FilterFooter onCancel={onCancel} onSave={onSave} />
      </Box>
    </ClickAwayListener>
  );
};
