import {
  CommentIdComponents,
  CommentType,
  FiQuestionnaireSystemTaskData,
  GroupedTask,
  IANATimezones,
  MinifiedTask,
  QuestionnaireSystemTaskData,
  SystemTaskData,
  TaskCategory,
  TaskInternalType,
  TaskResponse,
  TaskStatus
} from '@gcv/shared';
import { QuestionnairesApi } from 'api';
import { TasksApi } from 'api/TasksApi';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarStore } from 'stores/SnackBarStore';
import { UserStore } from 'stores/UserStore';
import { DateTimeHelpers } from 'util/dateTime.util';

export interface TaskDetails {
  account: string;
  assignee: string;
  associatedWith: string;
  comment_id_components?: CommentIdComponents;
  comment_type?: CommentType;
  completedBy: string;
  completedDate: string;
  completedNote: string;
  createdDate: string;
  data?: SystemTaskData;
  description: string;
  dueDate: string;
  groupedTasks: MinifiedTask[];
  id: string;
  internalType: TaskInternalType | undefined;
  isGrouped?: boolean;
  name: string;
  status: TaskStatus;
  taskCategory: TaskCategory;
}

export interface DecoratedTask {
  id: string;
  isGrouped?: boolean;
  groupKey: string;
  name: string;
  tasks: MinifiedTask[];
}

export interface PM {
  tasks: DecoratedTask[];
  isLoading: boolean;
}

@injectable()
export class CrbInboxRepo {
  @inject(TasksApi)
  tasksApi: TasksApi;

  @inject(QuestionnairesApi)
  questionnairesApi: QuestionnairesApi;

  @inject(CrbDispensaryStore)
  dispStore: CrbDispensaryStore;

  @inject(UserStore)
  userStore: UserStore;

  @inject(SnackbarStore)
  snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {
    tasks: [],
    isLoading: true
  };

  public load = action(async (taskStatus: 'open' | 'completed') => {
    this.programmersModel.isLoading = true;

    try {
      const status = taskStatus === 'open' ? [TaskStatus.OPEN, TaskStatus.PROCESSING] : [TaskStatus.COMPLETE];
      const response = await this.tasksApi.getCrbTasks(this.dispStore.currentDispensary.id, status);

      runInAction(() => {
        this.programmersModel = { tasks: response.tasks.map((t) => this.decorateTask(t)), isLoading: false };
      });
    } catch (error) {
      console.error(error);
    }
  });

  decorateTask = (t: MinifiedTask | GroupedTask): DecoratedTask => {
    if (this.isGroupedTask(t)) {
      return {
        id: t.tasks[0].id,
        isGrouped: t.is_grouped,
        groupKey: t.group_key,
        name: t.tasks[0].title ?? '--',
        tasks: t.tasks
      };
    } else {
      return {
        id: t.id,
        isGrouped: undefined,
        groupKey: '',
        name: t.title ?? '--',
        tasks: [t]
      };
    }
  };

  isGroupedTask = (task: MinifiedTask | GroupedTask | TaskDetails): task is GroupedTask => {
    return (task as GroupedTask).is_grouped || (task as TaskDetails).isGrouped;
  };

  public completeTask = action(
    async (crbId: string, taskId: string, timezone: IANATimezones, completed_note: string) => {
      this.programmersModel.isLoading = true;

      await this.tasksApi.updateCrbTask(
        crbId,
        taskId,
        TaskStatus.COMPLETE,
        completed_note,
        this.userStore.user.id,
        DateTimeHelpers.formatJsDateToISO(new Date(Date.now()), timezone)
      );
      const tasks = [...this.programmersModel.tasks];
      const index = tasks.findIndex((t) => taskId === t.id);
      tasks.splice(index, 1);

      runInAction(() => {
        this.programmersModel = { tasks, isLoading: false };
      });
    }
  );

  public completeQuestionnaire = action(async (crbId: string, taskId: string, questionnaireId: string) => {
    this.programmersModel.isLoading = true;
    const tasks = [...this.programmersModel.tasks];
    try {
      const index = tasks.findIndex((t) => taskId === t.id);
      const task = tasks[index];
      if (task.tasks[0] && task.tasks[0].data) {
        const taskData = (task.tasks[0].data as QuestionnaireSystemTaskData).questionnaire;
        await this.questionnairesApi.completeQuestionnaireResultForCRB(
          taskData.template_id,
          questionnaireId,
          crbId,
          taskData.bank_id
        );
      } else {
        throw new Error('Task data is undefined');
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage(
        'There was an error submitting the questionnaire. Please contact support.'
      );
    } finally {
      runInAction(() => {
        this.programmersModel = { tasks, isLoading: false };
      });
    }
  });
}
