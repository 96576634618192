import { GreenCheckAwsEnvironmentName } from 'domain/enums/green-check-aws-environment-name';
import { Environment } from './env-interface';
import { environment as envProd } from './environment.prod';
import { environment as envStaging } from './environment.staging';
import { environment as envIntegration } from './environment.integration';
import { environment as envLocal } from './environment.local';

export let environment: Environment = {
  production: false,
  amplify: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:1fd6dad7-8ab3-4370-9879-72ba79de1714',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_BZ33HXqWU',
    aws_user_pools_web_client_id: '433jh5d25md39a6mrk1ps0p6cd'
  },
  gcvConfig: {
    launchDarklyClientSideId: '5c12828be662e0172dd11e7f',
    webSocketUrl: 'wss://4vymdgbb46.execute-api.us-east-1.amazonaws.com/Prod'
  },
  env: GreenCheckAwsEnvironmentName.INTEGRATION,
  basePath: 'https://integration-okta.greencheckverified.com',
  banksApiBasePath: 'https://integration-banks-api.greencheckverified.com',
  storageConfig: {
    commentDocument: 'integration-comment-upload-documents-bucket',
    salesFile: 'integration-new-data-file-bucket',
    orgDocument: 'integration-org-documents-file-bucket',
    userDocument: 'integration-user-documents-file-bucket',
    sharedDocument: 'integration-gcv-shared-documents-file-bucket',
    coreTransactions: 'integration-core-transaction-data',
    sftpCoreTransactions: 'integration-sftp-core-transaction-data',
    publicFiles: 'integration-gcv-public-data-file-bucket'
  },
  auth0Config: {
    domain: 'NOT_IMPLEMENTED',
    clientId: 'NOT_IMPLEMENTED'
  },
  payqwickDefaultRedirectUri: 'NOT_IMPLEMENTED'
};

switch (process.env.REACT_APP_ENV) {
  case 'production':
    environment = envProd;
    break;
  case 'staging':
    environment = envStaging;
    break;
  case 'integration':
    environment = envIntegration;
    break;
  case 'local':
    environment = envLocal;
    break;
  default:
    break;
}
