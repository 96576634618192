import { injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

interface ModalObject {
  title: string;
  content: JSX.Element;
  isOpen: boolean;

  action?: JSX.Element;
  alignTitle?: 'left' | 'center' | 'right';
  onCancel?: () => void;
}

@injectable()
export class ModalStore {
  modal: ModalObject = {
    title: '',
    content: <></>,
    isOpen: false,
    action: <></>,
    alignTitle: 'left',
    onCancel: () => {
      return;
    }
  };

  isFormSubmitted = false;
  isFormValid = false;

  constructor() {
    makeAutoObservable(this);
  }

  hideModal = action(() => {
    this.modal = {
      title: '',
      content: <></>,
      isOpen: false,
      action: <></>,
      alignTitle: 'left',
      onCancel: () => {
        return;
      }
    };
    this.isFormSubmitted = false;
    this.isFormValid = false;
  });

  showModal = action(
    (
      title: string,
      content: JSX.Element,
      action?: JSX.Element,
      alignTitle?: 'left' | 'center' | 'right',
      onCancel?: () => void
    ) => {
      this.modal = {
        title,
        content,
        isOpen: true,
        action,
        alignTitle,
        onCancel
      };
    }
  );

  submitForm = action((submit: boolean) => {
    this.isFormSubmitted = submit;
  });

  validateForm = action((isValid: boolean) => {
    this.isFormValid = isValid;
  });

  clearStore = action(() => {
    this.modal = {
      title: '',
      content: <></>,
      isOpen: false,
      action: <></>,
      alignTitle: 'left',
      onCancel: () => {
        return;
      }
    };
  });
}

let modalStore: ModalStore | undefined;

export function getModalStore(): ModalStore {
  if (!modalStore) {
    modalStore = new ModalStore();
  }

  return modalStore;
}
