import { Bank, BankLocation, User } from '@gcv/shared';
import { BanksApi } from 'api';
import { updatePendoAccountForFi } from 'third-party-integrations/pendo';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { getSnackbarStore } from './SnackBarStore';
import { getUserStore } from './UserStore';
import { injectable } from 'inversify';

@injectable()
export class FiBankStore {
  isLoaded = false;
  isSavingOrganizationDetails = false;
  isSavingCannabisProgram = false;
  isStaffLoaded = false;
  staff: User[] = [] as User[];
  bank: Bank = {} as Bank;

  constructor() {
    makeAutoObservable(this);
  }

  bankApi = new BanksApi();

  snackbarStore = getSnackbarStore();
  userStore = getUserStore();

  loadBank = action(async (id: string) => {
    const bank = await this.bankApi.getBankById(id);
    updatePendoAccountForFi(bank, this.userStore.user);
    this.loadStaff(bank.id);
    runInAction(() => {
      this.bank = bank;
      this.isLoaded = true;
    });
  });

  updateBank = action((bank: Bank) => {
    this.bank = bank;
  });

  /**
   * To update the Bank Organization Profile Details.
   */
  updateBankProfileDetails = action(
    async (
      data: Parameters<InstanceType<typeof BanksApi>['updateBankOrganizationalDetails']>[1],
      locations: BankLocation[],
      exitEditMode?: () => void
    ) => {
      try {
        this.isSavingOrganizationDetails = true;

        await this.bankApi.addLocations(this.bank.id, locations);
        const response = (await this.bankApi.updateBankOrganizationalDetails(this.bank.id, data)) as Bank;
        this.updateBank(response);

        this.snackbarStore.showSuccessSnackbarMessage('Profile details have been updated successfully.');

        if (exitEditMode) exitEditMode();
      } catch (e) {
        this.snackbarStore.showErrorSnackbarMessage('There was an issue updating business details.');
      } finally {
        runInAction(() => (this.isSavingOrganizationDetails = false));
      }
    }
  );

  /**
   * To update the Banks' Cannabis Program.
   */
  updateCannabisProgram = action(
    async (
      data: Parameters<InstanceType<typeof BanksApi>['updateBankCannabisProgram']>[1],
      exitEditMode?: () => void
    ) => {
      try {
        runInAction(() => {
          this.isSavingCannabisProgram = true;
        });

        const response = (await this.bankApi.updateBankCannabisProgram(this.bank.id, data)) as Bank;
        this.updateBank(response);

        this.snackbarStore.showSuccessSnackbarMessage('Cannabis program has been updated successfully.');

        if (exitEditMode) exitEditMode();
      } catch (e) {
        this.snackbarStore.showErrorSnackbarMessage(
          'There was an issue updating the cannabis program details.'
        );
      } finally {
        runInAction(() => (this.isSavingCannabisProgram = false));
      }
    }
  );

  loadStaff = action(async (bankId: string) => {
    this.isStaffLoaded = false;
    const bankStaff = await this.bankApi.getBankStaff(bankId);
    runInAction(() => {
      this.staff = bankStaff;
      this.isStaffLoaded = true;
    });
  });

  updateStaff = action((user: User) => {
    const existingIndex = this.staff.findIndex((u) => u.id === user.id);
    if (existingIndex > -1) {
      const updatedArray = this.staff.slice();
      updatedArray.splice(existingIndex, 1, user);
      this.staff = updatedArray;
    } else {
      const updatedArray = this.staff.slice();
      updatedArray.push(user);
      this.staff = updatedArray;
    }
  });

  getStaffNameById = (id?: string) => {
    const existingIndex = this.staff.findIndex((u) => u.id === id);
    let name = '--';
    if (id && existingIndex > -1) {
      name = `${this.staff[existingIndex].firstName} ${this.staff[existingIndex].lastName}`;
    }
    return name;
  };

  clearStore = action(() => {
    this.isLoaded = false;
    this.bank = {} as Bank;
  });
}

let store: FiBankStore | undefined;

export function getFiBankStore(): FiBankStore {
  if (!store) {
    store = new FiBankStore();
  }

  return store;
}
