import { AccessScopes, AccessScopesToUserTypeMap, AccessUserType, UpdateAccessParams } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { SnackbarStore } from 'stores/SnackBarStore';
import { GcvEditAccessPM, GcvEditAccessRepo } from './edit-access.repo';
import { SelectOption } from 'ui';

export interface GcvEditAccessVM {
  isLoading: boolean;
  updateConfigLoading: boolean;

  accessData?: {
    name: string;
    description?: string;
    scopes: AccessScopes[];
    userType: AccessUserType;
    active: boolean;
    user_id?: string;
    org_id?: string;
    trace_api_key?: string;
  };
  editMode: boolean;
  availableScopes: SelectOption[];
  selectedScopes?: AccessScopes[];

  confirmRotateApiKeyModalOpen: boolean;
  confirmRotateApiKeyModalLoading: boolean;

  confirmRevokeAccessModalOpen: boolean;
  confirmRevokeAccessModalLoading: boolean;
}

@injectable()
export class GcvEditAccessPresenter {
  @inject(GcvEditAccessRepo)
  private repo: GcvEditAccessRepo;
  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  public editAccessViewModel: GcvEditAccessVM = {
    isLoading: true,
    editMode: false,
    availableScopes: [],
    confirmRotateApiKeyModalOpen: false,
    confirmRotateApiKeyModalLoading: false,
    confirmRevokeAccessModalOpen: false,
    confirmRevokeAccessModalLoading: false,
    updateConfigLoading: false
  };

  public load = action(async (client_id: string) => {
    observe(this.repo, 'gcvEditAccessProgrammersModel', (obj) => {
      const programmersModel = obj.newValue as GcvEditAccessPM;

      const availableScopes: SelectOption[] = programmersModel.accessMetadata
        ? Object.entries(AccessScopes)
            .map(([label, value]) => {
              return {
                label,
                value
              };
            })
            .filter((s) =>
              AccessScopesToUserTypeMap[programmersModel.accessMetadata!.value.user_type].includes(s.value)
            )
        : [];

      this.updateViewModel({
        isLoading: programmersModel.isLoading,
        accessData: programmersModel.accessMetadata
          ? {
              name: programmersModel.accessMetadata.value.name,
              description: programmersModel.accessMetadata.value.description,
              scopes: programmersModel.accessMetadata.value.scopes,
              userType: programmersModel.accessMetadata.value.user_type,
              active: programmersModel.accessMetadata.value.active,
              user_id: programmersModel.accessMetadata.value.user_id,
              org_id: programmersModel.accessMetadata.value.org_id,
              trace_api_key: programmersModel.accessMetadata.value.trace_access_config?.trace_api_key
            }
          : undefined,
        availableScopes,
        selectedScopes: programmersModel.accessMetadata?.value.scopes
      });
    });

    await this.repo.load(client_id);
  });

  private updateViewModel = action((viewModel: Partial<GcvEditAccessVM>) => {
    this.editAccessViewModel = { ...this.editAccessViewModel, ...viewModel };
  });

  public toggleEditMode = action(() => {
    this.updateViewModel({ editMode: !this.editAccessViewModel.editMode });
  });

  public setSelectedScopes = action((selectedScopes: AccessScopes[]) => {
    this.updateViewModel({ selectedScopes });
  });

  public updateAccessConfig = action((client_id: string, updateRequest: UpdateAccessParams) => {
    this.updateViewModel({ updateConfigLoading: true });
    this.repo
      .updateAccessConfig(client_id, { ...updateRequest, active: true })
      .then(() => {
        this.snackbarStore.showSuccessSnackbarMessage('Successfully updated access config');
      })
      .catch(() => {
        this.snackbarStore.showErrorSnackbarMessage('Failed to update access config');
        this.updateViewModel({
          accessData: this.editAccessViewModel.accessData
            ? { ...this.editAccessViewModel.accessData }
            : undefined,
          selectedScopes: this.editAccessViewModel.accessData?.scopes
        });
      })
      .finally(() => {
        this.updateViewModel({ updateConfigLoading: false, editMode: false });
      });
  });

  // Confirm Rotate API Key

  public setConfirmRotateApiKeyModalOpen = action((open: boolean) => {
    this.updateViewModel({ confirmRotateApiKeyModalOpen: open });
  });

  public rotateApiKey = action((client_id: string) => {
    this.updateViewModel({ confirmRotateApiKeyModalLoading: true });
    this.repo
      .rotateApiKey(client_id)
      .catch(() => {
        this.snackbarStore.showErrorSnackbarMessage('Failed to rotate API key');
      })
      .finally(() => {
        this.updateViewModel({ confirmRotateApiKeyModalOpen: false, confirmRotateApiKeyModalLoading: false });
      });
  });

  // Confirm Revoke Access Modal

  public setConfirmRevokeAccessModalOpen = action((open: boolean) => {
    this.updateViewModel({ confirmRevokeAccessModalOpen: open });
  });

  public revokeAccess = action((client_id: string) => {
    this.updateViewModel({ confirmRevokeAccessModalLoading: true });
    this.repo
      .updateAccessConfig(client_id, { active: false, scopes: [] })
      .then(() => {
        this.snackbarStore.showSuccessSnackbarMessage('Successfully revoked access');
      })
      .catch(() => {
        this.snackbarStore.showErrorSnackbarMessage('Failed to update access config');
      })
      .finally(() => {
        this.updateViewModel({ confirmRevokeAccessModalOpen: false, confirmRevokeAccessModalLoading: false });
      });
  });
}
