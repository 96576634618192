import { ClientMessage } from 'domain/types/client-message';
import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

@injectable()
export class MessagesRepo {
  appMessages: ClientMessage[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.reset();
  };

  reset = () => {
    this.appMessages = [];
  };
}
