import {
  Box,
  Checkbox,
  Collapse,
  Icon,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import * as React from 'react';
import { Button, Spinner, Tooltip } from 'ui';
import palette from 'ui/theme/palette';

export type ListOption = {
  archivedOption?: boolean;
  children?: ListOption[];
  disabled?: boolean;
  icon?: JSX.Element;
  label: string;
  parentValue?: string;
  selected?: boolean;
  sublabel?: React.ReactNode;
  value: string;
};

type OptionMap = {
  [key: string]: {
    archived?: boolean;
    checked: boolean;
    children?: OptionMap;
    disabled?: boolean;
    icon?: JSX.Element;
    label: string;
    open: boolean;
    sublabel?: React.ReactNode;
  };
};

/** Specified by the designs. */
const sublabelTextStyle = {
  color: palette.text.secondary,
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '1.4rem'
};

interface Props {
  options: ListOption[];
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  onSelect?: (value: string, selected: boolean) => void;
  onUserClickCallback?: (value: string, selected: boolean) => void;
  indentItems?: boolean;
  disabledItemTooltip?: string;
  dataCy?: string;
  archiveOptionIcon?: React.ReactNode;
  showArchived?: boolean;
  onClearChildren?: (parentId: string) => void;
}

export const List: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [optionMap, setOptionMap] = React.useState<OptionMap>({});

  const indentItems = !!props.options.find((a) => a.children?.length) ? props.indentItems : false;

  React.useEffect(() => {
    const buildOptionsMap = (options: ListOption[]) => {
      const newOptionMap: OptionMap = {};

      options.forEach((opt) => {
        newOptionMap[opt.value] = {
          archived: opt.archivedOption,
          checked: !!opt.selected,
          children: opt.children ? buildOptionsMap(opt.children) : undefined,
          disabled: opt.disabled,
          icon: opt.icon,
          label: opt.label,
          open: true,
          sublabel: opt.sublabel
        };
      });

      return newOptionMap;
    };

    setOptionMap(buildOptionsMap(props.options));
    setIsLoading(false);
  }, [props.options]);

  const getParent = (options: OptionMap, id: string) => {
    return options[id];
  };

  const getChild = (options: OptionMap, id: string) => {
    let child;

    for (const opt of Object.values(options)) {
      const children = opt.children;

      if (!children) {
        continue;
      }

      const childId = Object.keys(children).find((key) => key === id);

      if (childId) {
        child = children[childId];
        break;
      }
    }

    return child;
  };

  const countSelectedChildren = (parentId: string) => {
    const parent = optionMap[parentId];

    if (!parent || !parent.children) {
      return { total: 0, count: 0 };
    }

    let count = 0;
    let total = 0;

    Object.values(parent.children).forEach((child) => {
      total += 1;

      if (child.checked) {
        count += 1;
      }
    });

    return { count, total };
  };

  const handleSelectParent = (options: OptionMap, id: string) => {
    const parent = getParent(options, id);

    if (parent) {
      parent.checked = !parent.checked;

      if (props.onSelect) {
        props.onSelect(id, parent.checked);
      }
    } else {
      const child = getChild(options, id);

      if (child) {
        child.checked = !child.checked;

        if (props.onSelect) {
          props.onSelect(id, child.checked);
        }
      }
    }
  };

  const handleSelectChildren = (options: OptionMap, id: string) => {
    const parent = getParent(options, id);
    if (!parent) {
      return;
    }

    const children = parent.children;
    if (!children) {
      return;
    }

    Object.keys(children).forEach((key) => {
      children[key].checked = parent.checked;

      if (props.onSelect) {
        props.onSelect(key, parent.checked);
      }
    });
  };

  const handleSelectToggle = (value: string) => {
    const newOptions = { ...optionMap };

    handleSelectParent(newOptions, value);
    handleSelectChildren(newOptions, value);

    setOptionMap(newOptions);
  };

  const handleOpenToggle = (value: string) => {
    const newOptions = { ...optionMap };
    newOptions[value].open = !newOptions[value].open;

    setOptionMap(newOptions);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <MuiList
      disablePadding
      data-cy={props.dataCy}
      sx={{
        height: props.height ? props.height : 'auto',
        minHeight: props.minHeight,
        maxHeight: props.maxHeight,
        overflow: 'auto'
      }}
    >
      {Object.keys(optionMap).map((key) => {
        const labelId = `list-label-${key}`;
        const option = getParent(optionMap, key);
        const children: OptionMap | undefined = option.children;
        const hasChildren = children && Object.keys(children).length > 0;
        const selectedChildren = countSelectedChildren(key);

        return (
          <Box key={key}>
            <ListItem disablePadding>
              <ListItemButton
                dense
                disableRipple
                disableGutters
                data-cy={labelId}
                role={undefined}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (!option.disabled) {
                    handleSelectToggle(key);

                    if (props.onUserClickCallback) {
                      props.onUserClickCallback(key, option.checked);
                    }
                  }
                }}
                selected={option.checked}
                sx={{ p: props.indentItems ? '0 0 0 53px' : '0' }}
              >
                {hasChildren && (
                  <ListItemIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      handleOpenToggle(key);
                    }}
                    sx={{ mr: 0 }}
                  >
                    {option.open ? <Icon>expand_more</Icon> : <Icon>keyboard_arrow_right</Icon>}
                  </ListItemIcon>
                )}

                <ListItemIcon
                  sx={{
                    alignSelf: option.sublabel ? 'flex-start' : 'center',
                    ml: hasChildren ? '0' : props.indentItems ? '12px' : '36px',
                    mr: 0,
                    '&: hover': {
                      cursor: !!option.disabled ? 'not-allowed' : 'pointer'
                    },
                    minWidth: '24px'
                  }}
                >
                  {option.disabled && props.disabledItemTooltip ? (
                    <Tooltip placement="top-start" title={props.disabledItemTooltip}>
                      <Checkbox
                        disableRipple
                        disabled={option.disabled}
                        checked={
                          option.checked ||
                          (selectedChildren.count > 0 && selectedChildren.count >= selectedChildren.total)
                        }
                        edge="start"
                        indeterminate={
                          selectedChildren.count > 0 && selectedChildren.count < selectedChildren.total
                        }
                        inputProps={{ 'aria-labelledby': labelId }}
                        size="small"
                        sx={{
                          color: (theme) => theme.palette.divider,
                          border: (theme) => `1px solid ${theme.palette.common.white}`,
                          '&.Mui-focusVisible': {
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            boxSizing: 'border-box',
                            backgroundColor: 'transparent',
                            borderRadius: '4px'
                          }
                        }}
                        tabIndex={-1}
                      />
                    </Tooltip>
                  ) : (
                    <Checkbox
                      disableRipple
                      disabled={option.disabled}
                      checked={
                        option.checked ||
                        (selectedChildren.count > 0 && selectedChildren.count >= selectedChildren.total)
                      }
                      edge="start"
                      indeterminate={
                        selectedChildren.count > 0 && selectedChildren.count < selectedChildren.total
                      }
                      inputProps={{ 'aria-labelledby': labelId }}
                      size="small"
                      sx={{
                        color: (theme) => theme.palette.divider,
                        border: (theme) => `1px solid ${theme.palette.common.white}`,
                        '&.Mui-focusVisible': {
                          border: (theme) => `1px solid ${theme.palette.primary.main}`,
                          boxSizing: 'border-box',
                          backgroundColor: 'transparent',
                          borderRadius: '4px'
                        },
                        padding: '6px'
                      }}
                      tabIndex={-1}
                    />
                  )}
                </ListItemIcon>

                <ListItemText
                  disableTypography
                  id={labelId}
                  primary={
                    <div style={{ margin: option.sublabel ? '10px 0 0 0' : '0' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px' }}>{option.label}</span>
                        {option.archived && props.archiveOptionIcon ? props.archiveOptionIcon : null}
                        {option.icon ? option.icon : null}
                      </div>
                    </div>
                  }
                  secondary={
                    option.sublabel ? <div style={{ ...sublabelTextStyle }}>{option.sublabel}</div> : null
                  }
                  sx={{
                    margin: '0px',
                    color: option.disabled ? '#A5A8BA' : '#3a3c45',
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                    '&: hover': {
                      cursor: !!option.disabled ? 'not-allowed' : 'pointer'
                    }
                  }}
                />

                {props.onClearChildren && selectedChildren.count > 0 && (
                  <Button
                    variant={'text'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onClearChildren && props.onClearChildren(key);
                    }}
                    label={'Clear'}
                    color={'primary-text'}
                    style={{ padding: '0', height: 'min-content' }}
                  />
                )}
              </ListItemButton>
            </ListItem>

            {hasChildren && (
              <Collapse in={option.open} timeout="auto">
                <List
                  indentItems
                  archiveOptionIcon={props.archiveOptionIcon}
                  showArchived={props.showArchived}
                  onSelect={handleSelectToggle}
                  onUserClickCallback={props.onUserClickCallback}
                  options={
                    children
                      ? Object.keys(children).map((k) => {
                          const child = children[k];

                          return {
                            archivedOption: child.archived,
                            disabled: child.disabled,
                            icon: child.icon,
                            label: child.label,
                            selected: child.checked,
                            value: k
                          };
                        })
                      : []
                  }
                />
              </Collapse>
            )}
          </Box>
        );
      })}
    </MuiList>
  );
};
