import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

interface Props {
  id: string;
  type: string;
  dataCy?: string;
}

export const Drop: React.FC<Props> = (props) => {
  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#1270fc11' : 'transparent',
    width: '100%'
  });

  return (
    <Droppable droppableId={props.id} type={props.type}>
      {(droppableProvided, droppableSnapshot) => (
        <div
          {...droppableProvided.droppableProps}
          ref={droppableProvided.innerRef}
          style={getListStyle(droppableSnapshot.isDraggingOver)}
          data-cy={props.dataCy}
        >
          {props.children}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
