import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';

/**
 * A simple, size-adjustable spinner component.
 *
 * @param props optional size property, defaulting to 20px diameter.
 * @returns an InlineSpinner instance.
 */
export const InlineSpinner: React.FC<{ size: string | number | undefined }> = (props) => {
  const size = props.size ?? 20;

  return (
    <Box sx={{ height: size, width: size, ml: '0.5rem', mr: '0.5rem' }}>
      <CircularProgress
        variant="determinate"
        size={size}
        sx={{
          color: 'rgba(3, 166, 91, 0.3)',
          position: 'absolute'
        }}
        value={100}
      />
      <CircularProgress size={size} color="primary" />
    </Box>
  );
};
