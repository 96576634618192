export default {
  styleOverrides: {
    root: {
      backgroundColor: '#D8F4E7',
      color: '#03A65B',
      fontWeight: 700,
      fontSize: '13px',
      border: '0px',
      '&.Mui-disabled': {
        opacity: 0.6
      }
    },
    deletable: {
      '&:focus': {
        backgroundColor: '#d8f4ec'
      }
    }
  }
};
