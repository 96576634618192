import { GreenCheckAwsEnvironmentName, User } from '@gcv/shared';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { environment } from '../environments/environment';

export const logRocketConfig = { session_url: '' };

const removeAuthHeader = (request: any) => {
  // Clone the request to avoid modifying the original object
  const sanitizedRequest = { ...request };

  // Check if the request contains an Authorization header
  if (sanitizedRequest.headers && sanitizedRequest.headers.Authorization) {
    // Redact the sensitive information from the Authorization header
    sanitizedRequest.headers.Authorization = 'REDACTED';
  }

  // Return the sanitized request object
  return sanitizedRequest;
};

export const initLogRocket = (rollbarInstance: any) => {
  if (environment.env === GreenCheckAwsEnvironmentName.PROD) {
    LogRocket.init('ajzrpt/production-btbu3', {
      mergeIframes: true,
      network: {
        requestSanitizer: (request) => {
          return removeAuthHeader(request);
        }
      }
    });
  } else if (environment.env === GreenCheckAwsEnvironmentName.STAGING) {
    LogRocket.init('ajzrpt/staging-1hqux', {
      mergeIframes: true,
      network: {
        requestSanitizer: (request) => {
          return removeAuthHeader(request);
        }
      }
    });
  }

  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL((sessionURL) => {
    const logrocketSessionUrl = sessionURL;
    logRocketConfig.session_url = logrocketSessionUrl;
    try {
      rollbarInstance.configure({
        transform: function (obj: any) {
          obj.sessionURL = sessionURL;
        }
      });
    } catch (e) {
      rollbarInstance.error('Error setting LogRocket session url', e, {
        additional: sessionURL
      });
    }
  });
};

export function updateLogRocketConfig(user: User) {
  if (!user) {
    return;
  }

  const data = {
    userId: user.id,
    username: `${user.firstName} ${user.lastName}`,
    email: user.email,
    env: environment.env,
    companyType: user.companies[0]?.companyType
  };

  LogRocket.identify(user.id, {
    name: data.username,
    email: data.email
  });
}
