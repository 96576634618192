import { Box, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useInjection } from 'ioc';
import { Button, Container, ContainerItem, Error, Form, Header, InputRadio, Text } from 'ui';
import { ReactComponent as PlusIcon } from 'assets/images/ic-circle-plus.svg';
import { OnboardingPresenter } from '../../../onboarding.presenter';

interface Props {}

export const AccountOwnerInformationCard: React.FC<Props> = (props) => {
  const form = useForm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const presenter = useInjection(OnboardingPresenter);

  const subtitle = presenter.viewModel.provider.orgName || 'Your service provider';

  const buttonStyles = isMobile
    ? {
        padding: '4px 12px'
      }
    : {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 12px'
      };

  return (
    <Container width="100%" column padding="0" dataCy="account-owner-info-card">
      <ContainerItem padding="0">
        <Header mobileHeader={isMobile} content="Green Check Account Owners" type="h2" />
      </ContainerItem>
      <ContainerItem padding="6px 0 0 0">
        <Text
          sx={{ fontSize: '13px', fontWeight: 500, color: '#828599' }}
          content={`${subtitle} needs to verify all owners or signers on your Green Check account. `}
        />
      </ContainerItem>
      <ContainerItem padding="12px 0 0 0">
        <Form>
          <InputRadio
            {...form}
            name="singleAccountOwner"
            label="Are you the only Account Owner?"
            onChange={(val) => presenter.setSingleAccountOwner(val as boolean)}
            dataCy="account-owner-radio-button"
            options={[
              {
                checked: presenter.viewModel.singleAccountOwner,
                label: "Yes, it's just me!",
                value: true
              },
              {
                checked: !presenter.viewModel.singleAccountOwner,
                label: 'No, there are other Account Owners.',
                value: false
              }
            ]}
          />
        </Form>
      </ContainerItem>
      {presenter.viewModel.singleAccountOwner === false && (
        <Box sx={{ textAlign: 'center', width: 'inherit' }}>
          <Button
            startIcon={<PlusIcon style={{ marginRight: '-4px' }} />}
            color="primary-text"
            label="Add an Account Owner"
            onClick={() => {
              presenter.setAccountOwnerModal(true);
            }}
            style={buttonStyles}
            dataCy="add-owner-button"
          />
          {presenter.viewModel.requireAdditionalAccountOwners && (
            <Error content={'Please list other account owners'} />
          )}
        </Box>
      )}
    </Container>
  );
};
