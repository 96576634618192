import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      '&$selected': {
        backgroundColor: palette.primary.light
      },
      '&$hover': {
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: palette.primary.light
        }
      }
    }
  }
};
