import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

interface SpinnerProps {
  value: number;
  size?: string;
  thickness?: number;
  dataCy?: string;
}
export const DeterminateSpinner: React.FC<SpinnerProps> = (props) => {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        width: '100%'
      }}
    >
      <Box>
        <Box
          sx={{
            position: 'relative'
          }}
        >
          <CircularProgress
            variant="determinate"
            thickness={props.thickness ? props.thickness : 8}
            sx={{
              height: props.size ? props.size : '70px',
              width: props.size ? props.size : '70px',
              color: '#C6CCDA',
              position: 'absolute'
            }}
            value={100}
            size={props.size ? props.size : '70px'}
          />

          <CircularProgress
            thickness={props.thickness ? props.thickness : 8}
            variant="determinate"
            value={props.value}
            color="primary"
            sx={{
              height: props.size ? props.size : '70px',
              width: props.size ? props.size : '70px',
              position: 'absolute'
            }}
            size={props.size ? props.size : '70px'}
            data-cy={props.dataCy ? props.dataCy : 'loading-spinner'}
          />
        </Box>
      </Box>
    </Box>
  );
};
