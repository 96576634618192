import { Avatar, Box, ClickAwayListener, IconButton, Popover, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as DropdownArrow } from 'assets/images/drop-down-arrow.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings-icon.svg';
import { ReactComponent as NotificationBell } from 'assets/images/notification-bell.svg';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getFiBankStore } from 'stores/FiBankStore';
import { getNotificationStore } from 'stores/NotificationStore';
import { getUserStore } from 'stores/UserStore';
import { AppBar, Badge, Button, Container, ContainerItem, Divider, Icon, Text } from 'ui/atoms';
import { NotificationsDrawer } from 'ui/systems';
import { Banner } from '../Banner/banner.organism';
import { useComponent } from 'hooks';
import { useSavePromptContext } from 'app-context';
import { OrganizationRoleResolver } from '@gcv/shared';

interface Props extends Record<string, unknown> {
  toggleNavDrawer: () => void;
}

export const FiAppBar: React.FC<Props> = useComponent((props) => {
  const navHeight = '74px';
  const drawerWidth = '261px';

  const [savePromptState] = useSavePromptContext();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const bankStore = getFiBankStore();
  const userStore = getUserStore();
  const notificationStore = getNotificationStore();

  const resolver = new OrganizationRoleResolver();

  const [notificationDrawerOpen, setNotificationDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const showNotificationDrawer = (isOpen: boolean) => {
    setNotificationDrawerOpen(isOpen);

    // We need to notify any save prompts that are listening so they can reevaluate
    // their state upon the drawer being closed.
    if (!isOpen && savePromptState.notify) {
      savePromptState.allowHistoryBlock = true;
      savePromptState.notify();
    }
  };

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onClickNotificationBell = () => {
    if (savePromptState.isBlocking) {
      savePromptState.callback = () => {
        savePromptState.allowHistoryBlock = false;

        if (savePromptState.notify) {
          savePromptState.notify();
        }

        setNotificationDrawerOpen(true);
      };

      if (savePromptState.notify) {
        savePromptState.notify();
      }
    } else {
      setNotificationDrawerOpen((isOpen) => !isOpen);
    }
  };

  const logout = () => {
    if (savePromptState.isBlocking) {
      savePromptState.callback = () => {
        history.push('/logout');
      };

      if (savePromptState.notify) {
        savePromptState.notify();
      }
    } else {
      history.replace('/logout');
    }
  };

  const switchToSupportLogin = () => {
    window.sessionStorage.removeItem('gcvSupportUserOrg');
    window.sessionStorage.removeItem('gcvSupportUserOrg');
    history.push('/login/support');
  };

  const open = Boolean(anchorEl);

  return (
    <AppBar>
      <Box
        sx={{
          alignItems: 'center',
          borderRight: '1px solid #bdbdbd',
          display: 'flex',
          height: navHeight,
          justifyContent: 'center',
          width: isMobile ? 'auto' : drawerWidth
        }}
      >
        {isMobile ? (
          <IconButton
            disableRipple
            color="inherit"
            onClick={() => props.toggleNavDrawer()}
            size="large"
            sx={{ mx: 2 }}
          >
            <Icon dataCy="mobile-menu-icon" type="menu" />
          </IconButton>
        ) : (
          <Link to="/secure/fi/dashboard" data-cy="logo-link">
            <img src="/img/logo-2.svg" alt="Green Check Verified" height={'22px'} width={'168px'} />
          </Link>
        )}
      </Box>

      <Banner />

      <Divider vertical />
      {!resolver.userHasRole(bankStore.bank.groups, userStore.user, 'bank_examiner') && (
        <Box sx={{ pl: 2, pr: 1, color: '#A5A8BA' }}>
          <Badge badgeContent={notificationStore.notifications.length} color="primary">
            <NotificationBell onClick={onClickNotificationBell} style={{ cursor: 'pointer' }} />
          </Badge>
        </Box>
      )}

      <Box sx={{ color: (theme) => theme.palette.text.secondary, pl: 1, pr: 2 }}>
        <SettingsIcon
          onClick={() => history.push('/secure/fi/users/settings')}
          style={{ cursor: 'pointer' }}
        />
      </Box>

      <Divider vertical />

      <Container
        nowrap
        align="center"
        dataCy="mui-dropdown-container"
        onClick={handlePopoverClick}
        width="275px"
      >
        <ContainerItem grow>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              padding: '1rem',
              cursor: 'pointer'
            }}
          >
            <Text
              type="body2"
              inline
              content={`${bankStore.bank.orgName}`}
              dataCy="app-bar-dispensary-name"
              sx={{
                display: 'inline-block',
                width: '160px',
                whiteSpace: 'nowrap',
                overflow: 'hidden !important',
                textOverflow: 'ellipsis'
              }}
            />
          </Box>
        </ContainerItem>
        <ContainerItem>
          <DropdownArrow />
        </ContainerItem>
      </Container>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Container column dataCy="fi-logout-container" width="358px">
          <ContainerItem>
            <Avatar sx={{ mr: 1 }}>
              {userStore.user.firstName.slice(0, 1)}
              {userStore.user.lastName.slice(0, 1)}
            </Avatar>
          </ContainerItem>
          <ContainerItem>{userStore.user.email}</ContainerItem>
          {userStore.user.role === 'gcv_customer_support' && (
            <Box sx={{ width: '100%', position: 'sticky', bottom: '0px', padding: '8px' }}>
              <Button
                fullWidth
                color="primary-outlined"
                label="Switch Orgs"
                onClick={switchToSupportLogin}
                variant="text"
              />
            </Box>
          )}
          <ContainerItem dataCy="logout-link" width="100%">
            <Button
              fullWidth
              color="default"
              label="Log Out"
              onClick={() => {
                // An existing save prompt requires we close this manually.
                handlePopoverClose();
                logout();
              }}
              variant="text"
            />
          </ContainerItem>
        </Container>
      </Popover>

      <NotificationsDrawer
        drawerOpen={notificationDrawerOpen}
        setDrawerOpen={showNotificationDrawer}
        org={bankStore.bank}
      />
    </AppBar>
  );
});
