export type CardSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface ContainerSizesProps {
  size?: [CardSize, CardSize, CardSize, CardSize, CardSize] | [CardSize];
  xs?: CardSize;
  sm?: CardSize;
  md?: CardSize;
  lg?: CardSize;
  xl?: CardSize;
}

/**
 * A hook to easily use Grid sizes.
 *
 * Note: simply pass in the props (which has been extended be {ContainerSizesProps}).
 */
export const useContainerSizes = ({
  xl = 12,
  xs = 12,
  lg = 12,
  sm = 12,
  md = 12,
  size
}: ContainerSizesProps) => {
  if (size && size.length === 5) {
    [xs, sm, md, lg, xl] = size;
  } else if (size && size.length === 1) {
    xs = sm = md = lg = xl = size[0];
  }

  return { xs, sm, md, lg, xl };
};
