import { ContextOnSubmit } from '@feathery/react/dist/types/Form';
import {
  DueDiligenceStatus,
  InputMarketingOrgRegistration,
  USStates,
  User,
  UserInterests
} from '@gcv/shared';
import { debounce } from '@mui/material';
import { featheryPlantTouchingRoles } from 'domain/consts/feathery';
import * as H from 'history';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { UserStore } from 'stores/UserStore';
import { updatePendoForCrb } from 'third-party-integrations/pendo';
import { CrbWelcomeRepo, PM } from './welcome.repo';
import { stripCountryCode } from 'util/phone.util';

interface VM {
  isLoading: boolean;
  formData: Record<string, unknown>;
  user: null | User;
}

@injectable()
export class CrbWelcomePresenter {
  @inject(CrbWelcomeRepo)
  repo: CrbWelcomeRepo;

  @inject(CrbDispensaryStore)
  dispensaryStore: CrbDispensaryStore;

  @inject(UserStore)
  userStore: UserStore;

  vm: VM = {
    isLoading: true,
    formData: {},
    user: null
  };

  constructor() {
    makeAutoObservable(this);
  }

  public load = async () => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      this.vm.formData = programmersModel.formData;
    });

    this.vm.user = this.userStore.user;
    this.repo.load().then(() => {
      this.setIsLoading(false);
    });
  };

  private isPlantTouching = (role: string) => featheryPlantTouchingRoles.includes(role);

  private mapUserInterests = (formInterests: string[]) => {
    if (!formInterests || !formInterests.length) {
      return [];
    }

    const interests: UserInterests[] = [];

    for (const interest of formInterests) {
      switch (interest) {
        case 'Exploring banking solutions':
          interests.push(UserInterests.BusinessBanking);
          break;
        case 'Acquiring funding':
          interests.push(UserInterests.CommercialLoans);
          break;
        case 'Safeguarding assets':
          interests.push(UserInterests.BusinessInsurance);
          break;
        case 'Secure payment options':
          interests.push(UserInterests.Payments);
          break;
        case 'Managing your team':
          interests.push(UserInterests.PayrollAndHr);
          break;
        case 'Handling cash logistics':
          interests.push(UserInterests.CashLogistics);
          break;
        case 'Sending and receiving funds':
          interests.push(UserInterests.AchAndWires);
          break;
        case 'Paying bills electronically':
          interests.push(UserInterests.BillPay);
          break;
        default:
          break;
      }
    }
    return interests;
  };

  public onSubmit = async (history: H.History, context: ContextOnSubmit) => {
    const formData = context.getFieldValues();
    const userInterests = this.mapUserInterests(formData['interests_1'] as string[]);

    const data: InputMarketingOrgRegistration = {
      termsOfService: Boolean(formData['terms-of-service_1']),
      companyName: formData['business-name_1']?.toString().trim() ?? '',
      phone: stripCountryCode(formData['phone_1']?.toString() ?? ''), // feathery includes the country code, therefore exclude it here
      plantTouching: this.isPlantTouching(formData['business-role_1']?.toString() ?? ''),
      state: USStates[formData['state_1']?.toString().replaceAll(' ', '_') as keyof typeof USStates],
      userInterests: userInterests,
      monthlyCustomers: 0
    };

    if (context.isLastStep()) {
      await this.repo.registerMarketingUserOrg(data);
      await this.userStore.load(this.userStore.user.id);
      this.userStore.user.marketing_user_in_progress = false; // prevent initial welcome page from flashing

      const dispensaryId = this.userStore.user.companies[0].id;
      await this.dispensaryStore.loadDispensaries([dispensaryId], [], this.userStore.user.id);
      await this.dispensaryStore.setCurrentDispensary(dispensaryId);

      const provider = this.dispensaryStore.getDefaultProvider();

      updatePendoForCrb(
        this.dispensaryStore.currentDispensary,
        this.userStore.user,
        this.dispensaryStore.currentDispensary.name,
        provider.id,
        provider.templates.value.onboarding.status as DueDiligenceStatus,
        0,
        [],
        {
          role: formData['business-role_1'],
          interests: formData['interests'],
          banking_status: formData['banking-status_1'],
          is_plant_touching: this.dispensaryStore.currentDispensaryIsPlantTouching
        }
      );

      history.push('/secure/crb/dashboard');
    }
  };

  onChange = (formData: Record<string, unknown>) => this.repo.updateUserFeatheryFormResponse(formData);

  setIsLoading = action((isLoading: boolean) => {
    this.vm.isLoading = isLoading;
  });

  setFormData = (formData: Record<string, unknown>) => {
    this.vm.formData = formData;
  };
}
