import { Activity, IANATimezones } from '@gcv/shared';
import { Box } from '@mui/material';
import { ReactComponent as DocumentIcon } from 'assets/images/document-icon.svg';
import { ReactComponent as Bell } from 'assets/images/empty-states/bell.svg';
import { ReactComponent as ViewDocument } from 'assets/images/view-document.svg';
import { useInjection } from 'ioc';
import * as React from 'react';
import { Container, ContainerItem, Header, Label, Text } from 'ui/atoms';
import { theme } from 'ui/theme';
import { DateTimeHelpers } from 'util/dateTime.util';
import { cropInput } from 'util/format.util';
import { CommentsPresenter } from '../comments.presenter';
import { useComponent } from 'hooks';

const eventTypes = {
  BankArchivedFile: 'archived a file',
  BankRestoredFile: 'restored a file',
  BankReviewedFile: 'reviewed a file',
  BankUserAddedCommentOnRequirement: 'added a comment on the requirement',
  BankUserAddedInternalCommentOnRequirement: 'added an internal comment on the requirement',
  BankUserApprovedRequirement: 'approved the requirement',
  BankUserRequestedFile: 'requested an upload',
  BankUserUndoApproveRequirement: 'revoked requirement approval',
  BankUserUploadedFile: 'uploaded a file',
  CrbUserAddedCommentOnRequirement: 'added a comment on the requirement',
  CrbUserSetRequirementDoNotHave: 'added a comment on the requirement',
  CrbUserUploadedFile: 'uploaded a file'
};

interface Props extends Record<string, unknown> {
  iana_timezone: IANATimezones;
  viewFile: (docId: string) => void;
  dispensaryId?: string;
  requirementId?: string;
}

export const ActivityTab: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(CommentsPresenter);
  const [activities, setActivities] = React.useState<Activity[]>([]);

  const sortActivities = (items: Activity[]) => {
    return items.sort((a: Activity, b: Activity) => {
      const first = new Date(a.created_date);
      const second = new Date(b.created_date);

      // We want the latest, first.
      return -(first.getTime() - second.getTime());
    });
  };

  React.useEffect(() => {
    setActivities(sortActivities(presenter.viewModel.activities));
  }, []);

  return (
    <Container justify="flex-start" width="100%" height="100%">
      {activities.length < 1 ? (
        <ContainerItem padding="45px 30px" justify="center">
          <Bell style={{ width: '110px', marginBottom: '.5rem' }} />
          <Header content="No Activity" type="h3" />
          <Text
            content={`Interactions to documents & other activity appears here.`}
            type="body2"
            sx={{ paddingTop: '8px' }}
          />
        </ContainerItem>
      ) : (
        <ContainerItem justify="left" width="100%" padding="0px 4px">
          {activities.map((activity: Activity) => {
            return (
              <div
                key={activity.created_date}
                data-cy="activity-feed-entry"
                style={{
                  paddingBottom: '16px',
                  marginBottom: '16px',
                  borderBottom: `1px solid ${theme.palette.divider}`
                }}
              >
                <div data-cy="activity-feed-title">
                  <div style={{ marginBottom: '6px' }}>
                    <Header type="h5" content={'@' + activity.uploader_name} color="primary" inline />
                    <Header type="h5" content={' ' + eventTypes[activity.type]} inline />
                  </div>
                  <Label
                    type="subtitle2"
                    content={DateTimeHelpers.formatISOToFriendlyDateString(
                      activity.created_date,
                      props.iana_timezone
                    )}
                  />
                </div>
                {activity.document_id ? (
                  <Box
                    sx={{
                      marginTop: '15px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      overflow: 'auto',
                      boxSizing: 'border-box',
                      borderRadius: '6px',
                      padding: '2px 5px',
                      backgroundColor: '#F2F4FA',

                      '&:hover': {
                        backgroundColor: 'rgba(216, 244, 231, 0.5)'
                      }
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <DocumentIcon width="30px" height="22px" />
                      <div
                        data-cy="uploaded-file-name"
                        onClick={() => props.viewFile(activity.document_id)}
                        style={{
                          cursor: 'pointer',
                          width: '100%',
                          fontFamily: 'Lato',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '13px',
                          verticalAlign: 'center',
                          marginLeft: '10px'
                        }}
                      >
                        {cropInput(activity.document_name, 30, 'document')}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <ViewDocument
                        width="22px"
                        fill="grey"
                        color="grey"
                        style={{ cursor: 'pointer', color: 'grey' }}
                        onClick={() => props.viewFile(activity.document_id)}
                      />
                    </div>
                  </Box>
                ) : activity.comment ? (
                  <div>"{activity.comment}"</div>
                ) : null}
              </div>
            );
          })}
        </ContainerItem>
      )}
    </Container>
  );
});
