import { CustomField, CustomSection } from '@gcv/shared';
import * as React from 'react';
import { Button, Container, ContainerItem, Dialog, Header, Text } from 'ui';
interface Props {
  isOpen: boolean;
  field: CustomField | undefined;
  setIsOpen: (isOpen: boolean) => void;
  fieldList: (CustomSection | CustomField)[];
  checkReason: string;
}
import { ReactComponent as SmartRuleIcon } from 'assets/images/ic-rules-large.svg';

export const RemoveSmartRulesFirstModal: React.FC<Props> = (props) => {
  return (
    <Dialog noActions title="" isOpen={props.isOpen} width="459px" handleClose={() => props.setIsOpen(false)}>
      <Container column padding={0} width="100%">
        <ContainerItem justify="center" padding={0} width="100%">
          <SmartRuleIcon />
        </ContainerItem>
        <ContainerItem justify="center" padding="20px 0" width="100%">
          <Header
            type="h2"
            content={`This field has ${props.fieldList.length} smart rule${
              props.fieldList.length > 1 ? 's' : ''
            } connected to it.`}
          />
        </ContainerItem>
        <ContainerItem justify="center" width="100%" padding="0">
          <Text content={props.checkReason} />
        </ContainerItem>
        <ContainerItem width="100%" padding="0 8px 16px">
          <ul>
            {props.fieldList.map((item) => {
              return <li>{item.label}</li>;
            })}
          </ul>
        </ContainerItem>
        <ContainerItem justify="center" width="100%">
          <Button
            label="Okay"
            color="primary"
            onClick={() => {
              props.setIsOpen(false);
            }}
            width="335px"
          />
        </ContainerItem>
      </Container>
    </Dialog>
  );
};
