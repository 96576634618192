import { CrbAccountType } from '@gcv/shared';
import { SelectOption } from 'ui';

export const accountTypes: SelectOption[] = [
  {
    value: CrbAccountType.Checking,
    label: 'Checking'
  },
  {
    value: CrbAccountType.Savings,
    label: 'Savings'
  },
  {
    value: CrbAccountType.MoneyMarket,
    label: 'Money Market'
  },
  {
    value: CrbAccountType.CertificateOfDeposit,
    label: 'Certificate of Deposit'
  },
  {
    value: CrbAccountType.Other,
    label: 'Other'
  }
];

export const friendlyAccountTypes: { [key in CrbAccountType]: string } = {
  [CrbAccountType.CertificateOfDeposit]: 'Certificate of Deposit',
  [CrbAccountType.Checking]: 'Checking',
  [CrbAccountType.MoneyMarket]: 'Money Market',
  [CrbAccountType.RoyaltyAccount]: 'Royalty Account',
  [CrbAccountType.Savings]: 'Savings',
  [CrbAccountType.Other]: 'Other'
};
