import { api } from './api-util/api';
import { DateTime } from 'luxon';
import { Notification } from '@gcv/shared';

export class NotificationsApi {
  async getNotifications(orgId: string, userId: string, timeStamp?: string): Promise<Notification[]> {
    if (timeStamp) {
      return await api().get(`/notifications/${orgId}/${userId}?time_stamp=${timeStamp}`);
    } else {
      // legacy app limits notifications to past thirty days at most
      return await api().get(
        `/notifications/${orgId}/${userId}?time_stamp=${DateTime.utc().minus({ days: 30 }).toISO()}`
      );
    }
  }

  async markNotificationSeen(notificationId: string) {
    return await api().put(`/notifications/${notificationId}/seen`, {});
  }

  async clearAllNotifications(orgId: string, userId: string) {
    return await api().put(`/notifications/${orgId}/${userId}/seen`, {});
  }
}
