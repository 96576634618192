import { FieldValues } from 'react-hook-form';

export const errorMessages: any = (validate?: string) => {
  return {
    required: 'is a required field',
    maxLength: 'exceeded maximum length',
    minLength: 'is too short',
    max: 'number is too high',
    min: 'number is too low',
    pattern: "doesn't match the correct pattern",
    validate: 'is invalid'
  };
};

export const setDefaultValues = (defaults: Record<string, unknown>) => {
  Object.keys(defaults).map((key) => {
    if (!defaults[key]) {
      defaults[key] = '';
    }
  });

  return defaults as FieldValues;
};
