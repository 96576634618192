import { OnboardingDocumentRequirement, OnboardingDocumentRequirementResult } from '@gcv/shared';
import { useComponent } from 'hooks';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Button, Container, ContainerItem } from 'ui';
import { AddCommentModal } from './add-comment-modal';
import { UploadFileModal } from './upload-file-modal';
import { ReactComponent as CirclePlus } from 'assets/images/ic-circle-plus.svg';
import { useMediaQuery } from '@mui/material';
import { theme } from 'ui/theme';
import { useInjection } from 'ioc';
import { OnboardingPresenter } from 'ui/apps/crb/onboarding-two/onboarding.presenter';

export const UploadFileButton: React.FC<{
  requirement: OnboardingDocumentRequirement;
  result?: OnboardingDocumentRequirementResult;
}> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useForm({ defaultValues: { doNotHave: false } as FieldValues });
  const filesUploaded = props.result?.documents && Object.keys(props.result?.documents).length > 0;

  const [fileUploadOpen, setFileUploadOpen] = React.useState(false);
  const [addCommentModalOpen, setAddCommentModalOpen] = React.useState(false);

  return (
    <Container align="center" justify="center" padding={0} nowrap>
      <ContainerItem minWidth="125px" justify={filesUploaded ? 'center' : 'right'}>
        <Button
          label={filesUploaded ? 'Add ' : 'Upload Files'}
          color={filesUploaded ? 'primary-text' : 'primary'}
          onClick={() => setFileUploadOpen(true)}
          startIcon={filesUploaded && <CirclePlus />}
          dataCy="upload-file-button"
          style={{ padding: isMobile ? '0px 8px' : '6px 16px', fontSize: isMobile ? '12px' : '13px' }}
        />
      </ContainerItem>

      {!props.result || (props.result && Object.keys(props.result.documents).length === 0) ? (
        <>
          <ContainerItem padding="8px">or</ContainerItem>
          <ContainerItem margin="8px 0" minWidth="141px">
            <Button
              dataCy="dont-have-button"
              label="I don't have this"
              onClick={() => {
                setAddCommentModalOpen(true);
              }}
              color="primary-outlined"
              style={{ padding: isMobile ? '0px 8px' : '6px 16px', fontSize: isMobile ? '12px' : '13px' }}
            />
          </ContainerItem>
        </>
      ) : null}

      <UploadFileModal
        requirement={props.requirement}
        result={props.result}
        fileUploadOpen={fileUploadOpen}
        setFileUploadOpen={setFileUploadOpen}
      />

      <AddCommentModal
        requirement={props.requirement}
        result={props.result}
        title="Add Explanation"
        addCommentModalOpen={addCommentModalOpen}
        onClose={() => {
          setAddCommentModalOpen(false);
          form.setValue('doNotHave', false);
        }}
      />
    </Container>
  );
});
