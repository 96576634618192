import * as React from 'react';
import { AppBar as AppBarContainer, Toolbar } from '@mui/material';

interface Props {
  dataCy?: string;
  navHeight?: string;
}

export const AppBar: React.FC<Props> = (props) => {
  const navHeight = props.navHeight ? props.navHeight : '74px';
  return (
    <AppBarContainer
      position="fixed"
      sx={{
        backgroundColor: 'common.white',
        borderBottom: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxShadow: 'none',
        fontSize: '19px',
        height: navHeight
      }}
      data-cy={props.dataCy}
    >
      <Toolbar sx={{ height: '100%' }} disableGutters>
        {props.children}
      </Toolbar>
    </AppBarContainer>
  );
};
