import { DocumentUpload } from '@gcv/shared';
import { DocumentsApi } from 'api';

export class DocumentsApiMock extends DocumentsApi {
  async getDocuments(orgId: string): Promise<DocumentUpload[]> {
    return [
      {
        file_name: 'DD-routes.postman_collection.json',
        id: '13cbfb51-b856-4045-b1d9-03a99db9e215',
        org_id: 'd2cee74c-f60e-4804-8f83-9835c5313029',
        s3_key:
          'd2cee74c-f60e-4804-8f83-9835c5313029/DD-routes.postman_collection_4961381c-0d80-4d55-8e61-ba0c9ae4a7a2.json',
        upload_date: '2023-02-12T20:45:40.519Z',
        uploaded_by: '8d8cabfe-402b-4c8b-974b-1cfb17006f40'
      }
    ];
  }
}
