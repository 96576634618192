import { TaskAssociation } from '@gcv/shared';
import { FieldValues } from 'react-hook-form';

export enum TaskAssigneeType {
  GROUP = 'task_assignee_group',
  USER = 'task_assignee_user'
}

export interface TaskAssignee {
  id: string;
  name: string;
  userIds: string[];
  type: TaskAssigneeType;
}

export interface FormData extends FieldValues {
  title: string;
  assigneeId: string;
  description: string;
  associatedDispensaryId: string;
  associatedArea: string;
  dueDate: string;
}

/**
 * Data returned to modal client upon Submit.
 *
 * The value for 'associatedDispensaryId' will be null if the user chooses
 * 'No Associated Account' as the selection for the 'Account' field.
 */
export interface CreateTaskModalData {
  title: string;
  description: string;
  assignedUserIds?: string[];
  assignedGroupIds?: string[];
  assignedOrgId?: string;
  associatedArea: TaskAssociation;
  dueDate: string; // ISO-8601
  associatedDispensaryId: string | null;
}
