import { Box } from '@mui/material';
import { DocumentsApi } from 'api';
import { ReactComponent as ClipIcon } from 'assets/images/ic-clip-gray.svg';
import { environment } from 'environments/environment';
import { getFiBankStore } from 'stores/FiBankStore';
import { ContainerItem, Text } from 'ui';
import { getMimeType, openFileInNewWindow } from 'util/files.util';
import { cropInput } from 'util/format.util';

export const PreviewCustomFormAttachments: React.FC<{
  attachmentsPadding?: string;
  attachments?: { file_name: string; id: string }[];
  noLabel?: boolean;
  label?: string;
  labelColor?: 'gray' | 'black';
  orgId?: string;
}> = (props) => {
  const { attachmentsPadding, attachments, noLabel, label, labelColor, orgId } = props;

  const fiBankStore = getFiBankStore();
  const documentsApi: DocumentsApi = new DocumentsApi();

  if (!attachments?.length) {
    return null;
  }

  const openDocument = (id: string) => {
    documentsApi.getDocument(orgId ?? fiBankStore.bank.id, id).then((document) => {
      documentsApi
        .getDocumentLink(environment.storageConfig.sharedDocument, document.s3_key, 'get')
        .then((s3Url) =>
          fetch(s3Url.s3LinkPath).then(async (response) => {
            const blob = await response.blob();
            openFileInNewWindow(blob, getMimeType(document.file_name), document.file_name);
          })
        );
    });
  };

  return (
    <>
      {!noLabel && (
        <ContainerItem padding={attachmentsPadding ? attachmentsPadding : '4px 0'}>
          <Text
            sx={{
              width: '100%',
              fontWeight: '700',
              fontSize: '13px',
              lineHeight: '15px',
              color: (theme) =>
                labelColor === 'gray' ? theme.palette.text.secondary : theme.palette.text.primary
            }}
            content={label ? label : 'Attachments'}
          />
        </ContainerItem>
      )}
      <ContainerItem flex padding={noLabel ? '12px 0' : '8px 0 0 0'} align="center" flexWrap="wrap">
        {attachments?.map((file, index) => {
          return (
            <Box
              sx={{
                width: 'auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '2px'
              }}
              onClick={() => openDocument && openDocument(file.id)}
            >
              <ClipIcon width="18px" height="18px" />
              <Text
                sx={{
                  fontWeight: '400',
                  fontSize: '13px',
                  lineHeight: '140%',
                  cursor: 'pointer',
                  color: (theme) => theme.palette.secondary.main
                }}
                content={cropInput(file.file_name, 30, 'document')}
              />
              {attachments && index !== attachments?.length - 1 && (
                <Text
                  sx={{
                    fontWeight: '400',
                    fontSize: '13px',
                    lineHeight: '140%',
                    cursor: 'pointer',
                    color: (theme) => theme.palette.text.primary
                  }}
                  content=","
                />
              )}
            </Box>
          );
        })}
      </ContainerItem>
    </>
  );
};
