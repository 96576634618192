import { Box } from '@mui/material';
import { ReactComponent as FileIcon } from 'assets/images/reports-icons/ic-file.svg';
import { Dialog, Button } from 'ui';

interface ConfirmationDialogProps {
  title: string;
  message: string | JSX.Element;
  isOpen: boolean;
  handleClose: () => void;
  iconType?: string;
  secondParagraph?: any;
  contentTextAlign?: string;
  actionText?: string;
  handlePrimaryAction?: () => void;
  actionButtonColor?:
    | 'default'
    | 'default-outlined'
    | 'default-text'
    | 'text'
    | 'primary'
    | 'text'
    | 'primary-outlined'
    | 'primary-text'
    | 'secondary-text'
    | 'secondary-outlined'
    | 'warning'
    | 'warning-outlined'
    | 'icon-selected'
    | 'icon-deselected'
    | 'header-button';
  cancelAction?: boolean;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  // Hide the default title and Cancel button to reuse the dialog for a modal alert.
  return (
    <Dialog handleClose={props.handleClose} title="" isOpen={props.isOpen} noActions={true}>
      <Box sx={{ width: '100%', alignContent: 'center', marginBottom: '1rem', textAlign: 'center' }}>
        {props.iconType === 'warning' ? (
          <Box
            className="material-icons"
            sx={{ color: (theme) => theme.palette.error.main, fontSize: '60px' }}
          >
            error_outline
          </Box>
        ) : props.iconType === 'alert' ? (
          <Box
            className="material-icons"
            sx={{ color: (theme) => theme.palette.primary.main, fontSize: '60px' }}
          >
            help_outline
          </Box>
        ) : (
          <FileIcon />
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
          fontWeight: props.actionText ? '700' : '500',
          fontSize: props.iconType === 'warning' || props.actionText ? '20px' : '1.25rem',
          alignContent: 'center',
          marginBottom: props.iconType || props.actionText ? '20px ' : '1.5rem',
          textAlign: 'center'
        }}
      >
        {props.title}
      </Box>
      <Box
        sx={{
          width: '100%',
          alignContent: 'center',
          textAlign: props.contentTextAlign ?? 'center',
          marginBottom: props.secondParagraph ? '1rem' : '2rem'
        }}
      >
        {props.message}
      </Box>
      {props.secondParagraph ? (
        <Box
          sx={{
            width: '100%',
            alignContent: 'center',
            textAlign: props.contentTextAlign ?? 'center',
            marginBottom: '50px'
          }}
        >
          {props.secondParagraph}
        </Box>
      ) : null}

      <Box
        sx={{
          height: `${props.actionText ? '100%' : '58px'}`,
          width: '100%',
          alignContent: 'center',
          textAlign: 'center',
          paddingBottom: `${props.actionText ? '20px' : '0px'}`
        }}
      >
        <Button
          width={props.iconType === 'warning' || props.actionText ? '335px' : '100%'}
          fullWidth={true}
          label={props.actionText ? props.actionText : 'Okay, got it!'}
          onClick={props.handlePrimaryAction ?? props.handleClose}
          color={props.actionButtonColor ? props.actionButtonColor : 'primary'}
        />
        {props.cancelAction ? (
          <Button
            color="default-text"
            label="Cancel"
            style={{ alignSelf: 'center' }}
            onClick={props.handleClose}
          />
        ) : null}
      </Box>
    </Dialog>
  );
};
