import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { getFiBankStore } from 'stores/FiBankStore';
import { getDateRange } from 'util/dateRange.util';
import { DateTimeHelpers } from 'util/dateTime.util';

/**
 * Returns a stateful message describing the last modified timestamp.
 *
 * Argument 1: Some string variable that updates, for example:
 *
 *     model.currentTemplate.date_updated
 *
 * Argument 2 (optional): A string prepended to the message. If omitted, the message
 * string is capitalized (facilitating its use as a stand-alone phrase). If a prefix
 * is given, then, practically speaking, "yesterday" or "today" will be lowercase,
 * and the message will end with a period (to form a complete sentence). Example:
 *
 *     Today at 12:42 PM
 *     Your questionnaire was last modified today at 12:42 PM.
 *
 * The message will automatically update at the stroke of midnight.
 *
 * @param datetime a string as modifiable state denoting the last modified timestamp.
 * @param prefix a string to be prepended to the last modified message.
 * @returns a string describing the last modified timestamp.
 */
export const useLastModifiedMessage = (datetime: string, prefix?: string) => {
  const [midnightTimerFiredFlag, setMidnightTimerFiredFlag] = useState<number>();

  // Message is flexible to be as friendly as possible.
  const composeLastModifiedMessage = (date: Date) => {
    const timezone = getFiBankStore().bank.iana_timezone;
    const { start } = getDateRange('yesterday', timezone);
    const yesterdayDate = new Date(start);

    const yesterday = prefix ? 'yesterday' : 'Yesterday';
    const today = prefix ? 'today' : 'Today';

    if (!(date.valueOf() < yesterdayDate.valueOf())) {
      const day = date.getDate() === yesterdayDate.getDate() ? yesterday : today;
      return `${day} at ${DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_SIMPLE)}`;
    } else {
      const day = DateTimeHelpers.formatJsDateToFriendlyDate(date, timezone);
      return prefix ? `on ${day}` : day;
    }
  };

  // The return value of this hook updates automatically.
  const lastModifiedMessage = useMemo(() => {
    const message = composeLastModifiedMessage(new Date(datetime));
    return prefix ? `${prefix} ${message}.` : message;
  }, [datetime, midnightTimerFiredFlag]);

  // Update the until-midnight timer.
  useEffect(() => {
    const getSecondsUntilMidnight = () => {
      const now = new Date().valueOf() / 1000;
      const midnight = new Date(new Date().setHours(24, 0, 0, 0)).valueOf() / 1000;
      return Math.ceil(midnight - now);
    };

    const seconds = getSecondsUntilMidnight();

    const timer = () =>
      setTimeout(() => {
        // Signal that the timer has fired.
        setMidnightTimerFiredFlag(seconds);
      }, seconds);

    const timerId = timer();

    return () => clearTimeout(timerId);
  }, [lastModifiedMessage]);

  // Return memoized value.
  return lastModifiedMessage;
};
