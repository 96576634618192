import { Box } from '@mui/material';
import * as React from 'react';

interface Props {
  anchorRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const CommentsBarAnchor: React.FC<Props> = (props) => {
  return (
    <Box
      data-cy="comment-bar-anchor"
      ref={props.anchorRef}
      sx={{
        display: 'block',
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: '1px'
      }}
    />
  );
};
