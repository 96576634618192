import { ChangeEvent, MouseEventHandler } from 'react';
import { TableCell as MaterialTableCell, TableSortLabel as MaterialTableSortLabel } from '@mui/material';
import { Column, Row } from 'ui/organisms';
import { Icon, Tooltip } from 'ui/atoms';

interface Props<T> {
  selected: string[];
  rows: Row<T>[];
  column: Column<T>;
  columnsLength: number;
  orderBy: keyof T;
  order: 'asc' | 'desc';
  handleSelectAllClick: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
}

export const TableHeaderCell = <T,>(props: Props<T>) => {
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    props.handleSort(event, property);
  };
  return (
    <MaterialTableCell
      align={props.column.align ? props.column.align : 'left'}
      padding={'none'}
      sortDirection={props.orderBy === props.column.id ? props.order : false}
      sx={{
        width: props.column.widthPercent
          ? props.column.widthPercent + '%'
          : props.column.widthEven
          ? 100 / props.columnsLength + '%'
          : 'auto',
        height: '40px',
        p: 0,
        pl: '18px',
        color: 'text.secondary',
        // padding: '7px 18px',
        fontWeight: 'bold',
        '& .Mui-active': {
          color: 'text.primary'
        }
      }}
    >
      {props.column.noSort ? (
        props.column.label
      ) : (
        <MaterialTableSortLabel
          active={props.orderBy === props.column.id}
          direction={props.orderBy === props.column.id ? props.order : 'asc'}
          onClick={createSortHandler(props.column.id)}
          sx={{
            lineHeight: '16px',
            '& .MuiTableSortLabel-icon': {
              height: '14px'
            }
          }}
        >
          {props.column.tooltip ? (
            <>
              {props.column.label}
              &nbsp;
              <Tooltip title={props.column.tooltip}>
                <Icon type="help" color="primary" />
              </Tooltip>
            </>
          ) : (
            props.column.label
          )}
        </MaterialTableSortLabel>
      )}
    </MaterialTableCell>
  );
};
