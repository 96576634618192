import * as React from 'react';
import { MenuList } from '@mui/material';

import { FilterListChild, FilterListSectionItem, Text } from 'ui';

interface Props {
  filterList: FilterListChild[];
  onChange: (listItem: FilterListChild) => void;
  selectedList: FilterListChild[];
  selectAllState: number;
  itemStyle?: React.CSSProperties;
}

export const FilterListBasic: React.FC<Props> = (props) => {
  const getSelectedItemsFromChildren = () => {
    let counter = 0;
    props.filterList.forEach((listItem) => {
      if (listItem.selected) counter += 1;
    });
    return counter;
  };

  React.useEffect(() => {
    setSelectedItems(getSelectedItemsFromChildren());
  }, [props.filterList]);

  const [selectedItems, setSelectedItems] = React.useState(getSelectedItemsFromChildren());
  const onSelectMenuItem = (
    checked: boolean,
    setChecked: React.Dispatch<React.SetStateAction<boolean>>,
    listItem: FilterListChild
  ) => {
    props.onChange(listItem);
    listItem.selected = !listItem.selected;
    const newChecked = !checked;
    setChecked(newChecked);
    if (listItem.selected) {
      setSelectedItems(selectedItems + 1);
    } else {
      setSelectedItems(selectedItems - 1);
    }
  };

  React.useEffect(() => {
    setSelectedItems(props.selectedList.length);
  }, [props.selectedList]);

  return props.filterList?.filter((child) => child.visible !== false).length ? (
    <MenuList
      sx={{ width: '100%', maxHeight: 'calc(100vh - 350px)', overflowY: 'auto', overflowX: 'hidden' }}
    >
      {props.filterList.map((subListItem) => (
        <FilterListSectionItem
          subListItem={subListItem}
          onSelectMenuItem={onSelectMenuItem}
          selectedItems={selectedItems}
          key={subListItem.value}
          selectAllState={props.selectAllState}
          sx={props.itemStyle}
        ></FilterListSectionItem>
      ))}
    </MenuList>
  ) : (
    <Text content="No results" type="body2" sx={{ marginLeft: '5px', marginTop: '1rem' }} />
  );
};
