import React from 'react';
import { openUrlFromFile } from 'util/files.util';
import { ReactComponent as DocumentIcon } from 'assets/images/document-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash-icon.svg';
import { Box } from '@mui/material';
import { cropInput } from 'util/format.util';

interface Props {
  file: File;
  paddingNone?: boolean;
  borderNone?: boolean;
  onRemoveFile?: (file: File) => void;
  dataCy?: string;
  fullWidth?: boolean;
  width?: string;
  newFilesPadding?: string; // added this prop because, the existingFiles & newFiles are not aligned properly in Custom Field Questionnaire modal .
}

export const File: React.FC<Props> = (props) => {
  return (
    <Box
      data-cy={props.dataCy}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: props.fullWidth ? '100%' : props.width ? props.width : '420px',
        boxSizing: 'border-box',
        padding: props.newFilesPadding ? props.newFilesPadding : props.paddingNone ? '2px' : '12px', // added this prop because, the existingFiles & newFiles are not aligned properly in Custom Field Questionnaire modal .
        borderBottom: props.borderNone ? '0px' : (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: '#F2F4FA',
        borderRadius: '6px',

        '&:hover': {
          backgroundColor: 'rgba(216, 244, 231, 0.5)'
        }
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <DocumentIcon width="30px" height="22px" />
        <div
          data-cy="uploaded-file-name"
          onClick={() => openUrlFromFile(props.file)}
          style={{
            cursor: 'pointer',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '32px',
            verticalAlign: 'center',
            marginLeft: '10px'
          }}
        >
          {cropInput(props.file.name, 30, 'document')}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <TrashIcon
          data-cy="close-icon"
          height="20px"
          width="18px"
          style={{ cursor: 'pointer', marginRight: '8px' }}
          onClick={() => (props.onRemoveFile ? props.onRemoveFile(props.file) : undefined)}
        />
      </div>
    </Box>
  );
};
