import { injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';

export enum SnackbarSeverity {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning'
}

interface SnackbarObject {
  severity: SnackbarSeverity;
  message: string | JSX.Element;
  isOpen: boolean;
  disableAutoHide?: boolean;
  onClick?: () => void;
  autoHideOverrideInMilliseconds?: number;
}

@injectable()
export class SnackbarStore {
  snackbar: SnackbarObject = {
    severity: SnackbarSeverity.Info,
    message: '',
    isOpen: false
  };

  constructor() {
    makeAutoObservable(this);
  }

  hideSnackbar = action(() => {
    this.snackbar = {
      ...this.snackbar,
      message: '',
      isOpen: false
    };
  });

  showSnackbar = action(
    (
      severity: SnackbarSeverity,
      message: string | JSX.Element,
      onClick?: () => void,
      disableAutoHide?: boolean,
      autoHideOverrideInMilliseconds?: number
    ) => {
      this.snackbar = {
        severity,
        message,
        isOpen: true,
        onClick,
        disableAutoHide,
        autoHideOverrideInMilliseconds
      };
    }
  );

  clearStore = action(() => {
    this.snackbar = {
      severity: SnackbarSeverity.Info,
      message: '',
      isOpen: false,
      disableAutoHide: false
    };
  });

  /**
   * Simpler abstraction.
   */
  private snackbarActionCreator = (severity: SnackbarSeverity) =>
    action((message: string | JSX.Element) => {
      this.showSnackbar(severity, message);
    });

  showSuccessSnackbarMessage = this.snackbarActionCreator(SnackbarSeverity.Success);

  showErrorSnackbarMessage = this.snackbarActionCreator(SnackbarSeverity.Error);

  showInfoSnackbarMessage = this.snackbarActionCreator(SnackbarSeverity.Info);

  showWarningSnackbarMessage = this.snackbarActionCreator(SnackbarSeverity.Warning);
}

let snackbarStore: SnackbarStore | undefined;

export function getSnackbarStore(): SnackbarStore {
  if (!snackbarStore) {
    snackbarStore = new SnackbarStore();
  }

  return snackbarStore;
}
