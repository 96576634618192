import {
  AccountActivity as AccountActivityRes,
  AccountInternalMap,
  AccountOwnerData,
  AccountOwnerMetadata,
  ArchiveCrbRequest,
  ArchiveCrbResponse,
  AutoGenReview,
  AutoGenReviewRequest,
  Bank,
  BankDispensary,
  BankInternalTransactionCodes,
  BankInternalTransactionCodesRequest,
  BankLastIngestionInfo,
  BankLocation,
  BankOverview,
  BankPeerGroups,
  CoreTransactionUploadAction,
  DailySummary,
  Deposit,
  Dispensary,
  DispensaryAccountReview,
  DueDiligenceStatus,
  Effect,
  FincenReportTableDataResponse,
  FincenSarReport,
  FullMUO,
  FullMUOBankResponse,
  Group,
  IngestionReportResponse,
  InheritDocumentsMap,
  Invitation,
  InviteNewDispensaryForBankRequest,
  InviteNewDispensaryForBankResponse,
  InviteNewUserRequest,
  MinifiedDispensary,
  MUO,
  MuoAccountReview,
  MUOBankResponse,
  OnboardingDocumentRequirementResult,
  PayqwickUserRole,
  Post,
  SarPeriodAggregate,
  Task,
  TemplateResultId,
  TemplateResultResponse,
  UnarchiveCrbRequest,
  UnarchiveCrbResponse,
  UpdateDispensaryInternalIdResponse,
  User
} from '@gcv/shared';
import { GroupPermissions, UserPermissions } from 'domain/types/permissions';
import { injectable } from 'inversify';
import { DepositTableExtended } from 'ui/apps/fi/deposits/deposits.repo';
import { api, banksApi } from './api-util/api';

@injectable()
export class BanksApi {
  async getBankById(bankId: string): Promise<Bank> {
    return await api().get(`/banks/${bankId}`);
  }

  async getBanks(): Promise<Bank[]> {
    return await api().get('/banks');
  }

  async getBankStaff(bankId: string): Promise<User[]> {
    return await api().get(`/organizations/${bankId}/users`);
  }

  async inviteBankUser(bankId: string, data: InviteNewUserRequest): Promise<{ org: Bank; user: User }> {
    return await api().post(`/banks/${bankId}/invite-user`, data);
  }

  async getBankOverview(bankId: string, startDate: string, endDate: string): Promise<BankOverview> {
    return await api().get(`/banks/${bankId}/overview`, {
      startDate,
      endDate
    });
  }

  async getBankDispensary(bankId: string, dispId: string): Promise<MUO | BankDispensary> {
    return await api().get(`/banks/${bankId}/dispensaries/${dispId}`);
  }

  async getMinifiedDispensaries(bankId: string): Promise<MinifiedDispensary[]> {
    return await api().get(`/banks/${bankId}/minified-dispensaries`);
  }

  async getInvitedDispensaries(bankId: string): Promise<Invitation[]> {
    return await api().get(`/banks/${bankId}/invited-crbs`);
  }

  async getDailySummaries(bankId: string, startDate: string, endDate: string): Promise<DailySummary[]> {
    return await api().get(`/banks/${bankId}/dailySummaryTable`, { startDate, endDate });
  }

  async upsertCustomFields(bankId: string, template_json: string): Promise<Bank> {
    return await api().post(`/banks/${bankId}/custom-fields`, { template_json });
  }

  async createBank(orgName: string, state: string, country: string, dispensaries: string[]): Promise<Bank> {
    return await api().post('/banks', { orgName, state, country, dispensaries });
  }

  async assignDispensaryToBank(bankId: string, dispId: string, templateId: string): Promise<Bank> {
    return await api().put(`/banks/${bankId}/dispensaries/${dispId}`, { template_id: templateId });
  }

  async addDispensaryToBank(
    bankId: string,
    body: InviteNewDispensaryForBankRequest
  ): Promise<InviteNewDispensaryForBankResponse | { data: any }> {
    return await api().post(`/banks/${bankId}/dispensaries`, body);
  }

  async removeDispensaryFromBank(bankId: string, dispId: string): Promise<Bank> {
    return await api().delete(`/banks/${bankId}/dispensaries/${dispId}`);
  }

  async updateBankGroups(orgId: string, groups: Group[]): Promise<Bank> {
    return await api().put(`/groups/${orgId}`, groups);
  }

  async upsertBankTheme(bankId: string, theme: { color?: string; logo_s3_key?: string }) {
    return await api().post(`/banks/${bankId}/theme`, { theme });
  }

  async getBankInternalCodes(bankId: string): Promise<BankInternalTransactionCodes> {
    return await api().get(`/banks/${bankId}/internal-transaction-codes`);
  }

  async getAccountActivity(
    bankId: string,
    dispId: string,
    startDate: string,
    endDate: string,
    accountNumbers: string[],
    locationIds: string[],
    batchSize: string
  ): Promise<AccountActivityRes> {
    return await api().post(
      `/banks/${bankId}/dispensaries/${dispId}/activity?startDate=${startDate}&endDate=${endDate}&batchSize=${batchSize}`,
      { accountNumbers, locationIds }
    );
  }

  async getBankPeerGroups(bankId: string): Promise<BankPeerGroups> {
    return await api().get(`/banks/${bankId}/peer-groups`);
  }

  async createBankPeerGroup(bankId: string, body: Record<string, any>): Promise<BankPeerGroups> {
    return await api().post(`/banks/${bankId}/peer-groups`, body);
  }

  async updatePeergroupDispensaries(
    bankId: string,
    peerGroupId: string,
    body: Record<string, any>
  ): Promise<BankPeerGroups> {
    return await api().post(`/banks/${bankId}/peer-groups/${peerGroupId}/dispensaries`, body);
  }

  async updateBankPeerGroup(
    bankId: string,
    peerGroupId: string,
    body: Record<string, any>
  ): Promise<BankPeerGroups> {
    return await api().put(`/banks/${bankId}/peer-groups/${peerGroupId}`, body);
  }

  async addDispensaryToPeerGroup(
    bankId: string,
    peerGroupId: string,
    dispensaryId: string
  ): Promise<BankPeerGroups> {
    return await api().post(`/banks/${bankId}/peer-groups/${peerGroupId}/dispensary/${dispensaryId}`, {});
  }

  async updateBankOrganizationalDetails(
    bankId: string,
    {
      /**
       * Organization
       */
      orgName,
      certificateOrCharterNumber,
      rssdNumber,
      primaryReg,
      /**
       * Contact Information
       */
      phoneNumber,
      website,
      /**
       * Hours of Operation
       */
      businessHours,
      /**
       * Main Office
       */
      address,
      city,
      state,
      postalCode,
      country,
      /**
       * Regulatory Reporting Organization
       */
      transmitter_control_code,
      regulatorContactOffice,
      ein,
      primaryRegulatorPhone
    }: Pick<
      Bank,
      | 'orgName'
      | 'certificateOrCharterNumber'
      | 'rssdNumber'
      | 'primaryReg'
      | 'phoneNumber'
      | 'website'
      | 'businessHours'
      | 'address'
      | 'city'
      | 'state'
      | 'postalCode'
      | 'country'
      | 'transmitter_control_code'
      | 'ein'
      | 'regulatorContactOffice'
      | 'primaryRegulatorPhone'
    >
  ) {
    /**
     * Clearing just a few props so as to not allow all values to be send.
     */
    return await api().put(`/banks/${bankId}/organizational-details`, {
      orgName,
      certificateOrCharterNumber,
      rssdNumber,
      primaryReg,

      phoneNumber,
      website,

      businessHours,

      address,
      city,
      state,
      postalCode,
      country,

      regulatorContactOffice,
      ein,
      primaryRegulatorPhone,
      transmitter_control_code
    });
  }

  async updateBankCannabisProgram(
    bankId: string,
    data: { primary_point_of_contact: Exclude<Bank['primary_point_of_contact'], undefined> }
  ) {
    return await api().put(`/banks/${bankId}/cannabis-program`, data);
  }

  async addTransportVendor(bankId: string, body: Record<string, any>) {
    return await api().post(`/banks/${bankId}/transportVendor`, body);
  }

  async approveOperationalDetails(bankId: string, dispensaryId: string, body: Record<string, any>) {
    return await api().post(`/banks/${bankId}/transportVendor/${dispensaryId}`, body);
  }

  async updateOperationalDetails(
    bankId: string,
    dispensaryId: string,
    data: {
      businessHours?: any;
      monthlySales?: number;
      monthlyCustomers?: string;
      pos_name?: string;
      ftEmployees?: string;
      ptEmployees?: string;
      business_type?: 'retail' | 'wholesale';
    }
  ): Promise<Dispensary> {
    return await api().put(`/banks/${bankId}/crb/${dispensaryId}/operational-details`, data);
  }

  async getMuo(bankId: string): Promise<MUOBankResponse[]> {
    return await api().get(`/banks/${bankId}/muo`);
  }

  async addLocation(
    bankId: string,
    body: InviteNewDispensaryForBankRequest
  ): Promise<InviteNewDispensaryForBankResponse> {
    return await api().post(`/banks/${bankId}/dispensaries`, body);
  }

  async switchDDTemplate(
    bankId: string,
    crbId: string,
    template_id: string,
    inheritDocumentsMap: InheritDocumentsMap,
    status: DueDiligenceStatus
  ): Promise<MinifiedDispensary> {
    return await api().put(`/banks/${bankId}/crbs/${crbId}/switch-onboarding-template`, {
      template_id,
      inheritDocumentsMap,
      status
    });
  }

  async getFincenReports(bankId: string): Promise<FincenReportTableDataResponse> {
    return await api().get(`/banks/${bankId}/fincen-reports`);
  }

  async getUpcomingFincenReports(bankId: string): Promise<SarPeriodAggregate[]> {
    return await api().get(`/fincen-reports/sar-periods/${bankId}`);
  }

  async updateDispensaryNickname(bankId: string, dispId: string, nickname: string): Promise<any> {
    return await api().put(`/banks/${bankId}/dispensaries/${dispId}/nickname`, { nickname });
  }

  async updateBusinessDetails(bankId: string, dispId: string, body: Record<string, any>): Promise<any> {
    return await api().put(`/banks/${bankId}/dispensaries/${dispId}/business-details`, body);
  }

  async createLocation(
    bankId: string,
    dispId: string,
    body: Record<string, any>
  ): Promise<FullMUOBankResponse> {
    return await api().put(`/banks/${bankId}/dispensaries/${dispId}/convert-to-muo`, body);
  }

  async removeLocationFromParent(
    bankId: string,
    muoId: string,
    dispId: string
  ): Promise<FullMUOBankResponse> {
    return await api().delete(`/banks/${bankId}/muo/${muoId}/locations/${dispId}`);
  }

  async getDispensaryUpcomingReports(
    bankId: string,
    crbId: string
  ): Promise<{
    finCenReports: Pick<FincenSarReport, 'id' | 'due_date'>[];
    accountMonitoringReviews: Pick<Task, 'id' | 'process_date'>[];
  }> {
    return await api().get(`/banks/${bankId}/crb/${crbId}/upcoming-reports`);
  }

  async archiveMuo(bankId: string, muoId: string): Promise<FullMUOBankResponse> {
    return await api().delete(`/banks/${bankId}/muo/${muoId}`);
  }

  async archiveCrb(bankId: string, crbId: string, request: ArchiveCrbRequest): Promise<ArchiveCrbResponse> {
    return await api().put(`/banks/${bankId}/crb/${crbId}/archive`, request);
  }

  async unarchiveCrb(
    bankId: string,
    crbId: string,
    data: UnarchiveCrbRequest
  ): Promise<UnarchiveCrbResponse> {
    return await api().put(`/banks/${bankId}/crb/${crbId}/unarchive`, data);
  }

  async setToAwaitingReview(
    bankId: string,
    dispId: string,
    templateResultId: TemplateResultId
  ): Promise<FullMUO> {
    return await api().put(
      `/banks/${bankId}/dispensaries/${dispId}/template-results/${templateResultId}/set-awaiting-review`,
      {}
    );
  }

  async associateParent(
    bankId: string,
    muoId: string,
    body: { dispensary_id: string; merge_autogen_reviews: boolean }
  ): Promise<FullMUO> {
    return await api().post(`/banks/${bankId}/muo/${muoId}/locations`, body);
  }

  async createMuoReview(
    bankId: string,
    muoId: string,
    body: Record<string, any>,
    v2?: boolean
  ): Promise<MuoAccountReview> {
    return await api().post(`/banks/${bankId}/muo/${muoId}/review?v2=${!!v2}`, body);
  }

  async createDispensaryReview(
    bankId: string,
    dispId: string,
    body: Record<string, any>,
    foreground: boolean,
    v2?: boolean
  ): Promise<Partial<DispensaryAccountReview>> {
    return await api().post(
      `/banks/${bankId}/dispensaries/${dispId}/review?v2=${!!v2}&foreground=${foreground}`,
      body
    );
  }

  async addDefaultTransportVendor(bankId: string, vendorId: string, body: Record<string, any>): Promise<any> {
    return await api().put(`/banks/${bankId}/transportVendor/${vendorId}/dispensaries`, body);
  }

  async setFavoriteTransportVendor(
    bankId: string,
    vendorId: string,
    dispensaryId: string
  ): Promise<BankDispensary> {
    return await api().put(`/banks/${bankId}/transportVendor/${vendorId}/dispensaries/${dispensaryId}`, {});
  }

  async removeFavoriteFromTransportVendor(
    bankId: string,
    vendorId: string,
    dispensaryId: string
  ): Promise<BankDispensary> {
    return await api().delete(`/banks/${bankId}/transportVendor/${vendorId}/dispensaries/${dispensaryId}`);
  }

  async updateTransportVendor(bankId: string, vendorId: string, body: Record<string, any>): Promise<any> {
    return await api().put(`/banks/${bankId}/transportVendor/${vendorId}`, body);
  }

  async getDepositsTable(
    bankId: string,
    startDate: string,
    endDate: string,
    filterType?: string
  ): Promise<DepositTableExtended[]> {
    filterType = filterType || 'date_created';
    return await api().get(
      `/banks/${bankId}/depositsTable?startDate=${startDate}&endDate=${endDate}&filter=${filterType}`
    );
  }

  async upsertBankTransactionCodes(bankId: string, codes: BankInternalTransactionCodesRequest) {
    return await api().post(`/banks/${bankId}/internal-transaction-codes`, { internal_codes: codes });
  }

  async getDepositById(bankId: string, depositId: string): Promise<Deposit> {
    return await api().get(`/banks/${bankId}/deposits/${depositId}`);
  }

  async reconcileDeposit(bankId: string, deposit: Deposit): Promise<Deposit> {
    return await api().post(
      `/banks/${bankId}/dispensaries/${deposit.dispensary_id}/deposits/${deposit.deposit_id}/accept`,
      deposit
    );
  }

  async editDepositStatus(bankId: string, deposit: Deposit, status: string, date: string): Promise<Deposit> {
    status = status === 'under_review' ? 'under-review' : 'pending';
    return await api().post(
      `/banks/${bankId}/dispensaries/${deposit.dispensary_id}/deposits/${deposit.deposit_id}/${status}`,
      {
        reviewStartDate: date
      }
    );
  }

  async completeCoreTransactionUpload(
    bankId: string,
    key: string,
    bucket: string,
    action: CoreTransactionUploadAction
  ): Promise<Deposit> {
    return await api().post(`/banks/${bankId}/complete-core-transaction-upload`, { key, bucket, action });
  }

  async deleteBank(bankId: string): Promise<null> {
    return await api().delete(`/banks/${bankId}`);
  }

  /* Old endpoint for single ID */
  async updateDispensaryInternalId(
    bankId: string,
    dispensaryId: string,
    internalId: string,
    dryRun: boolean
  ): Promise<UpdateDispensaryInternalIdResponse> {
    return await api().put(
      `/banks/${bankId}/dispensary/${dispensaryId}/internalId`,
      {
        internal_id: internalId
      },
      { dryRun }
    );
  }

  /* New endpoint for multiple ID's */
  async updateDispensaryBankAccounts(
    bankId: string,
    dispensaryId: string,
    accounts: AccountInternalMap,
    dryRun: boolean
  ): Promise<UpdateDispensaryInternalIdResponse> {
    return await api().put(
      `/banks/${bankId}/dispensary/${dispensaryId}/internalId`,
      {
        accounts: accounts
      },
      { dryRun }
    );
  }

  async renameDocumentFriendlyName(
    bankId: string,
    crbId: string,
    templateId: string,
    requirementId: string,
    body: Record<string, any>
  ): Promise<OnboardingDocumentRequirementResult> {
    return await api().put(
      `/banks/${bankId}/crbs/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/friendly-filenames`,
      body
    );
  }

  async addLocations(bankId: string, locations: BankLocation[]): Promise<Bank> {
    return await api().put(`/banks/${bankId}/add-locations`, locations);
  }

  async updateAutoGenReviews(
    bankId: string,
    dispensaryId: string,
    auto_gen_reviews: AutoGenReviewRequest[]
  ): Promise<AutoGenReview[]> {
    return await api().put(`/banks/${bankId}/dispensaries/${dispensaryId}/autoGenReviews`, {
      auto_gen_reviews: auto_gen_reviews
    });
  }

  async getDispensaryLastIngestion(bankID: string, crbID: string): Promise<BankLastIngestionInfo> {
    return await api().get(`/banks/${bankID}/dispensaries/${crbID}/lastSync`);
  }

  async getAutoGenReviews(bankId: string, dispensaryId: string): Promise<AutoGenReview[]> {
    return await api().get(`/banks/${bankId}/dispensaries/${dispensaryId}/autoGenReviews`);
  }

  async deleteAutoGenReview(bankId: string, dispensaryId: string, scheduleId: string): Promise<void> {
    await api().delete(`/banks/${bankId}/dispensaries/${dispensaryId}/schedule/${scheduleId}/autoGenReviews`);
  }

  async getDispensaryIngestionData(
    bankID: string,
    dispId: string,
    startDate: string,
    endDate: string
  ): Promise<IngestionReportResponse[]> {
    return await api().get(
      `/banks/${bankID}/dispensaries/${dispId}/ingestion-reports?startDate=${startDate}&endDate=${endDate}`
    );
  }

  async getPostForBank(bankId: string, postId: string): Promise<Post> {
    return await api().get(`/banks/${bankId}/post/${postId}`);
  }

  /**
   * Approve a CRB user and grants access to PQ. Use must not already be created in PQ and must have a role defined
   * @param bankId Bank ID
   * @param userId ID of the CRB user to approve in PQ
   * @param eid Entity ID
   * @returns Updated User record
   */
  async approvePayqwickUser(bankId: string, userId: string, eid: string): Promise<User> {
    return await banksApi().post(`/banks/${bankId}/users/${userId}/approve-payqwick`, { eid });
  }

  /**
   * Approve a CRB user and grants access to PQ. Can be used to add user to additional orgs
   * @param bankId Bank ID
   * @param userId ID of the CRB user to approve in PQ
   * @param eid Entity ID
   * @returns Updated User record
   */
  async grantPayqwickAccess(
    bankId: string,
    crbId: string,
    userId: string,
    eid: string,
    role: PayqwickUserRole
  ): Promise<User> {
    return await api().post(`/banks/${bankId}/crb/${crbId}/user/${userId}/grant-pq-access`, {
      eid,
      role
    });
  }

  async getCrbOnboardingApplicationSnapshotPdf(bankId: string, crbId: string): Promise<{ s3Link: string }> {
    return await api().get(`/banks/${bankId}/crb/${crbId}/application-snapshot-pdf`);
  }

  async getCrbOnboardingLiveApplicationSnapshotPdf(
    bankId: string,
    crbId: string
  ): Promise<{ s3Link: string }> {
    return await api().get(`/banks/${bankId}/crb/${crbId}/live-application-snapshot-pdf`);
  }

  async reingestDispensarySales(
    bankId: string,
    crbId: string,
    dates: { start_date: string; end_date: string }
  ): Promise<void> {
    return await api().post(`/banks/${bankId}/crb/${crbId}/ingest-sales`, dates);
  }

  async getAccountOwnerMetadata(bankId: string): Promise<AccountOwnerMetadata> {
    return await api().get(`/banks/${bankId}/metadata/account-owners`);
  }

  async updateAccountOwnerMetadata(bankId: string, data: AccountOwnerData): Promise<AccountOwnerMetadata> {
    return await api().put(`/banks/${bankId}/metadata/account-owners`, data);
  }

  async getUsersWithPermissions(bankId: string): Promise<UserPermissions> {
    return await api().get(`/banks/${bankId}/users/permissions`);
  }

  async getGroupsWithPermissions(bankId: string): Promise<GroupPermissions> {
    return await api().get(`/banks/${bankId}/groups/permissions`);
  }

  async getArchiveUserSideEffects(bankId: string, userIds: string[]): Promise<Effect[]> {
    return await banksApi().post(`/banks/${bankId}/effects/archive-user/query`, { userIds });
  }

  async executeArchiveUserSideEffects(
    bankId: string,
    userIds: string[],
    sideEffects: Effect[]
  ): Promise<void> {
    return await banksApi().post(`/banks/${bankId}/effects/archive-user/execute`, { userIds, sideEffects });
  }

  async getUpdateGroupSideEffects(bankId: string, groupId: string): Promise<Effect[]> {
    return await banksApi().post(`/banks/${bankId}/effects/update-group/query`, { groupId });
  }

  async executeUpdateGroupSideEffects(bankId: string, groupId: string, sideEffects: Effect[]): Promise<void> {
    return await banksApi().post(`/banks/${bankId}/effects/update-group/execute`, { groupId, sideEffects });
  }

  async moveDocument(
    bankId: string,
    crbId: string,
    documentId: string,
    moveFromRequirementId: string,
    moveToRequirementId: string,
    otherLocation?: 'due_diligence_template' | 'questionnaire_template'
  ): Promise<TemplateResultResponse> {
    return await banksApi().post(`/banks/${bankId}/crb/${crbId}/move-documents`, {
      documentId,
      moveFromRequirementId,
      moveToRequirementId,
      otherLocation
    });
  }

  async getAccountOwnerLastUpdatedInfo(bankId: string): Promise<{
    [appAreaName: string]: { updatedBy: string; updatedDate: string };
  }> {
    return await banksApi().get(`/banks/${bankId}/account-owners/last-updated`);
  }

  async getBankSigmaUrl(bankId: string): Promise<{ url: string }> {
    return await banksApi().get(`/banks/${bankId}/generate-sigma-url`);
  }

  async resendInviteEmail(bankId: string, inviteId: string): Promise<{ url: string }> {
    return await banksApi().post(`/banks/${bankId}/resend-invitation`, { invitationId: inviteId });
  }
}
