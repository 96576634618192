import { CommentType } from '@gcv/shared';
import { useMediaQuery, useTheme } from '@mui/material';
import { useComponent } from 'hooks';
import * as React from 'react';
import { Button, Card, Container, ContainerItem, OnBoardingPage, OverlaySpinner, Page, Spinner } from 'ui';
import { getCommentStore } from 'stores/CommentStore';
import { AccountOwnerInformationCard } from './components/account-owner-information-card';
import { AccountOwnersTable } from './components/account-owners-table';
import { AddAccountOwnerModal } from './components/add-account-owner-modal';
import { useInjection } from 'ioc';
import { OnboardingPresenter } from '../../onboarding.presenter';

interface Props extends Record<string, unknown> {
  onNext: () => void;
  onBack: () => void;
  stepNumber: number;
  totalSteps: number;
}

export const AccountOwners: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const commentStore = getCommentStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const subtitle = presenter.viewModel.provider.id
    ? `Let's get started with ${presenter.viewModel.provider.orgName}. We'll guide you through the application process.`
    : "Let's get started. We'll guide you through the application process.";

  React.useEffect(() => {
    commentStore.setCurrentPost({
      type: CommentType.ACCOUNT,
      title: presenter.viewModel.dispensary.name,
      idComponents: {
        orgId: presenter.viewModel.dispensary.id,
        crbId: presenter.viewModel.dispensary.id,
        fiId: presenter.viewModel.provider.id
      }
    });
  }, []);

  if (presenter.viewModel.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {presenter.viewModel.isLoadingAccountOwner ? <OverlaySpinner /> : null}
      <OnBoardingPage
        alignBottomActions="center"
        bottomActions={
          <Button
            label="Continue"
            color="primary"
            onClick={() => {
              presenter.saveAccountOwners(props.onNext);
            }}
            isLoading={presenter.viewModel.isLoading}
            dataCy="save-button"
          />
        }
        dataCy="account-owners-page"
        subtitle={subtitle}
        title={` Hello there, ${presenter.viewModel.user?.firstName}`}
        totalSteps={props.totalSteps}
        step={props.stepNumber}
        bgCard
      >
        <Container column={isMobile} padding={0}>
          <ContainerItem padding="0">
            <AccountOwnerInformationCard />
          </ContainerItem>

          {presenter.viewModel.invitedUsers.length ? (
            <ContainerItem flex column padding="12px 0 0 0" overflow="auto" width="100%">
              <AccountOwnersTable />
            </ContainerItem>
          ) : null}
        </Container>

        <AddAccountOwnerModal />
      </OnBoardingPage>
    </>
  );
});
