import { CustomField } from '@gcv/shared';
import { useMediaQuery, useTheme } from '@mui/material';

import { Container, ContainerItem, Text } from 'ui';
import { PreviewCustomFormAttachments } from './preview-custom-form-attachments';

export const PreviewCustomFormDisplayContentType: React.FC<{
  field: CustomField;
  attachmentsPadding?: string;
  orgId: string;
}> = (props) => {
  const { field, attachmentsPadding, orgId } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container padding="8px 0 0 0" column>
      <ContainerItem padding="2px 0 8px 0">
        <Text sx={{ fontWeight: '700', fontSize: '16px', lineHeight: '19px' }} content={field.label} />
      </ContainerItem>
      <ContainerItem padding="8px 0">
        <Text
          sx={{
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            whiteSpace: 'pre-wrap',
            width: isMobile ? '100%' : '580px'
          }}
          content={field.paragraph}
        />
      </ContainerItem>
      <PreviewCustomFormAttachments
        attachments={field.attachments}
        attachmentsPadding={attachmentsPadding}
        orgId={orgId}
      />
    </Container>
  );
};
