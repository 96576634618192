import { TableCell as MaterialTableCell } from '@mui/material';
import { Column } from 'ui/organisms';
import { Tooltip } from '../Tooltip/tooltip.atom';

interface Row<T> {
  id: string;
  data: T;
  disabled?: boolean;
  disabledTooltip?: string;
}

interface Props<T> {
  row: Row<T>;
  column: Column<T>;
  handleClick: (event: React.MouseEvent<unknown>, id: string, data: T) => void;
  index: number;
  isLastItem: boolean;
  dataCy?: string;
}

export const TableCell = <T,>(props: Props<T>) => {
  return (
    <MaterialTableCell
      align={props.column.align}
      key={props.column.id + '-' + props.index}
      onClick={(event) => props.handleClick(event, props.row.id, props.row.data)}
      sx={{
        padding: props.column.disablePadding ? '0' : '9px 18px',
        width: props.column.widthPercent ? `${props.column.widthPercent}%` : null,
        borderBottom: (theme) => (props.isLastItem ? 'none' : `0.016rem solid ${theme.palette.divider}`),
        lineHeight: '18.2px',
        fontSize: '13px',
        wordWrap: 'break-word'
      }}
      data-cy="table-cell"
    >
      <Tooltip
        placement={'top'}
        title={props.row.disabledTooltip || ''}
        disabled={!props.row.disabled || props.column.disableRowTooltip}
        display="block"
      >
        {props.column.format ? props.column.format(props.row.data) : props.row.data[props.column.id]}
      </Tooltip>
    </MaterialTableCell>
  );
};
