import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';

interface Props {
  activeSlide: number;
  dataCy?: string;
}

export const Carousel: React.FC<Props> = (props) => {
  return (
    <SwipeableViews data-cy={props.dataCy} index={props.activeSlide} style={{ minHeight: '1px', flex: '1' }}>
      {props.children}
    </SwipeableViews>
  );
};
