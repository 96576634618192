export default {
  styleOverrides: {
    root: {
      borderRadius: '0'
    },
    elevation1: {
      boxShadow: 'none'
    }
  }
};
