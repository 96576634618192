import { Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import * as React from 'react';

interface Props {
  content: string | undefined;
  startIcon?: React.ReactNode;
  dataCy?: string;
  inline?: boolean;
  type?: 'body1' | 'body2' | 'body3' | 'body4';
  sx?: SxProps<Theme>;
  textOverflow?: 'ellipsis' | 'clip' | 'unset';
}

export const Text: React.FC<Props> = (props) => {
  const component = props.inline ? 'span' : 'div';
  // material ui doesn't have a body3 variant so we add one to match designs
  const variant = props.type && props.type !== 'body3' && props.type !== 'body4' ? props.type : 'body1';
  const overflow = props.textOverflow ? 'hidden' : 'unset';
  const sx =
    props.type === 'body3'
      ? {
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '13px',
          lineHeight: '140%'
        }
      : props.type === 'body4'
      ? {
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '10px',
          lineHeight: '140%'
        }
      : {};

  return (
    <Typography
      component={component}
      data-cy={props.dataCy}
      variant={variant}
      overflow={overflow}
      sx={{ ...sx, ...props.sx }}
    >
      <span>{props.startIcon ? props.startIcon : null}</span>
      {props.content}
    </Typography>
  );
};
