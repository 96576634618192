import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useRollbarPerson } from '@rollbar/react';

import { getUserStore } from '../../stores/UserStore';
import { getUserType } from 'ui/routing/router-util';
import { RouteProps } from './router';
import { updateLogRocketConfig } from 'third-party-integrations/log-rocket';

export const GcvRoute = ({ component: Component, path, exact }: RouteProps) => {
  let isGcvuser = false;
  let role = '';

  const userStore = getUserStore();

  if (userStore.isLoaded) {
    role = getUserType(userStore.user);

    if (role === 'gcv') {
      isGcvuser = true;
    }

    useRollbarPerson({
      id: userStore.user?.id,
      name: userStore.user?.firstName + ' ' + userStore?.user?.lastName,
      email: userStore.user?.email
    });
    updateLogRocketConfig(userStore.user);
  }

  return isGcvuser ? (
    <Route exact={exact} path={path} component={Component} />
  ) : (
    <Redirect to={{ pathname: '/' }} />
  );
};
