import { Box } from '@mui/system';
import * as React from 'react';
import { Container, ContainerItem, Text, Title } from 'ui/atoms';
import { theme } from 'ui/theme';

interface Props {
  align?: 'flex-start' | 'center' | 'flex-end';
  icon?: string;
  justify?: 'left' | 'center' | 'right';
  title: string | React.ReactNode;
  subtitle?: string;
  subHeader?: string;
  actions?: React.ReactNode;
  type?: 'h1' | 'h2';
  nowrap?: boolean;
  bottomBorder?: boolean;
  titlePadding?: string;
}

export const CardHeader: React.FC<Props> = (props) => {
  return (
    <Box
      className="card-header"
      sx={{
        marginBottom: !props.title && !props.subtitle && props.actions ? '-3.625rem' : 0,
        zIndex: 15,
        borderBottom: props.bottomBorder ? '1px solid #C6CCDA' : null,
        paddingBottom: props.bottomBorder ? '1.625rem' : null
      }}
    >
      <Container
        dataCy="card-header"
        padding={props.titlePadding ? props.titlePadding : '1.625rem 24px 0 24px'}
        width="100%"
        align="center"
        nowrap={props.nowrap}
        justify="space-between"
      >
        <Container column padding={0}>
          <ContainerItem column flex grow align={props.align} justify={props.justify} padding={0}>
            {typeof props.title === 'string' ? (
              <Title content={props.title} dataCy="card-title" icon={props.icon} type={props.type || 'h2'} />
            ) : (
              props.title
            )}
          </ContainerItem>
          {props.subHeader && (
            <ContainerItem grow align={props.align} justify={props.justify} padding={'5px 25px   0 0'}>
              <Text
                content={props.subHeader}
                sx={{
                  color: props.type === 'h1' ? theme.palette.text.secondary : theme.palette.text.primary
                }}
                type={props.type === 'h1' ? 'body1' : 'body2'}
              />
            </ContainerItem>
          )}
        </Container>

        {props.actions && <ContainerItem padding={0}>{props.actions}</ContainerItem>}
      </Container>

      {props.subtitle && (
        <Container dataCy="card-subheader" padding="0rem 24px 0 24px" width="100%">
          <ContainerItem grow align={props.align} justify={props.justify} padding={0}>
            <Text
              content={props.subtitle}
              dataCy="card-subtitle"
              sx={{ color: props.type === 'h1' ? theme.palette.text.secondary : theme.palette.text.primary }}
              type={props.type === 'h1' ? 'body1' : 'body2'}
            />
          </ContainerItem>
        </Container>
      )}
    </Box>
  );
};
