import { CommentType, DocumentUpload } from '@gcv/shared';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useAdditionalInfo, useComponent } from 'hooks';
import { useInjection } from 'ioc';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { getCommentStore } from 'stores/CommentStore';
import { Button, Container, ContainerItem, OnBoardingPage, Spinner, ZeroState } from 'ui';
import { AdditionalInfoGenerator } from 'ui/organisms/AdditionalInfoGenerator/additional-info-generator.organism';
import { OnboardingPresenter } from '../../onboarding.presenter';
import { useParams } from 'react-router-dom';

interface Props extends Record<string, unknown> {
  isLastStep: boolean;
  onBack: () => void;
  onNext: () => void;
  stepNumber: number;
  totalSteps: number;
}

export const AdditionalInformation: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const commentStore = getCommentStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useForm({ mode: 'onChange' });
  const {
    addExistingFilesToMap,
    onFilesChange,
    existingFilesIdsMap,
    setExistingFilesIdsMap,
    refreshFileComponent
  } = useAdditionalInfo(form, presenter.viewModel.allDocumentsMap);
  const { template_id, template_result_id, bank_id } =
    useParams<{ template_id: string; template_result_id: string; bank_id: string }>();

  const subtitle = presenter.viewModel.provider.orgName || 'Your service provider';
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    commentStore.setCurrentPost({
      type: CommentType.ACCOUNT,
      title: presenter.viewModel.dispensary.name,
      idComponents: {
        orgId: presenter.viewModel.dispensary.id,
        crbId: presenter.viewModel.dispensary.id,
        fiId: presenter.viewModel.provider.id
      }
    });
  }, []);

  React.useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      await presenter.loadStore(template_id, template_result_id, bank_id);
      form.reset(presenter.getDefaultValues());
      setIsLoading(false);
    };

    load();
  }, [template_id, template_result_id, bank_id]);

  // upload new files to s3 and submit form data
  const onNewFilesChange = async (fieldId: string, files: File[]) => {
    onFilesChange(presenter.viewModel.dispensary.id, fieldId, files, () => {
      handleOnBlur(fieldId);
    });
  };

  const save = async (data: FieldValues) => {
    if (Object.keys(form.formState.errors).length === 0) {
      setIsLoading(true);
      const customFields = { responses: data };
      presenter.submitAdditionalInformation(
        customFields,
        props.isLastStep,
        props.onNext,
        template_id,
        template_result_id,
        bank_id
      );
      setIsLoading(false);
    }
  };

  const findUpdatedFields = (fields: FieldValues): string[] => {
    const candidates = Object.keys(fields).filter((k) => {
      if (fields[k] === 0) {
        return true;
      }
      return !!fields[k];
    });

    return candidates;
  };

  const fieldToSectionMap = React.useMemo(() => {
    if (!presenter.viewModel.onboardingTemplate.custom_requirements) {
      return {};
    } else {
      const map = {} as Record<string, any>;

      Object.values(presenter.viewModel.onboardingTemplate.custom_requirements)
        .filter((s) => !s.archived)
        .forEach((requirement) => {
          requirement.custom_section.fields.forEach((field) => {
            map[field.id] = requirement.custom_section.id;
          });
        });

      return map;
    }
  }, [presenter.viewModel.onboardingTemplate.custom_requirements]);

  const fieldToTypeMap = React.useMemo(() => {
    if (!presenter.viewModel.onboardingTemplate.custom_requirements) {
      return {};
    } else {
      const map = {} as Record<string, any>;

      Object.values(presenter.viewModel.onboardingTemplate.custom_requirements)
        .filter((s) => !s.archived)
        .forEach((requirement) => {
          requirement.custom_section.fields.forEach((field) => {
            map[field.id] = field.type;
          });
        });

      return map;
    }
  }, [fieldToSectionMap]);

  const handleOnBlur = async (fileUploadFieldId?: string) => {
    setIsSaving(true);
    const customFieldValues = form.getValues() as FieldValues;

    if (typeof fileUploadFieldId !== 'string') {
      fileUploadFieldId = undefined;
    }

    const fieldKeys = fileUploadFieldId ? [fileUploadFieldId] : findUpdatedFields(customFieldValues);
    const sectionIds = Array.from(new Set(fieldKeys.map((fieldKey) => fieldToSectionMap[fieldKey])));

    if (
      sectionIds.length &&
      presenter.viewModel.onboardingTemplate &&
      Object.keys(fieldToSectionMap).length > 0
    ) {
      fieldKeys.forEach((fieldKey) => {
        if (fieldToTypeMap[fieldKey] === 'checkbox') {
          form.trigger(fieldKey);
        }
      });

      const sectionUpdateRequests = sectionIds.map((sectionId) => {
        const fieldMap = {} as Record<string, any>;

        const fieldEntries = Object.entries(customFieldValues);

        if (fieldEntries) {
          fieldEntries.forEach(([k, v]) => {
            if (sectionId === fieldToSectionMap[k]) {
              fieldMap[k] = v;
            }
          });
        }

        const customFields = { responses: fieldMap };
        return () => presenter.autoSaveAdditionalInfo(customFields, sectionId);
      });

      // long forms can have many sections and the api cannot handle too many requests at once
      // so we send 2 requests at a time to avoid hitting the api rate limit and getting failed requests
      while (sectionUpdateRequests.length > 0) {
        await Promise.all(sectionUpdateRequests.splice(0, 2).map((req) => req()));
      }

      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <OnBoardingPage
      alignBottomActions="center"
      title="Additional Information"
      subtitle={`You’re almost done! Below are just a few more questions that ${subtitle} needs you to answer as part of your application.`}
      step={props.stepNumber}
      totalSteps={props.totalSteps}
      bgCard
      bottomActions={
        <Container padding={0}>
          <ContainerItem>
            <Button color="default-outlined" label="Back" onClick={props.onBack} dataCy="back-button" />
          </ContainerItem>
          <ContainerItem>
            <Button
              label={props.isLastStep ? 'Submit' : 'Continue'}
              color="primary"
              onClick={form.handleSubmit(save, presenter.onSubmitError)}
              isLoading={isSaving || presenter.viewModel.isLoading}
              dataCy="save-button"
            />
          </ContainerItem>
        </Container>
      }
      dataCy="custom-additional-information"
    >
      <Box padding="0" width="100%" sx={{ overflowAnchor: 'none' }}>
        {presenter.viewModel.onboardingTemplate &&
        presenter.viewModel.onboardingTemplate?.custom_requirements &&
        Object.keys(presenter.viewModel.onboardingTemplate.custom_requirements).length > 0 ? (
          <AdditionalInfoGenerator
            form={form}
            fieldWidth={isMobile ? '100%' : '50%'}
            requirement={presenter.viewModel.onboardingTemplate}
            editMode={true}
            onFileUpload={async (requirementId, fieldId, files) => {
              onNewFilesChange(fieldId, files);
              return undefined;
            }}
            onFileDelete={async (reqId: string, fieldId: string, doc: DocumentUpload) => {
              await presenter.deleteOnboardingTemplateDocument(reqId, fieldId, doc);
              const updatedFiles = existingFilesIdsMap[fieldId].filter((id) => id !== doc.id);
              existingFilesIdsMap[fieldId] = updatedFiles;
              setExistingFilesIdsMap(existingFilesIdsMap);
              form.setValue(fieldId, updatedFiles);
              handleOnBlur(fieldId);
            }}
            documentMap={presenter.viewModel.allDocumentsMap}
            isLoading={
              presenter.viewModel.uploadingOnboardingTemplateDocuments ||
              presenter.viewModel.deletingOnboardingTemplateDocuments ||
              presenter.viewModel.savingOnboardingTemplate
            }
            timezone={presenter.viewModel.dispensary.iana_timezone}
            addExistingFilesToMap={addExistingFilesToMap}
            onBlur={handleOnBlur}
            refreshFileComponent={refreshFileComponent}
          />
        ) : (
          <ZeroState
            title={'There is no additional information needed at this time'}
            subTitle={'If your banking partner requests any additional information, it will show up here.'}
            icon="/img/empty-states/bankDash.svg"
            dataCy="additional-info-zero-state"
          />
        )}
      </Box>
    </OnBoardingPage>
  );
});
