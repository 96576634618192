import {
  AccountReviewType,
  BankDispensaryMetadata,
  DispensaryAccountReviewWithData,
  DispensaryAccountReviewWithDataV2,
  MUO,
  ReviewTargets
} from '@gcv/shared';
import { friendlyAccountTypes } from 'domain/consts/account-types';
import { getFiDispensaryStore } from 'stores/FiDispensaryStore';
import {
  MUODispensaryAccountReviewWithData,
  MUODispensaryAccountReviewWithDataV2
} from 'ui/apps/fi/monitoring/pages/review/model';
import { FilterListChild } from '../FilterDropdown/filter-dropdown.organism';
import { FilterSelectOption } from '../FilterSelect/filter-select.organism.model';

export const ACCOUNT_NUMBERS_LABEL = 'account_numbers';
export const SALES_LOCATION_LABEL = 'sales_location';

const getAccountNumberOptions = (accountNumbers: string[], metadata: BankDispensaryMetadata) => {
  const option = accountNumbers.map((an) => {
    const account = metadata.value.accounts?.[an];
    let label = `*${an.slice(-4)}`;
    if (account) {
      const nickname = account?.name ? account.name : account.type ? friendlyAccountTypes[account.type] : '';
      if (nickname) {
        label = `${nickname} ${label}`;
      }
    }
    const childOption: FilterListChild = {
      label,
      value: an,
      selected: true
    };
    return childOption;
  });
  return option;
};

export const getFilterOptionsForReview = (
  review: MUODispensaryAccountReviewWithData | DispensaryAccountReviewWithData,
  childReviews: DispensaryAccountReviewWithDataV2[]
): FilterSelectOption[] => {
  let filterDropDownOptions: FilterSelectOption[] = [];
  const disp = getFiDispensaryStore().findDispensary(review.dispensary_id);

  if (review.type === AccountReviewType.MUO) {
    const muo = disp as MUO;
    const muoReview = review as unknown as MUODispensaryAccountReviewWithDataV2;
    if (muo.shared_internal_id) {
      const accountNumbersOption: FilterSelectOption = {
        label: 'Account Numbers',
        value: ACCOUNT_NUMBERS_LABEL,
        selected: true,
        children: getAccountNumberOptions(childReviews[0].account_numbers, review.dispensary_metadata)
      };
      const salesLocationOption: FilterSelectOption = {
        label: 'Sales Location',
        value: SALES_LOCATION_LABEL,
        selected: true,
        children: Object.keys(muoReview.targets).map((crbId) => {
          const matchingReview = childReviews.find(
            (r) => r.dispensary_id === crbId
          ) as DispensaryAccountReviewWithDataV2;
          const option: FilterSelectOption = {
            label: matchingReview!.dispensary_name,
            value: crbId,
            selected: true
          };
          return option;
        })
      };
      filterDropDownOptions = [accountNumbersOption, salesLocationOption];
    } else {
      filterDropDownOptions = Object.keys(muoReview.targets).map((crbId) => {
        const matchingReview = childReviews.find(
          (r) => r.dispensary_id === crbId
        ) as DispensaryAccountReviewWithDataV2;
        const option: FilterSelectOption = {
          label: matchingReview.dispensary_name,
          value: crbId,
          selected: true,
          children: getAccountNumberOptions(
            matchingReview.account_numbers,
            matchingReview.dispensary_metadata
          )
        };
        return option;
      });
    }
  } else {
    const reviewV2 = review as unknown as DispensaryAccountReviewWithDataV2;
    const option: FilterSelectOption = {
      label: review.dispensary_name,
      value: review.dispensary_id,
      selected: true,
      children: getAccountNumberOptions(reviewV2.account_numbers, reviewV2.dispensary_metadata)
    };
    return [option];
  }

  return filterDropDownOptions;
};

export const getReviewTargetsFromSelection = (
  filterSelections: FilterSelectOption[],
  rawOptions: FilterSelectOption[]
): ReviewTargets => {
  const accountNumbersOption = filterSelections.find((o) => o.value === ACCOUNT_NUMBERS_LABEL);
  const salesLocationsOption = filterSelections.find((o) => o.value === SALES_LOCATION_LABEL);

  if (accountNumbersOption || salesLocationsOption) {
    const allLocations = salesLocationsOption?.children?.length
      ? salesLocationsOption.children
      : rawOptions.find((o) => o.value === SALES_LOCATION_LABEL)?.children ?? [];
    const allAccountNumbers = accountNumbersOption?.children ?? [];
    const targets: ReviewTargets = allLocations.reduce((targets, location) => {
      targets[location.value] = { account_numbers: allAccountNumbers.map((c) => c.value) };
      return targets;
    }, {} as ReviewTargets);
    return targets;
  } else if (filterSelections.every((op) => !op.children)) {
    const targets: ReviewTargets = filterSelections.reduce((targets, parent) => {
      const accountNumbers = parent.children ?? [];
      const account_numbers = accountNumbers.map((c) => c.value);
      targets[parent.value] = { account_numbers };
      return targets;
    }, {} as ReviewTargets);
    return targets;
  } else {
    const targets: ReviewTargets = filterSelections.reduce((targets, parent) => {
      const accountNumbers = parent.children ?? [];
      const account_numbers = accountNumbers.map((c) => c.value);
      targets[parent.value] = { account_numbers };
      return targets;
    }, {} as ReviewTargets);
    return targets;
  }
};
