import { LicenseDataMap } from '@gcv/shared';
import { GcvAdminApi } from 'api';
import { injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';

@injectable()
export class ComplianceStore {
  licenseDataMap = {} as LicenseDataMap;
  isLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  gcvAdminApi = new GcvAdminApi();

  loadLicenses = action(async () => {
    this.gcvAdminApi.getAllLicenseData().then((licenses) => {
      runInAction(() => {
        this.licenseDataMap = licenses;
        this.isLoaded = true;
      });
    });
  });

  clearStore = action(() => {
    this.licenseDataMap = {} as LicenseDataMap;
    this.isLoaded = false;
  });
}

let store: ComplianceStore | undefined;

export function getComplianceStore(): ComplianceStore {
  if (!store) {
    store = new ComplianceStore();
  }

  return store;
}
