import { SftpMissedScheduleNotificationData } from '@gcv/shared';
import { DateTime } from 'luxon';
import { getFiBankStore } from 'stores/FiBankStore';
import { DateTimeHelpers } from '../dateTime.util';

const parseExpectedDateFromNotification = (
  notificationData: SftpMissedScheduleNotificationData
): string | undefined => {
  if (!notificationData?.expected_date) {
    return undefined;
  } else {
    try {
      // Example: August 18, 2022
      return `${DateTimeHelpers.parseFromTableDateString(
        notificationData?.expected_date,
        getFiBankStore().bank.iana_timezone
      ).toLocaleString(DateTime.DATE_FULL)}`;
    } catch (e) {
      console.log(`Parsing expected date: ${e}`);
      return undefined;
    }
  }
};

export const createDateExpectedMessage = (notificationData: SftpMissedScheduleNotificationData) => {
  const date = parseExpectedDateFromNotification(notificationData);
  return date
    ? `A file that was expected on ${date} was not received.`
    : 'A file that was expected was not received.';
};
