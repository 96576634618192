export default {
  styleOverrides: {
    root: {
      border: 'none !important',

      '&&:hover': {
        backgroundColor: 'transparent'
      }
    }
  }
};
