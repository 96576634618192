import { FilterListChild } from 'ui';

export const filterUtil = <T>(
  callback: (filteredList: T[]) => void,
  unfilteredList: T[],
  filterTerms: FilterListChild[]
) => {
  if (!filterTerms.length) {
    callback(unfilteredList);
    return;
  }

  const newFilterTerms: { [T: string]: string[] } = {};
  filterTerms.forEach((term) => {
    term.parentValue = term.parentValue ? term.parentValue : 'unknown';
    newFilterTerms[term.parentValue] = newFilterTerms[term.parentValue]
      ? [...newFilterTerms[term.parentValue], term.value]
      : [term.value];
  });

  const dedupedFilterTerms: { [T: string]: Set<string> } = {};
  Object.keys(newFilterTerms).forEach((filterTerm) => {
    dedupedFilterTerms[filterTerm] = new Set(newFilterTerms[filterTerm]);
  });

  const filteredList = unfilteredList.filter((l) => {
    const value = l as unknown as Record<string, string>;

    let match = false;

    // match every filter term not just one
    for (const [key, values] of Object.entries(dedupedFilterTerms)) {
      if (values.has(value[key])) {
        match = true;
      } else {
        match = false;
        break;
      }
    }

    return match;
  });

  callback(filteredList);
};
