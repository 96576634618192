import { Box } from '@mui/material';
import * as React from 'react';
import { getBannerStore } from 'stores/BannerStore';
import { Button, Header } from 'ui/atoms';

interface Props {}

export const Banner: React.FC<Props> = (props) => {
  const { banner } = getBannerStore();

  return (
    <>
      {banner.isOpen ? (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Header type="h3" content={banner.message} dataCy="banner-message" />
          {banner.action ? (
            <>
              &nbsp;&nbsp;&nbsp;
              <Button
                onClick={banner.action}
                label={banner.actionLabel ?? ''}
                color="primary"
                dataCy="banner-button"
              />
            </>
          ) : null}
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1
          }}
        />
      )}
    </>
  );
};
