import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import * as jwt from 'jsonwebtoken';

//TODO: Move to @gcv/shared.
enum GreenCheckRoles {
  gcv_access = 'gcv_access',
  gcd_access = 'gcd_access'
}

interface GreenCheckToken extends jwt.JwtPayload {
  green_check_roles: GreenCheckRoles[];
}

const selectedApplicationSessionStorageKey = 'GCVSelectedApplicationSessionStorageKey';

export enum GCApplicationAccess {
  GCV = 'GreenCheckVerified',
  PQ = 'GreenCheckDirect',
  All = 'CarteBlanche',
  NoAccess = 'NoAccess'
}

/**
 * Object fetched from authentication, representing session.
 *
 * {
 *   id_token: string;
 *   access_token: string;
 *   expires_in: number;
 *   scope?: string | undefined;
 * }
 */
export type Auth0SessionToken = GetTokenSilentlyVerboseResponse;

/**
 * API authorization (HTTP bearer) token.
 *
 * All public methods are static, with state managed internally by a private singleton instance.
 * Enables client code to explicitly set the session token, from which the HTTP Authorization bearer
 * token and other info can be gotten.
 */
class ApiAuth0Session {
  private static _instance: ApiAuth0Session;
  private _sessionToken: Auth0SessionToken | undefined;

  private static get instance() {
    if (!ApiAuth0Session._instance) {
      ApiAuth0Session._instance = new ApiAuth0Session();
    }

    return ApiAuth0Session._instance;
  }

  /**
   * Updates the session token.
   *
   * @param sessionToken Auth0SessionToken representing session.
   */
  public static updateToken(sessionToken: Auth0SessionToken) {
    ApiAuth0Session.instance._sessionToken = sessionToken;
  }

  /**
   * Resets token value to blank.
   */
  public static reset(): void {
    ApiAuth0Session.instance._sessionToken = undefined;
    ApiAuth0Session.resetSelectedApplication();
  }

  /**
   * Returns true if token is not blank; false, otherwise.
   */
  public static get hasHTTPBearerToken(): boolean {
    return ApiAuth0Session.HTTPBearerToken !== undefined && !!ApiAuth0Session.HTTPBearerToken.length;
  }

  /**
   * Returns Auth0 HTTP bearer token.
   */
  public static get HTTPBearerToken(): string {
    return ApiAuth0Session.instance._sessionToken?.id_token ?? '';
  }

  /**
   * Decrypted JWT version of the ID token.
   */
  public static get userIdToken(): jwt.JwtPayload | undefined {
    const idToken = ApiAuth0Session.instance._sessionToken?.id_token;

    if (!idToken) {
      return undefined;
    } else {
      return jwt.decode(idToken) as jwt.JwtPayload;
    }
  }

  /**
   * Return Auth0 access token for session.
   */
  public static get accessToken(): string {
    return ApiAuth0Session.instance._sessionToken?.access_token ?? '';
  }

  /**
   * Return array of token query parameters.
   */
  public static get sessionToken(): Auth0SessionToken {
    return ApiAuth0Session.instance._sessionToken ?? ({} as Auth0SessionToken);
  }

  /**
   * Return access for authorized user.
   */
  public static get userAccess(): GCApplicationAccess {
    const idToken = ApiAuth0Session.userIdToken as GreenCheckToken;

    if (!idToken) {
      return GCApplicationAccess.NoAccess;
    } else if (idToken.green_check_roles?.includes(GreenCheckRoles.gcd_access)) {
      // if they have access to PQ, they have access to all, send them to SSO
      return GCApplicationAccess.All;
    } else {
      // always have GCV access
      return GCApplicationAccess.GCV;
    }
  }

  // The selectedApplication property needs to persist across a browser refresh.

  /**
   * Return GCV/PQ application selected by the user, or user defaults if no explicit selection.
   */
  public static get selectedApplication(): GCApplicationAccess {
    const session = window.sessionStorage.getItem(selectedApplicationSessionStorageKey);

    if (session) {
      return session as GCApplicationAccess;
    } else {
      return ApiAuth0Session.userAccess;
    }
  }

  /**
   * Set GCV or PQ application selection for user.
   */
  public static set selectedApplication(selection: GCApplicationAccess) {
    window.sessionStorage.setItem(selectedApplicationSessionStorageKey, selection);
  }

  /**
   * Reset value of selected application to undefined.
   */
  public static resetSelectedApplication() {
    window.sessionStorage.removeItem(selectedApplicationSessionStorageKey);
  }
}

export default ApiAuth0Session;
