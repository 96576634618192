import { IANATimezones, InitialCrbRole, InitialFiRole, InitialGcvRole } from '@gcv/shared';
import * as React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Column, EditUserModal, Row, Spinner, Table, TableContainer } from 'ui';
import { DateTimeHelpers } from 'util/dateTime.util';
import ActiveUsersActions, { ActiveUsersActionsProps } from './active-users-actions';
import { useComponent } from 'hooks/useComponent';

export interface UserRow {
  id: string;
  firstName: string;
  lastName: string;
  status?: string;
  lastLogin?: string;
}

interface Props extends Record<string, unknown> {
  form: UseFormReturn<FieldValues, any>;
  iana_timezone: IANATimezones;
  isStaffLoaded: boolean;
  selectedUserId?: string;
  selectedUsers?: string[];
  setSelectedUserId: (id: string) => void;
  setSelectedUsers: (rows: string[]) => void;
  updateUser: (data: FieldValues) => Promise<void>;
  updateUserLoading: boolean;
  userRoleOptions: { label: string; value: InitialFiRole | InitialCrbRole | InitialGcvRole }[];
  userRows: Row<UserRow>[];
  actions?: React.ReactNode;
  actionsProps: ActiveUsersActionsProps;
  showFilterZeroState?: boolean;
}

export const ActiveUsersTable: React.FC<Props> = useComponent((props) => {
  const [error, setError] = React.useState('');

  const columns: Column<UserRow>[] = [
    {
      label: 'First Name',
      id: 'firstName'
    },
    {
      label: 'Last Name',
      id: 'lastName'
    },
    {
      label: 'Status',
      id: 'status'
    },
    {
      label: 'Last Login',
      id: 'lastLogin',
      format: (row) =>
        row.lastLogin
          ? DateTimeHelpers.formatISODateStringToTableDateAndTimeString(row.lastLogin, props.iana_timezone)
          : '--'
    }
  ];

  const handleRowClick = (id: string) => {
    setError('');
    props.setSelectedUserId(id);
  };

  const { model, actions, organization, form, selectedUserId } = props.actionsProps;

  return (
    <>
      {!props.isStaffLoaded ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table
            selectable
            clickable
            columns={columns}
            updateSelected={props.selectedUsers}
            rows={props.userRows}
            defaultSort="firstName"
            onClick={handleRowClick}
            dataCy="users-table"
            defaultRowCount={10}
            returnSelection={(rows) => props.setSelectedUsers(rows)}
            actions={
              <ActiveUsersActions
                model={model}
                actions={actions}
                organization={organization}
                form={form}
                selectedUserId={selectedUserId}
              />
            }
            showFilterZeroState={props.showFilterZeroState}
          />
        </TableContainer>
      )}

      <EditUserModal
        form={props.form}
        error={error}
        userRoleOptions={props.userRoleOptions}
        onClose={() => props.setSelectedUserId('')}
        title="Edit User"
        isOpen={!!props.selectedUserId}
        cancelButton={{
          label: 'Cancel',
          color: 'default-outlined',
          onClick: () => props.setSelectedUserId('')
        }}
        completeButton={{
          label: 'Update',
          color: 'primary',
          onClick: props.form.handleSubmit(props.updateUser),
          isLoading: props.updateUserLoading
        }}
      />
    </>
  );
});
