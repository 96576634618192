import { useInjection } from 'ioc';
import * as React from 'react';
import { Button, Container, ContainerItem, Dialog, InputTextArea } from 'ui';
import { CommentsPresenter } from '../comments.presenter';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { useComponent } from 'hooks';

interface Props extends Record<string, unknown> {
  submitCommentAndTask: (data: FieldValues) => void;
}

export const CommentsModalTaskComplete: React.FC<Props> = useComponent((props) => {
  const form = useForm({ mode: 'onBlur' });

  const presenter = useInjection(CommentsPresenter);

  return (
    <Dialog
      handleClose={() => presenter.openMarkAsCompleteModal(false)}
      isOpen={presenter.viewModel.isMarkAsCompleteModalOpen}
      title={'Mark as Complete'}
      action={
        <Button
          color="primary"
          label="Mark as Complete"
          onClick={form.handleSubmit((data: FieldValues) => {
            data.completeTask = true;

            props.submitCommentAndTask(data);
            presenter.openMarkAsCompleteModal(false);
          })}
        />
      }
    >
      <Container padding="0" width="100%">
        <ContainerItem padding="0" width="100%">
          <InputTextArea
            {...form}
            label={'Add a Note'}
            name={'completed_note'}
            placeholder="Add a Note (optional)"
            background="lightGray"
            defaultValue={presenter.viewModel.commentVal}
          />
        </ContainerItem>
      </Container>
    </Dialog>
  );
});
