// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const reorder = <T extends unknown>(
  list: Array<T> | undefined,
  startIndex: number,
  endIndex: number
) => {
  if (!list) {
    return [];
  }

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Return list of objects sorted according to specified property.
 *
 * @param list of objects of type T.
 * @param field property of type T used for sorting.
 * @param ascending sort objects by ascending if true; descending, if false.
 * @returns the sorted list.
 */
export const sortArrayByField = <T, F extends keyof T>(list: T[], field: F, ascending = true): T[] => {
  return list.sort((a: T, b: T) => {
    const [first, second] = ascending ? [a, b] : [b, a];
    if (first[field] < second[field]) {
      return -1;
    } else if (first[field] === second[field]) {
      return 0;
    } else {
      return 1;
    }
  }) as T[];
};
