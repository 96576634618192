import { User } from '@gcv/shared';
import { injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { hideLendica } from 'third-party-integrations/lendica';
import { initializePendo } from 'third-party-integrations/pendo';
import { DateTimeHelpers } from 'util/dateTime.util';
import { UsersApi } from '../api/UsersApi';
import { getComplianceStore } from './ComplianceStore';
import { SnackbarSeverity, getSnackbarStore } from './SnackBarStore';
import { clearStores } from './store.util';

@injectable()
export class UserStore {
  isLoaded = false;
  isLoading = false;
  isLoggedIn = false;
  mfaInfo: { mfaEnabled: boolean; mfaRequired: boolean } = { mfaEnabled: false, mfaRequired: false };
  user: User = {} as User;

  constructor() {
    makeAutoObservable(this);
  }

  usersApi = new UsersApi();
  complianceStore = getComplianceStore();
  snackbarStore = getSnackbarStore();

  load = action(async (id: string, setLoading?: (value: boolean) => void) => {
    try {
      if (setLoading) {
        setLoading(true);
      }
      this.setIsLoading(true);

      const user = await this.usersApi.getUser(id);
      if (user.invitation_status === 'archived') {
        this.setIsLoading(false);
        return user;
      }

      const mfaInfo = await this.usersApi.checkMfaRequired(user.email);

      runInAction(() => {
        initializePendo(user);
        this.user = user;
        this.isLoaded = true;
        this.mfaInfo = mfaInfo;
      });

      const updatedUser = await this.usersApi.updateUserIdentification(user.id, {
        lastLogin: DateTimeHelpers.getUtcIsoString(),
        invitation_status: user.invitation_status === 'pending' ? 'accepted' : undefined
      });

      //load compliance data once upon login as it is constant data and used by all apps
      await this.complianceStore.loadLicenses();

      return updatedUser;
    } catch (err) {
      this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'There was an issue logging in.');
      throw err; //rethrow for rollbar
    } finally {
      this.setIsLoading(false);
    }
  });

  setIsLoading = action(async (isLoading: boolean) => {
    this.isLoading = isLoading;
  });

  updateUser = action((user: User) => {
    this.user = user;
  });

  clearStore = action(() => {
    this.isLoggedIn = false;
    this.isLoaded = false;
    this.user = {} as User;
    this.mfaInfo = { mfaEnabled: false, mfaRequired: false };
  });

  /**
   * Do not call this directly to logout, instead, use history.replace('/logout') to redirect to our
   * logout component that handles auth0 logout and calls this function
   */
  logout = action(async () => {
    runInAction(() => {
      clearStores();
      hideLendica();
    });
  });
}

let userStore: UserStore;

export function getUserStore(): UserStore {
  if (!userStore) {
    userStore = new UserStore();
  }

  return userStore;
}
