import ApiAuth0Session from 'api/api-util/api-auth0-session';
import { ApiError } from 'api/api-util/api-error';
import { ApiResponse } from 'api/api-util/api-response';
import { getEndpoints } from 'api/api-util/endpoints';
import { API } from 'aws-amplify';
import { logError } from 'third-party-integrations/rollbar';
import { filterOutEmptyProperties } from 'util/objectUtils';

/*
  Creates an API to use for CRUD operations
  - New base urls can be used by exporting `createApi({ endpointName: ENDPOINT_NAME });` as seen below
  - ENDPOINT_NAME can be added to ENDPOINTS in 'src/domain/consts/endpoints.ts'
  - Endpoints will be configured automatically on app init
*/
export const createApi = ({ endpointName }: { endpointName: string }) => {
  // The header will be inserted only if an Auth0 authorization token exists.
  const headers = ApiAuth0Session.hasHTTPBearerToken
    ? {
        Authorization: `Bearer ${ApiAuth0Session.HTTPBearerToken}`
      }
    : {};

  return {
    // Create
    post: async <TRequest, TResponse>(
      endpoint: string,
      body: TRequest,
      queryStringParameters?: Record<string, unknown>
    ): Promise<TResponse> => {
      try {
        const response: ApiResponse<TResponse> = await API.post(endpointName, endpoint, {
          body,
          queryStringParameters: queryStringParameters
            ? filterOutEmptyProperties(queryStringParameters)
            : null,
          headers
        });

        if (response?.data) {
          return response.data;
        } else {
          return response as unknown as TResponse;
        }
      } catch (e: any) {
        logError(e, {
          endpointName,
          endpoint,
          queryStringParameters
        });
        throw new ApiError(e);
      }
    },

    // Read
    get: async <TResponse>(
      endpoint: string,
      queryStringParameters?: Record<string, unknown>
    ): Promise<TResponse> => {
      try {
        const response: ApiResponse<TResponse> = await API.get(endpointName, endpoint, {
          queryStringParameters: queryStringParameters
            ? filterOutEmptyProperties(queryStringParameters)
            : null,
          headers
        });

        if (response?.data) {
          return response.data;
        } else {
          return response as unknown as TResponse;
        }
      } catch (e: any) {
        logError(e, {
          endpointName,
          endpoint,
          queryStringParameters
        });
        throw new ApiError(e);
      }
    },

    // Update
    patch: async <TRequest, TResponse>(
      endpoint: string,
      body: TRequest,
      queryStringParameters?: Record<string, unknown>
    ): Promise<TResponse> => {
      try {
        const response: ApiResponse<TResponse> = await API.patch(endpointName, endpoint, {
          body,
          queryStringParameters: queryStringParameters
            ? filterOutEmptyProperties(queryStringParameters)
            : null,
          headers
        });

        if (response?.data) {
          return response.data;
        } else {
          return response as unknown as TResponse;
        }
      } catch (e: any) {
        logError(e, {
          endpointName,
          endpoint,
          queryStringParameters
        });
        throw new ApiError(e);
      }
    },
    put: async <TRequest, TResponse>(
      endpoint: string,
      body: TRequest,
      queryStringParameters?: Record<string, unknown>
    ): Promise<TResponse> => {
      try {
        const response: ApiResponse<TResponse> = await API.put(endpointName, endpoint, {
          body,
          queryStringParameters: queryStringParameters
            ? filterOutEmptyProperties(queryStringParameters)
            : null,
          headers
        });

        if (response?.data) {
          return response.data;
        } else {
          return response as unknown as TResponse;
        }
      } catch (e: any) {
        logError(e, {
          endpointName,
          endpoint,
          queryStringParameters
        });
        throw new ApiError(e);
      }
    },
    putVerboseResponse: async <TRequest, TResponse>(
      endpoint: string,
      body: TRequest,
      queryStringParameters?: Record<string, unknown>
    ): Promise<ApiResponse<TResponse>> => {
      try {
        const response: ApiResponse<TResponse> = await API.put(endpointName, endpoint, {
          body,
          queryStringParameters: queryStringParameters
            ? filterOutEmptyProperties(queryStringParameters)
            : null,
          headers
        });
        return response;
      } catch (e: any) {
        logError(e, {
          endpointName,
          endpoint,
          queryStringParameters
        });
        throw new ApiError(e);
      }
    },
    // Delete
    delete: async <TResponse>(
      endpoint: string,
      queryStringParameters?: Record<string, unknown>
    ): Promise<TResponse> => {
      try {
        const response: ApiResponse<TResponse> = await API.del(endpointName, endpoint, {
          queryStringParameters: queryStringParameters
            ? filterOutEmptyProperties(queryStringParameters)
            : null,
          headers
        });

        if (response?.data) {
          return response.data;
        } else {
          return response as unknown as TResponse;
        }
      } catch (e: any) {
        logError(e, {
          endpointName,
          endpoint,
          queryStringParameters
        });
        throw new ApiError(e);
      }
    }
  };
};

/**
 * The default api with GCV Services base URL
 * */
export const api = () => createApi({ endpointName: getEndpoints().GREEN_CHECK_SERVICES.name });

/**
 * The newer banks-specific API Gateway endpoint
 */
export const banksApi = () => createApi({ endpointName: getEndpoints().GREEN_CHECK_BANKS_SERVICES.name });
