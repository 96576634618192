import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useRollbarPerson } from '@rollbar/react';

import { getUserStore } from '../../stores/UserStore';
import { getUserType, userHasRole } from 'ui/routing/router-util';
import { RouteProps } from './router';
import { getFiBankStore } from 'stores/FiBankStore';
import { updateLogRocketConfig } from 'third-party-integrations/log-rocket';

export const FiRoute = ({ component: Component, path, exact, routeType }: RouteProps) => {
  let isBankUser = false;
  let userHasRoles = false;

  const userStore = getUserStore();
  const bankStore = getFiBankStore();

  //check user type
  if (userStore.isLoaded) {
    const type = getUserType(userStore.user);

    if (type === 'bank') {
      isBankUser = true;
    }

    useRollbarPerson({
      id: userStore.user?.id,
      name: userStore.user?.firstName + ' ' + userStore?.user?.lastName,
      email: userStore.user?.email
    });
    updateLogRocketConfig(userStore.user);
  }

  //check user role
  if (userStore.isLoaded && bankStore.isLoaded && bankStore.bank.groups) {
    userHasRoles = userHasRole(bankStore.bank.groups, userStore.user, routeType.roles);
  }

  return isBankUser && userHasRoles ? (
    <Route exact={exact} path={path} component={Component} />
  ) : !userHasRoles ? (
    <Redirect to={{ pathname: '/error' }} />
  ) : (
    <Redirect to={{ pathname: '/' }} />
  );
};
