import { environment } from 'environments/environment';
import { versions } from 'environments/versions';
import { Environment } from 'environments/env-interface';

const CUSTOM_HEADER = {
  custom_header: async () => {
    return { 'X-Green-Check-Client-Hash': versions.revision };
  }
};

export const getEndpoints = (customEnvironment?: Environment) => {
  const endpoint = customEnvironment?.basePath ?? environment.basePath;
  return {
    GREEN_CHECK_SERVICES: {
      name: 'GcvServicesApi',
      endpoint: endpoint,
      region: customEnvironment?.amplify?.aws_cognito_region ?? environment.amplify.aws_cognito_region,
      ...CUSTOM_HEADER
    },
    GREEN_CHECK_BANKS_SERVICES: {
      name: 'GcvServicesBanksApi',
      endpoint: customEnvironment?.banksApiBasePath ?? environment.banksApiBasePath,
      region: customEnvironment?.amplify?.aws_cognito_region ?? environment.amplify.aws_cognito_region,
      ...CUSTOM_HEADER
    }
  };
};
