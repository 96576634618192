import { useEffect } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Box, SelectChangeEvent } from '@mui/material';
import { ContainerItem, InputSelect, Option } from 'ui';
import { ReportType } from 'api';
import ReportStatus from './reports-status';

interface Props {
  form: UseFormReturn<FieldValues, any>;
  reportType: ReportType;
  setSelections: (selections: Option | Option[] | null) => void;
}

const MultiStatusReportsDropdown = (props: Props) => {
  const reportStatus = new ReportStatus(props.reportType);

  useEffect(() => {
    props.form.setValue(
      props.reportType === ReportType.SALES ? 'sales_statuses' : 'license_statuses',
      reportStatus.getReportOptions()
    );
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (e: SelectChangeEvent<any>) => {
    // Rely on MUI sending us a string array.
    const values = e.target.value as string[];
    const options: Option[] = reportStatus.getReportOptions().filter((o) => values.includes(o.value));
    props.setSelections(options);
  };

  return (
    <ContainerItem width="100%" padding={'0'}>
      <Box
        sx={{
          mt: '1rem',
          width: '100%',
          height: 'auto',
          position: 'relative'
        }}
      >
        <InputSelect
          multiSelect
          useChip
          label="Statuses"
          name={ReportType.SALES ? 'sales_statuses' : 'license_statuses'}
          background="white"
          options={reportStatus.getReportOptions()}
          additionalOnChange={handleOnChange}
          emptyLabel="All"
          {...props.form}
        />
      </Box>
    </ContainerItem>
  );
};

export default MultiStatusReportsDropdown;
