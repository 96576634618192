import { Typography } from '@mui/material';
import * as React from 'react';
import { theme } from 'ui/theme';

interface Props {
  content: string;
  dataCy?: string;
  inline?: boolean;
}

export const Error: React.FC<Props> = (props) => {
  return (
    <Typography
      data-cy={props.dataCy}
      variant={'body1'}
      color={theme.palette.error.main}
      fontSize={'12px'}
      sx={{ minHeight: '1px' }}
    >
      {props.content}
    </Typography>
  );
};
