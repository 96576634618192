import { InputBaseComponentProps } from '@mui/material';
import * as React from 'react';
import { Control, Validate } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { InputText } from 'ui';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputMaskedProps {
  id?: string;
  label: string;
  tooltip?: string | React.ReactFragment;
  name: string;
  mask: string | RegExp | { mask: string }[];
  control?: Control;
  dataCy?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  viewOnly?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<string | number>;
    minLength?: ValidationRule<string | number>;
    max?: ValidationRule<string | number>;
    min?: ValidationRule<string | number>;
    pattern?: ValidationRule<RegExp>;
    validate?: Validate<string | number> | Record<string, Validate<string | number>>;
  };
  inputProps?: InputBaseComponentProps;
  placeholder?: string;
  value?: any;
  background?: 'white' | 'gray';
  darkLabel?: boolean;
}

interface TextMaskCustomProps extends InputBaseComponentProps {
  mask: RegExp[];
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, TextMaskCustomProps>((props, ref) => {
  const { mask, onChange, ...rest } = props;

  return (
    <IMaskInput
      {...rest}
      //@ts-expect-error typings are incorrect in latest react-imask version 6.2.2
      // see: https://github.com/uNmAnNeR/imaskjs/issues/554
      inputRef={ref}
      mask={mask}
      onAccept={(value: any) => {
        if (onChange && value !== undefined) {
          onChange({ target: { name: props.name, value } } as React.ChangeEvent<HTMLInputElement>);
        }
      }}
    />
  );
});

export const InputMasked: React.FC<InputMaskedProps> = (props) => {
  const { mask, name, inputProps, ...rest } = props;

  return (
    <InputText
      {...rest}
      name={name}
      inputComponent={TextMaskCustom}
      inputProps={{ ...inputProps, mask, name }}
      darkLabel={props.darkLabel}
    />
  );
};
