import { api, banksApi } from './api-util/api';
import {
  CreateFincenReportRequest,
  FincenCtrReport,
  FincenReportTableDataResponse,
  FincenSarReport,
  FincenExportRecord,
  SarPeriodAggregate,
  DispensaryPeriodSummaryCoreTransactions
} from '@gcv/shared';
import { injectable } from 'inversify';
import { FieldValues } from 'react-hook-form';

@injectable()
export class FinCENApi {
  async getFinCENReportTableDataByBankId(bankId: string): Promise<FincenReportTableDataResponse> {
    return await api().get(`/banks/${bankId}/fincen-reports`);
  }

  async generateFincenXml(body: {
    bankId: string;
    selectedReportIds: string[];
  }): Promise<{ fileName: string; fileData: string }> {
    return await api().post(`/gcv/fincen/xml`, body);
  }

  async exportFincenReports(body: {
    selectedReportIds: string[];
  }): Promise<{ fileName: string; fileData: string }> {
    return await api().post(`/fincen-reports/xml`, body);
  }

  async updateFincenTracking(bankId: string, reportId: string, body: Record<string, any>): Promise<any> {
    return await api().put(`/fincen-reports/${bankId}/reports/${reportId}/tracking`, body);
  }

  async updateFincenExportTracking(
    bankId: string,
    body: { exportId: string; trackingId: string }
  ): Promise<(FincenSarReport | FincenCtrReport)[]> {
    return await api().post(`/fincen-xml-exports/${bankId}/tracking`, body);
  }

  async getReportById(bankId: string, reportId: string): Promise<FincenSarReport | FincenCtrReport> {
    return await api()
      .get<FincenSarReport[] | FincenCtrReport[]>(`/banks/${bankId}/fincen-reports/${reportId}`)
      .then((reports) => reports[0]);
  }

  async createReport(
    bankId: string,
    data: CreateFincenReportRequest
  ): Promise<FincenSarReport | FincenCtrReport> {
    return await api().post(`/banks/${bankId}/fincen-reports`, data);
  }

  async sarHomeGroup(bankId: string, reportId: string, data: FieldValues): Promise<FincenSarReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/sarHomeGroup`, data);
  }

  async sarPart1FormGroup(bankId: string, reportId: string, data: FieldValues): Promise<FincenSarReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/sarPart1FormGroup`, data);
  }

  async sarPart2FormArray(bankId: string, reportId: string, data: FieldValues): Promise<FincenSarReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/sarPart2FormArray`, data);
  }

  async sarPart3FormArray(bankId: string, reportId: string, data: FieldValues): Promise<FincenSarReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/sarPart3FormArray`, data);
  }

  async sarPart4FormGroup(bankId: string, reportId: string, data: FieldValues): Promise<FincenSarReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/sarPart4FormGroup`, data);
  }

  async sarPart5FormGroup(bankId: string, reportId: string, data: FieldValues): Promise<FincenSarReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/sarPart5FormGroup`, data);
  }

  async ctrHomeGroup(bankId: string, reportId: string, data: FieldValues): Promise<FincenCtrReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/ctrHomeGroup`, data);
  }

  async ctrPart1FormGroup(bankId: string, reportId: string, data: FieldValues): Promise<FincenCtrReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/ctrPart1FormGroup`, data);
  }

  async ctrPart2FormArray(bankId: string, reportId: string, data: FieldValues): Promise<FincenCtrReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/ctrPart2FormArray`, data);
  }

  async ctrPart3FormArray(bankId: string, reportId: string, data: FieldValues): Promise<FincenCtrReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/ctrPart3FormArray`, data);
  }

  async ctrPart4FormGroup(bankId: string, reportId: string, data: FieldValues): Promise<FincenCtrReport> {
    return await api().put(`/banks/${bankId}/fincen-reports/${reportId}/ctrPart4FormGroup`, data);
  }

  async completeReport(bankId: string, reportId: string): Promise<FincenSarReport | FincenCtrReport> {
    return await api().post(`/banks/${bankId}/fincen-reports/${reportId}/complete`, {});
  }

  async getExportReports(bankId: string): Promise<FincenExportRecord[]> {
    return await api().get(`/fincen-xml-exports/${bankId}`);
  }

  async deleteFincenReport(reportId: string): Promise<any> {
    return await api().delete(`/fincen-reports/${reportId}`);
  }

  async getUpcomingFincenReports(bankId: string): Promise<SarPeriodAggregate[]> {
    return await api().get(`/fincen-reports/sar-periods/${bankId}`);
  }

  async updateFincenExportReports(bankId: string, body: any): Promise<any> {
    return await api().post(`/fincen-xml-exports/${bankId}/bulk-update`, body);
  }

  async createAmendedSar(
    bankId: string,
    reportId: string,
    data: { activity_start_date: string; filing_name: string; prior_report_bsa_id: string }
  ): Promise<FincenSarReport> {
    return await banksApi().post(`/banks/${bankId}/fincen-reports/${reportId}/amended-sar`, data);
  }
}
