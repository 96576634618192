import * as React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';

interface Props {
  dataCy?: string;
  labelPlacement: 'bottom' | 'end' | 'start' | 'top';
  name: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onClick?: (event: React.SyntheticEvent | React.MouseEvent) => void;
  readOnly?: boolean;
  value: boolean;
  indeterminate?: boolean;
  id?: string;
  standalone?: boolean;
}

export const CheckBox: React.FC<Props> = (props) => {
  return (
    <MuiCheckbox
      id={props.id}
      data-cy={props.dataCy}
      sx={{
        padding: props.standalone ? '0 1px 0 0' : '1px',
        color: (theme) => theme.palette.divider,
        border: (theme) => `1px solid ${theme.palette.common.white}`,
        marginRight: props.labelPlacement === 'end' ? '.25rem' : '',
        marginLeft: props.labelPlacement === 'start' ? '.25rem' : '',
        marginTop: props.labelPlacement === 'top' ? '.25rem' : '',
        marginBottom: props.labelPlacement === 'bottom' ? '.25rem' : props.standalone ? '0.2rem' : '',
        '&.Mui-focusVisible': {
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          borderRadius: '4px'
        },
        '&.Mui-disabled': {
          pointerEvents: 'auto',
          cursor: 'not-allowed'
        },
        '&:hover': {
          cursor: props.readOnly ? 'not-allowed' : 'default'
        }
      }}
      size="small"
      disableRipple
      checked={props.value}
      disabled={props.readOnly}
      onBlur={props.onBlur}
      onChange={props.onChange}
      onClick={props.onClick}
      name={props.name}
      color="primary"
      indeterminate={props.indeterminate}
    />
  );
};
