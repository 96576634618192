import { Icon, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FilterSelectOption } from 'ui';

interface Props {
  filterToggle: boolean;
  toggleFilter: () => void;
  selectedFilters: FilterSelectOption[];
  headerComponent?: boolean;
  noMargin?: boolean;
  allLabel?: string;
}

export const FilterSelectButton: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const selectedCount = () => {
    return props.selectedFilters.reduce((parentCount, parent) => {
      if (parent.selected) {
        parentCount += 1;
      }

      if (parent.children && parent.children.length > 0) {
        parentCount += parent.children.reduce((childCount, child) => {
          if (child.selected) {
            childCount += 1;
          }

          return childCount;
        }, 0);
      }

      return parentCount;
    }, 0);
  };

  const count = selectedCount();

  return (
    <Box
      className="filterSelectButton-topBox"
      sx={{
        color: 'text.primary',
        fontSize: '14px',
        lineHeight: '1.4375em',
        boxSizing: 'border-box',
        display: isMobile ? 'block' : 'inline-flex',
        marginTop: props.noMargin ? 0 : '12px'
      }}
    >
      <Box
        className="filterSelectButton-buttonBox"
        data-cy="account-select-dropdown"
        sx={{
          height: props.headerComponent ? '35px' : '41px',
          alignItems: 'center',
          border: '1px solid',
          color: 'text.primary',
          display: 'flex',
          marginTop: isMobile ? '4px' : '0',
          padding: '0 0.75rem',
          borderRadius: '4px',
          cursor: 'pointer',
          boxShadow: props.filterToggle ? '0px 0px 0px 3px #03a65b40' : 'none',
          borderColor: props.filterToggle ? 'primary.main' : 'divider',
          minWidth: '165px',
          position: 'relative',
          backgroundColor: props.headerComponent ? 'background.paper' : '#F9FAFD',
          '&: hover': {
            borderColor: props.headerComponent ? 'primary.border' : undefined
          }
        }}
        onClick={props.toggleFilter}
      >
        <Box
          sx={{
            marginRight: '10px',
            width: '145px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {count > 1 ? (
            <span>
              {count} {props.allLabel ? props.allLabel : 'Accounts'}
            </span>
          ) : null}
          {count === 1 ? <span>{props.selectedFilters.find((s) => s.selected)?.label}</span> : null}
          {count === 0 ? <span>{props.allLabel ? 'All ' + props.allLabel : 'All Accounts'}</span> : null}
        </Box>
        {props.filterToggle ? (
          <Icon
            sx={{
              fontSize: '20px',
              position: 'absolute',
              right: '6px',
              pointerEvents: 'none',
              color: 'rgba(0, 0, 0, 0.54)'
            }}
          >
            expand_less_outlined
          </Icon>
        ) : (
          <Icon
            sx={{
              fontSize: '20px',
              position: 'absolute',
              right: '6px',
              pointerEvents: 'none',
              color: 'rgba(0, 0, 0, 0.54)'
            }}
          >
            expand_more_outlined
          </Icon>
        )}
      </Box>
    </Box>
  );
};
