import { FormControl, styled, TextareaAutosize } from '@mui/material';
import * as React from 'react';
import { Control, Controller, FieldError, Validate } from 'react-hook-form';
import { Container, ContainerItem, Error, Icon, Input, InputLabel, Tooltip } from 'ui';
import customPalette from 'ui/theme/palette';
import { errorMessages } from 'util/forms.util';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface Props {
  label: string;
  name: string;
  control?: Control;
  placeholder?: string;
  defaultValue?: string;
  readOnly?: boolean;
  viewOnly?: boolean;
  focus?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<string | number>;
    minLength?: ValidationRule<string | number>;
    max?: ValidationRule<string | number>;
    min?: ValidationRule<string | number>;
    pattern?: ValidationRule<RegExp>;
    validate?: Validate<any> | Record<string, Validate<any>>;
  };
  style?: React.CSSProperties;
  dataCy?: string;
  minRows?: number;
  maxRows?: number;
  onBlur?: () => void;
  tooltip?: string | React.ReactFragment;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  background?: 'white' | 'gray' | 'lightGray';
}

export const InputTextArea: React.FC<Props> = (props) => {
  return (
    <FormControl fullWidth data-cy={props.dataCy}>
      <Container padding={0} align="flex-start">
        <ContainerItem padding={0} align="center" flex margin="0 0 6px 0">
          <InputLabel
            dataCy={'input-label'}
            label={props.label}
            name={props.name}
            required={props.viewOnly ? false : props.rules?.required?.value}
            readOnly={props.readOnly}
            rules={props.rules}
            viewOnly={props.viewOnly}
          />
        </ContainerItem>

        {props.tooltip ? (
          <ContainerItem padding={0} align="center" flex margin="-3px 0 0 2px">
            <Tooltip variant="text" title={props.tooltip} placement="right">
              <Icon type="help" color="primary" />
            </Tooltip>
          </ContainerItem>
        ) : null}
      </Container>

      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
          <>
            <Input
              multiline
              readOnly={props.readOnly}
              name={props.name}
              dataCy={'input-text'}
              onBlur={() => {
                if (props.readOnly) {
                  return;
                }

                if (props.onBlur) {
                  props.onBlur();
                }

                onBlur();
              }}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }

                onChange(e.target.value);
              }}
              value={(props.readOnly || props.viewOnly) && (value == null || value === '') ? '--' : value}
              error={error}
              background={props.background || 'white'}
              placeholder={props.placeholder}
              viewOnly={props.viewOnly}
              focus={props.focus}
              minRows={props.minRows ? props.minRows : 3}
              maxRows={props.maxRows}
            />
            <Error
              content={error ? `* ${props.label} ${error.message || errorMessages()[error.type]}` : ''}
            />
          </>
        )}
      />
    </FormControl>
  );
};
