import { makeAutoObservable } from 'mobx';

export class Model {
  constructor() {
    makeAutoObservable(this);
  }

  customTimeRangeOptionLabel = 'Custom';
}

export const model = new Model();
