import { Avatar as MuiAvatar } from '@mui/material';
import React from 'react';
import { theme } from 'ui/theme';

export interface AvatarProps {
  content: string;
  size: 'small' | 'medium' | 'large' | 'extraLarge';
  dataCy?: string;
}

const commonStyles = { backgroundColor: '#F2F4FA', color: '#3A3C45' };

const size = {
  small: {
    width: '24px',
    height: '24px',
    fontFamily: 'Lato',
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '14px',
    border: `2px solid ${theme.palette.common.white}`,
    whiteSpace: 'nowrap'
  },
  medium: {
    width: '34px',
    height: '34px',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    border: `2px solid ${theme.palette.common.white}`,
    whiteSpace: 'nowrap'
  },
  large: {
    width: '42px',
    height: '42px',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '14px',
    border: `2px solid ${theme.palette.common.white}`,
    whiteSpace: 'nowrap'
  },
  extraLarge: {
    width: '130px',
    height: '130px',
    fontFamily: 'Lato',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '60px',
    border: `2px solid ${theme.palette.common.white}`,
    whiteSpace: 'nowrap'
  }
};

export const Avatar: React.FC<AvatarProps> = (props) => {
  return (
    <MuiAvatar
      sx={{
        ...size[props.size],
        ...commonStyles
      }}
      data-cy={props.dataCy}
    >
      {props.content}
    </MuiAvatar>
  );
};
