import { CRBMBusinessSearchResult, CRBMLicense } from '@gcv/shared';
import { api } from './api-util/api';

export class CrbmApi {
  async getLicense(licenseId: string): Promise<CRBMLicense> {
    return await api().get(`/crbm/license/${licenseId}`);
  }

  async searchLicenses(orgId: string, licenseNumber: string): Promise<CRBMLicense[]> {
    return await api().get(`/crbm/license`, { orgId, licenseNumber });
  }

  async searchBusinesses(orgId: string): Promise<CRBMBusinessSearchResult[]> {
    return await api().get(`/crbm/business`, { orgId });
  }

  async searchBusinessLicenses(orgId: string): Promise<CRBMLicense[]> {
    return await api().get(`/crbm/business/${orgId}/license`);
  }
}
