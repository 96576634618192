import {
  OnboardingDocumentRequirement,
  OnboardingDocumentRequirementResult,
  RequirementCategory
} from '@gcv/shared';
import { Box } from '@mui/system';
import { useComponent } from 'hooks';
import * as React from 'react';
import { Container, ContainerItem, Spinner } from 'ui';
import { CommentAddedBox } from './comment-added-box';
import { FileUploadedBox } from './file-uploaded-box';
import { MultipleFilesUploadedBox } from './multiple-files-uploaded-box';
import { UploadFileButton } from './upload-file-button';
import { UploadLicenseButton } from './upload-license-button';
import { useInjection } from 'ioc';
import { OnboardingPresenter } from 'ui/apps/crb/onboarding-two/onboarding.presenter';
import { useMediaQuery } from '@mui/material';
import { theme } from 'ui/theme';

interface Props extends Record<string, unknown> {
  requirement: OnboardingDocumentRequirement;
  result?: OnboardingDocumentRequirementResult;
}

export const RequirementActionsContainer: React.FC<Props> = useComponent(({ requirement, result }) => {
  const presenter = useInjection(OnboardingPresenter);
  const [viewAll, setViewAll] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const doesRequirementHaveComment = () => {
    return result?.do_not_have_comments && result?.do_not_have && !doesRequirementHaveDocuments();
  };
  const doesRequirementHaveDocuments = () => {
    return result?.documents && Object.keys(result.documents).length > 0;
  };

  return (
    <Container column justify="center" padding={0} width="100%" align="center">
      {doesRequirementHaveComment() && (
        <ContainerItem justify="center" padding={0} align="center">
          <CommentAddedBox requirement={requirement} result={result} />
        </ContainerItem>
      )}

      {doesRequirementHaveDocuments() && (viewAll || Object.keys(result!.documents).length === 1) ? (
        <div
          style={{
            border: '1px solid #C6CCDA',
            borderRadius: '6px',
            padding: isMobile ? '0' : '0px 8px',
            maxWidth: '400px',
            minWidth: isMobile ? 'unset' : '300px'
          }}
        >
          <ContainerItem dataCy="files-uploaded-container" padding={0} width="100%">
            {Object.values(result!.documents).map((doc, index) => {
              return (
                <Box
                  sx={{
                    borderBottom:
                      index !== Object.values(result!.documents).length - 1 ? '1px solid #C6CCDA' : ''
                  }}
                >
                  <FileUploadedBox
                    requirement={requirement}
                    result={result}
                    documentUpload={presenter.viewModel.allDocumentsMap[doc.id]}
                    documentId={doc.id}
                    key={doc.id}
                  />
                </Box>
              );
            })}
          </ContainerItem>
        </div>
      ) : null}

      {doesRequirementHaveDocuments() &&
      !viewAll &&
      result?.documents &&
      Object.keys(result.documents).length > 1 ? (
        <ContainerItem padding={0} width="100%" maxWidth="300px">
          <MultipleFilesUploadedBox setViewAll={() => setViewAll(true)} />
        </ContainerItem>
      ) : null}

      {!doesRequirementHaveComment() ? (
        <ContainerItem padding={0} width="100%" justify="center">
          {requirement.category !== RequirementCategory.License ? (
            <UploadFileButton requirement={requirement} result={result} />
          ) : (
            <UploadLicenseButton requirement={requirement} result={result} />
          )}
        </ContainerItem>
      ) : null}
    </Container>
  );
});
