import { action, makeAutoObservable } from 'mobx';

type ColorTypes = 'primary' | 'secondary' | 'warning' | '';

interface BannerObject {
  color: ColorTypes;
  message: string;
  isOpen: boolean;
  action?: () => void;
  actionLabel?: string;
}

class BannerStore {
  banner: BannerObject = {
    color: '',
    message: '',
    isOpen: false
  };

  constructor() {
    makeAutoObservable(this);
  }

  hideBanner = action(() => {
    this.banner = {
      ...this.banner,
      message: '',
      isOpen: false
    };
  });

  setBanner = action((color: ColorTypes, message: string, actionLabel: string, action?: () => void) => {
    this.banner = {
      color,
      message,
      action,
      actionLabel,
      isOpen: true
    };
  });

  clearStore = action(() => {
    this.banner = {
      color: '',
      message: '',
      isOpen: false
    };
  });
}

let bannerStore: BannerStore | undefined;

export function getBannerStore(): BannerStore {
  if (!bannerStore) {
    bannerStore = new BannerStore();
  }

  return bannerStore;
}
