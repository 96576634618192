import { AppAreaName, AppAreaOwner, DispensaryId } from '@gcv/shared';
import { BanksApi } from 'api';
import { FiBankStore, getFiBankStore } from 'stores/FiBankStore';
import { v4 as uuid } from 'uuid';

export class RelationshipManagerService {
  private banksApi: BanksApi = new BanksApi();
  private bankStore: FiBankStore = getFiBankStore();

  public loadRelationshipManagers = async () => {
    const accountOwnerMetadata = await this.banksApi.getAccountOwnerMetadata(this.bankStore.bank.id);
    const managers: Record<DispensaryId, string> = {};

    Object.entries(accountOwnerMetadata.value.crb_level).forEach(([key, value]) => {
      for (const appArea of value) {
        if (appArea.label === 'Relationship Manager') {
          const assigned = this.bankStore.staff.find((s) => s.id === appArea.assigned_user);

          if (assigned) {
            managers[key] = `${assigned.firstName} ${assigned.lastName}`;
          } else {
            const group = this.bankStore.bank.groups.find((g) => g.id === appArea.assigned_group);

            if (group) {
              managers[key] = group.name;
            } else {
              managers[key] = '--';
            }
          }
        }
      }
    });

    return managers;
  };

  public saveRelationshipManager = async (orgId: string, relationshipManagerId: string) => {
    const accountOwnerMetadata = await this.banksApi.getAccountOwnerMetadata(this.bankStore.bank.id);
    const accountOwnerData = accountOwnerMetadata.value;

    if (!accountOwnerData.crb_level[orgId]) {
      accountOwnerData.crb_level[orgId] = [];
    }

    let relationshipManagerData = accountOwnerData.crb_level[orgId].find(
      (appAreaOwner) => appAreaOwner.label === AppAreaName.RelationshipManager
    );

    if (!relationshipManagerData) {
      const newRelationshipManagerData = {
        label: 'Relationship Manager',
        assigned_user: null,
        assigned_group: null,
        id: uuid()
      } as AppAreaOwner;

      relationshipManagerData = newRelationshipManagerData;
      accountOwnerData.crb_level[orgId].push(relationshipManagerData);
    }

    if (this.bankStore.staff.some((u) => u.id === relationshipManagerId)) {
      const user = this.bankStore.staff.find((s) => s.id === relationshipManagerId);

      if (user) {
        relationshipManagerData.assigned_user = user.id;
        relationshipManagerData.assigned_group = null;
      }
    } else if (this.bankStore.bank.groups.some((g) => g.id === relationshipManagerId)) {
      const group = this.bankStore.bank.groups.find((g) => g.id === relationshipManagerId);

      if (group) {
        relationshipManagerData.assigned_user = null;
        relationshipManagerData.assigned_group = group.id;
      }
    } else {
      relationshipManagerData.assigned_user = null;
      relationshipManagerData.assigned_group = null;
    }

    await this.banksApi.updateAccountOwnerMetadata(this.bankStore.bank.id, accountOwnerData);
  };
}
