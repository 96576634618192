import { DocumentUpload, DocumentUploadSymlink, OnboardingDocumentRequirement } from '@gcv/shared';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as AttachmentIcon } from 'assets/images/ic-clip-grey.svg';
import { ReactComponent as FileIcon } from 'assets/images/ic-file.svg';
import { ReactComponent as OpenExternalIcon } from 'assets/images/ic-external.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import {
  Button,
  Container,
  ContainerItem,
  Dialog,
  InputFile,
  Table,
  TableContainer,
  Text,
  Column,
  Row,
  Hyperlink
} from 'ui';
import { OnboardingPresenter } from 'ui/apps/crb/onboarding-two/onboarding.presenter';
import { DateTimeHelpers } from 'util/dateTime.util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cropInput } from 'util/format.util';
import palette from 'ui/theme/palette';

interface UploadFileModalProps extends Record<string, unknown> {
  requirement: OnboardingDocumentRequirement;
  fileUploadOpen: boolean;
  setFileUploadOpen: (open: boolean) => void;
}

interface TableData {
  file_name: string;
  uploaded_on: string;
  requirement_name: string;
  document_id: string;
  org_id: string;
}

export const UploadFileModal: React.FC<UploadFileModalProps> = useComponent(
  ({ requirement, fileUploadOpen, setFileUploadOpen }) => {
    const { documentSharing }: { documentSharing: boolean } = useFlags();
    const presenter = useInjection(OnboardingPresenter);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tempFiles, setTempFiles] = React.useState<File[]>([]);
    const [showAllAttachments, setShowAttachments] = React.useState(false);
    const [selectedDocuments, setSelectedDocuments] = React.useState<
      { file_name: string; uploaded_on: string; requirement_name: string; document_id: string }[]
    >([]);
    const defaultMaxFilesToShow = 5;

    const columns: Column<TableData>[] = [
      {
        id: 'file_name',
        label: 'My Documents',
        widthPercent: !isMobile ? 45 : undefined,
        format: (td) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>{isMobile ? cropInput(td.file_name, 12, 'document') : td.file_name}</div>
              <ContainerItem padding={0} margin={0}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => presenter.openDocument(td.document_id, td.file_name)}
                >
                  <OpenExternalIcon />
                </div>
              </ContainerItem>
            </div>
          );
        }
      },
      { id: 'requirement_name', label: 'Requirement', widthPercent: !isMobile ? 35 : undefined },
      { id: 'uploaded_on', label: 'Uploaded On', widthPercent: !isMobile ? 20 : undefined }
    ];

    const rows: Row<TableData>[] = [];

    const isRootOrLinkedInRequirementResult = (sharedRootDoc: DocumentUpload): boolean => {
      const requirementResult = presenter.viewModel.templateResults.requirements_results.find(
        (docReqResult) => docReqResult.requirement_id === requirement.requirement_id
      );
      if (!requirementResult?.documents || Object.keys(requirementResult.documents).length === 0) {
        return false;
      } else {
        const requirementResultDocIds = Object.keys(requirementResult.documents);
        if (requirementResultDocIds.includes(sharedRootDoc.id)) {
          return true;
        }
        for (const requirementResultDocId of requirementResultDocIds) {
          const linkedDocId = (
            presenter.viewModel.allDocumentsMap[requirementResultDocId] as DocumentUploadSymlink
          ).linked_doc_id;
          if (!linkedDocId) continue;
          const splitLinkedDocId = linkedDocId.split('_');
          if (splitLinkedDocId.length !== 2) continue;
          const rootDocId = splitLinkedDocId[1];
          if (sharedRootDoc.id === rootDocId) {
            return true;
          }
        }
        return false;
      }
    };

    if (fileUploadOpen) {
      presenter.viewModel.sharedRootDocs.map((sharedRootDoc) => {
        for (const document of sharedRootDoc.documents) {
          if (!document.deleted && !isRootOrLinkedInRequirementResult(document)) {
            rows.push({
              id: document.id,
              data: {
                file_name: document.file_name,
                uploaded_on: DateTimeHelpers.formatISOToTableDateString(
                  document.upload_date,
                  presenter.viewModel.dispensary.iana_timezone
                ),
                requirement_name: sharedRootDoc.requirement.name,
                document_id: document.id
              }
            } as Row<TableData>);
          }
        }
      });
    }

    const getInitiallySelectedDocIds = (): string[] => {
      const docIds: string[] = [];
      presenter.viewModel.sharedRootDocs.map((doc) => {
        if (doc.requirement.requirement_id === requirement.requirement_id) {
          doc.documents.map((d) => docIds.push(d.id));
        }
      });
      return docIds;
    };

    const handleSaveClick = async (): Promise<void> => {
      if (!documentSharing) {
        presenter.uploadFilesToRequirement(requirement, tempFiles);
      } else {
        if (selectedDocuments.length) {
          const sharedDocuments: DocumentUpload[] = [];
          for (const rootDoc of presenter.viewModel.sharedRootDocs) {
            for (const doc of rootDoc.documents) {
              if (selectedDocuments.some((sd) => sd.document_id === doc.id)) {
                sharedDocuments.push(doc);
              }
            }
          }
          presenter.uploadFilesToRequirement(requirement, tempFiles, sharedDocuments);
        } else {
          presenter.uploadFilesToRequirement(requirement, tempFiles);
        }
      }
    };

    const rowsAndSharedRootDocsGreaterThanZero =
      presenter.viewModel.sharedRootDocs && presenter.viewModel.sharedRootDocs.length > 0 && rows.length > 0;

    return (
      <Dialog
        action={
          <Button
            label="Save"
            color="primary"
            disabled={tempFiles.length === 0 && selectedDocuments.length === 0}
            onClick={handleSaveClick}
            dataCy="upload-button"
            style={{ minWidth: isMobile ? '100%' : '100px' }}
            isLoading={
              presenter.viewModel.isLoadingDocumentChange || presenter.viewModel.isLoadingDocumentUpload
            }
          />
        }
        handleClose={() => setFileUploadOpen(false)}
        isOpen={fileUploadOpen}
        title={
          documentSharing && rowsAndSharedRootDocsGreaterThanZero
            ? `Select or upload ${requirement.name}`
            : documentSharing && !rowsAndSharedRootDocsGreaterThanZero
            ? `Upload ${requirement.name} document`
            : requirement.name
        }
        dataCy="upload-file-modal"
        width={
          isMobile ? '360px' : documentSharing && rowsAndSharedRootDocsGreaterThanZero ? '1190px' : '432px'
        }
      >
        <Container column padding="0">
          {documentSharing && rowsAndSharedRootDocsGreaterThanZero && (
            <ContainerItem padding="0 0 8px 0">
              <Text
                sx={{ color: palette.text.secondary }}
                content="Choose from previously uploaded documents, or upload new documents."
              />
            </ContainerItem>
          )}
          <ContainerItem padding="0 0 0 0" width="100%">
            <Container padding="0" width="100%">
              {documentSharing && rowsAndSharedRootDocsGreaterThanZero && (
                <ContainerItem width={isMobile ? '100%' : '60%'} padding="8px 8px 8px 0">
                  <TableContainer>
                    <Table
                      maxHeight="360px"
                      selectable
                      columns={columns}
                      defaultSelected={
                        presenter.viewModel.sharedRootDocs.some(
                          (srd) => srd.requirement.requirement_id === requirement.requirement_id
                        )
                          ? getInitiallySelectedDocIds()
                          : undefined
                      }
                      returnSelection={(selectedRows) => {
                        const selectedDocs: {
                          file_name: string;
                          uploaded_on: string;
                          requirement_name: string;
                          document_id: string;
                        }[] = [];
                        for (const row of rows) {
                          if (selectedRows.includes(row.id)) {
                            selectedDocs.push(row.data);
                          }
                        }
                        setSelectedDocuments(selectedDocs);
                      }}
                      rows={rows}
                      defaultSort={'selected' as keyof TableData}
                      onClick={() => {
                        return;
                      }}
                      minWidth={isMobile ? '600px' : undefined}
                      noPagination
                    />
                  </TableContainer>
                </ContainerItem>
              )}

              <ContainerItem
                padding={0}
                width={
                  isMobile ? '100%' : documentSharing && rowsAndSharedRootDocsGreaterThanZero ? '40%' : '100%'
                }
              >
                <Box
                  sx={{
                    borderLeft:
                      documentSharing && rowsAndSharedRootDocsGreaterThanZero
                        ? '1px solid #C6CCDA'
                        : undefined,
                    minHeight: documentSharing && rowsAndSharedRootDocsGreaterThanZero ? '400px' : undefined,
                    paddingLeft: documentSharing && rowsAndSharedRootDocsGreaterThanZero ? '8px' : undefined
                  }}
                >
                  {requirement.attachments?.length ? (
                    <>
                      <Text
                        content="Attachments"
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '13px',
                          lineHeight: '15px',
                          marginBottom: '0.5rem'
                        }}
                      />
                      <Text
                        type="body3"
                        content={
                          'Your financial institution has provided the following documents or templates to help you fulfill this requirement. Please view any attachments before uploading your own documents.'
                        }
                      />

                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {requirement.attachments
                          .slice(
                            0,
                            showAllAttachments ? requirement.attachments.length : defaultMaxFilesToShow
                          )
                          .map((file, index) => (
                            <Box key={file.id} sx={{ display: 'flex', alignItems: 'start', mr: '0.25rem' }}>
                              <Box sx={{ pt: '2px' }}>
                                <AttachmentIcon />
                              </Box>
                              <Hyperlink
                                onClick={() => presenter.viewAttachment(file.id)}
                                label={file.file_name}
                                containerSx={{
                                  '::after': {
                                    content:
                                      requirement.attachments && index !== requirement.attachments?.length - 1
                                        ? '","'
                                        : '""'
                                  },
                                  mr: '4px'
                                }}
                              />
                            </Box>
                          ))}

                        {requirement.attachments && requirement.attachments?.length > defaultMaxFilesToShow && (
                          <Button
                            style={{
                              padding: 0,
                              textDecoration: 'underline',
                              height: '16px'
                            }}
                            color="text"
                            label={showAllAttachments ? 'See less files' : 'See more files'}
                            onClick={() => {
                              setShowAttachments(!showAllAttachments);
                            }}
                          />
                        )}
                      </Box>
                    </>
                  ) : null}

                  {documentSharing && rowsAndSharedRootDocsGreaterThanZero && (
                    <Box width="100%">
                      <Text content="Selected documents" />
                      <Box
                        sx={{
                          border: selectedDocuments.length ? '1px solid #C6CCDA' : undefined,
                          width: '100%',
                          borderRadius: selectedDocuments.length ? '4px' : undefined,
                          minHeight: '35px'
                        }}
                      >
                        {selectedDocuments.length === 0 && (
                          <Text
                            type="body2"
                            sx={{ color: palette.text.secondary }}
                            content="There are no documents selected"
                          />
                        )}
                        {selectedDocuments.map((doc, index) => {
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: '0.25rem',
                                justifyItems: 'center',
                                padding: '6px'
                              }}
                            >
                              <Box sx={{ pt: '2px', pr: '4px' }}>
                                <FileIcon />
                              </Box>
                              <Text sx={{ fontWeight: '700' }} content={doc.file_name} />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                  <Box sx={{ paddingTop: '10px' }}>
                    <Text content="Upload a new document" />
                    <InputFile
                      fullWidth
                      multiple={true}
                      acceptedTypes={'all'}
                      onFilesChange={(files: File[]) => {
                        setTempFiles([...files]);
                      }}
                      dataCy="upload-file-input"
                    />
                  </Box>
                </Box>
              </ContainerItem>
            </Container>
          </ContainerItem>
        </Container>
      </Dialog>
    );
  }
);
