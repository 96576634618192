import palette from './palette';

export default {
  h1: {
    color: palette.text.primary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px'
  },
  h2: {
    color: palette.text.primary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px'
  },
  h3: {
    color: palette.text.primary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px'
  },
  h4: {
    color: palette.text.primary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px'
  },
  h5: {
    color: palette.text.primary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '16px'
  },
  h6: {
    // this is our error style
    color: palette.error.main,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '16px'
  },
  body1: {
    color: palette.text.primary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px'
  },
  body2: {
    color: palette.text.primary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px'
  },
  subtitle1: {
    color: palette.text.primary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '15px'
  },
  subtitle2: {
    color: palette.text.secondary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px'
  },
  button: {
    color: palette.primary.main,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '16px',
    cursor: 'pointer'
  },
  a: {
    color: palette.link,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    cursor: 'pointer'
  }
};
