import { QuestionnaireResponse, QuestionnaireResultResponse } from '@gcv/shared';
import { QuestionnairesApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { FiBankStore } from 'stores/FiBankStore';

export interface PM {
  isLoading: boolean;
  questionnaireResponse: QuestionnaireResponse;
  questionnaireResultResponse: QuestionnaireResultResponse;
}

@injectable()
export class QuestionnaireRepo {
  @inject(QuestionnairesApi)
  private questionnairesApi: QuestionnairesApi;

  @inject(FiBankStore)
  private fiBankStore: FiBankStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PM = {
    isLoading: true,
    questionnaireResponse: {} as QuestionnaireResponse,
    questionnaireResultResponse: {} as QuestionnaireResultResponse
  };

  private updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  public reset = action(() => {
    this.updateProgrammersModel({
      isLoading: true,
      questionnaireResponse: {} as QuestionnaireResponse,
      questionnaireResultResponse: {} as QuestionnaireResultResponse
    });
  });

  public load = action(async (questionnaireId: string, crbId: string, responseId: string) => {
    const response = await this.questionnairesApi.getCRBQuestionnaireResponse(
      crbId,
      questionnaireId,
      responseId
    );
    const req = await this.questionnairesApi.getQuestionnaire(
      this.fiBankStore.bank.id,
      questionnaireId,
      response.template_version
    );

    runInAction(() => {
      this.updateProgrammersModel({
        isLoading: false,
        questionnaireResponse: req,
        questionnaireResultResponse: response
      });
    });
  });
}
