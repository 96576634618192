import {
  BankDispensaryMetadata,
  BankDispensaryMetadataData,
  CRBTemplates,
  CrbServiceProvider,
  DailySummary,
  Deposit,
  Dispensary,
  DispensaryBank,
  DocumentUpload,
  DueDiligenceDocument,
  EmptyAuth,
  EventTypes,
  GetUserConnectedInfoResponse,
  Group,
  IANATimezones,
  Invitation,
  InvitationConfirmationType,
  InviteNewUserRequest,
  MarketplaceServiceProviderOfferResponse,
  MaxDeposit,
  MinifiedReviewCoreTransaction,
  OnboardingDocumentRequirement,
  PartialDispensaryForGcvAdmin,
  PosConfig,
  PosOrganizationLocation,
  Post,
  Sale,
  TransportVendor,
  USStates,
  User
} from '@gcv/shared';
import { injectable } from 'inversify';
import { FieldValues } from 'react-hook-form';
import { getFiBankStore } from 'stores/FiBankStore';
import { DateTimeHelpers } from 'util/dateTime.util';
import { api, banksApi } from './api-util/api';
import { ApiResponse } from './api-util/api-response';

@injectable()
export class DispensariesApi {
  async getDispensary(dispensaryId: string): Promise<Dispensary> {
    return await api().get(`/dispensaries/${dispensaryId}`);
  }

  async getLimitedDispensaries(userId: string): Promise<GetUserConnectedInfoResponse[]> {
    return await api().get(`/users/${userId}/connected-info`);
  }

  async deleteDispensary(dispensaryId: string): Promise<null> {
    return await api().delete(`/dispensaries/${dispensaryId}`);
  }

  /** @deprecated Deprecated for marketplace things */
  async getDispensaryBank(dispensaryId: string): Promise<DispensaryBank> {
    return await api().get(`/dispensaries/${dispensaryId}/bank`);
  }

  async getDispensaryServiceProviders(
    dispensaryId: string
  ): Promise<{ [bankId: string]: CrbServiceProvider }> {
    return await api().get(`/dispensaries/${dispensaryId}/service-providers`);
  }

  async getAllDispensaries(): Promise<PartialDispensaryForGcvAdmin[]> {
    return await api().get(`/gcv/dispensaries`);
  }

  async getLastSyncedData(dispensaryId: string): Promise<any> {
    return await api().get(`/dispensaries/${dispensaryId}/lastSync`);
  }

  async createDispensary(body: {
    name: string;
    state: USStates;
    posConfig: PosConfig<EmptyAuth>;
    iana_timezone: IANATimezones;
    country: string;
  }): Promise<PartialDispensaryForGcvAdmin> {
    return await api().post('/dispensaries', body);
  }

  async getUsersInvitations(userId: string): Promise<Invitation[]> {
    return await api().get(`/users/${userId}/invitation-list`);
  }

  async confirmDispensaryInvitation(
    invitationId: string,
    newAccountName: string,
    confirmationType: InvitationConfirmationType,
    crbId: string
  ): Promise<any> {
    return await api().post(`/users/confirm-invitation`, {
      invitation_id: invitationId,
      new_account_name: newAccountName,
      confirmation_type: confirmationType,
      crb_id: crbId
    });
  }

  // using any here to cover any of the extended auth interfaces for each pos
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getPosConfig(dispensaryId: string): Promise<PosConfig<any>> {
    return await api().get(`/dispensaries/${dispensaryId}/posConfig`);
  }

  // using any here to cover any of the extended auth interfaces for each pos
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async updatePosConfig(dispId: string, posConfig: PosConfig<any>): Promise<Dispensary> {
    return await api().put(`/dispensaries/${dispId}/posConfig`, { posConfig });
  }

  async setInitialMaxDeposit(dispensaryId: string, newMaxDeposit: number): Promise<MaxDeposit> {
    return await api().put(`/dispensaries/${dispensaryId}/initialMaxDeposit`, {
      maxDeposit: newMaxDeposit
    });
  }

  async setMaxDeposit(bankId: string, dispensaryId: string, newMaxDeposit: number): Promise<MaxDeposit> {
    return await banksApi().put(`/banks/${bankId}/dispensaries/${dispensaryId}/maxDeposit`, {
      newMaxDeposit
    });
  }

  async migrateDocuments(
    sourceDispensaryId: string,
    targetDispensaryId: string,
    sourceBankId: string,
    targetBankId: string
  ): Promise<unknown> {
    return await api().post(`/gcv/migrate-documents`, {
      sourceDispensaryId,
      targetDispensaryId,
      sourceBankId,
      targetBankId
    });
  }

  async triggerSalesIngestion(
    dispensaryId: string,
    start: string,
    end?: string,
    hours_to_increment?: number
  ): Promise<unknown> {
    return await api().post(`/gcv/dispensary/${dispensaryId}/ingest-sales`, {
      start_date: start,
      end_date: end,
      hours_to_increment: hours_to_increment ? hours_to_increment : 24
    });
  }

  async getDispensaryStaff(dispensaryId: string): Promise<User[]> {
    return await api().get(`/organizations/${dispensaryId}/users`);
  }

  async updateDispensaryBusinessDetails(
    dispensaryId: string,
    data: {
      name?: string;
      dba?: string;
      entityType?: string;
      establishedDate?: string;
      ein?: string;
      streetAddress?: string;
      city?: string;
      state?: string;
      country?: string;
      postalCode?: string;
      website?: string;
      phone?: string;
      iana_timezone?: string;
      mailingStreetAddress?: string;
      mailingCity?: string;
      mailingState?: string;
      mailingPostalCode?: string;
    }
  ): Promise<Dispensary> {
    return await api().put(`/dispensaries/${dispensaryId}/business-details`, {
      ...data,
      mailingStreetAddress: data.mailingStreetAddress ? data.mailingStreetAddress : data.streetAddress,
      mailingCity: data.mailingCity ? data.mailingCity : data.city,
      mailingState: data.mailingState ? data.mailingState : data.state,
      mailingPostalCode: data.mailingPostalCode ? data.mailingPostalCode : data.postalCode
    });
  }

  async updateDispensaryOperationalDetails(
    dispensaryId: string,
    data: {
      businessHours?: any;
      monthlySales?: number;
      monthlyCustomers?: string;
      pos_name?: string;
      ftEmployees?: string;
      ptEmployees?: string;
      business_type?: 'retail' | 'wholesale';
    }
  ): Promise<Dispensary> {
    return await api().put(`/dispensaries/${dispensaryId}/operational-details`, data);
  }

  async trackSalesUpload(dispensaryId: string, data: Record<string, any>): Promise<Dispensary> {
    return await api().post(`/document-management/dispensaries/${dispensaryId}/track/sales/upload`, data);
  }

  async updateDispensaryBankCustomFields(
    dispensaryId: string,
    formId: string,
    responses: Record<string, unknown>
  ): Promise<Dispensary> {
    return await api().post(`/dispensaries/${dispensaryId}/bank-custom-fields`, { formId, responses });
  }

  async updateDispensaryGroup(dispId: string, updatedGroups: Group[]): Promise<Dispensary> {
    return await api().put(`/groups/${dispId}`, updatedGroups);
  }

  async inviteDispensaryUser(
    dispensaryId: string,
    data: InviteNewUserRequest
  ): Promise<{ org: Dispensary; user: User }> {
    return await api().post(`/dispensaries/${dispensaryId}/invite-user`, data);
  }

  async getMaxDeposit(dispensaryId: string): Promise<MaxDeposit> {
    return await api().get(`/dispensaries/${dispensaryId}/maxDeposit`);
  }

  async getMetaData(bankId: string, dispId: string): Promise<BankDispensaryMetadata | undefined> {
    return await api().get(`/banks/${bankId}/dispensary/${dispId}/metadata`);
  }

  async saveMetaData(
    bankId: string,
    dispId: string,
    body: BankDispensaryMetadataData
  ): Promise<BankDispensaryMetadata> {
    return await api().post(`/banks/${bankId}/dispensary/${dispId}/metadata`, body);
  }

  async getInternalDocuments(bankId: string, dispId: string): Promise<DocumentUpload[]> {
    return await api().get(`/banks/${bankId}/dispensary/${dispId}/internal-documents`);
  }

  async favoriteTransportVendor(
    dispId: string,
    transportVendorId: string,
    vendorType: 'user' | 'bank' | 'vendor'
  ): Promise<Dispensary> {
    return await api().put(
      `/dispensaries/${dispId}/transportVendor/${vendorType}/${transportVendorId}/favorite`,
      {}
    );
  }
  async editTransportVendor(
    dispId: string,
    transportVendorId: string,
    vendorType: 'user' | 'bank' | 'vendor',
    transportVendor: TransportVendor
  ): Promise<Dispensary> {
    return await api().put(
      `/dispensaries/${dispId}/transportVendor/${vendorType}/${transportVendorId}`,
      transportVendor
    );
  }

  async getTransportVendor(transportVendorId: string): Promise<TransportVendor | User> {
    return await api().get(`/transportVendor/${transportVendorId}`);
  }

  async createTransportVendor(dispensaryId: string, vendor: TransportVendor): Promise<Dispensary> {
    return await api().post(`/dispensaries/${dispensaryId}/transportVendor`, vendor);
  }

  async createDeposit(dispensaryId: string, bankId: string, deposit: FieldValues): Promise<Deposit> {
    return await api().post(`/dispensaries/${dispensaryId}/banks/${bankId}/deposits`, deposit);
  }

  async getDailySummaries(dispensaryId: string, startDate: string, endDate: string): Promise<DailySummary[]> {
    return await api().get(
      `/dispensaries/${dispensaryId}/dailySummary?startDate=${startDate}&endDate=${endDate}`
    );
  }
  async createDailySummariesReport(
    dispensaryId: string,
    payload: { reportData: { dispensaryId: string; endDate: string; startDate: string } }
  ) {
    return await api().post(`/dispensaries/${dispensaryId}/reporting/dailySummaries`, payload);
  }
  async createSalesReport(
    dispensaryId: string,
    payload: { reportData: { dispensaryId: string; endDate: string; startDate: string } }
  ) {
    return await api().post(`/dispensaries/${dispensaryId}/reporting/sales`, payload);
  }
  async createDepositsReport(
    dispensaryId: string,
    payload: { reportData: { dispensaryId: string; endDate: string; startDate: string } }
  ) {
    return await api().post(`/dispensaries/${dispensaryId}/reporting/deposits`, payload);
  }
  async getDeposits(dispensaryId: string, startDate?: string, endDate?: string): Promise<Deposit[]> {
    return await api().get(`/dispensaries/${dispensaryId}/deposits`, { startDate, endDate });
  }
  async getSales(dispensaryId: string, startDate: string, endDate: string): Promise<Sale[]> {
    return await api().get(`/dispensaries/${dispensaryId}/sales`, { startDate, endDate });
  }

  async getEventByType(dispensaryId: string, eventType: EventTypes): Promise<any[]> {
    return await api().get(`/dispensaries/${dispensaryId}/event/${eventType}`);
  }

  async setPosConfig(
    dispensaryId: string,
    data: {
      posConfig: {
        posName: any;
        auth?: any;
        fetch?:
          | {
              fetchStrategy: string;
            }
          | {
              fetchStrategy: string;
            }
          | undefined;
        status?: string | undefined;
      };
    }
  ): Promise<ApiResponse<Dispensary>> {
    return await api().putVerboseResponse(`/dispensaries/${dispensaryId}/posConfig`, data);
  }

  async getPosLocations(dispensaryId: string): Promise<PosOrganizationLocation[]> {
    return await api().get(`/dispensaries/${dispensaryId}/posConfig/locations`);
  }

  async putPosLocation(dispensaryId: string, locationId: string): Promise<void> {
    return await api().put(`/dispensaries/${dispensaryId}/posConfig/locations`, { location_id: locationId });
  }

  async syncSales(dispId: string) {
    return await api().put(`/dispensaries/${dispId}/sales/sync`, {});
  }

  async fetchTxForInternalIds(
    ids: string[],
    details: {
      startDate: string;
      endDate: string;
    }
  ): Promise<MinifiedReviewCoreTransaction[]> {
    const { id: bankId, iana_timezone: timezone } = getFiBankStore().bank;
    const path = `/banks/${bankId}/core-transactions/internalId/query?startDate=${DateTimeHelpers.formatISOToDateString(
      details.startDate,
      timezone
    )}&endDate=${DateTimeHelpers.formatISOToDateString(details.endDate, timezone)}`;

    return await api().post(path, { internalIds: ids });
  }

  async getMarketplaceOffersForDispensary(
    dispensaryId: string,
    isPlantTouching: boolean
  ): Promise<MarketplaceServiceProviderOfferResponse[]> {
    return await api().get(
      `/dispensaries/${dispensaryId}/marketplace/offerings?plant_touching=${isPlantTouching}`
    );
  }

  async applyToMarketplaceOffer(
    dispensaryId: string,
    offerId: string,
    bankId: string,
    isPlantTouching: boolean
  ): Promise<CRBTemplates> {
    return await api().post(`/dispensaries/${dispensaryId}/marketplace/offer/${offerId}/apply`, {
      bank_id: bankId,
      plant_touching: isPlantTouching
    });
  }

  async sendLearnMoreOfferEmail(
    dispensaryId: string,
    offerId: string,
    emailTemplateId: string,
    mailMerge: Record<string, string>[]
  ): Promise<any> {
    return await api().post(`/dispensaries/${dispensaryId}/marketplace/offer/${offerId}/learn-more`, {
      emailTemplateId,
      mailMerge
    });
  }

  async sendConnectNowEmail(dispensaryId: string, bankId: string, offerId: string): Promise<void> {
    return await api().post(
      `/dispensaries/${dispensaryId}/banks/${bankId}/marketplace/offer/${offerId}/connect-now`,
      {}
    );
  }

  async getPostForDispensary(crbId: string, postId: string): Promise<Post> {
    return await api().get(`/dispensaries/${crbId}/post/${postId}`);
  }

  async getSharedRootDocuments(crbId: string): Promise<
    {
      requirement: OnboardingDocumentRequirement;
      ddDocuments: DueDiligenceDocument[];
      documents: DocumentUpload[];
    }[]
  > {
    return await api().get(`/dispensaries/${crbId}/shared-root-documents`);
  }

  async getAllRootDocuments(crbId: string): Promise<
    {
      requirement: OnboardingDocumentRequirement;
      ddDocuments: DueDiligenceDocument[];
      documents: DocumentUpload[];
    }[]
  > {
    return await api().get(`/dispensaries/${crbId}/all-root-documents`);
  }

  async updateDispensaryConfig(
    dispensaryId: string,
    config: { allow_edit_profile: boolean }
  ): Promise<Dispensary> {
    return await api().put(`/dispensaries/${dispensaryId}/config`, config);
  }
}
