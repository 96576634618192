import { Divider as DividerContainer } from '@mui/material';
import * as React from 'react';

interface Props {
  dataCy?: string;
  vertical?: boolean;
}

export const Divider: React.FC<Props> = (props) => {
  const dataCy = props.dataCy ? props.dataCy : '';
  const orientation = props.vertical ? 'vertical' : 'horizontal';
  const width = props.vertical ? '1px' : '100%';

  return (
    <DividerContainer
      data-cy={dataCy}
      orientation={orientation}
      sx={{ m: 0, width: width }}
      variant={props.vertical ? 'inset' : 'fullWidth'}
    />
  );
};
