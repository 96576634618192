import { DialogActions } from '@mui/material';
import * as React from 'react';

interface Props {}

export const DialogFooter: React.FC<Props> = (props) => {
  return (
    <DialogActions sx={{ padding: '0' }} data-cy="dialog-footer">
      {props.children}
    </DialogActions>
  );
};
