import { environment } from 'environments/environment';
import { useComponent } from 'hooks/useComponent';
import { useInjection } from 'ioc';
import * as React from 'react';
import { Button, Container, ContainerItem, Dialog, FileContainer, Spinner } from 'ui';
import { OnboardingPresenter } from '../../../onboarding.presenter';

interface Props extends Record<string, unknown> {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  isMobile: boolean;
}

export const UploadIdentificationDocsModal: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const { modalOpen, setModalOpen, isMobile } = props;

  const [idFront, setIdFront] = React.useState<File>();
  const [idBack, setIdBack] = React.useState<File>();
  const [idFrontRemoved, setIdFrontRemoved] = React.useState<boolean>(false);
  const [idBackRemoved, setIdBackRemoved] = React.useState<boolean>(false);

  const handleClose = () => {
    setIdFrontRemoved(false);
    setIdBackRemoved(false);
    setModalOpen(false);
  };

  const handleSave = () => {
    if (idFront || idBack) {
      presenter.updateIdentityDocs(idFront, idBack).then(() => {
        handleClose();
      });
    }
  };

  return (
    <Dialog
      isOpen={modalOpen}
      title="Upload Identification Documents"
      handleClose={handleClose}
      action={
        <Button
          isLoading={presenter.viewModel.isSavingIdDocs}
          label="Save"
          color="primary"
          onClick={handleSave}
          disabled={
            ((!presenter.viewModel.idFrontDocument || idFrontRemoved) && !idFront) ||
            ((!presenter.viewModel.idBackDocument || idBackRemoved) && !idBack) ||
            (presenter.viewModel.idFrontDocument &&
              presenter.viewModel.idBackDocument &&
              !idFrontRemoved &&
              !idBackRemoved)
          }
          style={{ minWidth: isMobile ? '100%' : '100px' }}
          dataCy="save-ids-button"
        />
      }
    >
      {presenter.viewModel.isDeletingIdDocs ? (
        <Spinner />
      ) : (
        <Container padding="0" width="100%">
          <ContainerItem padding="0" width="100%">
            <FileContainer
              fullWidth
              existingFilesLabel="Uploaded ID Front"
              uploadFilesLabel="Upload ID Front"
              fileBucket={environment.storageConfig.userDocument}
              existingS3Files={
                presenter.viewModel.idFrontDocument ? [presenter.viewModel.idFrontDocument] : []
              }
              onRemoveExistingFile={async () => {
                setIdFrontRemoved(true);
                await presenter.deleteFrontIdDoc();
              }}
              acceptedTypes="imagesAndPdfs"
              multiple={false}
              onNewFilesChange={(file) => setIdFront(file[0])}
              dataCy="id-front"
            />
          </ContainerItem>
          <ContainerItem padding="0" width="100%">
            <FileContainer
              fullWidth
              existingFilesLabel="Uploaded ID Back"
              uploadFilesLabel="Upload ID Back"
              fileBucket={environment.storageConfig.userDocument}
              existingS3Files={presenter.viewModel.idBackDocument ? [presenter.viewModel.idBackDocument] : []}
              onRemoveExistingFile={async () => {
                setIdBackRemoved(true);
                await presenter.deleteBackIdDoc();
              }}
              acceptedTypes="imagesAndPdfs"
              multiple={false}
              onNewFilesChange={(file) => setIdBack(file[0])}
              dataCy="id-back"
            />
          </ContainerItem>
        </Container>
      )}
    </Dialog>
  );
});
