import { Accordion, ClickAwayListener, Popper, Slide, useMediaQuery, useTheme } from '@mui/material';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { CommentStore } from 'stores/CommentStore';

interface Props extends Record<string, unknown> {
  anchorRef: React.MutableRefObject<HTMLDivElement | null>;
  onClose?: () => void;
}

export const CommentsBarContainer: React.FC<Props> = useComponent((props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const commentStore = useInjection(CommentStore);

  const [displayBar, setDisplayBar] = React.useState(false);

  React.useEffect(() => {
    setDisplayBar(Boolean(commentStore.currentPostId) && Boolean(props.anchorRef.current));
  }, [commentStore.currentPostId, props.anchorRef.current]);

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        // allow override for situations such as
        // a drawer being open and clicking inside the drawer
        if (props.onClose) {
          props.onClose();
        } else {
          commentStore.openComments(false);
        }
      }}
    >
      <Popper
        transition
        anchorEl={props.anchorRef.current}
        id={'comments-bar-popper'}
        open={displayBar}
        style={{
          zIndex: theme.zIndex.drawer + 1,
          boxShadow:
            '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
        }}
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} direction="up" in={displayBar} timeout={350}>
            <Accordion
              disableGutters
              elevation={0}
              expanded={commentStore.commentsOpen}
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderBottom: 0,
                borderRight: 0,
                width: commentStore.commentsOpen ? (isMobile ? '340px' : '384px') : 'auto',
                '&.MuiAccordion-root': {
                  borderRadius: '0px',
                  boxShadow: 'none',
                  padding: '0px'
                },
                '& .MuiAccordionSummary-root': {
                  padding: 0,
                  display: 'inline-block'
                },
                '& .MuiAccordionDetails-root': {
                  padding: '0 0 0 2px',
                  width: '100%',
                  height: isMobile ? '40vh' : '60vh',
                  overflow: 'auto'
                }
              }}
            >
              {props.children ?? <></>}
            </Accordion>
          </Slide>
        )}
      </Popper>
    </ClickAwayListener>
  );
});
